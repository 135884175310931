import React from 'react';

const IconSend = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.00950893 1L0 7.99998L14.2857 10L0 12L0.00950893 19L20 10L0.00950893 1Z" fill="currentColor" />
        </svg>
    );
};

export default IconSend;
