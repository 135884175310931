import React, { memo, useMemo, useState } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { Select } from '@components/Select';
import { Skeleton } from '@components/Skeleton';
import { Form } from '@components/Form';
import { Box } from '@components/Box';
import { Link } from '@components/Link';
import message from '@components/Message';
import { ALL_ACTIVE_WORKERS } from '@graphql/queries/user';
import { ASSIGN_ORDER_WORKERS } from '@graphql/mutations/order';
import { MEMBER_INFO } from '@constants/routes';
import { useLocation } from 'react-router-dom';
import {
    getStrings,
    mapDesignerIdsToValues,
    mapValuesToDesignerIds,
    parseDesignerOptions,
} from '@pages/Admin/Requests/blocks/DetailRequest/designer-field-lib';

const RowDesignerField = memo(({ form, requestId, designerIds, requestStatus }) => {
    const { getFieldDecorator } = form;
    const { loading, data, refetch } = useQuery(ALL_ACTIVE_WORKERS, {
        fetchPolicy: 'network-only',
    });
    const location = useLocation();
    const [isSaving, setIsSaving] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [assignOrderWorkers] = useMutation(ASSIGN_ORDER_WORKERS);

    const designersResult = useMemo(() => parseDesignerOptions(Array.isArray(data?.allUsers) ? data.allUsers : []), [
        data,
    ]);

    const designerIdValues = useMemo(
        () => mapDesignerIdsToValues(getStrings(designerIds), designersResult.idToDataMap),
        [designerIds, designersResult.idToDataMap]
    );

    if (loading) {
        return <Skeleton w="100%" h="38" />;
    }

    const options = designersResult.array.map(({ data: designer, value }) => {
        return (
            <Select.Option key={designer.id} value={value}>
                <Box
                    as={isOpen ? 'div' : Link}
                    to={{
                        pathname: MEMBER_INFO.replace(':id', designer.id ?? ''),
                        state: { previousPage: location.pathname },
                    }}
                    maxW="221"
                    d="inline-flex"
                >
                    <Box d="flex" alignItems="center" colorScheme="cta">
                        <Box isTruncate maxW="185">
                            {designer.firstname} {designer.lastname?.[0]}
                        </Box>
                        <Box>&nbsp;({designer._workProjectsMeta?.count})</Box>
                    </Box>
                </Box>
            </Select.Option>
        );
    });

    const handleChange = async vals => {
        const ids = mapValuesToDesignerIds(getStrings(vals), designersResult.valueToIdMap);

        try {
            message.destroy();
            message.loading('Updating designer(s)...', 50000);
            setIsSaving(true);
            setIsOpen(false);

            await assignOrderWorkers({ variables: { id: +requestId, workersIds: ids } })
                .then(async () => {
                    await refetch();
                    message.destroy();
                    message.success('Designer(s) has been updated');
                    setIsSaving(false);
                })
                .catch(err => {
                    console.log(err);
                    setIsSaving(false);
                    message.destroy();
                    message.error('Error on updating designer(s)');
                });
        } catch (e) {
            console.log(e);
            setIsSaving(false);
            setIsOpen(false);
            message.destroy();
            message.error('Error on updating designer(s)');
        }
    };

    const handleDropdownVisibleChange = bool => {
        setIsOpen(bool);
    };

    return (
        <Box>
            <Form>
                <Form.Item label="" colon={false} required={false} style={{ marginBottom: -5 }}>
                    {getFieldDecorator('assignedDesigners', {
                        initialValue: designerIdValues,
                    })(
                        <Select
                            mode="multiple"
                            placeholder="Select Designer(s)"
                            dropdownMenuStyle={{ width: 278 }}
                            dropdownMatchSelectWidth={false}
                            showArrow
                            style={{ maxHeight: 80, overflowY: 'auto', overflowX: 'hidden' }}
                            onChange={handleChange}
                            disabled={requestStatus === 'DRAFT' || isSaving}
                            loading={isSaving}
                            onDropdownVisibleChange={handleDropdownVisibleChange}
                        >
                            {options}
                        </Select>
                    )}
                </Form.Item>
            </Form>
        </Box>
    );
});

export default Form.create()(RowDesignerField);
