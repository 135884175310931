import React from 'react';

const IconPlay = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.5799 19.85C2.47884 19.8518 2.37898 19.8277 2.2899 19.78C2.19669 19.7227 2.12059 19.6414 2.06957 19.5447C2.01855 19.4479 1.99448 19.3392 1.9999 19.23L1.6499 0.829952C1.65464 0.715575 1.68951 0.604479 1.75096 0.507901C1.81242 0.411323 1.8983 0.332688 1.9999 0.279952C2.09533 0.22366 2.20411 0.19397 2.3149 0.19397C2.4257 0.19397 2.53447 0.22366 2.6299 0.279952L17.9999 8.26995C18.098 8.31822 18.1813 8.39195 18.2411 8.48342C18.3009 8.5749 18.335 8.68077 18.3399 8.78995C18.3462 8.89855 18.3247 9.00696 18.2775 9.10494C18.2302 9.20292 18.1588 9.28725 18.0699 9.34995L2.9399 19.74C2.83513 19.8149 2.70869 19.8536 2.5799 19.85ZM2.9299 1.84995L3.1999 18L16.4899 8.90995L2.9299 1.84995Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconPlay;
