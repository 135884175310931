import React from 'react'

const Icon = () => {
  return (
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M57.156 65.835H8.844V9.37201L19.47 0.165009H57.156V65.835Z" fill="#F4B931"/>
      <path d="M8.844 9.37201H19.47V0.165009L8.844 9.37201Z" fill="#F9DB8C"/>
      <path d="M40.524 28.578H24.882C23.0412 28.578 21.549 30.0702 21.549 31.911V46.992C21.549 48.8328 23.0412 50.325 24.882 50.325H40.524C42.3648 50.325 43.857 48.8328 43.857 46.992V31.911C43.857 30.0702 42.3648 28.578 40.524 28.578Z" fill="white"/>
      <path d="M41.151 33.759H24.189V45.87H41.151V33.759Z" fill="#F3B62D"/>
    </svg>
  )
}

export default Icon
