import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Basepage } from '@components/Basepage';
import DocumentTitle from '@components/DocumentTitle';
import { PageContainer } from '@components/PageContainer';
import { Skeleton } from '@components/Skeleton';
import withLoggedUser from '@components/WithLoggedUser';
import { ORDER_COUNTERS } from '@graphql/queries/order';
import { UNREAD_COMPANY_ORDER_MESSAGES } from '@graphql/queries/company';
import AccountsTab from '@pages/Admin/Requests/AccountsTab';
import RequestsTab from '@pages/Admin/Requests/RequestsTab';

const WorkerRequests = ({ viewer }) => {
    const { data: orderCounter, loading: loadingOrderCounter } = useQuery(ORDER_COUNTERS, {
        variables: {
            where: {
                workers_some: { id: viewer?.id },
                status_in: ['ONGOING_PROJECT', 'SUBMITTED', 'ONGOING_REVISION'],
            },
        },
        fetchPolicy: 'network-only',
    });
    const { data: accountCounter, loading: loadingAccountCounter } = useQuery(UNREAD_COMPANY_ORDER_MESSAGES, {
        variables: {
            designer: viewer?.id,
        },
        fetchPolicy: 'network-only',
    });

    const [activeTab, setActiveTab] = useState('REQUESTS');

    const handleChangeTab = tab => {
        if (tab !== activeTab) {
            setActiveTab(tab);
        }
    };

    const isAccountsActive = activeTab === 'ACCOUNTS';

    return (
        <DocumentTitle title="Requests | ManyPixels">
            <Basepage>
                <PageContainer maxW="1232">
                    <Box mb="30">
                        <Text hide="mobile" textVariant="H3">
                            Requests
                        </Text>
                        <Text hide="desktop" textVariant="H4">
                            Requests
                        </Text>
                    </Box>
                    {loadingOrderCounter || loadingAccountCounter ? (
                        <>
                            <Box
                                d="flex"
                                alignItems="center"
                                borderW="0"
                                borderB="1"
                                borderStyle="solid"
                                borderColor="element-stroke"
                            >
                                <Skeleton w="64" h="31" mb="11" mr="27" />
                                <Skeleton w="64" h="31" mb="11" />
                            </Box>
                            <Box py="30">
                                <Box borderW="1" borderStyle="solid" borderColor="other-gray">
                                    <Box px="16" py="16" bg="#FAFAFA">
                                        <Skeleton w="100%" h="18" />
                                    </Box>
                                    <Box
                                        px="16"
                                        py="16"
                                        borderW="0"
                                        borderT="1"
                                        borderStyle="solid"
                                        borderColor="other-gray"
                                    >
                                        <Skeleton w="100%" h="18" />
                                    </Box>
                                    <Box
                                        px="16"
                                        py="16"
                                        borderW="0"
                                        borderT="1"
                                        borderStyle="solid"
                                        borderColor="other-gray"
                                    >
                                        <Skeleton w="100%" h="18" />
                                    </Box>
                                    <Box
                                        px="16"
                                        py="16"
                                        borderW="0"
                                        borderT="1"
                                        borderStyle="solid"
                                        borderColor="other-gray"
                                    >
                                        <Skeleton w="100%" h="18" />
                                    </Box>
                                    <Box
                                        px="16"
                                        py="16"
                                        borderW="0"
                                        borderT="1"
                                        borderStyle="solid"
                                        borderColor="other-gray"
                                    >
                                        <Skeleton w="100%" h="18" />
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box
                                d="flex"
                                alignItems="center"
                                borderW="0"
                                borderB="1"
                                borderStyle="solid"
                                borderColor="element-stroke"
                            >
                                <Box
                                    d="flex"
                                    mr="27"
                                    alignItems="flex-start"
                                    cursor="pointer"
                                    onClick={() => handleChangeTab('REQUESTS')}
                                    mb="-1"
                                >
                                    <Text
                                        textVariant="H6"
                                        colorScheme={!isAccountsActive ? 'cta' : 'primary'}
                                        mr="6"
                                        pb="11"
                                        pos="relative"
                                        overflow="hidden"
                                    >
                                        Requests
                                        <Box
                                            h="3"
                                            w="63.109"
                                            bg="cta"
                                            pos="absolute"
                                            bottom="0"
                                            trans="left 250ms ease-in-out"
                                            left={!isAccountsActive ? '0' : '-63.109'}
                                        />
                                    </Text>
                                    {orderCounter?.unreadOrder > 0 && (
                                        <Text
                                            textVariant="SmallTitle"
                                            colorScheme="white"
                                            bg="other-pink"
                                            radii="12"
                                            px="9"
                                            lineH="20"
                                            minW="28"
                                            textAlign="center"
                                        >
                                            {orderCounter?.unreadOrder}
                                        </Text>
                                    )}
                                </Box>
                                <Box
                                    d="flex"
                                    alignItems="flex-start"
                                    cursor="pointer"
                                    onClick={() => handleChangeTab('ACCOUNTS')}
                                    mb="-1"
                                >
                                    <Text
                                        textVariant="H6"
                                        colorScheme={isAccountsActive ? 'cta' : 'primary'}
                                        mr="6"
                                        pb="11"
                                        pos="relative"
                                        overflow="hidden"
                                    >
                                        Accounts
                                        <Box
                                            h="3"
                                            w="64.172"
                                            bg="cta"
                                            pos="absolute"
                                            bottom="0"
                                            trans="left 250ms ease-in-out"
                                            left={isAccountsActive ? '0' : '64.172'}
                                        />
                                    </Text>
                                    {accountCounter?.unreadCompanyOrderMessages > 0 && (
                                        <Text
                                            textVariant="SmallTitle"
                                            colorScheme="white"
                                            bg="other-pink"
                                            radii="12"
                                            px="9"
                                            lineH="20"
                                            minW="28"
                                            textAlign="center"
                                        >
                                            {accountCounter?.unreadCompanyOrderMessages}
                                        </Text>
                                    )}
                                </Box>
                            </Box>
                            <Box py="30">
                                <Box
                                    h={isAccountsActive ? 'auto' : '0'}
                                    overflow={isAccountsActive ? 'visible' : 'hidden'}
                                >
                                    <AccountsTab viewer={viewer} />
                                </Box>
                                <Box
                                    h={!isAccountsActive ? 'auto' : '0'}
                                    overflow={!isAccountsActive ? 'visible' : 'hidden'}
                                >
                                    <RequestsTab viewer={viewer} />
                                </Box>
                            </Box>
                        </>
                    )}
                </PageContainer>
            </Basepage>
        </DocumentTitle>
    );
};

export default withLoggedUser(WorkerRequests);
