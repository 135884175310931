import React from 'react';

const IconBrandFacebook = () => {
    return (
        <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.97311 0.912898L7.60083 0.909092C4.93566 0.909092 3.21331 2.67617 3.21331 5.41118V7.48694H0.828096C0.621985 7.48694 0.455078 7.65404 0.455078 7.86015V10.8677C0.455078 11.0738 0.622175 11.2407 0.828096 11.2407H3.21331V18.8297C3.21331 19.0358 3.38022 19.2027 3.58633 19.2027H6.69836C6.90447 19.2027 7.07138 19.0356 7.07138 18.8297V11.2407H9.86025C10.0664 11.2407 10.2333 11.0738 10.2333 10.8677L10.2344 7.86015C10.2344 7.76119 10.195 7.66641 10.1252 7.59638C10.0553 7.52634 9.96017 7.48694 9.8612 7.48694H7.07138V5.72729C7.07138 4.88153 7.27292 4.45218 8.37465 4.45218L9.97273 4.45161C10.1786 4.45161 10.3456 4.28452 10.3456 4.0786V1.28592C10.3456 1.08019 10.1788 0.913279 9.97311 0.912898Z"
                fill="#475993"
            />
        </svg>
    );
};

export default IconBrandFacebook;
