import React, { useCallback, useState } from 'react';
import { Form } from '@components/Form';
import { Box } from '@components/Box';
import { Input } from '@components/Input';
import { Button } from '@components/Button';
import coupon from '@assets/icons/coupon.svg';

const CouponForm = ({ form, handleCoupon }) => {
    const { getFieldDecorator, validateFields } = form;
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = useCallback(
        e => {
            e.preventDefault();
            validateFields(async (err, values) => {
                if (!err && !isSubmitting) {
                    setIsSubmitting(true);
                    await handleCoupon(values.coupon);
                }
                setIsSubmitting(false);
            });
        },
        [isSubmitting, validateFields, handleCoupon]
    );

    return (
        <Box mb="-10">
            <Form onSubmit={handleSubmit}>
                <Box d="flex">
                    <Box flex="1 1 0%">
                        <Form.Item>
                            {getFieldDecorator('coupon', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'This field cannot be empty',
                                    },
                                ],
                            })(<Input prefix={<img src={coupon} alt="Coupon" />} placeholder="Enter coupon" />)}
                        </Form.Item>
                    </Box>
                    <Box w="106">
                        <Form.Item>
                            <Button h={['40', '40']} type="primary" loading={isSubmitting} htmlType="submit" w="100%">
                                Apply
                            </Button>
                        </Form.Item>
                    </Box>
                </Box>
            </Form>
        </Box>
    );
};

export default Form.create()(CouponForm);
