import React from 'react';

const IconFileFolder = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.129997 17.53H14.73L17.82 4.07999L4.08 3.72999L2.97 2.20999H0.129997V17.53Z" fill="#08122A" />
            <path d="M16.27 0.920013H4.73V13.8H16.27V0.920013Z" fill="#B9E5F9" />
            <path d="M7.71 3.78H13.34" stroke="#0097F9" strokeWidth="0.5" strokeMiterlimit="10" />
            <path d="M17.23 17.58H0.129997L2.84 5.39001H19.93L17.23 17.58Z" fill="#0097F9" />
            <path d="M16.04 11.85H4.4L4.68 10.82H16.32L16.04 11.85Z" fill="#46BAF9" />
        </svg>
    );
};

export default IconFileFolder;
