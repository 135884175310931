import { css } from 'styled-components';
import * as theme from '../Theme';

export const popConfirmGlobalCss = css`
    .ant-popover-arrow {
        box-shadow: none !important;
        background: #fff;
        border: 1px solid ${theme.COLOR_OUTLINE_GRAY} !important;
        width: 24px;
        height: 19px;
        bottom: 2px !important;
        mix-blend-mode: color-dodge;
    }

    .ant-popover-title {
        padding: 16px;
        font-weight: 600;
    }

    .ant-popover-inner {
        border-radius: 0;
        border: 1px solid ${theme.COLOR_OUTLINE_GRAY};
        box-shadow: none;

        .ant-popover-inner-content {
            padding: 0;
            background: ${theme.COLOR_BACKGROUND_GRAY};
            .ant-popover-message {
                padding: 0;
                margin-bottom: 20px;

                > .anticon {
                    font-size: 21px;
                    top: 0;
                }

                .ant-popover-message-title {
                    ${theme.TYPO_P4}
                    color: ${theme.COLOR_TEXT_PRIMARY};
                    padding-left: 32.5px;
                }
            }

            .ant-popover-buttons {
                margin-bottom: 0;

                button {
                    width: 59px;
                    height: 34px;
                    padding: 0;
                    border-radius: 0;
                    margin-left: 14px;
                    ${theme.TYPO_SMALL_TITLE}
                    text-transform: uppercase;
                }
            }
        }
    }
`;
