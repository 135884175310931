import React from 'react';

const IconDownloadAlt = ({ color = 'currentColor' }) => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7647 7.05882L18.4706 7.05882L10.2353 15.2941L2 7.05882L6.70586 7.05882L6.70586 0L13.7647 0L13.7647 7.05882ZM2 17.647L18.4706 17.647V20L2 20L2 17.647Z"
                fill={color}
            />
        </svg>
    );
};

export default IconDownloadAlt;
