import React, { memo } from 'react';
import { Box } from '@components/Box';
import IconSend from '@components/Svg/IconSend';
import { Spin, Icon } from 'antd';

export const SendButtonPlugin = memo(({ isDisabled, isLoading }) => {
    return (
        <Box
            as="button"
            type="submit"
            disabled={isDisabled || isLoading}
            d="inline-flex"
            alignItems="center"
            cursor="pointer"
            appearance="none"
            outline="none"
            borderW="0"
            bg="transparent"
            px="0"
            colorScheme={isDisabled || isLoading ? 'inherit' : 'cta'}
            _disabled={{
                cursor: 'not-allowed',
                colorScheme: 'inherit',
            }}
            _hover={{ colorScheme: 'cta' }}
        >
            {isLoading ? <Spin indicator={<Icon type="loading" spin />} /> : <IconSend />}
        </Box>
    );
});
