import React from 'react';
import { Box } from '@components/Box';
import IconShieldDraft from '@components/Svg/IconShieldDraft';
import { SectionHeader } from './SectionHeader';
import { DroppableRequest } from './DroppableRequest';
import { RowItemRequest } from './RowItemRequest';
import { DropdownActionRequest } from './DropdownActionRequest';

export const SectionDraftRequest = ({
  data,
  refetch,
  isNotCustomer = false,
}) => {
  return (
    <Box pt="30">
      <SectionHeader
        title="Draft"
        total={Array.isArray(data) ? data.length : 0}
      />
      {Array.isArray(data) && (
        <DroppableRequest name="draft">
          {data.map((item, index) => (
            <RowItemRequest
              key={item.id}
              owners={item.owners}
              id={item.id}
              index={index}
              enableDrag={false}
              enableDrop={false}
              name={item.name}
              category={item.category}
              workers={item.workers}
              brand={item.brand}
              date={item.status === 'SUBMITTED' ? item.submittedAt : item.updatedAt}
              dateLabel={item.status === 'SUBMITTED' ? "Submitted" : "Last Updated"}
              status={item.status}
              iconProps={{
                colorScheme: '#9098A9',
                borderColor: '#9098A9',
                bg: 'badge-gray',
                pt: '1',
                pl: '1',
              }}
              unreadMessages={item.unreadMessages}
              icon={
                <Box transform="scale(1)">
                  <IconShieldDraft />
                </Box>
              }
              optionComponent={DropdownActionRequest}
              optionProps={{
                id: item.id,
                enableSubmit: true,
                enableDuplicate: true,
                enableDelete: true,
                refetch,
                status: item.status,
                name: item.name,
                description: item.description,
                service: item.service,
                category: item.category,
                deliverables: item.deliverables,
                otherDeliverables: item.otherDeliverables,
              }}
              isNotCustomer={isNotCustomer}
            />
          ))}
        </DroppableRequest>
      )}
    </Box>
  );
};
