import React from 'react';

const IconFilePng = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.32 19.95H2.68V2.83999L6.09 0.0499878H17.32V19.95Z" fill="#E6E6E4" />
            <path d="M13.18 5.92999H6.7V11.58H13.18V5.92999Z" fill="white" />
            <path opacity="0.26" d="M7.99 5.92999H6.7V7.21999H7.99V5.92999Z" fill="#31B89A" />
            <path opacity="0.26" d="M10.58 5.92999H9.29V7.21999H10.58V5.92999Z" fill="#31B89A" />
            <path opacity="0.26" d="M13.17 5.92999H11.88V7.21999H13.17V5.92999Z" fill="#31B89A" />
            <path opacity="0.26" d="M9.29 7.20999H8V8.49999H9.29V7.20999Z" fill="#31B89A" />
            <path opacity="0.26" d="M11.88 7.20999H10.59V8.49999H11.88V7.20999Z" fill="#31B89A" />
            <path opacity="0.26" d="M7.99 8.48999H6.7V9.77999H7.99V8.48999Z" fill="#31B89A" />
            <path d="M10.58 8.48999H9.29V9.77999H10.58V8.48999Z" fill="#C6C6C4" />
            <path d="M13.17 8.48999H11.88V9.77999H13.17V8.48999Z" fill="#C6C6C4" />
            <path d="M6.7 9.46999L9.71 7.73999L11.22 8.71999L13.18 7.81999V11.58H6.7V9.46999Z" fill="#31B89A" />
            <path
                d="M4.4 14H5.83C6.02162 13.9991 6.21277 14.0192 6.4 14.06C6.56564 14.088 6.72286 14.153 6.86 14.25C6.99913 14.341 7.10999 14.4692 7.18 14.62C7.26704 14.8005 7.30825 14.9997 7.3 15.2C7.30407 15.3989 7.26661 15.5964 7.19 15.78C7.12463 15.9278 7.02108 16.0555 6.89 16.15C6.75387 16.2443 6.60122 16.3122 6.44 16.35C6.25277 16.3908 6.06162 16.4109 5.87 16.41H5.25V17.94H4.4V14ZM5.25 15.67H5.82H6.04C6.10724 15.6538 6.17141 15.6268 6.23 15.59C6.28548 15.5563 6.33049 15.5078 6.36 15.45C6.40227 15.3809 6.42315 15.3009 6.42 15.22C6.41452 15.1421 6.39052 15.0667 6.35 15C6.29917 14.9426 6.23814 14.8951 6.17 14.86C6.09648 14.8294 6.01908 14.8093 5.94 14.8H5.69H5.25V15.67Z"
                fill="#08122A"
            />
            <path d="M7.83 14H9L10.64 16.74V14H11.49V17.94H10.37L8.69 15.14V17.94H7.83V14Z" fill="#08122A" />
            <path
                d="M15.75 17.66C15.5179 17.8093 15.2652 17.9238 15 18C14.7274 18.0601 14.4491 18.0902 14.17 18.09C13.8841 18.0915 13.6 18.0442 13.33 17.95C13.0852 17.8534 12.8612 17.7109 12.67 17.53C12.4874 17.3421 12.3415 17.1216 12.24 16.88C12.1367 16.6052 12.0859 16.3135 12.09 16.02C12.0868 15.7265 12.1376 15.435 12.24 15.16C12.3415 14.9184 12.4874 14.6979 12.67 14.51C12.8442 14.2999 13.0583 14.1266 13.3 14C13.57 13.9058 13.8541 13.8585 14.14 13.86C14.4316 13.8684 14.7208 13.9155 15 14C15.2552 14.0775 15.4883 14.2146 15.68 14.4L15.08 15.06C14.9723 14.9414 14.8389 14.8491 14.69 14.79C14.5194 14.7177 14.3351 14.6835 14.15 14.69C13.978 14.6855 13.8071 14.7197 13.65 14.79C13.5097 14.8496 13.3836 14.9382 13.28 15.05C13.1706 15.1651 13.0856 15.3012 13.03 15.45C12.9775 15.6149 12.9506 15.7869 12.95 15.96C12.9489 16.1332 12.9759 16.3054 13.03 16.47C13.0856 16.6188 13.1706 16.7548 13.28 16.87C13.3858 16.9818 13.5112 17.0734 13.65 17.14C13.8092 17.2023 13.9791 17.2329 14.15 17.23C14.3088 17.233 14.4671 17.2128 14.62 17.17C14.7344 17.1344 14.8449 17.0875 14.95 17.03V16.4H14.25V15.6H15.76L15.75 17.66Z"
                fill="#08122A"
            />
            <path d="M2.68 2.83999H5.9V0.0499878L2.68 2.83999Z" fill="#AFAFAC" />
        </svg>
    );
};

export default IconFilePng;
