import React from 'react';

const IconFilePdf = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.32 19.95H2.68V2.83999L6.09 0.0499878H17.32V19.95Z" fill="#E6E6E4" />
            <path d="M17.32 13.31H2.69V19.95H17.32V13.31Z" fill="#EB222D" />
            <path
                d="M5.29 14.73H6.73C6.91636 14.7148 7.10365 14.7148 7.29 14.73C7.45686 14.7644 7.6161 14.8288 7.76 14.92C7.89075 15.0134 7.99709 15.1369 8.07 15.28C8.15621 15.4573 8.19742 15.653 8.19 15.85C8.19429 16.0424 8.15676 16.2335 8.08 16.41C8.01731 16.5594 7.91322 16.6878 7.78 16.78C7.64335 16.8712 7.49066 16.9357 7.33 16.97C7.14285 17.0113 6.95165 17.0314 6.76 17.03H6.14V18.53H5.29V14.73ZM6.14 16.36H6.71H6.93C6.99724 16.3438 7.06141 16.3168 7.12 16.28C7.17822 16.2465 7.22653 16.1982 7.26 16.14C7.27703 16.0677 7.27703 15.9924 7.26 15.92C7.26095 15.8348 7.23659 15.7513 7.19 15.68C7.14406 15.6176 7.08176 15.5692 7.01 15.54C6.9369 15.5082 6.85931 15.488 6.78 15.48H6.14V16.36Z"
                fill="white"
            />
            <path
                d="M8.72 14.73H10C10.293 14.727 10.5853 14.7606 10.87 14.83C11.1339 14.8927 11.3822 15.0084 11.6 15.17C11.8121 15.3247 11.9808 15.5313 12.09 15.77C12.2174 16.049 12.279 16.3535 12.27 16.66C12.2798 16.9462 12.2215 17.2307 12.1 17.49C11.9859 17.7227 11.822 17.9276 11.62 18.09C11.415 18.2528 11.1808 18.3749 10.93 18.45C10.666 18.5393 10.3887 18.5833 10.11 18.58H8.72V14.73ZM9.57 17.8H10C10.185 17.8015 10.3696 17.7813 10.55 17.74C10.7081 17.7021 10.8575 17.6342 10.99 17.54C11.1131 17.4399 11.2122 17.3135 11.28 17.17C11.3531 16.9962 11.3873 16.8085 11.38 16.62C11.3858 16.4511 11.3516 16.2832 11.28 16.13C11.2115 15.9926 11.1123 15.8729 10.99 15.78C10.8648 15.6859 10.722 15.6179 10.57 15.58C10.4045 15.5309 10.2326 15.5073 10.06 15.51H9.56L9.57 17.8Z"
                fill="white"
            />
            <path d="M12.88 14.73H15.47V15.51H13.73V16.29H15.34V17.08H13.73V18.58H12.88V14.73Z" fill="white" />
            <path d="M2.68 2.83999H5.9V0.0499878L2.68 2.83999Z" fill="#AFAFAC" />
            <path d="M14.44 5.56H5.62V6.59H14.44V5.56Z" fill="#AFAFAC" />
            <path d="M14.44 8.85001H5.62V9.88001H14.44V8.85001Z" fill="#AFAFAC" />
        </svg>
    );
};

export default IconFilePdf;
