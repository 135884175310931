import React from 'react';

const IconShieldDraft = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 7.91652V10.0001H2.08362L8.23168 3.85205L6.14806 1.76843L0 7.91652Z" fill="currentColor" />
      <path
        d="M9.83727 1.45992L8.53985 0.162522C8.32316 -0.0541741 7.97032 -0.0541741 7.75362 0.162522L6.73682 1.17933L8.82044 3.26295L9.83724 2.24614C10.054 2.02945 10.054 1.67661 9.83727 1.45992Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconShieldDraft;
