import gql from 'graphql-tag';

export const ADD_SERVICE = gql`
    mutation AddService(
        $name: String!
        $type: String!
        $howToFillUpLink: String
        $deliverables: [String!]!
        $productImageId: String!
        $questions: [QuestionInput!]
    ) {
        createService(
            name: $name
            type: $type
            howToFillUpLink: $howToFillUpLink
            deliverables: $deliverables
            productImageId: $productImageId
            questions: $questions
        ) {
            id
            name
            type {
                id
                name
            }
            howToFillUpLink
            deliverables
            icon {
                id
                secret
                url
            }
            questions {
                id
            }
        }
    }
`;

export const UPDATE_SERVICE = gql`
    mutation updateService(
        $id: ID!
        $name: String!
        $type: String!
        $howToFillUpLink: String
        $deliverables: [String!]!
        $isActivated: Boolean
        $productImageId: String
    ) {
        updateService(
            id: $id
            name: $name
            type: $type
            howToFillUpLink: $howToFillUpLink
            deliverables: $deliverables
            isActivated: $isActivated
            productImageId: $productImageId
        ) {
            id
            name
            type {
                id
                name
            }
            howToFillUpLink
            deliverables
            icon {
                id
                secret
                url
            }
            questions {
                id
            }
        }
    }
`;

export const SET_SERVICE_ICON = gql`
    mutation SetCategoryIcon($serviceServiceId: ID!, $iconFileId: ID!) {
        setServiceIcon(serviceServiceId: $serviceServiceId, iconFileId: $iconFileId) {
            iconFile {
                id
            }
            serviceService {
                id
            }
        }
    }
`;
