import React from 'react';
import { Icon } from 'antd';

const UploadSvg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.36434 6.58821H1.59961L8.18787 0L14.7761 6.58822L11.0114 6.58821L11.0114 12.2353L5.36434 12.2353L5.36434 6.58821ZM1.59961 14.1176H14.7761V16H1.59961V14.1176Z" fill="#009DFF" />
  </svg>
);

const UploadIcon = (props) => <Icon component={UploadSvg} {...props} />;

export default UploadIcon;
