import React from 'react';

const IconFilePpt = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.32 19.95H2.68V2.83999L5.9 0.0499878H17.32V19.95Z" fill="#E15F33" />
            <path d="M14.98 6.07999H5.48V12.49H14.98V6.07999Z" fill="white" />
            <path d="M2.68 2.83999H5.9V0.0499878L2.68 2.83999Z" fill="#E6E6E4" />
            <path
                d="M5.94 14.27H7.25C7.41964 14.2544 7.59036 14.2544 7.76 14.27C7.91071 14.301 8.05358 14.3623 8.18 14.45C8.30147 14.5312 8.40107 14.6411 8.47 14.77C8.54712 14.9322 8.58482 15.1104 8.58 15.29C8.58276 15.4651 8.54869 15.6389 8.48 15.8C8.41598 15.9317 8.32381 16.0478 8.21 16.14C8.08291 16.2244 7.94004 16.2823 7.79 16.31C7.62066 16.3312 7.44934 16.3312 7.28 16.31H6.72V17.68H5.94V14.27ZM6.72 15.75H7.23H7.43C7.49071 15.738 7.54846 15.7142 7.59994 15.6799C7.65142 15.6456 7.69562 15.6014 7.73 15.55C7.74693 15.4844 7.74693 15.4156 7.73 15.35C7.73299 15.2722 7.71205 15.1955 7.67 15.13C7.6402 15.0782 7.5992 15.0338 7.55 15C7.48388 14.9683 7.41288 14.948 7.34 14.94H6.72V15.75Z"
                fill="white"
            />
            <path
                d="M9.06 14.27H10.36C10.533 14.2544 10.707 14.2544 10.88 14.27C11.0307 14.301 11.1736 14.3623 11.3 14.45C11.4215 14.5312 11.5211 14.6411 11.59 14.77C11.6633 14.9332 11.6976 15.1112 11.69 15.29C11.6956 15.4644 11.665 15.6381 11.6 15.8C11.5352 15.9341 11.4392 16.0507 11.32 16.14C11.1978 16.2267 11.0577 16.2847 10.91 16.31C10.7406 16.3307 10.5694 16.3307 10.4 16.31H9.83V17.68H9.06V14.27ZM9.83 15.75H10.35H10.55C10.6118 15.7418 10.6703 15.7176 10.72 15.68C10.7728 15.6473 10.8173 15.6028 10.85 15.55C10.8669 15.4844 10.8669 15.4156 10.85 15.35C10.853 15.2722 10.8321 15.1955 10.79 15.13C10.7477 15.0771 10.6926 15.0358 10.63 15.01C10.5639 14.9783 10.4929 14.958 10.42 14.95H9.83V15.75Z"
                fill="white"
            />
            <path d="M12.86 15H11.86V14.32H14.63V15H13.63V17.82H12.86V15Z" fill="white" />
            <path
                d="M13.69 9.59001C13.6541 10.0463 13.4419 10.4706 13.0984 10.7731C12.7549 11.0755 12.3071 11.2322 11.85 11.21C11.6092 11.2024 11.3723 11.1466 11.1535 11.0458C10.9346 10.945 10.7382 10.8012 10.576 10.6231C10.4137 10.445 10.2888 10.2361 10.2088 10.0088C10.1288 9.78154 10.0952 9.54051 10.11 9.30001C10.1308 8.87326 10.3071 8.46886 10.6055 8.16308C10.9039 7.85729 11.3039 7.67125 11.73 7.64001C11.73 7.71001 11.73 7.79001 11.73 7.86001C11.73 8.35001 11.73 8.86001 11.73 9.33001C11.73 9.53001 11.73 9.60002 11.98 9.60002C12.43 9.60002 12.87 9.60002 13.31 9.60002L13.69 9.59001Z"
                fill="#E15F33"
            />
            <path
                d="M14 9.20999H12.1V7.35999C12.588 7.38308 13.0507 7.58399 13.4007 7.92481C13.7507 8.26563 13.9639 8.72277 14 9.20999Z"
                fill="#E15F33"
            />
            <path
                d="M8 8.15001H6.67C6.52 8.15001 6.47 8.15001 6.47 7.95001C6.47 7.49001 6.47 7.48001 6.94 7.48001H9.32C9.5 7.48001 9.56 7.48001 9.56 7.72001C9.56 8.15001 9.56 8.15001 9.11 8.15001H8Z"
                fill="#E15F33"
            />
            <path
                d="M8 8.93001H9.35C9.51 8.93001 9.56 8.98001 9.55 9.13001C9.55 9.68001 9.62 9.59001 9.09 9.59001H6.71C6.54 9.59001 6.46 9.59001 6.47 9.36001C6.47 8.92001 6.47 8.92001 6.9 8.92001L8 8.93001Z"
                fill="#E15F33"
            />
            <path
                d="M8 10.37C8.44 10.37 8.88 10.37 9.32 10.37C9.47 10.37 9.54 10.37 9.53 10.56C9.53 11.14 9.59 11.03 9.07 11.04H6.72C6.54 11.04 6.46 11.04 6.47 10.8C6.47 10.3 6.4 10.38 6.9 10.37H8Z"
                fill="#E15F33"
            />
        </svg>
    );
};

export default IconFilePpt;
