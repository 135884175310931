import React, { useState } from 'react';
import { Popup } from '@components/Popup';
import { Form } from '@components/Form';
import { Button } from '@components/Button';
import { Box } from '@components/Box';
import { Input } from '@components/Input';
import message from '@components/Message';
import { Select } from '@components/Select';
import { useMutation, useQuery } from 'react-apollo';
import { ALL_CUSTOMERS_BY_COMPANIES } from '@graphql/queries/company';
import { REGISTER_USER } from '@graphql/mutations/auth';
import { useCustomerPopupContext } from './CustomerPopupContext';

const CustomerPopupAddUser = ({ form, refetch }) => {
    const { isAdding, setAdding } = useCustomerPopupContext();
    const { getFieldDecorator, validateFields } = form;
    const [isLoading, setLoading] = useState(false);
    const [signupUser] = useMutation(REGISTER_USER);
    const [confirmDirty, setConfirmDirty] = useState('');

    const { data, loading } = useQuery(ALL_CUSTOMERS_BY_COMPANIES, {
        variables: {
            keyword: '',
            plan: 'ALL',
            status: 'ALL',
            team: 'ALL',
        },
    });

    const onClose = () => {
        setAdding(false);
    };

    const handleSubmit = ev => {
        ev.preventDefault();
        validateFields(async (err, values) => {
            if (!err && !isLoading) {
                setLoading(true);
                message.loading('Adding user...', 2000);
                try {
                    await signupUser({
                        variables: {
                            email: values.email,
                            password: values.password,
                            firstname: values.firstname,
                            lastname: values.lastname,
                            invitation: true,
                            referrer: null,
                            companyId: values.companyId,
                            companyRole: 'MEMBER',
                            withEmailSent: true,
                        },
                    });

                    message.destroy();
                    message.success('Invitation has been sent');

                    setLoading(false);
                    setAdding(false);
                    refetch();
                    return true;
                } catch (error) {
                    message.destroy();
                    const errors = error.graphQLErrors || [];
                    const formErrorMessage = errors.length > 0 ? errors[0].message : 'Error on sending invitation';
                    message.error(formErrorMessage);
                    setLoading(false);
                    return false;
                }
            }
        });
    };

    const handleConfirmBlur = e => setConfirmDirty(confirmDirty || !!e.target.value);

    const handleCheckPassword = (rule, value, callback) => {
        if (value && confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    const handleComparePassword = (rule, value, callback) => {
        if (value && value !== form.getFieldValue('password')) {
            callback('Passwords do not match');
        } else {
            callback();
        }
    };

    const accounts = data?.allCustomersByCompanies.data ?? [];

    return (
        <Popup
            visible={isAdding}
            onCancel={onClose}
            title="Add user"
            variant="default"
            width={500}
            footer={null}
            destroyOnClose
        >
            <Form onSubmit={handleSubmit}>
                <Form.Item label="Account" colon={false} required={false} style={{ marginBottom: 20 }}>
                    {getFieldDecorator('companyId', {
                        rules: [{ required: true, message: 'Please select account' }],
                    })(
                        <Select loading={loading} mode="default" placeholder="Choose account">
                            {accounts?.map(account => (
                                <Select.Option key={account.id} value={account.id}>
                                    {account.name}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="First name" colon={false} required={false} style={{ marginBottom: 20 }}>
                    {getFieldDecorator('firstname', {
                        rules: [{ required: true, message: 'Please enter first name' }],
                    })(<Input placeholder="Enter first name" />)}
                </Form.Item>
                <Form.Item label="Last name" colon={false} required={false} style={{ marginBottom: 20 }}>
                    {getFieldDecorator('lastname', {
                        rules: [{ required: true, message: 'Please enter last name' }],
                    })(<Input placeholder="Enter last name" />)}
                </Form.Item>
                <Form.Item label="Email" colon={false} required={false} style={{ marginBottom: 20 }}>
                    {getFieldDecorator('email', {
                        rules: [{ required: true, message: 'Please enter email address' }],
                    })(<Input type="email" placeholder="Enter email address" />)}
                </Form.Item>
                <Form.Item label="Password" colon={false} required={false} style={{ marginBottom: 20 }}>
                    {getFieldDecorator('password', {
                        rules: [
                            { required: true, message: 'Please enter password' },
                            { validator: handleCheckPassword, message: 'Passwords do not match' },
                        ],
                    })(<Input type="password" placeholder="Enter password" />)}
                </Form.Item>
                <Form.Item label="Confirm Password" colon={false} required={false}>
                    {getFieldDecorator('confirm', {
                        rules: [
                            { required: true, message: 'Please enter confirmation password' },
                            {
                                validator: handleComparePassword,
                                message: 'Passwords do not match',
                            },
                        ],
                    })(<Input type="password" placeholder="Enter password" onBlur={handleConfirmBlur} />)}
                </Form.Item>
                <Box textAlign="right">
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                        SEND INVITATION
                    </Button>
                </Box>
            </Form>
        </Popup>
    );
};

export default Form.create()(CustomerPopupAddUser);
