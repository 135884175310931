import React from 'react';
import { Box } from '@components/Box';
import IconShieldDelivered from '@components/Svg/IconShieldDelivered';
import { DroppableRequest } from './DroppableRequest';
import { RowItemRequest } from './RowItemRequest';
import { DropdownActionRequest } from './DropdownActionRequest';

export const SectionDeliveredRequest = ({
  data,
  refetch,
  isNotCustomer = false,
}) => {

  return (
    <Box pt="10">
      {Array.isArray(data) && (
        <DroppableRequest name="delivered">
          {data.map((item, index) => (
            <RowItemRequest
              key={item.id}
              owners={item.owners}
              id={item.id}
              index={index}
              name={item.name}
              category={item.category}
              date={item.status === 'SUBMITTED' ? item.submittedAt : item.updatedAt}
              dateLabel={item.status === 'SUBMITTED' ? "Submitted" : "Last Updated"}
              status={item.status}
              brand={item.brand}
              workers={item.workers}
              unreadMessages={item.unreadMessages}
              iconProps={{
                colorScheme: '#009846',
                borderColor: '#C7CBD4',
                pt: '1',
                pl: '1',
              }}
              icon={<IconShieldDelivered />}
              optionComponent={DropdownActionRequest}
              optionProps={{
                id: item.id,
                enableComplete: true,
                enableDownload: true,
                enableDuplicate: true,
                enableDelete: true,
                refetch,
                status: item.status,
                lastFolder: Number.isFinite(item?.previews?.length)
                  ? item.previews[item.previews.length - 1]
                  : null,
                requestName: item.name,
              }}
              isNotCustomer={isNotCustomer}
            />
          ))}
        </DroppableRequest>
      )}
    </Box>
  );
};
