import React from 'react';

const IconFileFigma = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.32 19.95H2.68V2.83999L5.9 0.0499878H17.32V19.95Z" fill="#333333" />
            <path d="M2.68 2.83999H5.9V0.0499878L2.68 2.83999Z" fill="#AFAFAC" />
            <path
                d="M13.48 7.4V7.61C13.4897 7.69974 13.4897 7.79026 13.48 7.88C13.3976 8.17748 13.2276 8.44332 12.9923 8.64303C12.7569 8.84274 12.4669 8.96711 12.16 9H12H10.41V6H12.05H12.29C12.621 6.07276 12.9173 6.25618 13.13 6.52C13.3139 6.73829 13.4287 7.0063 13.46 7.29C13.46 7.29 13.47 7.37 13.48 7.4Z"
                fill="#FE7262"
            />
            <path
                d="M10.42 10.67C10.42 10.82 10.42 10.96 10.42 11.1V12.1H8.78H8.62C8.2988 12.0421 8.00408 11.8843 7.77783 11.6491C7.55158 11.4139 7.40536 11.1132 7.36 10.79C7.36493 10.7569 7.36493 10.7232 7.36 10.69V10.49C7.36468 10.4703 7.36468 10.4497 7.36 10.43C7.38067 10.2495 7.43137 10.0738 7.51 9.91001C7.64207 9.63277 7.85931 9.40502 8.13 9.26001C8.29634 9.16305 8.47959 9.09857 8.67 9.07001H10.41V10.7L10.42 10.67Z"
                fill="#A159FE"
            />
            <path
                d="M10.41 9H8.8H8.62C8.37069 8.95888 8.13568 8.85585 7.93652 8.70036C7.73735 8.54487 7.58038 8.34188 7.48 8.11C7.40958 7.94863 7.36892 7.77584 7.36 7.6V7.4C7.35505 7.35012 7.35505 7.29988 7.36 7.25C7.4238 6.88848 7.61546 6.56195 7.9 6.33C8.12168 6.14535 8.39311 6.03052 8.68 6H8.79H10.41V9Z"
                fill="#F14E1E"
            />
            <path
                d="M8.8 12.1H10.41C10.41 12.61 10.41 13.1 10.41 13.63C10.4068 13.976 10.2864 14.3108 10.0685 14.5796C9.85055 14.8484 9.54792 15.0354 9.21 15.11C9.13704 15.1204 9.06296 15.1204 8.99 15.11H8.78C8.56439 15.1214 8.349 15.0838 8.15 15C7.93783 14.8856 7.75569 14.7228 7.6184 14.5247C7.48112 14.3266 7.39258 14.0988 7.36 13.86C7.36541 13.8269 7.36541 13.7931 7.36 13.76V13.55C7.36493 13.5169 7.36493 13.4832 7.36 13.45C7.40502 13.1234 7.55367 12.8199 7.78409 12.584C8.01451 12.3482 8.31452 12.1926 8.64 12.14L8.8 12.1Z"
                fill="#0ACE83"
            />
            <path
                d="M10.42 10.67V10.46C10.4151 10.3968 10.4151 10.3332 10.42 10.27C10.482 9.96912 10.6321 9.69347 10.8511 9.47803C11.0701 9.26259 11.3481 9.11709 11.65 9.06H11.84H12.04H12.22C12.5348 9.12346 12.8217 9.2844 13.04 9.52C13.2626 9.74802 13.4033 10.0435 13.44 10.36C13.44 10.36 13.44 10.43 13.44 10.47V10.68C13.44 10.68 13.44 10.74 13.44 10.77C13.3999 11.0829 13.264 11.3758 13.051 11.6086C12.838 11.8413 12.5582 12.0024 12.25 12.07C12.0095 12.1223 11.7599 12.115 11.5228 12.0488C11.2858 11.9825 11.0686 11.8594 10.89 11.69C10.6284 11.4564 10.4583 11.1374 10.41 10.79C10.4084 10.7497 10.4117 10.7094 10.42 10.67Z"
                fill="#1ABBFD"
            />
        </svg>
    );
};

export default IconFileFigma;
