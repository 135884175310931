import React, { useState, memo, useCallback } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { Form } from '@components/Form';
import { Input } from '@components/Input';
import { Button } from '@components/Button';
import { Popup } from '@components/Popup';
import { Box } from '@components/Box';
import message from '@components/Message';
import ProductsField from '@pages/Admin/Settings/Plans/blocks/ProductsField';
import { ALL_ACTIVATED_SERVICES } from '@graphql/queries/service';
import { UPLOAD_FILE } from '@graphql/mutations/file';
import CategoryImageUploader from '../../blocks/CategoryImageUploader';

const AddCategory = memo(({ visible, onCancel, onAdd, refetchCategories, form }) => {
    const { getFieldDecorator, validateFields, resetFields } = form;
    const { loading, data } = useQuery(ALL_ACTIVATED_SERVICES, {
        fetchPolicy: 'network-only',
    });
    const [uploadFile] = useMutation(UPLOAD_FILE);
    const [isLoading, setIsLoading] = useState(null);

    const onCloseModal = () => {
        resetFields();
        onCancel();
    };

    const handleSubmit = useCallback(
        async e => {
            e.preventDefault();
            validateFields(async (err, values) => {
                if (!err && !isLoading) {
                    setIsLoading(true);

                    try {
                        message.destroy();
                        message.loading('Adding category...', 50000);
                        await uploadFile({ variables: { file: values.categoryImage } })
                            .then(async ({ data }) => {
                                await onAdd({
                                    title: values.title,
                                    servicesIds: values.services,
                                    categoryImageId: data.uploadFile.id,
                                });
                                message.destroy();
                                message.success('Category successfully added');
                                await refetchCategories();
                                resetFields();
                                setIsLoading(false);
                                onCancel();
                                return true;
                            })
                            .catch(err => {
                                console.log(err);
                                setIsLoading(false);
                                message.destroy();
                                message.error('Error on uploading your image');
                                return false;
                            });
                    } catch (e) {
                        message.destroy();
                        setIsLoading(false);
                        console.error(e);
                        return false;
                    }
                }
            });
        },
        [isLoading, validateFields, onAdd, onCancel, refetchCategories, uploadFile, resetFields]
    );

    return (
        <Popup
            variant="default"
            width={900}
            title="Add category"
            visible={visible}
            onCancel={onCloseModal}
            footer={null}
            centered
            destroyOnClose
        >
            <Form onSubmit={handleSubmit}>
                <Form.Item label="Name" colon={false} required={false}>
                    {getFieldDecorator('title', {
                        rules: [
                            {
                                required: true,
                                message: 'This field cannot be empty',
                            },
                        ],
                    })(<Input placeholder="Enter category name" />)}
                </Form.Item>
                <Form.Item label="Associated products" colon={false} required={false}>
                    {getFieldDecorator('services', {
                        rules: [
                            {
                                required: true,
                                message: 'This field cannot be empty',
                            },
                        ],
                    })(<ProductsField loading={loading} data={data} />)}
                </Form.Item>
                <Form.Item label="Category image" colon={false} required={false}>
                    {getFieldDecorator('categoryImage', {
                        rules: [
                            {
                                required: true,
                                message: 'Please upload an image',
                            },
                        ],
                    })(<CategoryImageUploader />)}
                </Form.Item>
                <Form.Item>
                    <Box d="flex" justifyContent="flex-end">
                        <Button loading={isLoading} type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Box>
                </Form.Item>
            </Form>
        </Popup>
    );
});

export default Form.create()(AddCategory);
