import React, { useCallback, useState } from 'react';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Button } from '@components/Button';
import { Pagination } from '@components/Pagination';
import { Skeleton } from '@components/Skeleton';
import {
    ORDER_STATUS_DELIVERED_PROJECT,
    ORDER_STATUS_DELIVERED_REVISION,
    ORDER_STATUS_ONGOING_PROJECT,
    ORDER_STATUS_ONGOING_REVISION,
    ORDER_STATUS_SUBMITTED,
    ORDER_TAB_STATUSES,
} from '@constants/order';
import { withResponsive } from '@components/ResponsiveProvider';
import SubscriptionPaused from '../SubscriptionPaused';
import { groupBy } from '../../utils/groupBy';
import { SectionRequestRenderer } from './SectionRequestRenderer';
import { EmptyQueue } from './EmptyQueue';
import { EmptyRequest } from './EmptyRequest';
import { DelayedSearchInput } from '../DelayedSearchInput';
import downloadMultiRequestAsZip from '@utils/downloadMultiRequestAsZip';
import IconDownloadAlt from '@components/Svg/IconDownloadAlt';
import message from '@components/Message';
import { Progress } from 'antd';
import { blue } from '@ant-design/colors';
import moment from 'moment';

function arrayEquals(a, b) {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
}

export const SectionRequest = withResponsive(
    ({
        name,
        statuses,
        data,
        isSubscriptionPaused,
        activeFilter,
        activeStatusName,
        handleChangeStatus,
        page,
        max,
        handleChangePage,
        totalRequestCount,
        allRequestsTotal,
        handleChangeMax,
        refetch,
        loading,
        windowWidth,
        search,
        handleSearch,
        showSearchBox = false,
        isNotCustomer = false,
        isWorker = false,
        showDownloadAllFiles = false,
        customEmpty,
    }) => {
        const [isDownloadingFiles, setIsDownloadingFiles] = useState(false);
        const [downloadProgress, setDownloadProgress] = useState(0);

        if (isSubscriptionPaused && !activeFilter.some(item => ORDER_TAB_STATUSES.DELIVERED.includes(item))) {
            return <SubscriptionPaused />;
        }

        const isDesktop = windowWidth >= 768;

        const dataIsNotEmpty = Array.isArray(data) && data.length > 0;
        const handleZippingFiles = prcnt => {
            setDownloadProgress(prcnt)
            if (Math.ceil(prcnt) >= 100) {
                setIsDownloadingFiles(false);
                message.success('Files have been downloaded successfully')
            }
        };
    
        const handleDownloadAllFolders = useCallback(() => {
            setIsDownloadingFiles(true);
            downloadMultiRequestAsZip(data, `${moment().format('DD MMM, HH:mm A')}-${page}#${max}`, handleZippingFiles)
        }, [data, page, max])

        const groupedData = dataIsNotEmpty
            ? groupBy(data, (item) => {
                  if (
                      (arrayEquals(activeFilter, ORDER_TAB_STATUSES.QUEUE) &&
                          [ORDER_STATUS_ONGOING_PROJECT, ORDER_STATUS_ONGOING_REVISION, ORDER_STATUS_SUBMITTED].indexOf(
                              item.status
                          ) > -1) ||
                      (arrayEquals(activeFilter, [ORDER_STATUS_ONGOING_PROJECT, ORDER_STATUS_ONGOING_REVISION]) &&
                          [ORDER_STATUS_ONGOING_PROJECT, ORDER_STATUS_ONGOING_REVISION].indexOf(item.status) > -1)
                  ) {
                      return 'ACTIVE';
                  }
                  if (
                      arrayEquals(activeFilter, ORDER_TAB_STATUSES.DELIVERED) &&
                      [ORDER_STATUS_DELIVERED_PROJECT, ORDER_STATUS_DELIVERED_REVISION].indexOf(item.status) > -1
                  ) {
                      return ORDER_STATUS_DELIVERED_PROJECT;
                  }
                  return item.status;
              })
            : {};

        const keys = Object.keys(groupedData);
        const groupedKeys =
            name === 'queue'
                ? keys.filter((item) => (activeFilter ? ['ACTIVE', ...activeFilter] : ['ACTIVE']).indexOf(item) > -1)
                : keys.filter((item) => (activeFilter ?? []).indexOf(item) > -1);

        if (name === 'queue') {
            groupedKeys.sort();
        } else if (name === 'delivered') {
            groupedKeys.sort().reverse();
        }

        return (
            <>
                {Array.isArray(statuses) && !showSearchBox && (
                    <>
                        {isDownloadingFiles && (
                            <Box mt="20">
                                <Progress size="small" strokeColor={blue[4]} percent={downloadProgress} showInfo={false} />
                            </Box>
                        )}
                        <Box d="flex" justifyContent="space-between" alignItems="center" mt={['10', '20']}>
                        
                            <Box d="flex" alignItems="center">
                                <Text hide="mobile" textVariant="H6" pb="2" colorScheme="primary" mr="20">
                                    Status
                                </Text>
                                <Box py="10" d="flex" flexWrap="nowrap" overflow="auto">
                                    {statuses?.map((status) => (
                                        <Button
                                            mr="10"
                                            noColorTransitions
                                            key={status.label}
                                            type={arrayEquals(activeFilter, status.name) ? 'primary' : 'default'}
                                            isBadge
                                            lineH="1"
                                            onClick={() => handleChangeStatus(status.label)}
                                        >
                                            {status.label}
                                        </Button>
                                    ))}
                                </Box>
                            </Box>
                        {showDownloadAllFiles && (
                                <Box
                                    fontSize="20"
                                    colorScheme="tertiary"
                                    _hover={{ colorScheme: 'cta' }}
                                    onClick={handleDownloadAllFolders}
                                    ml="8"
                                    >
                                    <IconDownloadAlt />
                                </Box>
                        )}
                        </Box>
                    </>
                )}
                {showSearchBox && (
                    <Box d="flex" justifyContent="space-between" mt={['10', '26']} mb="10">
                        <Box hide="mobile" mr="20" w="100%" maxW="440">
                            <DelayedSearchInput
                                initialValue={search}
                                placeholder={isNotCustomer ? 'Search by name, request number' : 'Search'}
                                onChange={handleSearch}
                            />
                        </Box>
                        {Array.isArray(statuses) && (
                            <Box d="flex" alignItems="center">
                                <Text hide="mobile" textVariant="H6" pb="0" colorScheme="primary" mr="20">
                                    Status
                                </Text>
                                <Box d="flex" flexWrap="nowrap" overflow="auto">
                                    {statuses?.map((status) => {
                                        if (isWorker && status.label === 'Paused') {
                                            return null;
                                        }
                                        return (
                                            <Button
                                                mr="10"
                                                noColorTransitions
                                                key={status.label}
                                                type={arrayEquals(activeFilter, status.name) ? 'primary' : 'default'}
                                                isBadge
                                                lineH="1"
                                                style={{ height: 32 }} // enforce
                                                onClick={() => handleChangeStatus(status.label)}
                                            >
                                                {status.label}
                                            </Button>
                                        );
                                    })}
                                </Box>
                            </Box>
                        )}
                    </Box>
                )}
                {loading ? (
                    <Box mt="10">
                        <Skeleton w="102" h="20" mb="20" />
                        <Box d="flex" w="100%" mb="20">
                            <Box pr="20" pt="25">
                                <Skeleton w="22" h="24" variant="shield" />
                            </Box>
                            <Box flex={1}>
                                <Skeleton w="100%" h="78" />
                            </Box>
                        </Box>
                        <Box d="flex" w="100%" mb="20">
                            <Box pr="20" pt="25">
                                <Skeleton w="22" h="24" variant="shield" />
                            </Box>
                            <Box flex={1}>
                                <Skeleton w="100%" h="78" />
                            </Box>
                        </Box>
                        <Box d="flex" w="100%" mb="20">
                            <Box pr="20" pt="25">
                                <Skeleton w="22" h="24" variant="shield" />
                            </Box>
                            <Box flex={1}>
                                <Skeleton w="100%" h="78" />
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <>
                        {dataIsNotEmpty &&
                            groupedKeys.map((key) => (
                                <SectionRequestRenderer
                                    refetch={refetch}
                                    key={key}
                                    type={key}
                                    data={groupedData[key]}
                                    enableDrag={!search && activeStatusName === 'All' && !isWorker}
                                    isNotCustomer={isNotCustomer}
                                />
                            ))}
                        {dataIsNotEmpty && (
                            <Box>
                                {!(
                                    arrayEquals(activeFilter, ORDER_TAB_STATUSES.QUEUE) && activeStatusName === 'All'
                                ) ? (
                                    <Pagination
                                        total={totalRequestCount ?? 0}
                                        defaultCurrent={1}
                                        style={{
                                            width: 'auto',
                                            marginLeft: 'auto',
                                            justifyContent: isDesktop ? undefined : 'flex-end',
                                            marginTop: 0,
                                        }}
                                        showSizeChanger
                                        showLessItems
                                        showTotal={
                                            isDesktop
                                                ? (total, range) =>
                                                      `Showing ${range[0]} to ${range[1]} of ${total} requests`
                                                : undefined
                                        }
                                        current={page}
                                        onChange={(newPage) => handleChangePage(newPage)}
                                        pageSize={max}
                                        onShowSizeChange={(current, choice) => handleChangeMax(choice)}
                                    />
                                ) : (
                                    <Text colorScheme="primary">
                                        {totalRequestCount ?? 0} Request{(totalRequestCount ?? 0) > 1 ? 's' : ''}
                                    </Text>
                                )}
                            </Box>
                        )}

                        {!dataIsNotEmpty ? (
                            <>
                                {customEmpty ? (
                                    <>{customEmpty}</>
                                ) : name === 'queue' ? (
                                    allRequestsTotal ? (
                                        <EmptyRequest />
                                    ) : (
                                        <EmptyQueue />
                                    )
                                ) : (
                                    <EmptyRequest />
                                )}
                            </>
                        ) : null}
                    </>
                )}
            </>
        );
    }
);
