import React from 'react'

const Icon = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M51.96 59.85H8.04V8.51999L18.27 0.149994H51.96V59.85Z" fill="#E6E6E4"/>
      <path d="M39.54 17.79H20.1V34.74H39.54V17.79Z" fill="#378ACF"/>
      <path d="M35.1 29.28H25.53V30.03H35.1V29.28Z" fill="#31B89A"/>
      <path d="M8.04 8.51999H17.7V0.149994L8.04 8.51999Z" fill="#AFAFAC"/>
      <path d="M27.3636 51.3223C26.7293 51.6532 26.0673 51.8923 25.3778 52.0394C24.6883 52.1865 23.985 52.26 23.2679 52.26C22.5048 52.26 21.8015 52.1405 21.158 51.9015C20.5144 51.6624 19.9582 51.3223 19.4894 50.881C19.0205 50.4305 18.6527 49.8881 18.3861 49.2537C18.1287 48.6194 18 47.9115 18 47.13C18 46.3485 18.1287 45.6406 18.3861 45.0063C18.6527 44.3719 19.0205 43.8341 19.4894 43.3928C19.9582 42.9423 20.5144 42.5976 21.158 42.3585C21.8015 42.1195 22.5048 42 23.2679 42C24.0494 42 24.7665 42.0965 25.4192 42.2896C26.0811 42.4735 26.6603 42.7952 27.1568 43.2549L25.6398 44.9098C25.3456 44.6064 25.0147 44.3765 24.6469 44.2202C24.2792 44.064 23.8195 43.9858 23.2679 43.9858C22.8082 43.9858 22.3899 44.064 22.013 44.2202C21.6452 44.3765 21.3281 44.5972 21.0615 44.8822C20.7948 45.158 20.588 45.489 20.4409 45.8751C20.303 46.2612 20.234 46.6795 20.234 47.13C20.234 47.5897 20.303 48.0126 20.4409 48.3987C20.588 48.7756 20.7948 49.1066 21.0615 49.3916C21.3281 49.6674 21.6452 49.8835 22.013 50.0398C22.3899 50.196 22.8082 50.2742 23.2679 50.2742C23.7368 50.2742 24.1413 50.2236 24.4815 50.1225C24.8216 50.0122 25.0928 49.9019 25.2951 49.7915V48.2056H23.5437V46.2198H27.3636V51.3223Z" fill="#08122A"/>
      <path d="M29.2297 42.2482H31.381V52.0118H29.2297V42.2482Z" fill="#08122A"/>
      <path d="M33.4206 42.2482H39.971V44.234H35.5719V46.2198H39.64V48.2056H35.5719V52.0118H33.4206V42.2482Z" fill="#08122A"/>
      <path d="M25.8 30C27.9208 30 29.64 28.2808 29.64 26.16C29.64 24.0392 27.9208 22.32 25.8 22.32C23.6792 22.32 21.96 24.0392 21.96 26.16C21.96 28.2808 23.6792 30 25.8 30Z" fill="#F2D465"/>
      <path d="M35.1 22.5H25.53V23.25H35.1V22.5Z" fill="#F2D465"/>
      <path d="M34.68 30C36.8008 30 38.52 28.2808 38.52 26.16C38.52 24.0392 36.8008 22.32 34.68 22.32C32.5592 22.32 30.84 24.0392 30.84 26.16C30.84 28.2808 32.5592 30 34.68 30Z" fill="#31B89A"/>
    </svg>
  )
}

export default Icon