/**
 * Referenced from
 *
 * https://github.com/ShinyChang/React-Text-Truncate/blob/master/src/TextTruncate.js
 */

import React, { useLayoutEffect, useRef, useState, useCallback } from 'react';
import { Text } from '@components/Text';

const truncateText = '...';
const maxCalculateTimes = 10;

export const EllipsisMultiple = ({ children, line, ...textProps }) => {
    const [, forceUpdate] = useState(1);

    const textRef = useRef(null);
    const canvasEllipsis = useRef(null);
    const rafId = useRef(null);

    useLayoutEffect(() => {
        const setup = () => {
            const canvas = document.createElement('canvas');
            const docFragment = document.createDocumentFragment();
            const style = window.getComputedStyle(textRef.current);
            const font = [style['font-style'], style['font-weight'], style['font-size'], style['font-family']].join(
                ' '
            );
            docFragment.appendChild(canvas);

            canvasEllipsis.current = canvas.getContext('2d');
            canvasEllipsis.current.font = font;

            forceUpdate(old => old + 1);
        };

        const handleUpdate = () => {
            const style = window.getComputedStyle(textRef.current);
            const font = [style['font-style'], style['font-weight'], style['font-size'], style['font-family']].join(
                ' '
            );
            canvasEllipsis.current.font = font;
            forceUpdate(old => old + 1);
        };

        const handleWindowResize = () => {
            if (rafId.current !== null) {
                window.cancelAnimationFrame(rafId.current);
            }

            rafId.current = window.requestAnimationFrame(handleUpdate);
        };

        // TODO: find a way to make sure font is loaded to canvas
        // currently only setting up timeout
        setup();
        const timeout = window.setTimeout(() => {
            setup();
        }, 250);
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.clearTimeout(timeout);
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const calculateWidth = useCallback(text => {
        if (!canvasEllipsis.current) {
            return 0;
        }

        return Math.ceil(canvasEllipsis.current.measureText(text).width);
    }, []);

    const getText = useCallback(() => {
        if (textRef.current === null) {
            return null;
        }

        const scopeWidth = textRef.current.getBoundingClientRect().width;
        if (scopeWidth === 0) {
            return null;
        }

        if (scopeWidth >= calculateWidth(children)) {
            return children;
        }

        let childText = '';
        let currentPos = 1;
        let maxTextLength = children.length;
        let truncatedText = '';
        let splitPos = 0;
        let startPos = 0;
        let displayLine = line;
        let width = 0;
        let lastIsEng = false;
        let isPrevLineWithoutSpace = false;
        let lastPos = 0;
        let lastSpaceIndex = -1;
        let ext = '';
        let loopCnt = 0;

        while (displayLine-- > 0) {
            ext = displayLine ? '' : truncateText + (childText ? ' ' + childText : '');
            while (currentPos <= maxTextLength) {
                truncatedText = children.substr(startPos, currentPos);
                width = calculateWidth(truncatedText + ext);
                if (width < scopeWidth) {
                    splitPos = children.indexOf(' ', currentPos + 1);
                    if (splitPos === -1) {
                        currentPos += 1;
                        lastIsEng = false;
                    } else {
                        lastIsEng = true;
                        currentPos = splitPos;
                    }
                } else {
                    do {
                        if (loopCnt++ >= maxCalculateTimes) {
                            break;
                        }
                        truncatedText = children.substr(startPos, currentPos);
                        if (!displayLine) {
                            currentPos--;
                        }
                        if (truncatedText[truncatedText.length - 1] === ' ') {
                            truncatedText = children.substr(startPos, currentPos - 1);
                        }
                        if (lastIsEng) {
                            lastSpaceIndex = truncatedText.lastIndexOf(' ');
                            if (lastSpaceIndex > -1) {
                                currentPos = lastSpaceIndex;
                                if (displayLine) {
                                    currentPos++;
                                }
                                truncatedText = children.substr(startPos, currentPos);
                            } else {
                                currentPos--;
                                truncatedText = children.substr(startPos, currentPos);
                            }
                        } else {
                            currentPos--;
                            truncatedText = children.substr(startPos, currentPos);
                        }
                        width = calculateWidth(truncatedText + ext);
                    } while (width >= scopeWidth && truncatedText.length > 0);
                    startPos += currentPos;
                    break;
                }
            }

            if (currentPos >= maxTextLength) {
                startPos = maxTextLength;
                break;
            }

            if (lastIsEng && !isPrevLineWithoutSpace && children.substr(lastPos, currentPos).indexOf(' ') === -1) {
                isPrevLineWithoutSpace = children.substr(lastPos, currentPos).indexOf(' ') === -1;
                displayLine--;
            }
            lastPos = currentPos + 1;
        }

        if (startPos === maxTextLength) {
            return children;
        }

        return `${children.substr(0, startPos)}${truncateText}`;
    }, [children, calculateWidth, line]);

    return (
        <Text {...textProps} ref={textRef} overflow="hidden">
            {getText()}
        </Text>
    );
};
