import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const downloadFilesAsZip = async (files, folderName, onChangePercent, setAbort) => {
    const zip = new JSZip();
    const nbFiles = files.length;
    const percentPart = 100 / (2 * nbFiles);
    let percent = 0;
    const addPercent = completedPercent => {
        percent += completedPercent;
        if (onChangePercent) {
            onChangePercent(Math.ceil(percent));
        }
    };

    const controller  = new AbortController();
    const signal = controller.signal;
    const abort = () => {
        controller.abort()
    }

    if (setAbort) {
        setAbort(() => abort);
    }

    const blobFiles = await Promise.all(
        files.map(async file => {

            const response = await fetch(file.url, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                signal,
                headers: {
                    Origin: window.location.origin,
                },
            });

            addPercent(percentPart);

            return response.blob();
        })
    );

    blobFiles.forEach((blobFile, index) => {
        zip.file(files[index].name, blobFile);
        addPercent(percentPart);
    });

    const blobZipFolder = await zip.generateAsync({ type: 'blob' });
    saveAs(blobZipFolder, `${folderName}.zip`);

    return blobZipFolder;
};

export default downloadFilesAsZip;
