import React, { memo } from 'react';
import ProfileInfoForm from './ProfileInfoForm';
import ChangePasswordForm from './ChangePasswordForm';

const ProfileInfo = memo(({ user }) => {
    return (
        <>
            <ProfileInfoForm user={user} />
            <hr />
            <ChangePasswordForm user={user} />
        </>
    );
});

export default ProfileInfo;
