export const TYPO_FAMILY_MULISH = 'Mulish';
export const TYPO_FAMILY_GEOMANIST = 'Geomanist';

// Header
export const TYPO_H1 = {
    fontSize: '52px',
    fontWeight: '900',
    fontFamily: TYPO_FAMILY_MULISH,
    lineHeight: '65px',
    fontStyle: 'normal',
};
export const TYPO_H2 = {
    fontSize: '48px',
    fontWeight: '900',
    fontFamily: TYPO_FAMILY_MULISH,
    lineHeight: '64px',
    fontStyle: 'normal',
};

export const TYPO_H3 = {
    fontSize: '30px',
    fontWeight: '900',
    fontFamily: TYPO_FAMILY_MULISH,
    lineHeight: '44px',
    fontStyle: 'normal',
};

export const TYPO_H4 = {
    fontSize: '24px',
    fontWeight: '900',
    fontFamily: TYPO_FAMILY_MULISH,
    lineHeight: '36px',
    fontStyle: 'normal',
};

export const TYPO_H5 = {
    fontSize: '20px',
    fontWeight: '900',
    fontFamily: TYPO_FAMILY_MULISH,
    lineHeight: '26px',
    fontStyle: 'normal',
};

export const TYPO_H6 = {
    fontSize: '14px',
    fontWeight: '900',
    fontFamily: TYPO_FAMILY_MULISH,
    lineHeight: '20px',
    fontStyle: 'normal',
};

export const TYPO_BUTTON = {
    fontSize: '14px',
    fontWeight: '900',
    fontFamily: TYPO_FAMILY_MULISH,
    lineHeight: '20px',
    fontStyle: 'normal',
};

export const TYPO_PRIMARY_BUTTON = {
    fontSize: '16px',
    fontWeight: '900',
    fontFamily: TYPO_FAMILY_MULISH,
    lineHeight: '28px',
    fontStyle: 'normal',
};

export const TYPO_SMALL_TITLE = {
    fontSize: '12px',
    fontWeight: '900',
    fontFamily: TYPO_FAMILY_MULISH,
    lineHeight: '18px',
    fontStyle: 'normal',
};

// Body
export const TYPO_P1 = {
    fontSize: '24px',
    fontWeight: '300',
    fontFamily: TYPO_FAMILY_GEOMANIST,
    lineHeight: '30px',
    fontStyle: 'normal',
};

export const TYPO_P2 = {
    fontSize: '18px',
    fontWeight: '300',
    fontFamily: TYPO_FAMILY_GEOMANIST,
    lineHeight: '26px',
    fontStyle: 'normal',
};

export const TYPO_P3 = {
    fontSize: '16px',
    fontWeight: '300',
    fontFamily: TYPO_FAMILY_GEOMANIST,
    lineHeight: '22px',
    fontStyle: 'normal',
};

export const TYPO_P4 = {
    fontSize: '14px',
    fontWeight: '300',
    fontFamily: TYPO_FAMILY_GEOMANIST,
    lineHeight: '20px',
    fontStyle: 'normal',
};

export const TYPO_P5 = {
    fontSize: '12px',
    fontWeight: '300',
    fontFamily: TYPO_FAMILY_GEOMANIST,
    lineHeight: '18px',
    fontStyle: 'normal',
};

export const TYPO_HEADLINE = {
    fontSize: '20px',
    fontWeight: '400',
    fontFamily: TYPO_FAMILY_GEOMANIST,
    lineHeight: '28px',
    fontStyle: 'normal',
};

export const TYPO_BADGE = {
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: TYPO_FAMILY_GEOMANIST,
    lineHeight: '20px',
    fontStyle: 'normal',
};

export const TYPO_SMALL_NOTIFICATION = {
    fontSize: '10px',
    fontWeight: '900',
    fontFamily: TYPO_FAMILY_MULISH,
    lineHeight: '16px',
    fontStyle: 'normal',
};
