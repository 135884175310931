import React, { forwardRef } from 'react';
import { Box } from '@components/Box';
import { Badge } from '@components/Badge';
import { ORDER_STATUS_LABELS } from '@constants/order';

const ALL_STATUS = ['DRAFT', 'SUBMITTED', 'ONGOING_PROJECT', 'ON_HOLD', 'DELIVERED_PROJECT', 'COMPLETED'];

const DESIGNER_STATUS = {
    SUBMITTED: ['ONGOING_PROJECT', 'DELIVERED_PROJECT'],
    ONGOING_PROJECT: ['DELIVERED_PROJECT'],
    ONGOING_REVISION: ['DELIVERED_PROJECT'],
    DELIVERED_PROJECT: ['ONGOING_PROJECT'],
};

const StatusSelectToRef = ({ isOpenStatusSelect, requestStatus, handleChangeOrderStatus, isWorker }, ref) => {
    const statuses = isWorker ? DESIGNER_STATUS[requestStatus] || [] : ALL_STATUS;

    if (!isOpenStatusSelect) {
        return <></>;
    }

    return (
        <Box
            ref={ref}
            bg="white"
            w="150"
            h={statuses.length > 2 ? "276" : `${statuses.length * 54}`}
            overflowY={statuses.length > 2 ? "auto" : "hidden"}
            overflowX="hidden"
            boxShadow={
                isOpenStatusSelect
                    ? '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)'
                    : 'none'
            }
            radii="2"
            left="3"
            pos="absolute"
            py={isOpenStatusSelect ? '4' : '0'}
            mt={isOpenStatusSelect ? '6' : '0'}
            trans="all 0.1s ease-in-out"
            zIndex="1"
        >
            {statuses.map(status => (
                <Box
                    key={status}
                    px="12"
                    pt="10"
                    pb="10"
                    cursor="pointer"
                    _hover={{ bg: 'outline-gray' }}
                    onClick={() => handleChangeOrderStatus(status)}
                >
                    <Badge variant={ORDER_STATUS_LABELS[status]}>{ORDER_STATUS_LABELS[status]}</Badge>
                </Box>
            ))}
        </Box>
    );
};

export const StatusSelect = forwardRef(StatusSelectToRef);
