import React, { memo, useCallback, useState } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { Switch } from 'antd';
import includes from 'lodash/includes';
import { Form } from '@components/Form';
import { Button } from '@components/Button';
import { Popup } from '@components/Popup';
import { Box } from '@components/Box';
import { Image } from '@components/Image';
import { GET_ALL_TEAMS } from '@graphql/queries/team';
import RoleField from '../RoleField';
import { Radio, RadioGroup } from '@components/Radio';
import DesignTypeField from '../DesignTypeField';
import message from '@components/Message';
import { Text } from '@components/Text';
import {
    CHANGE_USER_ROLE,
    DESIGNER_JOIN_TEAM,
    DESIGNER_LEAVE_TEAM,
    TEAM_LEADER_JOIN_TEAM,
    TEAM_LEADER_LEAVE_TEAM,
    SET_USER_ARCHIVED,
    UPDATE_USER_DESIGN_TYPE,
    DISCONNECT_USER_DESIGN_TYPE,
    DISCONNECT_ASSIGN_ORDERS,
    DISCONNECT_ASSIGN_COMPANIES,
} from '@graphql/mutations/user';

const EditUserPopup = memo(({ visible, onCloseModal, user, form, refetchUsers }) => {
    const { getFieldDecorator, validateFields, getFieldValue } = form;
    const { data } = useQuery(GET_ALL_TEAMS, {
        fetchPolicy: 'network-only',
    });
    const [teamLeaderLeaveTeam] = useMutation(TEAM_LEADER_LEAVE_TEAM);
    const [teamLeaderJoinTeam] = useMutation(TEAM_LEADER_JOIN_TEAM);
    const [designerLeaveTeam] = useMutation(DESIGNER_LEAVE_TEAM);
    const [designerJoinTeam] = useMutation(DESIGNER_JOIN_TEAM);
    const [changeRole] = useMutation(CHANGE_USER_ROLE);
    const [setUserArchived] = useMutation(SET_USER_ARCHIVED);
    const [updateUserSpecialities] = useMutation(UPDATE_USER_DESIGN_TYPE);
    const [disconnectUserSpecialities] = useMutation(DISCONNECT_USER_DESIGN_TYPE);
    const [disconnectAssignOrders] = useMutation(DISCONNECT_ASSIGN_ORDERS);
    const [disconnectAssignCompanies] = useMutation(DISCONNECT_ASSIGN_COMPANIES);
    const [isLoading, setIsLoading] = useState(false);
    const selectedRole = getFieldValue('role');
    const teams = data?.allTeams;
    const getUserTeam = useCallback(
        role => {
            if (role === 'manager' && user.teamLeadersTeams.length) {
                return user.teamLeadersTeams[0].id;
            }
            if (role === 'worker' && user.designerTeams.length) {
                return user.designerTeams[0].id;
            }
            return;
        },
        [user]
    );
    const getUnrelevantAssignments = useCallback(
        specialitiesIds => {
            const assignmentIds =
                user?.assignedCustomers
                    ?.filter(ac => !includes(specialitiesIds, ac.type.id))
                    ?.map(ac => ({ id: ac.id })) ?? null;

            return assignmentIds;
        },
        [user]
    );
    const handleSubmit = useCallback(
        async e => {
            e.preventDefault();
            validateFields(async (err, values) => {
                if (!err && !isLoading) {
                    setIsLoading(true);
                    message.destroy();
                    message.loading('Updating member...', 2000);
                    try {
                        const teamId = getUserTeam(user.role);

                        // leave user team
                        if (user.role === 'manager' && teamId) {
                            await teamLeaderLeaveTeam({
                                variables: {
                                    id: user.id,
                                    teamId,
                                },
                            });
                        }
                        if (user.role === 'worker' && teamId) {
                            await designerLeaveTeam({
                                variables: {
                                    id: user.id,
                                    teamId,
                                },
                            });
                        }

                        // update user team
                        if (values.role === 'manager') {
                            await teamLeaderJoinTeam({
                                variables: {
                                    id: user.id,
                                    teamId: values.team,
                                },
                            });
                        }
                        if (values.role === 'worker') {
                            await designerJoinTeam({
                                variables: {
                                    id: user.id,
                                    teamId: values.team,
                                },
                            });
                        }

                        // remove assigned company
                        if (values.role === 'worker' && values.team !== teamId) {
                            await disconnectAssignCompanies({
                                variables: {
                                    assignedCompaniesIds: user.assignedCustomers.map(ac => ({ id: ac.id })),
                                    id: user.id,
                                },
                            });
                        }

                        // change the role of user
                        await changeRole({
                            variables: {
                                role: values.role,
                                id: user.id,
                            },
                        });

                        // update user archived
                        await setUserArchived({
                            variables: {
                                id: user.id,
                                archived: !values.status,
                            },
                        });

                        // remove specialities
                        if (user?.specialities?.length > 0) {
                            await disconnectUserSpecialities({
                                variables: {
                                    specialitiesIds: user.specialities.map(sp => ({ id: sp.id })),
                                    id: user.id,
                                },
                            });
                        }

                        // update user design types
                        if (values.role === 'worker') {
                            await updateUserSpecialities({
                                variables: {
                                    specialitiesIds: values.specialities.map(id => ({ id })),
                                    id: user.id,
                                },
                            });
                        }

                        // remove from any assigned requests and accounts
                        if (user.role === 'worker' && values.role !== 'worker') {
                            await disconnectAssignOrders({
                                variables: {
                                    id: user.id,
                                },
                            });
                            await disconnectAssignCompanies({
                                variables: {
                                    assignedCompaniesIds: user.assignedCustomers.map(ac => ({ id: ac.id })),
                                    id: user.id,
                                },
                            });
                        }

                        // update assignments based on specialities
                        if (values.role === 'worker' && values.team === teamId) {
                            const spIds = values.specialities.map(id => id);
                            const unrelevantAssignmentIds = getUnrelevantAssignments(spIds);
                            if (unrelevantAssignmentIds && unrelevantAssignmentIds?.length > 0) {
                                await disconnectAssignCompanies({
                                    variables: {
                                        assignedCompaniesIds: unrelevantAssignmentIds,
                                        id: user.id,
                                    },
                                });
                            }
                        }

                        await refetchUsers();
                        message.destroy();
                        message.success(`${user.firstname} info has been updated`);
                        setIsLoading(false);
                        onCloseModal();
                        return true;
                    } catch (error) {
                        message.destroy();
                        setIsLoading(false);
                        console.error(error);
                        return false;
                    }
                }
            });
        },
        [
            teamLeaderLeaveTeam,
            teamLeaderJoinTeam,
            designerLeaveTeam,
            designerJoinTeam,
            refetchUsers,
            setUserArchived,
            changeRole,
            user,
            getUserTeam,
            validateFields,
            onCloseModal,
            updateUserSpecialities,
            disconnectUserSpecialities,
            disconnectAssignOrders,
            disconnectAssignCompanies,
            getUnrelevantAssignments,
            isLoading
        ]
    );

    return (
        <Popup
            variant="default"
            width={500}
            title="Edit member"
            visible={visible}
            onCancel={onCloseModal}
            footer={null}
            destroyOnClose
        >
            {visible && (
                <Form onSubmit={handleSubmit}>
                    <Box d="flex" alignItems="center" mb="20">
                        <Image
                            src={user?.picture?.url}
                            size={40}
                            fontSize={14}
                            isRounded
                            name={`${user?.firstname ?? ''} ${user?.lastname ?? ''}`}
                        />
                        <Box pl="8">
                            <Text textVariant="P4">
                                {user.firstname} {user.lastname}
                            </Text>
                            <Text textVariant="P5" colorScheme="secondary">
                                {user.email}
                            </Text>
                        </Box>
                    </Box>
                    <Form.Item label="Role" colon={false} required={true} style={{ marginBottom: 20 }}>
                        {getFieldDecorator('role', {
                            initialValue: user.role,
                            rules: [
                                {
                                    required: true,
                                    message: 'Please select a role',
                                },
                            ],
                        })(<RoleField width={460} showAll={false} onFieldChange={() => {}} />)}
                    </Form.Item>
                    {((!selectedRole && user.role !== 'owner') || (selectedRole && selectedRole !== 'owner')) && (
                        <Form.Item label="Team" colon={false} required={true} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('team', {
                                initialValue: getUserTeam(user.role),
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select a team',
                                    },
                                ],
                            })(
                                <RadioGroup>
                                    <Box d="flex">
                                        {teams.map(team => (
                                            <Radio value={team.id} key={team.id}>
                                                {team.name}
                                            </Radio>
                                        ))}
                                    </Box>
                                </RadioGroup>
                            )}
                        </Form.Item>
                    )}
                    {((!selectedRole && user.role === 'worker') || (selectedRole && selectedRole === 'worker')) && (
                        <Form.Item label="Design Type" colon={false} required={true} style={{ marginBottom: 20 }}>
                            {getFieldDecorator('specialities', {
                                initialValue: user.specialities.map(sp => sp.id),
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select design type',
                                    },
                                ],
                            })(
                                <DesignTypeField multiple={true} showAll={false} onFieldChange={() => {}} width={460} />
                            )}
                        </Form.Item>
                    )}
                    <Form.Item label="Status" colon={false} required={true}>
                        {getFieldDecorator('status', {
                            valuePropName: 'checked',
                            initialValue: !user.archived,
                        })(<Switch />)}
                    </Form.Item>
                    <Form.Item>
                        <Box d="flex" justifyContent="flex-end">
                            <Button loading={isLoading} type="primary" htmlType="submit">
                                Update
                            </Button>
                        </Box>
                    </Form.Item>
                </Form>
            )}
        </Popup>
    );
});

export default Form.create()(EditUserPopup);
