import React from 'react';

const IconCompany = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.58 19.82H0.940039C0.767648 19.82 0.602318 19.7515 0.48042 19.6296C0.358521 19.5077 0.290039 19.3424 0.290039 19.17C0.290039 18.9976 0.358521 18.8323 0.48042 18.7104C0.602318 18.5885 0.767648 18.52 0.940039 18.52H17.58C17.7524 18.52 17.9178 18.5885 18.0397 18.7104C18.1616 18.8323 18.23 18.9976 18.23 19.17C18.23 19.3424 18.1616 19.5077 18.0397 19.6296C17.9178 19.7515 17.7524 19.82 17.58 19.82Z"
        fill="currentColor"
      />
      <path
        d="M11.4102 19.7301H0.810156C0.637766 19.7301 0.472436 19.6616 0.350537 19.5397C0.228638 19.4178 0.160156 19.2524 0.160156 19.0801V3.08005C0.160156 2.90766 0.228638 2.74233 0.350537 2.62043C0.472436 2.49854 0.637766 2.43005 0.810156 2.43005H11.4102C11.5825 2.43005 11.7479 2.49854 11.8698 2.62043C11.9917 2.74233 12.0602 2.90766 12.0602 3.08005V19.0801C12.0602 19.2524 11.9917 19.4178 11.8698 19.5397C11.7479 19.6616 11.5825 19.7301 11.4102 19.7301ZM1.46016 18.4301H10.7602V3.77005H1.46016V18.4301Z"
        fill="currentColor"
      />
      <path
        d="M18.76 19.83H11.44C11.2676 19.83 11.1023 19.7615 10.9804 19.6396C10.8585 19.5177 10.79 19.3523 10.79 19.18V6.34995C10.79 6.26459 10.8069 6.18007 10.8395 6.10121C10.8722 6.02235 10.9201 5.95069 10.9804 5.89033C11.0408 5.82997 11.1124 5.7821 11.1913 5.74943C11.2702 5.71676 11.3547 5.69995 11.44 5.69995H18.76C18.8454 5.69995 18.9299 5.71676 19.0088 5.74943C19.0876 5.7821 19.1593 5.82997 19.2197 5.89033C19.28 5.95069 19.3279 6.02235 19.3606 6.10121C19.3932 6.18007 19.41 6.26459 19.41 6.34995V19.18C19.41 19.3523 19.3416 19.5177 19.2197 19.6396C19.0978 19.7615 18.9324 19.83 18.76 19.83ZM12.09 18.53H18.09V6.99995H12.09V18.53Z"
        fill="currentColor"
      />
      <path
        d="M4.90977 6.68H3.40977C3.23738 6.68 3.07204 6.61152 2.95015 6.48962C2.82825 6.36773 2.75977 6.2024 2.75977 6.03C2.75977 5.85761 2.82825 5.69228 2.95015 5.57039C3.07204 5.44849 3.23738 5.38 3.40977 5.38H4.90977C5.08216 5.38 5.24749 5.44849 5.36939 5.57039C5.49128 5.69228 5.55977 5.85761 5.55977 6.03C5.55977 6.2024 5.49128 6.36773 5.36939 6.48962C5.24749 6.61152 5.08216 6.68 4.90977 6.68Z"
        fill="currentColor"
      />
      <path
        d="M8.4801 6.68H7.0001C6.82771 6.68 6.66238 6.61152 6.54048 6.48962C6.41858 6.36773 6.3501 6.2024 6.3501 6.03C6.3501 5.85761 6.41858 5.69228 6.54048 5.57039C6.66238 5.44849 6.82771 5.38 7.0001 5.38H8.5001C8.67249 5.38 8.83782 5.44849 8.95972 5.57039C9.08162 5.69228 9.1501 5.85761 9.1501 6.03C9.1501 6.2024 9.08162 6.36773 8.95972 6.48962C8.83782 6.61152 8.67249 6.68 8.5001 6.68H8.4801Z"
        fill="currentColor"
      />
      <path
        d="M4.90977 9.85005H3.40977C3.23738 9.85005 3.07204 9.78157 2.95015 9.65967C2.82825 9.53777 2.75977 9.37244 2.75977 9.20005C2.75977 9.02766 2.82825 8.86233 2.95015 8.74043C3.07204 8.61853 3.23738 8.55005 3.40977 8.55005H4.90977C5.08216 8.55005 5.24749 8.61853 5.36939 8.74043C5.49128 8.86233 5.55977 9.02766 5.55977 9.20005C5.55977 9.37244 5.49128 9.53777 5.36939 9.65967C5.24749 9.78157 5.08216 9.85005 4.90977 9.85005Z"
        fill="currentColor"
      />
      <path
        d="M8.4801 9.85005H7.0001C6.82771 9.85005 6.66238 9.78157 6.54048 9.65967C6.41858 9.53777 6.3501 9.37244 6.3501 9.20005C6.3501 9.02766 6.41858 8.86233 6.54048 8.74043C6.66238 8.61853 6.82771 8.55005 7.0001 8.55005H8.5001C8.67249 8.55005 8.83782 8.61853 8.95972 8.74043C9.08162 8.86233 9.1501 9.02766 9.1501 9.20005C9.1501 9.37244 9.08162 9.53777 8.95972 9.65967C8.83782 9.78157 8.67249 9.85005 8.5001 9.85005H8.4801Z"
        fill="currentColor"
      />
      <path
        d="M15.7198 9.85005H14.2198C14.0474 9.85005 13.8821 9.78157 13.7602 9.65967C13.6383 9.53777 13.5698 9.37244 13.5698 9.20005C13.5698 9.02766 13.6383 8.86233 13.7602 8.74043C13.8821 8.61853 14.0474 8.55005 14.2198 8.55005H15.7198C15.8922 8.55005 16.0575 8.61853 16.1794 8.74043C16.3013 8.86233 16.3698 9.02766 16.3698 9.20005C16.3698 9.37244 16.3013 9.53777 16.1794 9.65967C16.0575 9.78157 15.8922 9.85005 15.7198 9.85005Z"
        fill="currentColor"
      />
      <path
        d="M4.90977 13.16H3.40977C3.23738 13.16 3.07204 13.0915 2.95015 12.9696C2.82825 12.8477 2.75977 12.6824 2.75977 12.51C2.75977 12.3376 2.82825 12.1723 2.95015 12.0504C3.07204 11.9285 3.23738 11.86 3.40977 11.86H4.90977C5.08216 11.86 5.24749 11.9285 5.36939 12.0504C5.49128 12.1723 5.55977 12.3376 5.55977 12.51C5.55977 12.6824 5.49128 12.8477 5.36939 12.9696C5.24749 13.0915 5.08216 13.16 4.90977 13.16Z"
        fill="currentColor"
      />
      <path
        d="M8.4801 13.16H7.0001C6.82771 13.16 6.66238 13.0915 6.54048 12.9696C6.41858 12.8477 6.3501 12.6824 6.3501 12.51C6.3501 12.3376 6.41858 12.1723 6.54048 12.0504C6.66238 11.9285 6.82771 11.86 7.0001 11.86H8.5001C8.67249 11.86 8.83782 11.9285 8.95972 12.0504C9.08162 12.1723 9.1501 12.3376 9.1501 12.51C9.1501 12.6824 9.08162 12.8477 8.95972 12.9696C8.83782 13.0915 8.67249 13.16 8.5001 13.16H8.4801Z"
        fill="currentColor"
      />
      <path
        d="M4.90977 16.33H3.40977C3.23738 16.33 3.07204 16.2615 2.95015 16.1396C2.82825 16.0178 2.75977 15.8524 2.75977 15.68C2.75977 15.5076 2.82825 15.3423 2.95015 15.2204C3.07204 15.0985 3.23738 15.03 3.40977 15.03H4.90977C5.08216 15.03 5.24749 15.0985 5.36939 15.2204C5.49128 15.3423 5.55977 15.5076 5.55977 15.68C5.55977 15.8524 5.49128 16.0178 5.36939 16.1396C5.24749 16.2615 5.08216 16.33 4.90977 16.33Z"
        fill="currentColor"
      />
      <path
        d="M8.47996 16.33H6.99996C6.82359 16.33 6.65445 16.2599 6.52974 16.1352C6.40502 16.0105 6.33496 15.8414 6.33496 15.665C6.33496 15.4886 6.40502 15.3195 6.52974 15.1948C6.65445 15.0701 6.82359 15 6.99996 15H8.49996C8.67235 15 8.83768 15.0685 8.95958 15.1904C9.08148 15.3123 9.14996 15.4776 9.14996 15.65C9.14996 15.8224 9.08148 15.9877 8.95958 16.1096C8.83768 16.2315 8.67235 16.3 8.49996 16.3L8.47996 16.33Z"
        fill="currentColor"
      />
      <path
        d="M16.9999 6.82996H13.7999C13.6292 6.82998 13.4654 6.76287 13.3438 6.64313C13.2222 6.52339 13.1525 6.36061 13.1499 6.18996V4.35996C13.1499 4.18757 13.2184 4.02224 13.3403 3.90034C13.4622 3.77844 13.6275 3.70996 13.7999 3.70996H16.9999C17.1723 3.70996 17.3376 3.77844 17.4595 3.90034C17.5814 4.02224 17.6499 4.18757 17.6499 4.35996V6.18996C17.6473 6.36061 17.5776 6.52339 17.456 6.64313C17.3344 6.76287 17.1706 6.82998 16.9999 6.82996ZM14.3999 5.53996H16.2999V4.99996H14.3999V5.53996Z"
        fill="currentColor"
      />
      <path
        d="M7.2401 3.49997H4.0001C3.82851 3.49739 3.66468 3.42808 3.54333 3.30674C3.42199 3.1854 3.35268 3.02156 3.3501 2.84997V0.999975C3.35773 0.831898 3.42926 0.673082 3.55008 0.555984C3.67089 0.438887 3.83186 0.372351 4.0001 0.369975H7.2001C7.28679 0.365944 7.37343 0.379052 7.45505 0.408547C7.53667 0.438041 7.61167 0.483344 7.67577 0.541862C7.73986 0.60038 7.79178 0.670964 7.82856 0.749573C7.86533 0.828182 7.88625 0.913273 7.8901 0.999975V2.84997C7.8901 3.02237 7.82162 3.1877 7.69972 3.30959C7.57782 3.43149 7.41249 3.49997 7.2401 3.49997ZM4.6901 2.19997H6.5901V1.66997H4.6901V2.19997Z"
        fill="currentColor"
      />
      <path
        d="M16.3901 19.68H13.7901C13.6177 19.68 13.4524 19.6115 13.3305 19.4896C13.2086 19.3677 13.1401 19.2024 13.1401 19.03V13.61C13.1401 13.4376 13.2086 13.2722 13.3305 13.1503C13.4524 13.0284 13.6177 12.96 13.7901 12.96H16.3901C16.5625 12.96 16.7279 13.0284 16.8498 13.1503C16.9717 13.2722 17.0401 13.4376 17.0401 13.61V19C17.0442 19.0878 17.0304 19.1756 16.9995 19.2579C16.9687 19.3403 16.9215 19.4155 16.8607 19.4791C16.7999 19.5427 16.7269 19.5933 16.646 19.6278C16.5651 19.6623 16.4781 19.6801 16.3901 19.68ZM14.3901 18.38H15.6901V14.26H14.3901V18.38Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconCompany;
