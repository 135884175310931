import React from 'react';

const IconEmailInvitation = () => {
    return (
        <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.5 16.7253V2C0.5 1.17157 1.17157 0.5 2 0.5H20C20.8284 0.5 21.5 1.17157 21.5 2V16.7028C21.5 17.2521 21.1998 17.7574 20.7175 18.0201L10.7891 23.428L1.26067 18.0304L1.01422 18.4654L1.26067 18.0304C0.790564 17.7641 0.5 17.2656 0.5 16.7253Z"
                fill="currentColor"
                fillOpacity="0.19"
                stroke="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.72729 6.64838H9.36366V7.74731H7.72729V9.39569H6.63637V7.74731H5V6.64838H6.63637V5H7.72729V6.64838ZM12.6364 10.6046C13.8419 10.6046 14.8182 9.6183 14.8182 8.40674C14.8182 7.19243 13.8419 6.20888 12.6364 6.20888C11.431 6.20888 10.4546 7.19243 10.4546 8.40672C10.4546 9.61828 11.431 10.6046 12.6364 10.6046ZM12.6364 11.7033C11.1827 11.7033 8.27271 12.4369 8.27271 13.9012V15.0001H17V13.9012C17 12.4369 14.09 11.7033 12.6364 11.7033Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconEmailInvitation;
