import React, { forwardRef, useState } from 'react';
import { Form } from '@components/Form';
import { Box } from '@components/Box';
import { Input } from '@components/Input';
import { Button } from '@components/Button';
import { Select } from '@components/Select';
import FieldColor from './FieldColor';

const colorOptions = [
    { label: 'HEX', value: 'HEX' },
    { label: 'RGB', value: 'RGB' },
    { label: 'CMYK', value: 'CMYK' },
    { label: 'HSV', value: 'HSV' },
    { label: 'HSL', value: 'HSL' },
];

const FormColor = forwardRef(({ form, onAdd, initialValues, loading }, ref) => {
    const [stateType, setStateType] = useState(() => initialValues?.type);

    // Use state to manage changed type input color
    // and reset value input
    const handleChangeType = value => {
        setStateType(value);

        let defaultColor;
        switch (value) {
            case 'HEX':
                defaultColor = '#000000';
                break;
            case 'RGB':
                defaultColor = '0,0,0';
                break;
            case 'CMYK':
                defaultColor = '0%,0%,0%,100%';
                break;
            case 'HSV':
                defaultColor = '0°,0%,0%';
                break;
            case 'HSL':
                defaultColor = '0°,0%,0%';
                break;
            default:
                break;
        }

        form.setFieldsValue({
            colorValue: defaultColor,
        });
    };

    const handleSubmit = ev => {
        ev.preventDefault();
        ev.stopPropagation(); // Preventing other form being submitted
        
        form.validateFields(async (err, values) => {
            if (!err) {
                onAdd(values);
            }
        });
    };

    return (
        <Form ref={ref} onSubmit={handleSubmit}>
            <Form.Item label="Name" colon={false} required={false} style={{ marginBottom: 20 }}>
                {form.getFieldDecorator('name', {
                    validateTrigger: 'onBlur',
                    initialValue: initialValues?.name,
                    rules: [
                        {
                            required: true,
                            message: 'Please enter color name',
                        },
                    ],
                })(<Input placeholder="Give your color a name" />)}
            </Form.Item>
            <Form.Item label="Color" colon={false} required={false} style={{ marginBottom: 20 }}>
                <Box d={['block', 'flex']} flexDir="row" mx="-7px">
                    <Box flex={1} px="7px" mb={['16', '0']}>
                        <Form.Item style={{ marginBottom: 0 }}>
                            {form.getFieldDecorator('colorValue', {
                                initialValue: initialValues?.colorValue ?? '#000000',
                            })(<FieldColor type={stateType} />)}
                        </Form.Item>
                    </Box>
                    <Box ml="auto" px="7px" maxW={['auto', '108']} w="100%">
                        <Form.Item colon={false} required={false} style={{ marginBottom: 0 }}>
                            {form.getFieldDecorator('type', {
                                initialValue: initialValues?.type ?? 'HEX',
                                validateTrigger: 'onBlur',
                            })(
                                <Select placeholder="Type" onChange={handleChangeType}>
                                    {colorOptions.map(opt => (
                                        <Select.Option key={opt.value} value={opt.value}>
                                            {opt.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Box>
                </Box>
            </Form.Item>
            <Form.Item>
                <Box d="flex" justifyContent="flex-end" alignItems="center">
                    <Button type="primary" htmlType="submit" h="34" fontSize="12" loading={loading}>
                        {initialValues && initialValues.name ? 'Update' : 'Add Color'}
                    </Button>
                </Box>
            </Form.Item>
        </Form>
    );
});

export default Form.create()(FormColor);
