import React, { useState, useCallback, memo } from 'react';
import { Form } from '@components/Form';
import message from '@components/Message';
import { Box } from '@components/Box';
import { Input } from '@components/Input';
import { Text } from '@components/Text';
import { Button } from '@components/Button';
import { Select } from '@components/Select';
import { FormItemFlex } from '@components/FormItemFlex';
import COUNTRIES from '@constants/countries';
import { UPDATE_SOURCE } from '@graphql/mutations/subscription';
import { useMutation } from '@apollo/react-hooks';

const countriesOptions = COUNTRIES.map(country => (
    <Select.Option key={country.name} value={country.name}>
        {country.name}
    </Select.Option>
));

const BillingAddress = memo(({ form, billingSource, refetch }) => {
    const { getFieldDecorator, validateFields, getFieldsValue } = form;
    const [isLoading, setIsLoading] = useState(false);
    const [updateBillingAddress] = useMutation(UPDATE_SOURCE);
    const handleSubmit = useCallback(
        e => {
            e.preventDefault();
            validateFields(async (err, values) => {
                if (!err && !isLoading) {
                    message.loading('Updating billing address...');
                    setIsLoading(true);

                    try {
                        await updateBillingAddress({
                            variables: {
                                billingAddress: {
                                    line1: values.billingAddress,
                                    city: values.city,
                                    postal_code: values.zipCode,
                                    state: values?.state,
                                    country: values.country,
                                },
                            },
                        });
                        await refetch();
                        message.destroy();
                        message.success('Billing address has been updated');
                        setIsLoading(false);
                        return true;
                    } catch (e) {
                        setIsLoading(false);
                        console.error(e);
                        return false;
                    }
                }
            });
        },
        [isLoading, validateFields, updateBillingAddress, refetch]
    );

    const values = getFieldsValue();
    const isDisabled = !(values.billingAddress && values.city && values.zipCode && values.country);

    return (
        <Box my={['20', '30']}>
            <Text textVariant="H5" colorScheme="primary" mb="20">
                Billing address
            </Text>
            <Form onSubmit={handleSubmit}>
                <Box mb={['16', '30']}>
                    <Form.Item label="Address" colon={false} style={{ marginBottom: 0 }}>
                        {getFieldDecorator('billingAddress', {
                            initialValue: billingSource.owner?.address?.line1,
                        })(<Input placeholder="Enter your billing address" />)}
                    </Form.Item>
                </Box>
                <FormItemFlex justifyContent="space-between" itemWidthPct={22} spacing={10}>
                    <Box mb={['16', '30']} w={['100%', '25%']}>
                        <Form.Item label="City" colon={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('city', {
                                initialValue: billingSource.owner?.address?.city,
                            })(<Input placeholder="Enter your city" />)}
                        </Form.Item>
                    </Box>
                    <Box mb={['16', '30']} w={['100%', '25%']}>
                        <Form.Item label="Zip code" colon={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('zipCode', {
                                initialValue: billingSource.owner?.address?.postal_code,
                            })(<Input placeholder="Enter your zip code" />)}
                        </Form.Item>
                    </Box>
                    <Box mb={['16', '30']} w={['100%', '25%']}>
                        <Form.Item label="State" colon={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('state', {
                                initialValue: billingSource.owner?.address?.state,
                            })(<Input placeholder="Enter your state" />)}
                        </Form.Item>
                    </Box>
                    <Box mb={['16', '30']} w={['100%', '25%']}>
                        <Form.Item label="Country" colon={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('country', {
                                initialValue: billingSource.owner?.address?.country,
                            })(<Select placeholder="Choose your country">{countriesOptions}</Select>)}
                        </Form.Item>
                    </Box>
                </FormItemFlex>
                <Box mt={['4', '0']}>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                            h="34"
                            fontSize="12"
                            type="primary"
                            disabled={isDisabled}
                            htmlType="submit"
                            loading={isLoading}
                            w={['100%', 'auto']}
                        >
                            Update
                        </Button>
                    </Form.Item>
                </Box>
            </Form>
        </Box>
    );
});

export default Form.create()(BillingAddress);
