import gql from 'graphql-tag';

export const UPDATE_SOURCE = gql`
    mutation UpdateSource(
        $card: String
        $id: ID
        $billingAddress: BillingAddressInput
        $email: String
        $expYear: Int
        $expMonth: Int
        $cardHolder: String
    ) {
        updateSource(
            card: $card
            id: $id
            billingAddress: $billingAddress
            email: $email
            expYear: $expYear
            expMonth: $expMonth
            cardHolder: $cardHolder
        ) {
            source
        }
    }
`;

export const UPDATE_SUBSCRIPTION = gql`
    mutation UpdateSubscription($quantity: Int!, $planId: ID!, $discount: String) {
        updateSubscription(quantity: $quantity, planId: $planId, discount: $discount) {
            id
        }
    }
`;

export const ACCEPT_CANCELLATION_PROMOTION = gql`
    mutation AcceptCancellationPromotion($planId: ID!) {
        acceptCancellationPromotion(planId: $planId) {
            id
        }
    }
`;

export const RESUME_SUBSCRIPTION = gql`
    mutation ResumeSubscription {
        resumeSubscription {
            id
        }
    }
`;

export const FIND_STRIPE_DISCOUNT = gql`
    mutation FindStripeDiscount($code: String) {
        findStripeDiscount(code: $code) {
            value
            percent
            name
            id
        }
    }
`;
