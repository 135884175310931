import React from 'react';

const IconUpload = props => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M15.3199 14.808H0.679912C0.542 14.808 0.409735 14.7532 0.312217 14.6557C0.214698 14.5582 0.159912 14.4259 0.159912 14.288V11.312C0.159912 11.1741 0.214698 11.0418 0.312217 10.9443C0.409735 10.8468 0.542 10.792 0.679912 10.792C0.817825 10.792 0.950089 10.8468 1.04761 10.9443C1.14513 11.0418 1.19991 11.1741 1.19991 11.312V13.768H14.7999V11.448C14.7999 11.3101 14.8547 11.1778 14.9522 11.0803C15.0497 10.9828 15.182 10.928 15.3199 10.928C15.4578 10.928 15.5901 10.9828 15.6876 11.0803C15.7851 11.1778 15.8399 11.3101 15.8399 11.448V14.288C15.8399 14.3563 15.8265 14.4239 15.8003 14.487C15.7742 14.5501 15.7359 14.6074 15.6876 14.6557C15.6393 14.704 15.582 14.7423 15.5189 14.7684C15.4558 14.7945 15.3882 14.808 15.3199 14.808Z"
                fill="white"
            />
            <path
                d="M7.95188 2.40003C7.81397 2.40003 7.68171 2.45481 7.58419 2.55233C7.48667 2.64985 7.43188 2.78211 7.43188 2.92003V11.552C7.43188 11.6899 7.48667 11.8222 7.58419 11.9197C7.68171 12.0172 7.81397 12.072 7.95188 12.072C8.0898 12.072 8.22206 12.0172 8.31958 11.9197C8.4171 11.8222 8.47189 11.6899 8.47189 11.552V2.95203C8.47626 2.88109 8.46605 2.81002 8.44188 2.74319C8.41771 2.67636 8.3801 2.61519 8.33137 2.56346C8.28264 2.51174 8.22383 2.47054 8.15856 2.44243C8.09329 2.41432 8.02295 2.39989 7.95188 2.40003Z"
                fill="white"
            />
            <path
                d="M7.99996 1.82397C7.92907 1.82442 7.85905 1.83958 7.79432 1.8685C7.7296 1.89742 7.67159 1.93947 7.62396 1.99197L4.86396 4.91197C4.8085 5.01324 4.78833 5.13009 4.80661 5.24409C4.8249 5.35809 4.8806 5.46277 4.96495 5.54161C5.0493 5.62046 5.15748 5.66899 5.27246 5.67956C5.38743 5.69012 5.50266 5.66213 5.59996 5.59997L7.99996 3.11997L10.232 5.59997C10.2777 5.6525 10.3333 5.69552 10.3956 5.72656C10.4579 5.7576 10.5257 5.77606 10.5952 5.78089C10.6646 5.78572 10.7344 5.77682 10.8004 5.7547C10.8664 5.73258 10.9274 5.69767 10.98 5.65197C11.0325 5.60627 11.0755 5.55068 11.1065 5.48835C11.1376 5.42603 11.156 5.3582 11.1609 5.28874C11.1657 5.21929 11.1568 5.14956 11.1347 5.08354C11.1126 5.01752 11.0777 4.9565 11.032 4.90397L8.34396 1.99997C8.25668 1.89863 8.13321 1.83546 7.99996 1.82397Z"
                fill="white"
            />
        </svg>
    );
};

export default IconUpload;
