import React from 'react';

const IconFeedbackOutline = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="iconFeedbackOutlineMask" fill="white">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.8231 23.3781L12 30L8.17686 23.3781C3.42394 21.7818 0 17.2907 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 17.2907 20.5761 21.7818 15.8231 23.3781Z"
                />
            </mask>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.8231 23.3781L12 30L8.17686 23.3781C3.42394 21.7818 0 17.2907 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 17.2907 20.5761 21.7818 15.8231 23.3781Z"
                fill="white"
            />
            <path
                d="M12 30L12.866 30.5L12 32L11.134 30.5L12 30ZM15.8231 23.3781L14.9571 22.8781L15.1462 22.5506L15.5047 22.4302L15.8231 23.3781ZM8.17686 23.3781L8.49525 22.4302L8.85378 22.5506L9.04289 22.8781L8.17686 23.3781ZM11.134 29.5L14.9571 22.8781L16.6892 23.8781L12.866 30.5L11.134 29.5ZM9.04289 22.8781L12.866 29.5L11.134 30.5L7.31084 23.8781L9.04289 22.8781ZM1 12C1 16.8483 4.13725 20.9664 8.49525 22.4302L7.85847 24.3261C2.71062 22.5971 -1 17.7332 -1 12H1ZM12 1C5.92487 1 1 5.92487 1 12H-1C-1 4.8203 4.8203 -1 12 -1V1ZM23 12C23 5.92487 18.0751 1 12 1V-1C19.1797 -1 25 4.8203 25 12H23ZM15.5047 22.4302C19.8627 20.9664 23 16.8483 23 12H25C25 17.7332 21.2894 22.5971 16.1415 24.3261L15.5047 22.4302Z"
                fill="currentColor"
                mask="url(#iconFeedbackOutlineMask)"
            />
        </svg>
    );
};

export default IconFeedbackOutline;
