import React from 'react';

const Icon = () => {
  return (
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M57.156 65.835H8.844V9.37201L19.47 0.165009H57.156V65.835Z" fill="#E6E6E4"/>
      <path d="M47.52 15.444H19.47V38.214H47.52V15.444Z" fill="#08122A"/>
      <path d="M8.844 9.37201H19.47V0.165009L8.844 9.37201Z" fill="#AFAFAC"/>
      <path d="M28.9054 53.1406C28.9054 53.6043 28.8925 54.0538 28.8667 54.489C28.8437 54.9243 28.7978 55.3123 28.729 55.6529C28.6602 55.9841 28.5613 56.2538 28.4323 56.4619C28.3061 56.6606 28.1398 56.76 27.9333 56.76C27.6925 56.76 27.4889 56.5708 27.3226 56.1923C27.1563 55.8138 27.0487 55.2224 27 54.4181L27.6151 53.9213C27.6237 54.1673 27.6566 54.3755 27.714 54.5458C27.7742 54.7161 27.8416 54.8013 27.9161 54.8013C27.9935 54.8013 28.0538 54.7398 28.0968 54.6168C28.1398 54.4843 28.1713 54.3234 28.1914 54.1342C28.2115 53.9449 28.2229 53.7415 28.2258 53.5239C28.2315 53.2968 28.2344 53.0886 28.2344 52.8994V46.4555H28.9054V53.1406Z" fill="#08122A"/>
      <path d="M30.9871 48.911C30.9326 48.6839 30.8581 48.5183 30.7634 48.4142C30.6717 48.3006 30.5842 48.2439 30.5011 48.2439C30.4523 48.2439 30.4022 48.2628 30.3505 48.3006C30.2989 48.3385 30.2502 48.4 30.2043 48.4852C30.1584 48.5609 30.1211 48.6649 30.0925 48.7974C30.0638 48.9299 30.0495 49.0907 30.0495 49.28C30.0495 49.5828 30.0839 49.8146 30.1527 49.9755C30.2215 50.1363 30.3075 50.2735 30.4108 50.3871C30.5168 50.5006 30.6301 50.6142 30.7505 50.7277C30.871 50.8413 30.9828 51.0021 31.086 51.2103C31.1921 51.4185 31.2796 51.6976 31.3484 52.0477C31.4172 52.3978 31.4516 52.8662 31.4516 53.4529C31.4516 54.0112 31.4201 54.4985 31.357 54.9148C31.2939 55.3312 31.2079 55.6766 31.0989 55.951C30.9928 56.2254 30.8681 56.4288 30.7247 56.5613C30.5842 56.6938 30.4351 56.76 30.2774 56.76C30.0796 56.76 29.8961 56.6606 29.7269 56.4619C29.5577 56.2632 29.4 55.9415 29.2538 55.4968L29.7312 53.7652C29.8 54.068 29.886 54.3045 29.9892 54.4748C30.0953 54.6357 30.2043 54.7161 30.3161 54.7161C30.3706 54.7161 30.4251 54.6972 30.4796 54.6594C30.5369 54.612 30.5871 54.5458 30.6301 54.4606C30.676 54.3755 30.7118 54.2667 30.7376 54.1342C30.7663 54.0017 30.7806 53.8456 30.7806 53.6658C30.7806 53.363 30.7448 53.1264 30.6731 52.9561C30.6043 52.7763 30.5168 52.6249 30.4108 52.5019C30.3047 52.3789 30.19 52.2559 30.0667 52.1329C29.9434 52.0099 29.8287 51.8443 29.7226 51.6361C29.6165 51.428 29.5276 51.1535 29.4559 50.8129C29.3871 50.4723 29.3527 50.0228 29.3527 49.4645C29.3527 48.9252 29.3842 48.452 29.4473 48.0452C29.5133 47.6383 29.5993 47.2976 29.7054 47.0232C29.8143 46.7488 29.9391 46.5454 30.0796 46.4129C30.2201 46.271 30.3649 46.2 30.514 46.2C30.686 46.2 30.8523 46.2804 31.0129 46.4413C31.1735 46.6021 31.3183 46.8718 31.4473 47.2503L30.9871 48.911Z" fill="#08122A"/>
      <path d="M31.788 51.48C31.788 50.6757 31.8282 49.9471 31.9085 49.2942C31.9916 48.6413 32.1063 48.0877 32.2526 47.6335C32.3988 47.1699 32.5723 46.8151 32.773 46.569C32.9737 46.323 33.1931 46.2 33.431 46.2C33.669 46.2 33.8884 46.323 34.0891 46.569C34.2898 46.8151 34.4633 47.1699 34.6095 47.6335C34.7558 48.0877 34.869 48.6413 34.9493 49.2942C35.0325 49.9471 35.0741 50.6757 35.0741 51.48C35.0741 52.2843 35.0325 53.0129 34.9493 53.6658C34.869 54.3187 34.7558 54.877 34.6095 55.3406C34.4633 55.7948 34.2898 56.1449 34.0891 56.391C33.8884 56.637 33.669 56.76 33.431 56.76C33.1931 56.76 32.9737 56.637 32.773 56.391C32.5723 56.1449 32.3988 55.7948 32.2526 55.3406C32.1063 54.877 31.9916 54.3187 31.9085 53.6658C31.8282 53.0129 31.788 52.2843 31.788 51.48ZM32.4848 51.48C32.4848 51.9531 32.5063 52.3884 32.5493 52.7858C32.5952 53.1738 32.6597 53.5144 32.7429 53.8077C32.826 54.0916 32.925 54.314 33.0397 54.4748C33.1572 54.6357 33.2877 54.7161 33.431 54.7161C33.5744 54.7161 33.7035 54.6357 33.8181 54.4748C33.9357 54.314 34.0361 54.0916 34.1192 53.8077C34.2024 53.5144 34.2655 53.1738 34.3085 52.7858C34.3543 52.3884 34.3773 51.9531 34.3773 51.48C34.3773 51.0163 34.3543 50.5858 34.3085 50.1884C34.2655 49.791 34.2024 49.4503 34.1192 49.1664C34.0361 48.8731 33.9357 48.646 33.8181 48.4852C33.7035 48.3243 33.5744 48.2439 33.431 48.2439C33.2877 48.2439 33.1572 48.3243 33.0397 48.4852C32.925 48.646 32.826 48.8731 32.7429 49.1664C32.6597 49.4503 32.5952 49.791 32.5493 50.1884C32.5063 50.5858 32.4848 51.0163 32.4848 51.48Z" fill="#08122A"/>
      <path d="M35.5301 46.4555H36.4419L37.7366 53.4387H37.7452V46.4555H38.4161V56.5045H37.5387L36.2097 49.351H36.2011V56.5045H35.5301V46.4555Z" fill="#08122A"/>
      <path d="M27.126 21.186H26.466C26.466 21.186 26.202 21.219 26.103 21.285C26.004 21.351 25.938 21.45 25.872 21.582C25.806 21.714 25.773 21.846 25.74 21.978C25.74 22.143 25.707 22.275 25.707 22.44V24.618C25.707 24.948 25.707 25.245 25.575 25.476C25.476 25.707 25.377 25.905 25.245 26.037C25.113 26.202 24.981 26.301 24.849 26.367C24.717 26.433 24.585 26.499 24.519 26.499C24.519 26.499 24.717 26.598 24.849 26.664C24.981 26.73 25.113 26.829 25.245 26.994C25.377 27.126 25.476 27.324 25.575 27.588C25.674 27.819 25.707 28.149 25.707 28.512V30.657C25.707 30.657 25.707 30.954 25.74 31.086C25.74 31.218 25.806 31.383 25.872 31.482C25.938 31.614 26.004 31.713 26.103 31.779C26.202 31.845 26.301 31.878 26.466 31.878H27.126V32.901H26.037C25.839 32.901 25.674 32.868 25.509 32.769C25.344 32.67 25.179 32.538 25.014 32.373C24.849 32.208 24.75 31.977 24.651 31.68C24.552 31.416 24.519 31.086 24.519 30.69V28.776C24.519 28.248 24.387 27.852 24.156 27.555C23.925 27.258 23.661 27.093 23.331 27.093V25.971C23.661 25.971 23.925 25.839 24.156 25.542C24.387 25.245 24.519 24.849 24.519 24.288V22.011C24.519 21.714 24.552 21.45 24.651 21.186C24.75 20.955 24.849 20.757 25.014 20.592C25.179 20.427 25.311 20.328 25.509 20.229C25.674 20.163 25.872 20.097 26.037 20.097H27.126V21.12V21.186Z" fill="#E6E6E4"/>
      <path d="M33.429 32.538H32.241L33.231 29.007H34.617L33.429 32.538ZM32.604 24.684C32.604 24.42 32.703 24.189 32.901 24.024C33.099 23.826 33.297 23.76 33.561 23.76C33.825 23.76 34.056 23.859 34.221 24.024C34.419 24.222 34.518 24.42 34.518 24.684C34.518 24.948 34.419 25.179 34.221 25.344C34.023 25.542 33.825 25.608 33.561 25.608C33.297 25.608 33.066 25.509 32.901 25.344C32.703 25.146 32.604 24.948 32.604 24.684Z" fill="#E6E6E4"/>
      <path d="M39.963 31.911H40.623C40.623 31.911 40.887 31.878 40.986 31.812C41.085 31.746 41.151 31.647 41.217 31.515C41.283 31.383 41.316 31.251 41.349 31.119C41.382 30.987 41.382 30.822 41.382 30.657V28.479C41.382 28.149 41.382 27.852 41.514 27.621C41.613 27.39 41.712 27.192 41.844 27.06C41.976 26.928 42.108 26.796 42.24 26.73C42.372 26.664 42.504 26.598 42.57 26.598C42.57 26.598 42.372 26.499 42.24 26.433C42.108 26.367 41.976 26.268 41.844 26.103C41.712 25.938 41.613 25.773 41.514 25.509C41.415 25.278 41.382 24.948 41.382 24.585V22.44C41.382 22.44 41.382 22.143 41.349 22.011C41.349 21.879 41.283 21.747 41.217 21.615C41.151 21.483 41.085 21.417 40.986 21.318C40.887 21.252 40.788 21.219 40.623 21.219H39.963V20.196H41.052C41.25 20.196 41.415 20.229 41.58 20.328C41.745 20.427 41.91 20.559 42.075 20.724C42.24 20.889 42.339 21.12 42.438 21.417C42.537 21.681 42.57 22.011 42.57 22.407V24.321C42.57 24.849 42.702 25.278 42.933 25.575C43.164 25.872 43.461 26.004 43.791 26.004V27.126C43.461 27.126 43.197 27.258 42.933 27.588C42.702 27.885 42.57 28.281 42.57 28.809V31.086C42.57 31.383 42.537 31.68 42.438 31.911C42.339 32.142 42.24 32.34 42.075 32.505C41.943 32.67 41.778 32.769 41.58 32.868C41.382 32.967 41.217 33 41.052 33H39.963V31.977V31.911Z" fill="#E6E6E4"/>
    </svg>
  )
}

export default Icon