import React, { memo, useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import moment from 'moment';
import { Basepage } from '@components/Basepage';
import { PageContainer } from '@components/PageContainer';
import { REQUESTS } from '@constants/routes';
import { USER_TYPE_WORKER } from '@constants/account';
import { Link } from '@components/Link';
import ArrowLeftIcon from '@components/Svg/ArrowLeft';
import { Text } from '@components/Text';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Badge } from '@components/Badge';
import { Breadcrumb, BreadcrumbItem } from '@components/Breadcrumb';
import withLoggedUser from '@components/WithLoggedUser';
import { Skeleton } from '@components/Skeleton';
import DocumentTitle from '@components/DocumentTitle';
import message from '@components/Message';
import { ORDER } from '@graphql/queries/order';
import { CHANGE_ORDER_STATUS } from '@graphql/mutations/order';
import { ALL_USERS } from '@graphql/queries/user';
import { ORDER_STATUS_LABELS } from '@constants/order';
import ArrowDownIcon from '@components/Svg/ArrowDown';
import { Tooltip } from 'antd';
import DetailContent from '../blocks/DetailRequest/DetailContent';
import SubscriptionInactive from '../blocks/SubscriptionInactive';
import { MessageContext } from '../blocks/DetailRequest/MessageContext';
import { DetailContext } from '../blocks/DetailRequest/DetailContext';
import DesignersField from '../blocks/DetailRequest/DesignersField';
import { StatusSelect } from '../blocks/DetailRequest/StatusSelect';
import * as qs from 'query-string';
import { useHistory } from 'react-router-dom';
function toArray(arrayLike) {
    const newArray = [];

    if (arrayLike?.length) {
        for (let x = 0; x < arrayLike.length; x += 1) {
            newArray.push(arrayLike[x]);
        }
    }

    return newArray;
}

const DetailRequest = memo(({ viewer, match }) => {
    const [activeFolderId, setActiveFolderId] = useState(() => {
        const parsed = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        return parsed.folder ?? null;
    });

    const statusRef = useRef(null);
    const [activeTab, setTab] = useState(() => {
        const parsed = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        return parsed.tab ?? 'messages';
    });

    const setActiveTab = (tab) => {
        let parsed = qs.parse(window.location.search);
        parsed.tab = tab;
        if (parsed.folder && tab !== 'files') {
            setActiveFolderId(null)
            delete parsed.folder
        }
        const location = window.location;
        const stringify = qs.stringify(parsed);
        setTab(tab)
  
        console.log(12312312)
        window.history.pushState('','',`${location.pathname}?${stringify}`);
    }

    const history = useHistory();

    const [refetching, setRefetching] = useState(false);
    const [isOpenStatusSelect, setIsOpenStatusSelect] = useState(false);
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const { params } = match;
    const viewerId = viewer?.id;
    const viewerRole = viewer?.role;
    const { data, loading, refetch } = useQuery(ORDER, {
        variables: {
            id: +params?.id,
        },
        fetchPolicy: 'network-only',
    });
    const { data: usersData } = useQuery(ALL_USERS, {
        variables: {
            where: {
                role_in: ['owner', 'manager', 'worker'],
                archived: false,
                id_not: viewerId,
            },
        },
        fetchPolicy: 'network-only',
    });
    const [updateOrderStatus] = useMutation(CHANGE_ORDER_STATUS);

    const request = {
        ...data?.Order,
    };
    const files = data?.Order?.attachments;
    const size = files ? files.reduce((acc, f) => acc + parseInt(f.size), 0) : 0;

    const previewsFinal = toArray(request.previews);
    previewsFinal.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
    const folders = [{ id: 'attachments', size, name: 'Discussion Attachments', files }].concat(previewsFinal);
    if (files?.length) {
        folders[0].createdAt = files[0]?.createdAt
        folders[0].updatedAt = files[files.length - 1]?.updatedAt
    }
    const users = usersData?.allUsers ?? [];

    const isSubscriptionActive = true;
    const isSubscriptionPaused = false;

    const designerIds =
        request?.workers && request?.workers?.length > 0 ? request?.workers?.map(worker => worker.id) : undefined;
    const dateNow = moment().startOf('day');
    const lastPrioritized = request?.prioritizedAt ? moment(request?.prioritizedAt).startOf('day') : null;
    const dateDiff = request?.prioritizedAt ? dateNow.diff(lastPrioritized, 'days') : null;
    const checkOngoingSubmittedStatus =
        request.status === 'ONGOING_PROJECT' || request.status === 'SUBMITTED' ? 'pointer' : 'default';
    const defaultPointer = viewerRole === USER_TYPE_WORKER ? checkOngoingSubmittedStatus : 'pointer';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const handleClickOutside = ev => {
            if (statusRef.current instanceof HTMLDivElement && !statusRef.current.contains(ev.target)) {
                setIsOpenStatusSelect(false);
            }
        };

        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [isOpenStatusSelect]);

    useEffect(() => {
        if (activeFolderId) {
            setActiveTab('files');
        }
    }, [activeFolderId]);

    const refetchRequests = async () => {
        setRefetching(true);
        await refetch();
        setRefetching(false);
    };

    const handleShowStatusSelect = bool => {
        if (viewerRole === USER_TYPE_WORKER) {
            if (['ONGOING_PROJECT', 'ONGOING_REVISION', 'SUBMITTED', 'DELIVERED_PROJECT', 'DELIVERED_REVISION'].includes(request.status)) {
                setIsOpenStatusSelect(bool);
            }
        } else {
            setIsOpenStatusSelect(bool);
        }
    };

    const handleChangeOrderStatus = async val => {
        setIsOpenStatusSelect(false);
        setIsUpdatingStatus(true);
        message.destroy();
        message.loading('Updating status...', 50000);
        await updateOrderStatus({
            variables: {
                id: request.id,
                status: val,
            },
        })
            .then(async () => {
                await refetch();
                message.destroy();
                message.success('Status has been updated');
                setIsUpdatingStatus(false);
            })
            .catch(e => {
                console.log(e);
                message.destroy();
                message.error('Error on updating request status');
                setIsUpdatingStatus(false);
            });
    };

    if (!(data?.Order && usersData?.allUsers)) {
        return (
            <Basepage>
                <MySkeleton />
            </Basepage>
        );
    }

    return (
        <DocumentTitle title={`#${+params?.id} ${request?.name ?? ''} | ManyPixels`}>
            <DetailContext.Provider
                value={{
                    folders,
                    request,
                    refetchRequests,
                    activeTab,
                    setActiveTab,
                    isSubscriptionPaused,
                    viewerId,
                    viewerRole,
                    activeFolderId,
                    users,
                    setActiveFolderId
                }}
            >
                <MessageContext.Provider value={{ messages: [] }}>
                    <Basepage>
                        {loading && !refetching ? (
                            <MySkeleton />
                        ) : (
                            <PageContainer maxW="1288" d="flex" flexDir="row">
                                <Box pt="4">
                                    <Button
                                        w="36"
                                        h="36"
                                        mobileH="36"
                                        type="default"
                                        className="ant-btn ant-btn-default"
                                        onClick={history.goBack}
                                        icon={<ArrowLeftIcon style={{ fontSize: 20 }} />}
                                    />
                                </Box>
                                <Box pl="20" flex="1" h="100%">
                                    <Box>
                                        <Box d="flex" alignItems="flex-start" justifyContent="space-between">
                                            <Tooltip
                                                title={request.name}
                                                overlayClassName="ant-default-tooltip-overlay"
                                                placement="bottomLeft"
                                                trigger="hover"
                                            >
                                                <Text
                                                    textVariant="H3"
                                                    colorScheme="headline"
                                                    mb="12"
                                                    cursor="default"
                                                    maxW="660"
                                                    isTruncate
                                                >
                                                    {request.name}
                                                </Text>
                                            </Tooltip>
                                            <Box d="flex" alignItems="center">
                                                <DesignersField
                                                    requestId={request.id}
                                                    designerIds={designerIds}
                                                    requestStatus={request.status}
                                                    isWorker={viewerRole === USER_TYPE_WORKER}
                                                />
                                                {isSubscriptionActive && (
                                                    <Box pos="relative">
                                                        <Box
                                                            ml="16"
                                                            cursor={defaultPointer}
                                                            style={{
                                                                pointerEvents: isUpdatingStatus ? 'none' : 'auto',
                                                            }}
                                                            onClick={() => handleShowStatusSelect(!isOpenStatusSelect)}
                                                        >
                                                            <Badge variant={ORDER_STATUS_LABELS[request.status]}>
                                                                {ORDER_STATUS_LABELS[request.status]}
                                                               
                                                                <Box
                                                                    ml="9"
                                                                    d="inline-block"
                                                                    w="20"
                                                                    h="20"
                                                                    transform={
                                                                        isOpenStatusSelect
                                                                            ? 'rotate(180deg)'
                                                                            : 'rotate(0deg)'
                                                                    }
                                                                >
                                                                    <ArrowDownIcon />
                                                                </Box>
                                                            </Badge>
                                                        </Box>

                                                        <StatusSelect
                                                            ref={statusRef}
                                                            isOpenStatusSelect={isOpenStatusSelect}
                                                            requestStatus={request.status}
                                                            handleChangeOrderStatus={handleChangeOrderStatus}
                                                            isWorker={viewerRole === USER_TYPE_WORKER}
                                                        />
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box d="flex" alignItems="center" mb="16">
                                            <Box>
                                                <Breadcrumb>
                                                    <BreadcrumbItem isFirst as={Link} to={REQUESTS}>
                                                        Requests
                                                    </BreadcrumbItem>
                                                    <BreadcrumbItem>Details</BreadcrumbItem>
                                                </Breadcrumb>
                                            </Box>
                                            <Box mx="10" h="20" w="1" bg="outline-gray" />
                                            <Box>
                                                <Text textVariant="H6" colorScheme="cta" lineH="20">
                                                    #{request.id}
                                                </Text>
                                            </Box>
                                            {dateDiff !== null && dateDiff < 1 ? (
                                                <>
                                                    <Box mx="10" h="20" w="1" bg="outline-gray" />
                                                    <Text textVariant="P4" colorScheme="other-red">
                                                        Priority Request
                                                    </Text>
                                                </>
                                            ) : null}
                                        </Box>
                                    </Box>
                                    {isSubscriptionActive ? (
                                        <Box>
                                            <DetailContent activeFolderId={activeFolderId} />
                                        </Box>
                                    ) : (
                                        <SubscriptionInactive spaceTop="153">
                                            This request and its associated files have been deleted as you are no longer
                                            an active subscriber. To submit new requests, please subscribe to a plan
                                            first.
                                        </SubscriptionInactive>
                                    )}
                                </Box>
                            </PageContainer>
                        )}
                    </Basepage>
                </MessageContext.Provider>
            </DetailContext.Provider>
        </DocumentTitle>
    );
});

export default withLoggedUser(DetailRequest);

function MySkeleton() {
    return (
        <PageContainer maxW="1288">
            <Box mb="16">
                <Box d="flex" justifyContent="space-between">
                    <Box flex="1" mr="20">
                        <Skeleton maxW="881" w="100%" h="44" mb="12" />
                        <Skeleton h="20" w="201" />
                    </Box>
                    <Skeleton w="104" h="32" />
                </Box>
            </Box>
            <Box d="flex" flexDir="row" mx="-15px" flexWrap="wrap">
                <Box px="15px" w="100%" maxW="calc(100% - 300px)">
                    <Box borderW="1" borderStyle="solid" borderColor="outline-gray">
                        <Box
                            borderW="0"
                            borderB="1"
                            borderStyle="solid"
                            borderColor="outline-gray"
                            px="20"
                            py="14"
                            d="flex"
                            alignItems="center"
                        >
                            <Skeleton w="45" h="20" mr="26" />
                            <Skeleton w="45" h="20" mr="26" />
                            <Skeleton w="45" h="20" />
                        </Box>
                        <Box px="20" pt="16" pb="14">
                            <Box d="flex" mb="16">
                                <Skeleton variant="avatar" w="32" h="32" mr="16" />
                                <Box flex="1">
                                    <Box d="flex" alignItems="center" justifyContent="space-between" mb="6">
                                        <Skeleton w="92" h="20" />
                                        <Skeleton w="74" h="20" />
                                    </Box>
                                    <Skeleton w="100%" h="40" />
                                </Box>
                            </Box>
                            <Box d="flex" mb="22">
                                <Skeleton variant="avatar" w="32" h="32" mr="16" />
                                <Box flex="1">
                                    <Box d="flex" alignItems="center" justifyContent="space-between" mb="6">
                                        <Skeleton w="92" h="20" />
                                        <Skeleton w="74" h="20" />
                                    </Box>
                                    <Skeleton w="100%" h="40" mb="10" />
                                    <Box d="flex" alignItems="center">
                                        <Skeleton maxW="241" w="100%" h="60" mr="14" />
                                        <Skeleton maxW="241" w="100%" h="60" />
                                    </Box>
                                </Box>
                            </Box>
                            <Box d="flex" alignItems="center" mb="22">
                                <Skeleton variant="avatar" w="32" h="32" mr="16" />
                                <Box flex="1">
                                    <Box d="flex" alignItems="center" justifyContent="space-between">
                                        <Skeleton maxW="303" w="100%" h="20" mr="20" />
                                        <Skeleton w="74" h="20" />
                                    </Box>
                                </Box>
                            </Box>
                            <Box d="flex" mb="16">
                                <Skeleton variant="avatar" w="32" h="32" mr="16" />
                                <Box flex="1">
                                    <Box d="flex" alignItems="center" justifyContent="space-between" mb="6">
                                        <Skeleton w="92" h="20" />
                                        <Skeleton w="74" h="20" />
                                    </Box>
                                    <Skeleton w="100%" h="40" />
                                </Box>
                            </Box>
                            <Box d="flex" mb="16">
                                <Skeleton variant="avatar" w="32" h="32" mr="16" />
                                <Box flex="1">
                                    <Box d="flex" alignItems="center" justifyContent="space-between" mb="6">
                                        <Skeleton w="92" h="20" />
                                        <Skeleton w="74" h="20" />
                                    </Box>
                                    <Skeleton w="100%" h="40" />
                                </Box>
                            </Box>
                            <Box d="flex" mb="34">
                                <Skeleton variant="avatar" w="32" h="32" mr="16" />
                                <Box flex="1">
                                    <Box d="flex" alignItems="center" justifyContent="space-between" mb="6">
                                        <Skeleton w="92" h="20" />
                                        <Skeleton w="74" h="20" />
                                    </Box>
                                    <Skeleton w="100%" h="40" />
                                </Box>
                            </Box>
                            <Box borderW="1" borderStyle="solid" borderColor="#BEC6D6" px="10" pt="16" pb="10">
                                <Skeleton w="155" h="20" mb="27" />
                                <Box d="flex" alignItems="center" justifyContent="space-between">
                                    <Box d="flex" alignItems="center">
                                        <Skeleton w="20" h="20" mr="16" />
                                        <Skeleton w="20" h="20" mr="16" />
                                        <Skeleton w="20" h="20" mr="16" />
                                        <Skeleton w="20" h="20" mr="16" />
                                        <Skeleton w="20" h="20" mr="16" />
                                        <Skeleton w="20" h="20" />
                                    </Box>
                                    <Box d="flex" alignItems="center">
                                        <Skeleton w="20" h="20" mr="24" />
                                        <Skeleton w="20" h="20" />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box px="15px" w="100%" maxW="300px">
                    <Box mb="14">
                        <Box
                            px="20"
                            pt="15"
                            pb="14"
                            borderW="1"
                            borderStyle="solid"
                            borderColor="outline-gray"
                            borderB="0"
                            borderBottomStyle="solid"
                            borderBottomColor="outline-gray"
                        >
                            <Skeleton w="107" h="20" />
                        </Box>
                        <Box px="20" pt="14" pb="16" borderW="1" borderStyle="solid" borderColor="outline-gray">
                            <Box mb="16">
                                <Skeleton w="36" h="16" mb="8" />
                                <Box d="flex" alignItems="center">
                                    <Skeleton w="24" h="24" mr="10" />
                                    <Skeleton w="58" h="16" mr="10" />
                                </Box>
                            </Box>
                            <Box mb="16">
                                <Skeleton w="36" h="16" mb="6" />
                                <Skeleton w="120" h="16" />
                            </Box>
                            <Box mb="16">
                                <Skeleton w="36" h="16" mb="6" />
                                <Skeleton w="94" h="18" />
                            </Box>
                            <Box mb="16">
                                <Skeleton w="36" h="16" mb="6" />
                                <Skeleton w="150" h="16" />
                            </Box>
                            <Box mb="16">
                                <Skeleton w="36" h="16" mb="6" />
                                <Skeleton w="150" h="16" />
                            </Box>
                            <Box mb="16">
                                <Skeleton w="36" h="16" mb="6" />
                                <Skeleton w="110" h="16" />
                            </Box>
                            <Skeleton w="100%" h="34" mb="10" />
                            <Skeleton w="100%" h="34" />
                        </Box>
                    </Box>
                    <Skeleton w="100%" h="170" />
                </Box>
            </Box>
        </PageContainer>
    );
}
