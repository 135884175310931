import React from 'react';

const IconMarkComplete = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.6799 19.29H0.839941C0.667551 19.29 0.502221 19.2216 0.380322 19.0997C0.258423 18.9778 0.189941 18.8124 0.189941 18.64V4.45005C0.189941 4.36469 0.206754 4.28017 0.23942 4.2013C0.272085 4.12244 0.319964 4.05079 0.380322 3.99043C0.44068 3.93007 0.512336 3.88219 0.591197 3.84953C0.670059 3.81686 0.754582 3.80005 0.839941 3.80005H13.7699C13.9423 3.80005 14.1077 3.86853 14.2296 3.99043C14.3515 4.11233 14.4199 4.27766 14.4199 4.45005C14.4199 4.62244 14.3515 4.78777 14.2296 4.90967C14.1077 5.03157 13.9423 5.10005 13.7699 5.10005H1.48994V18H14.9999V10C14.9999 9.82766 15.0684 9.66233 15.1903 9.54043C15.3122 9.41853 15.4776 9.35005 15.6499 9.35005C15.8223 9.35005 15.9877 9.41853 16.1096 9.54043C16.2315 9.66233 16.2999 9.82766 16.2999 10V18.62C16.3054 18.7908 16.2434 18.9568 16.1273 19.0822C16.0113 19.2076 15.8506 19.2823 15.6799 19.29Z"
                fill="currentColor"
            />
            <path
                d="M8.57983 14.48C8.49025 14.4795 8.40184 14.4595 8.32071 14.4215C8.23957 14.3836 8.16763 14.3285 8.10983 14.26L4.15983 9.67001C4.04844 9.5374 3.99429 9.36597 4.00929 9.19344C4.0243 9.0209 4.10722 8.8614 4.23983 8.75001C4.37244 8.63861 4.54387 8.58446 4.7164 8.59947C4.88893 8.61447 5.04844 8.6974 5.15983 8.83001L8.61983 12.83L18.8098 2.36001C18.8696 2.29894 18.9408 2.25024 19.0193 2.21669C19.0979 2.18314 19.1823 2.1654 19.2678 2.16447C19.3532 2.16354 19.438 2.17945 19.5172 2.21128C19.5965 2.24312 19.6688 2.29025 19.7298 2.35001C19.7909 2.40976 19.8396 2.48095 19.8731 2.55952C19.9067 2.63809 19.9244 2.7225 19.9254 2.80793C19.9263 2.89336 19.9104 2.97814 19.8786 3.05742C19.8467 3.1367 19.7996 3.20894 19.7398 3.27001L9.04983 14.27C8.9892 14.3343 8.91647 14.3859 8.8358 14.4219C8.75514 14.458 8.66815 14.4777 8.57983 14.48Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconMarkComplete;
