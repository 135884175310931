import React from 'react';

const IconOrderPause = () => {
  return (
    <svg width="163" height="161" viewBox="0 0 163 161" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M111.809 129.793C111.809 130.132 111.809 133.687 111.809 134.026C110.152 139.669 96.0972 143.676 79.0145 143.676C60.8462 143.676 46.106 138.879 46.106 132.954V77.594C51.8192 75.506 102.553 77.594 111.98 77.594C111.98 77.594 111.98 86.5667 111.98 93.5078"
        fill="white"
      />
      <path
        d="M78.9003 144.296C60.0464 144.296 45.249 139.274 45.249 133.01V77.5937C45.2558 77.4505 45.302 77.3118 45.3825 77.1926C45.463 77.0733 45.5749 76.9779 45.7061 76.9166C50.3338 75.3365 79.2431 76.0701 98.3826 76.578C104.553 76.578 109.466 76.9166 111.809 76.9166C111.976 76.8607 112.156 76.8607 112.323 76.9166C112.367 77.0831 112.367 77.2579 112.323 77.4245C112.323 77.4245 112.323 86.3971 112.323 93.3382C112.323 93.5328 112.245 93.7194 112.105 93.857C111.966 93.9945 111.777 94.0718 111.58 94.0718C111.393 94.0576 111.219 93.9741 111.092 93.838C110.964 93.7019 110.894 93.5234 110.895 93.3382C110.895 87.6951 110.895 80.3589 110.895 78.1581L98.0969 77.8195C80.9702 76.8816 63.804 76.8816 46.6773 77.8195V132.502C46.6773 137.355 59.6465 142.547 78.9003 142.547C95.3545 142.547 109.409 138.54 111.009 133.348C111.009 133.01 111.009 130.358 111.009 129.342C111.008 129.157 111.079 128.978 111.206 128.842C111.333 128.706 111.508 128.622 111.695 128.608C111.891 128.608 112.08 128.685 112.22 128.823C112.359 128.961 112.437 129.147 112.437 129.342C112.515 130.827 112.515 132.315 112.437 133.8C110.609 139.894 96.2115 144.296 78.9003 144.296Z"
        fill="#07122B"
      />
      <path d="M111.695 100.674C111.695 111.058 112.095 117.322 112.095 124.771L111.695 100.674Z" fill="white" />
      <path
        d="M112.095 125.448C112.005 125.448 111.916 125.431 111.832 125.397C111.749 125.363 111.674 125.313 111.61 125.25C111.546 125.187 111.496 125.112 111.461 125.03C111.427 124.948 111.409 124.86 111.409 124.771C111.409 121.329 111.409 118.168 111.409 114.782C111.409 111.397 111.409 106.318 111.409 100.675C111.401 100.581 111.413 100.487 111.444 100.398C111.475 100.309 111.524 100.228 111.589 100.159C111.653 100.09 111.731 100.035 111.818 99.9973C111.906 99.9598 112 99.9406 112.095 99.9409C112.292 99.9409 112.481 100.018 112.62 100.156C112.759 100.293 112.837 100.48 112.837 100.675C112.837 106.318 112.837 110.776 112.837 114.726C112.837 118.676 112.837 121.329 112.837 124.771C112.838 124.865 112.818 124.958 112.78 125.044C112.742 125.13 112.687 125.207 112.617 125.271C112.547 125.335 112.465 125.383 112.375 125.414C112.285 125.444 112.189 125.456 112.095 125.448Z"
        fill="#07122B"
      />
      <path
        d="M78.9002 89.8395C97.1066 89.8395 111.866 84.3822 111.866 77.6502C111.866 70.9183 97.1066 65.4609 78.9002 65.4609C60.6938 65.4609 45.9346 70.9183 45.9346 77.6502C45.9346 84.3822 60.6938 89.8395 78.9002 89.8395Z"
        fill="white"
      />
      <path
        d="M78.9003 90.5728C60.0464 90.5728 45.249 84.9296 45.249 77.6499C45.249 70.3702 60.0464 64.7271 78.9003 64.7271C97.7541 64.7271 112.609 70.3702 112.609 77.6499C112.609 84.9296 97.8112 90.5728 78.9003 90.5728ZM78.9003 66.1378C61.4748 66.1378 46.6773 71.386 46.6773 77.4242C46.6773 83.4624 61.4748 88.7106 78.9003 88.7106C96.3258 88.7106 111.18 83.406 111.18 77.4242C111.18 71.4424 96.3829 66.1378 78.9003 66.1378Z"
        fill="#07122B"
      />
      <path
        d="M106.496 77.5938C106.494 78.2307 106.316 78.8551 105.981 79.3996C103.468 83.5191 92.3267 86.6228 78.9005 86.6228C65.4743 86.6228 52.9051 83.1241 51.4196 78.5531C51.392 78.2339 51.392 77.913 51.4196 77.5938C51.4196 72.6278 63.7603 68.6211 78.9576 68.6211C94.155 68.6211 106.496 72.6278 106.496 77.5938Z"
        fill="white"
      />
      <path
        d="M78.9004 87.3001C64.1602 87.3001 52.3337 83.6885 50.7911 78.7225C50.7597 78.4223 50.7597 78.1197 50.7911 77.8196C50.7911 72.1764 63.1889 68.1133 79.0147 68.1133C94.8405 68.1133 107.581 72.3457 107.581 77.8196C107.567 78.5766 107.35 79.3165 106.953 79.964C103.867 84.1964 92.498 87.3001 78.9004 87.3001ZM78.9004 69.2983C62.8461 69.2983 52.1051 73.5872 52.1051 77.5938C52.077 77.8187 52.077 78.0461 52.1051 78.271C53.3049 82.0519 63.5317 85.8893 78.8433 85.8893C91.4125 85.8893 102.839 82.9549 105.353 79.0046C105.641 78.5882 105.8 78.098 105.81 77.5938C105.81 73.3614 95.0119 69.2983 78.9004 69.2983Z"
        fill="#07122B"
      />
      <path
        d="M105.981 79.3996C103.467 83.5191 92.3266 86.6228 78.9003 86.6228C65.4741 86.6228 52.9049 83.1241 51.4194 78.5531C53.9904 74.4336 65.1313 71.3862 78.4433 71.3862C92.7265 71.3862 104.439 74.885 105.981 79.3996Z"
        fill="#EF457E"
      />
      <path
        d="M54.0479 114.669V107.22C54.0479 106.769 54.0479 106.6 54.6192 106.656H56.3332L57.133 107.22C57.8487 107.005 58.6134 107.009 59.327 107.23C60.0406 107.452 60.6698 107.881 61.1323 108.462C61.814 107.975 62.6337 107.713 63.4748 107.713C64.3158 107.713 65.1355 107.975 65.8172 108.462C66.4565 108.714 66.9964 109.163 67.3567 109.743C67.7169 110.322 67.8783 111.001 67.8169 111.678V115.742C67.8169 116.249 67.8169 116.475 67.1884 116.362H65.1316V114.5C65.1316 113.71 65.1316 112.863 65.1316 112.073C65.1395 111.663 65.0006 111.262 64.7395 110.942C64.4783 110.622 64.1115 110.404 63.7033 110.324C62.9034 110.324 62.3321 110.832 62.3321 111.735V116.024L59.5326 115.742V112.863C59.5326 112.13 59.5326 111.396 59.5326 110.663C59.5485 110.283 59.4145 109.911 59.1587 109.627C58.9029 109.343 58.5453 109.168 58.1614 109.139C57.9852 109.114 57.8054 109.127 57.6352 109.179C57.465 109.231 57.3085 109.319 57.1773 109.438C57.046 109.557 56.9431 109.703 56.8762 109.866C56.8092 110.029 56.7799 110.205 56.7902 110.38V114.387C56.8137 114.518 56.8137 114.652 56.7902 114.782L54.0479 114.669Z"
        fill="#0099F6"
      />
      <path
        d="M72.2731 108.801C73.0512 108.408 73.9517 108.324 74.7906 108.565C75.6295 108.806 76.3435 109.355 76.7866 110.098C77.3516 110.934 77.6532 111.916 77.6532 112.92C77.6532 113.925 77.3516 114.906 76.7866 115.742C76.4771 116.063 76.1049 116.318 75.6924 116.493C75.2799 116.667 74.8358 116.757 74.3871 116.757C73.9383 116.757 73.4942 116.667 73.0817 116.493C72.6692 116.318 72.297 116.063 71.9875 115.742V118.902L69.5308 118.62C69.5308 118.62 69.5308 118.168 69.5308 117.942V108.913C69.5308 108.293 69.5308 108.123 70.2735 108.236C71.0162 108.349 71.1305 108.236 71.5875 108.236C71.8364 108.399 72.0663 108.588 72.2731 108.801ZM71.8161 112.581C71.7823 113.094 71.9462 113.6 72.2748 113.998C72.6034 114.395 73.0723 114.656 73.5872 114.726C74.5585 114.726 75.3012 114.049 75.3012 112.864C75.324 112.362 75.1633 111.87 74.8486 111.476C74.5338 111.082 74.0861 110.814 73.5872 110.719C73.3095 110.686 73.0278 110.722 72.7684 110.826C72.5089 110.929 72.2801 111.095 72.1033 111.309C71.9265 111.523 71.8074 111.778 71.7572 112.05C71.707 112.322 71.7272 112.602 71.8161 112.864V112.581Z"
        fill="#0099F6"
      />
      <path
        d="M76.1007 107.841C75.6171 107.785 75.1503 107.632 74.7295 107.39C74.7295 107.39 74.7295 107.051 74.7295 106.825C74.7028 106.657 74.7028 106.486 74.7295 106.317V105.132H75.9293C76.4618 105.093 76.9966 105.093 77.5291 105.132C77.4984 105.395 77.4984 105.66 77.5291 105.922V107.164C77.5013 107.332 77.5013 107.504 77.5291 107.672L76.1007 107.841Z"
        fill="#07122B"
      />
      <path
        d="M79.9857 114.895C81.3569 114.895 81.1855 114.895 81.1855 116.475C81.1855 118.056 81.1855 117.548 80.1571 117.435C78.2146 117.435 78.1574 117.096 78.4431 115.121C78.4431 115.121 78.4431 114.782 78.9002 114.782C79.2652 114.779 79.6294 114.817 79.9857 114.895Z"
        fill="#07122B"
      />
      <path
        d="M121.693 131.204L102.211 129.116C102.115 129.11 102.022 129.083 101.937 129.039C101.852 128.995 101.778 128.933 101.719 128.859C101.66 128.785 101.617 128.699 101.593 128.607C101.57 128.515 101.566 128.419 101.582 128.326C101.589 128.232 101.615 128.14 101.66 128.056C101.705 127.972 101.767 127.899 101.843 127.84C101.918 127.782 102.005 127.74 102.098 127.716C102.191 127.693 102.288 127.689 102.382 127.705L121.064 129.737V97.2886L101.925 95.9342C101.829 95.9277 101.736 95.9014 101.651 95.8571C101.567 95.8128 101.492 95.7515 101.433 95.677C101.374 95.6026 101.331 95.5167 101.308 95.4249C101.284 95.3331 101.28 95.2375 101.296 95.1442C101.311 94.9745 101.389 94.8165 101.516 94.7014C101.643 94.5864 101.81 94.5228 101.982 94.5234L121.693 96.16C121.865 96.1742 122.025 96.2517 122.141 96.3772C122.258 96.5027 122.322 96.6669 122.321 96.8371V130.696C122.362 130.775 122.382 130.862 122.382 130.95C122.382 131.038 122.362 131.125 122.321 131.204C122.226 131.259 122.117 131.287 122.007 131.287C121.897 131.287 121.788 131.259 121.693 131.204Z"
        fill="#07122B"
      />
      <path
        d="M121.693 131.204C121.552 131.217 121.409 131.185 121.287 131.114C121.164 131.044 121.066 130.937 121.008 130.809C120.946 130.628 120.952 130.432 121.026 130.255C121.099 130.079 121.235 129.935 121.408 129.85L124.264 128.721L124.55 95.3699L106.953 94.0719C106.86 94.0735 106.768 94.0545 106.683 94.0165C106.598 93.9786 106.523 93.9224 106.463 93.8522C106.403 93.782 106.359 93.6995 106.335 93.6106C106.311 93.5218 106.308 93.4287 106.324 93.3383C106.324 93.2494 106.342 93.1613 106.377 93.0792C106.411 92.997 106.462 92.9224 106.525 92.8595C106.589 92.7966 106.665 92.7467 106.748 92.7127C106.831 92.6786 106.92 92.6611 107.01 92.6611L125.235 94.0155C125.422 94.0297 125.597 94.1133 125.724 94.2493C125.851 94.3854 125.922 94.5639 125.921 94.7491L125.692 129.172C125.692 129.317 125.649 129.459 125.568 129.579C125.486 129.7 125.371 129.794 125.235 129.85L121.979 131.204H121.693Z"
        fill="#07122B"
      />
      <path
        d="M117.522 126.125L102.268 124.771C102.076 124.743 101.901 124.648 101.775 124.503C101.649 124.358 101.58 124.172 101.582 123.981C101.61 123.806 101.7 123.647 101.836 123.533C101.973 123.419 102.146 123.358 102.325 123.36L116.78 124.658V101.069L101.925 99.8278C101.832 99.8293 101.74 99.8104 101.655 99.7724C101.57 99.7344 101.495 99.6783 101.435 99.6081C101.375 99.5379 101.331 99.4554 101.307 99.3665C101.283 99.2776 101.28 99.1846 101.297 99.0942C101.297 98.9146 101.369 98.7423 101.497 98.6153C101.626 98.4883 101.8 98.417 101.982 98.417L117.522 99.7149C117.699 99.7421 117.86 99.8312 117.975 99.9659C118.091 100.101 118.153 100.272 118.151 100.449V125.391C118.189 125.481 118.208 125.577 118.208 125.674C118.208 125.77 118.189 125.866 118.151 125.956C118.072 126.038 117.971 126.097 117.861 126.127C117.75 126.157 117.633 126.156 117.522 126.125Z"
        fill="#07122B"
      />
      <path
        d="M96.0973 133.405C95.9098 133.405 95.7291 133.336 95.5913 133.21C95.4535 133.085 95.369 132.912 95.3546 132.728C94.8404 123.078 94.5547 113.259 94.5547 103.496C94.5544 103.402 94.5738 103.309 94.6118 103.223C94.6497 103.137 94.7054 103.06 94.7753 102.996C94.8451 102.932 94.9276 102.884 95.0175 102.853C95.1074 102.823 95.2027 102.811 95.2974 102.819C95.4793 102.819 95.6536 102.89 95.7822 103.017C95.9108 103.144 95.983 103.316 95.983 103.496C95.983 113.259 96.2687 123.021 96.7829 132.671C96.7998 132.762 96.7962 132.855 96.7723 132.944C96.7484 133.032 96.7048 133.115 96.6447 133.185C96.5845 133.255 96.5093 133.311 96.4246 133.349C96.3398 133.387 96.2475 133.406 96.1544 133.405H96.0973Z"
        fill="#07122B"
      />
      <path
        d="M59.5895 57.6732C59.4338 57.678 59.2811 57.6304 59.1564 57.5381C59.0318 57.4458 58.9428 57.3143 58.9039 57.1653C58.1346 54.7931 58.0202 52.261 58.5726 49.8305C59.125 47.4001 60.3241 45.1593 62.0462 43.3395C62.6175 42.7187 63.3031 42.1544 63.9316 41.5901C65.5087 40.5304 66.7395 39.0402 67.4738 37.3013C67.7847 34.8065 67.1319 32.2876 65.6456 30.2473C64.5454 28.5178 63.7711 26.6059 63.3602 24.6041C63.2082 21.6435 64.164 18.7311 66.0455 16.4215C66.098 16.3474 66.1648 16.2842 66.242 16.2356C66.3192 16.187 66.4054 16.1539 66.4956 16.1382C66.5858 16.1224 66.6782 16.1244 66.7677 16.144C66.8571 16.1636 66.9417 16.2003 67.0167 16.2522C67.0918 16.3041 67.1557 16.37 67.2049 16.4463C67.2541 16.5226 67.2877 16.6077 67.3036 16.6968C67.3195 16.7859 67.3175 16.8772 67.2977 16.9655C67.2779 17.0538 67.2407 17.1374 67.1881 17.2115C65.5359 19.2565 64.6841 21.8205 64.7886 24.4348C65.1464 26.4255 65.8623 28.3367 66.9025 30.078C67.7526 31.2283 68.3623 32.5343 68.6958 33.9198C69.0293 35.3054 69.08 36.7426 68.845 38.1478C68.0196 40.1305 66.6553 41.8491 64.9028 43.1138L62.846 44.2988C61.2901 45.9272 60.2044 47.9377 59.702 50.1212C59.1996 52.3046 59.2989 54.5811 59.9894 56.7138C60.026 56.7987 60.0447 56.8902 60.0442 56.9825C60.0437 57.0747 60.0242 57.166 59.9867 57.2505C59.9493 57.3351 59.8947 57.4112 59.8264 57.4742C59.7581 57.5371 59.6775 57.5857 59.5895 57.6167V57.6732Z"
        fill="#07122B"
      />
      <path
        d="M80.2715 37.4144C80.1158 37.4192 79.963 37.3716 79.8384 37.2793C79.7138 37.187 79.6247 37.0555 79.5859 36.9065C79.0772 34.9884 78.4475 33.1037 77.7005 31.2633V30.8118C77.321 29.8822 77.034 28.9183 76.8435 27.9338C76.6382 24.5223 77.6045 21.1421 79.5859 18.3404C79.7208 18.2252 79.8932 18.1619 80.0715 18.1619C80.2498 18.1619 80.4222 18.2252 80.5571 18.3404C80.6737 18.4737 80.7379 18.6439 80.7379 18.8201C80.7379 18.9962 80.6737 19.1665 80.5571 19.2997C78.7518 21.8392 77.8296 24.8888 77.929 27.9903C78.1366 28.8788 78.4234 29.7475 78.786 30.5861V31.0376C79.5532 32.9916 80.2019 34.9891 80.7285 37.0193C80.7643 37.1081 80.7788 37.2038 80.771 37.299C80.7632 37.3942 80.7333 37.4864 80.6835 37.5683C80.6338 37.6502 80.5655 37.7196 80.4842 37.7713C80.4028 37.8229 80.3104 37.8552 80.2143 37.8658L80.2715 37.4144Z"
        fill="#07122B"
      />
      <path
        d="M81.357 55.1339C81.267 55.1416 81.1762 55.1317 81.09 55.1046C81.0038 55.0776 80.9239 55.0339 80.8549 54.9762C80.7859 54.9185 80.7291 54.8479 80.6878 54.7684C80.6465 54.689 80.6215 54.6022 80.6143 54.5132C80.4352 52.809 80.6857 51.0876 81.3437 49.5023C82.0016 47.9171 83.0465 46.5172 84.3851 45.4276C85.9249 44.2976 87.6172 43.3858 89.4128 42.7189C90.6743 42.2272 91.8966 41.6425 93.0693 40.9695C94.5382 40.0621 95.7929 38.854 96.7488 37.4268C97.7046 35.9995 98.3394 34.3863 98.6101 32.696C98.8809 31.0058 98.7814 29.2778 98.3184 27.6288C97.8553 25.9798 97.0395 24.4481 95.9259 23.1371C95.853 23.0735 95.7945 22.9954 95.7545 22.9078C95.7145 22.8202 95.6938 22.7253 95.6938 22.6292C95.6938 22.5331 95.7145 22.4381 95.7545 22.3506C95.7945 22.263 95.853 22.1848 95.9259 22.1213C95.9896 22.0582 96.0653 22.0081 96.1486 21.9739C96.232 21.9397 96.3213 21.9221 96.4115 21.9221C96.5018 21.9221 96.5911 21.9397 96.6745 21.9739C96.7578 22.0081 96.8335 22.0582 96.8972 22.1213C98.1301 23.5907 99.0319 25.3028 99.5424 27.1435C100.053 28.9841 100.16 30.9111 99.8575 32.796C99.5548 34.681 98.8488 36.4805 97.7867 38.0748C96.7245 39.669 95.3306 41.0214 93.6977 42.0417C92.4871 42.7858 91.2049 43.4096 89.8698 43.904C88.2052 44.5262 86.6302 45.361 85.1849 46.387C84.0328 47.3431 83.134 48.5624 82.5666 49.939C81.9991 51.3157 81.7801 52.8083 81.9284 54.2874C81.9362 54.3764 81.9261 54.4661 81.8987 54.5512C81.8713 54.6363 81.8271 54.7152 81.7687 54.7834C81.7103 54.8516 81.6388 54.9077 81.5584 54.9485C81.4779 54.9893 81.3901 55.0139 81.2999 55.0211L81.357 55.1339Z"
        fill="#07122B"
      />
      <path
        d="M105.582 43.2267C105.464 43.2288 105.348 43.1982 105.247 43.1383C105.146 43.0785 105.064 42.9918 105.01 42.8881C104.946 42.8252 104.896 42.7504 104.861 42.6681C104.826 42.5858 104.809 42.4976 104.809 42.4084C104.809 42.3193 104.826 42.2311 104.861 42.1487C104.896 42.0664 104.946 41.9917 105.01 41.9288C106.556 40.9106 107.826 39.5356 108.712 37.923C109.598 36.3103 110.073 34.5086 110.095 32.6739C110.132 28.9107 109.25 25.1942 107.524 21.839C107.487 21.7575 107.466 21.6695 107.463 21.58C107.46 21.4905 107.474 21.4013 107.506 21.3174C107.538 21.2336 107.586 21.1568 107.648 21.0913C107.71 21.0259 107.784 20.9732 107.867 20.9361C107.949 20.8991 108.039 20.8784 108.129 20.8754C108.22 20.8724 108.31 20.887 108.395 20.9184C108.48 20.9499 108.558 20.9975 108.624 21.0586C108.69 21.1198 108.744 21.1932 108.781 21.2747C110.61 24.7607 111.552 28.6347 111.523 32.5611C111.467 34.6062 110.919 36.6089 109.926 38.4042C108.933 40.1996 107.522 41.7364 105.81 42.8881L105.582 43.2267Z"
        fill="#07122B"
      />
    </svg>
  );
};

export default IconOrderPause;
