import React, { useCallback, useEffect, useState } from 'react';
import { socket } from 'services/socket';

const withSocket = WrappedComponent => props => {
  const [messages, setMessages] = useState([]);
  const [pinnedMessages, setPinnedMessages] = useState([]);
  const [usersOnline, setUsersOnline] = useState([]);
  const [sending, setSending] = useState(false);
  const [isTypingAndUser, setIsTypingAndUser] = useState({
    isTyping: false,
    userTyping: null,
  });

  useEffect(() => {
    if (socket.disconnected) {
      socket.connect();
    }

    let typingTimeout = null;

    socket.on('roomUsers', users => {
      setUsersOnline(users);
    });

    socket.on('isTyping', user => {
      if (user && user !== null) {
        setIsTypingAndUser({ isTyping: true, userTyping: user });

        if (typingTimeout !== null) {
          window.clearTimeout(typingTimeout);
        }

        typingTimeout = window.setTimeout(() => {
          setIsTypingAndUser({ isTyping: false, userTyping: null });
        }, 3000);
      } else {
        setIsTypingAndUser({ isTyping: false, userTyping: null });
      }
    });

    return () => {
      if (typingTimeout !== null) {
        window.clearTimeout(typingTimeout);
      }
    };
  }, []);

  const joinRoom = useCallback((roomName, userId) => {
    if (socket.disconnected) {
      socket.connect();
    }
    socket.emit('joinRoom', { roomName, userId });
  }, []);

  const sendMessage = useCallback(messageData => {
    setSending(true);
    socket.emit('sendMessage', messageData);
  }, []);

  const updateMessage = useCallback(messageData => {
    socket.emit('updateMessage', messageData);
  }, []);

  const deleteMessage = useCallback(messageData => {
    socket.emit('deleteMessage', messageData);
  }, []);

  const typingMessage = useCallback((room, user) => {
    socket.emit('typingMessage', { room, user });
  }, []);

  const leaveRoom = useCallback((roomName, userId) => {
    socket.emit('leaveRoom', { roomName, userId });
  }, []);

  return (
    <WrappedComponent
      {...{
        ...props,
        sendMessage,
        updateMessage,
        deleteMessage,
        joinRoom,
        setMessages,
        setSending,
        messages,
        typingMessage,
        ...isTypingAndUser,
        socket,
        leaveRoom,
        usersOnline,
        sending,
        pinnedMessages,
        setPinnedMessages
      }}
    />
  );
};

export default withSocket;
