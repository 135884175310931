import React from 'react';

const Icon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_15436_27492)">
      <path d="M14.3311 2.63732C12.4864 1.3587 9.92697 1.90373 8.65871 3.80453L5.96687 7.83889C5.86677 7.98892 5.77831 8.14195 5.70077 8.29907C5.23975 9.22866 5.15977 10.269 5.42302 11.2131C5.49878 11.4837 5.60173 11.7453 5.73232 11.995C6.0288 12.5643 6.46678 13.067 7.03446 13.4458C7.45313 13.7251 7.90696 13.9131 8.37274 14.0151C8.66216 14.079 8.963 13.9577 9.13009 13.7073C9.41274 13.2837 9.1865 12.7182 8.69636 12.6096C8.39739 12.544 8.1054 12.4225 7.83678 12.2433C7.21664 11.8295 6.83697 11.1872 6.73191 10.4969C6.66097 10.035 6.7131 9.55007 6.89803 9.09791C6.96465 8.9367 7.04755 8.77837 7.14839 8.62723L9.84391 4.58735C10.644 3.38818 12.2484 3.011 13.4385 3.77958C14.6475 4.56077 14.9695 6.20094 14.1584 7.41665L13.3134 8.68312C13.1625 8.90927 13.1229 9.18899 13.2029 9.44642C13.2049 9.45253 13.2072 9.46045 13.2092 9.46656C13.373 9.99834 14.0819 10.0903 14.394 9.62259L15.3399 8.20499C16.5718 6.35645 16.1169 3.87673 14.3311 2.63732Z" fill="#08122A"/>
      <path d="M12.5758 9.78851C12.5001 9.51795 12.3971 9.25634 12.2665 9.00659C11.97 8.43729 11.532 7.93461 10.9644 7.55584C10.5457 7.27649 10.0919 7.08848 9.62609 6.98655C9.33667 6.92258 9.03583 7.04391 8.86874 7.29433C8.58609 7.71796 8.81233 8.28344 9.30247 8.39205C9.60144 8.45761 9.89343 8.57909 10.162 8.75832C10.7822 9.17209 11.1619 9.81444 11.2669 10.5047C11.3379 10.9666 11.2857 11.4515 11.1015 11.9026C11.0349 12.0638 10.952 12.2221 10.8512 12.3733L8.15493 16.4143C7.35555 17.6123 5.75299 17.9891 4.5639 17.2212C3.35308 16.4404 3.03077 14.7985 3.84119 13.5839L4.67959 12.3273C4.8349 12.0946 4.87571 11.8061 4.79376 11.5426C4.79177 11.5365 4.79051 11.5292 4.78747 11.5224C4.62609 10.9939 3.92205 10.9036 3.61216 11.368L2.68397 12.7591C1.43705 14.6279 1.8784 17.1529 3.70732 18.3891C5.54359 19.6302 8.06271 19.1093 9.31257 17.2361L12.0316 13.1609C12.1317 13.0109 12.2202 12.8579 12.2977 12.7007C12.7598 11.7719 12.838 10.7319 12.5758 9.78851Z" fill="#08122A"/>
      </g>
      <defs>
      <clipPath id="clip0_15436_27492">
      <rect width="20" height="20" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default Icon;