import React, { useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Badge } from '@components/Badge';
import { Link } from '@components/Link';
import IconDragHandler from '@components/Svg/IconDragHandler';
import IconRateNeutral from '@components/Svg/IconRateNeutral';
import IconRateBad from '@components/Svg/IconRateBad';
import IconRateGreat from '@components/Svg/IconRateGreat';
import { Tooltip } from 'antd';
import moment from 'moment';
import { EllipsisMultiple } from '@components/EllipsisMultiple';
import { BRAND, DETAIL_REQUEST } from '@constants/routes';
import { ORDER_STATUS_LABELS as BADGE_STATUS } from '@constants/order';
import { RowItemContainer, ShieldContainer } from '../../style';
import Avatar from '@components/Avatar/Avatar';
import IconNoBrand from '@components/Svg/IconNoBrand';
import { withResponsive } from '@components/ResponsiveProvider';

// Still don't know which rating variable used for this one
const RATE = {
  GREAT: 'Great',
  BAD: 'Bad',
  NEUTRAL: 'Neutral',
};

const RATE_MESSAGE = {
  [RATE.GREAT]: 'It was awesome',
  [RATE.BAD]: "It wasn't good",
  [RATE.NEUTRAL]: 'It was OK',
};

export const RowItemRequest = withResponsive(({
  id,
  index,
  name,
  category,
  status,
  rate,
  date,
  dateLabel,
  enableDrag,
  optionComponent: OptionComponent,
  optionProps,
  unreadMessages,
  icon,
  iconProps,
  isNotCustomer = false,
  brand,
  owners,
  windowWidth,
  workers,
})  => {
  const isMobile = windowWidth <= 768;
  const [isOpen, setOpen] = useState(false);
  const brandImg = useMemo(() => {
    if (!brand) {
      return undefined;
    }
    return brand?.logos && brand.logos[0]?.url;
  }, [brand]);

  const colorRate = useMemo(() => {
    switch (rate) {
      case RATE.NEUTRAL:
        return 'other-yellow';
      case RATE.GREAT:
        return 'other-green';
      case RATE.BAD:
        return 'other-red';
      default:
        return undefined;
    }
  }, [rate]);

  const handleVisibleChange = newOpen => {
    setOpen(newOpen);
  };

  const dateNode = useMemo(() => {
    const dateMoment = moment(date);

    if (!dateMoment.isValid()) return '-';

    const fullDisplayedDate = dateMoment.format('DD MMM, YYYY, HH:mm A');
    const diplayedDate =
      dateMoment.year() === moment().year()
        ? dateMoment.format('DD MMM, HH:mm A')
        : fullDisplayedDate;

    const titleNode = <>{fullDisplayedDate}</>;

    return (
      <Tooltip title={titleNode}>
        <span> {diplayedDate} </span>
      </Tooltip>
    );
  }, [date]);

  return (
    <Draggable draggableId={`${id}`} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          d="flex"
          flexDir="row"
          alignItems="center"
          flexWrap="nowrap"
          mb="20"
          pos="relative"
          {...provided.draggableProps}
          style={{
            ...provided.draggableProps.style,
            ...(snapshot.isDragging
              ? {
                  opacity: 0.4,
                }
              : {}),
          }}
        >
          <Box mr="20" alignSelf="flex-start" pt="32">
            <ShieldContainer {...(iconProps ?? {})}>
              {icon ?? (
                <Text textVariant="SmallTitle" colorScheme="primary">
                  {index + 1}
                </Text>
              )}
            </ShieldContainer>
          </Box>
          <RowItemContainer isOpen={isOpen}>
            <Box d="flex" alignItems="center" overflow="hidden">
              <Box w="100%" minW="0" maxW={['unset', '650']}>
                <Box d="flex" flexDir="row" alignItems="center">
                  {enableDrag ? (
                    <Box
                      {...provided.dragHandleProps}
                      pl="10"
                      pr={['5', '10']}
                      cursor="move"
                    >
                      <IconDragHandler />
                    </Box>
                  ) : (
                    <div
                      {...provided.dragHandleProps}
                      style={{ display: 'none' }}
                    />
                  )}
                  {rate && (
                    <Box px="7" colorScheme={colorRate}>
                      <Tooltip title={RATE_MESSAGE[rate]} trigger={['hover']}>
                        <Box as="span" fontSize="22">
                          {rate === RATE.NEUTRAL && <IconRateNeutral />}
                          {rate === RATE.BAD && <IconRateBad />}
                          {rate === RATE.GREAT && <IconRateGreat />}
                        </Box>
                      </Tooltip>
                    </Box>
                  )}
                  <Box px="14" w="100%">
                    <Text
                      as={Link}
                      d="block"
                      to={DETAIL_REQUEST.replace(':id', id)}
                      textVariant="Button"
                      isTruncate
                      colorScheme="primary"
                      fontSize="16"
                      lineH="28"
                    >
                      <EllipsisMultiple line={1}>{name}</EllipsisMultiple>
                    </Text>
                    <Box d="flex" alignItems="center" flexWrap="nowrap">
                      <Text
                        textVariant="P4"
                        colorScheme="cta"
                        pl="4"
                        fontSize={['12', '14']}
                      >
                        #{id}
                      </Text>
                      <Box w="1" h="12" bg="#D5D6DD" mx={['4', '8']} />
                      <Text
                        textVariant="P4"
                        colorScheme="primary"
                        pr="4"
                        whiteSpace="nowrap"
                        maxW={['82', 'auto']}
                        fontSize={['12', '14']}
                        textOverflow="ellipsis"
                        overflow="hidden"
                      >
                        {category?.title}
                      </Text>
                      {!isMobile && (
                        <>
                          <Box w="1" h="12" bg="#D5D6DD" mx={['4', '8']} alignItems="center"/>
                          {brand ? (
                            <Box fontSize="10" lineH="0" d="inline-block">
                              <Avatar
                                src={brandImg}
                                size={24}
                                name={brand?.name}
                                textVariant="SmallTitle"
                              />
                            </Box>
                          ): (
                            <Box fontSize="10" lineH="0" d="inline-block">
                              <IconNoBrand />
                            </Box>
                          )}
                            <Text
                              to={BRAND.replace(':brandId', brand?.id)}
                              pl="10"
                              textVariant="P4"
                              pr="4"
                              whiteSpace="nowrap"
                              fontSize={['12', '14']}
                            >
                            {brand?.name ?? 'No Brand'}
                          </Text>
                        </>
                      )}
                      {!isMobile && (
                        <>
                          <Box w="1" h="12" bg="#D5D6DD" mx={['4', '8']} alignItems="center"/>
                          <Text
                            textVariant="P4"
                            colorScheme="primary"
                            textAlign="left"
                            overflow="hidden"
                            maxW="140"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                          >
                            {owners
                              .map(owner => `${owner.firstname} ${owner.lastname}`)
                              .join(', ')}
                          </Text>
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box px="14" w="100%" maxW="170" hide="mobile">
                <Text textVariant="P4" colorScheme="secondary">
                  {`Designer{s}`}
                </Text>
                {workers.length ? (
                  <>
                    <Box
                      d="inline-flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Tooltip
                        title={
                          <Box d="flex" flexDir="column">
                            {workers.map(worker => (
                              <Box
                                d="flex"
                                flexDir="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                my="4"
                                key={worker.id}
                              >
                                <Avatar
                                  src={worker.picture.url}
                                  size={24}
                                  name={worker?.name}
                                />
                                <Text
                                  textVariant="P4"
                                  colorScheme="primary"
                                  ml="4"
                                >
                                  {worker?.firstname} {worker?.lastname}
                                </Text>
                              </Box>
                            ))}
                          </Box>
                        }
                        trigger="hover"
                        placement="top"
                      >
                        {workers.map((worker, i) => (
                          <Avatar
                            key={`${worker}-${i}`}
                            src={worker.picture.url}
                            size={24}
                            name={worker?.name}
                          />
                        ))}
                      </Tooltip>
                    </Box>
                    <EllipsisMultiple
                      textVariant="P4"
                      colorScheme="primary"
                      textAlign="left"
                      line={1}
                    >
                      {workers
                        .map(worker => `${worker.firstname} ${worker.lastname}`)
                        .join(', ')}
                    </EllipsisMultiple>
                  </>
                ) : (
                  <>
                    <Box
                      d="inline-flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Avatar size={24} />
                    </Box>
                    <Text textVariant="P4" colorScheme="primary">
                      Not assigned yet
                    </Text>
                  </>
                )}
              </Box>
              <Box px="14" w="100%" maxW="200" hide="mobile">
                <Text textVariant="P4" colorScheme="secondary">
                  {dateLabel}
                </Text>
                <Text textVariant="P4" colorScheme="primary">
                  {dateNode}
                </Text>
              </Box>
              <Box px="14" w="100%" maxW="150" hide="mobile" textAlign="center">
                <Badge variant={BADGE_STATUS[status]}>
                  {BADGE_STATUS[status]}
                </Badge>
              </Box>
              {!isNotCustomer && (
                <Box px={['10', '14']} w="100%" maxW="50" ml="auto">
                  <OptionComponent
                    onVisibleChange={handleVisibleChange}
                    {...optionProps}
                  />
                </Box>
              )}
            </Box>
          </RowItemContainer>
          {unreadMessages > 0 && (
            <Box
              w="20"
              pos="absolute"
              h="20"
              bg="other-pink"
              radii="999"
              top="-10"
              right="0"
            />
          )}
        </Box>
      )}
    </Draggable>
  );
})
