import styled from 'styled-components';
import { Tabs as AntdTabs } from 'antd';
import * as theme from '../Theme';

export const Tabs = styled(AntdTabs)`
    overflow: visible;

    .ant-tabs-bar {
        margin: 0;

        .ant-tabs-nav {
            .ant-tabs-tab {
                ${theme.TYPO_H6}
                margin-right: 27px;
                padding: 12px 0;
                font-size: 14px;
                font-weight: 900;
            }

            .ant-tabs-ink-bar {
                height: 3px;
            }
        }
    }
`;
