import styled from 'styled-components';
import { Form as AntdForm } from 'antd';
import * as theme from '../Theme';

export const Form = styled(AntdForm)`
    .ant-form-item {
        margin-bottom: 30px;

        &:last-child {
             margin-bottom: 0;
        }

		label {
			${theme.TYPO_H6}
			color: ${theme.COLOR_TEXT_PRIMARY};
		}
    }

	.ant-form-item-label {
		margin-bottom: 10px;
		line-height: 0;
		padding: 0;

		.ant-form-item-no-colon {
			padding-right: 12px;

			&:after {
				content: ' ';
				margin: 0;
				position: relative;
				top: 0;
			}
		}
	}

	.ant-form-item-required {
		&:before {
			position: absolute;
            right: 1px;
            color: #F34530;
            top: 0;
		}
	}

	.ant-form-explain {
		margin-top: 0;
		margin-bottom: 0;
		font-size: 12px;
		font-weight: 300;
		line-height: 18px;
		min-height: auto;
		height: 0;
	}

	.ant-form-item-control {
		line-height: unset;
	}

	&.ant-form-horizontal {
		.ant-form-item {
			margin-bottom: 30px;
		}
	}

    .has-error {
        .ant-select-auto-complete.ant-select .ant-input,
        .ant-select-selection,
        .ant-input {
            &,
            &:focus
            &:hover {
                border-color: ${theme.COLOR_OTHERS_RED};
            }
        }
    }
`;
