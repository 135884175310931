import React from 'react';
import { Avatar, Popconfirm, Skeleton } from 'antd';
import deleteIcon from '@assets/icons/trash-bin.svg';
import { AvatarBlockStyle } from './style';

export const AvatarBlock = ({ size, styles, src, onRemove, loading }) => {
    return (
        <AvatarBlockStyle styles={styles}>
            {loading ? (
                <Skeleton avatar={{ size: size }} active paragraph={false} title={false} />
            ) : (
                <>
                    <Avatar size={size} src={src} icon="user" />
                    {src
                        ? onRemove && (
                              <Popconfirm
                                  title="Are you sure you want to remove this picture?"
                                  onConfirm={onRemove}
                                  okText="Yes"
                                  cancelText="No"
                                  placement="topLeft"
                              >
                                  <a href="/" onClick={e => e.preventDefault()}>
                                      <img src={deleteIcon} alt="Trash Bin" />
                                  </a>
                              </Popconfirm>
                          )
                        : null}
                </>
            )}
        </AvatarBlockStyle>
    );
};
