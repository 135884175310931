import React, { useState, useCallback, memo } from 'react';
import { Form } from '@components/Form';
import message from '@components/Message';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Input } from '@components/Input';
import { Button } from '@components/Button';
import { TooltipIconBlock } from '@components/LabelWithTooltipBlock';
import { UPDATE_SOURCE } from '@graphql/mutations/subscription';
import { useMutation } from '@apollo/react-hooks';

const EmailAccount = memo(({ form, billingSource, refetch }) => {
    const { getFieldDecorator, validateFields } = form;
    const [isLoading, setIsLoading] = useState(false);
    const [updateEmail] = useMutation(UPDATE_SOURCE);

    const handleSubmit = useCallback(
        e => {
            e.preventDefault();
            validateFields(async (err, values) => {
                if (!err && !isLoading) {
                    message.destroy();
                    message.loading('Updating email...');
                    setIsLoading(true);

                    try {
                        await updateEmail({
                            variables: {
                                email: values.invoiceEmail,
                            },
                        });
                        await refetch();
                        message.destroy();
                        message.success('Email has been updated');
                        setIsLoading(false);
                        return true;
                    } catch (e) {
                        setIsLoading(false);
                        console.error(e);
                        return false;
                    }
                }
            });
        },
        [isLoading, validateFields, updateEmail, refetch]
    );

    const valueEmail = form.getFieldValue('invoiceEmail');

    return (
        <Box mt="30" mb="30">
            <Box mb="20">
                <TooltipIconBlock
                    tooltipIconSize="16px"
                    label="Billing email"
                    tooltip="Email where you would like to receive your invoices"
                    textVariant="H5"
                    colorScheme="primary"
                    w="350"
                />
                <Text hide="desktop" textVariant="H5" colorScheme="primary">
                    Billing email
                </Text>
            </Box>
            <Form onSubmit={handleSubmit}>
                <Form.Item label="Email" colon={false} required={false}>
                    {getFieldDecorator('invoiceEmail', {
                        initialValue: billingSource.owner.email, // set initialValue later on
                        rules: [
                            {
                                type: 'email',
                                message: 'Please enter a valid email address',
                            },
                            {
                                required: true,
                                message: 'Please enter a valid email address',
                            },
                        ],
                    })(<Input placeholder="Enter your email address" />)}
                </Form.Item>
                <Form.Item>
                    <Button
                        h="34"
                        fontSize="12"
                        disabled={!valueEmail}
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        w={['100%', 'auto']}
                    >
                        Update
                    </Button>
                </Form.Item>
            </Form>
        </Box>
    );
});

export default Form.create()(EmailAccount);
