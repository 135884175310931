import React, { memo, useMemo, useState } from 'react';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Form } from '@components/Form';
import debounce from 'lodash/debounce';
import SearchInput from '@components/Input/SearchInput';
import TeamField from '../blocks/TeamField';
import RoleField from '../blocks/RoleField';
import DesignTypeField from '../blocks/DesignTypeField';
import StatusField from '../blocks/StatusField';

const MembersTabFilters = memo(({ form, onChangeFilters, filters }) => {
    const { getFieldDecorator, resetFields, setFieldsValue } = form;
    const [showFilters] = useState(true);

    const debouncedOnChangeFilters = useMemo(() => debounce(onChangeFilters, 1000), [onChangeFilters]);

    const handleResetForm = () => {
        resetFields();

        Object.entries({
            keyword: '',
            team: 'ALL',
            designType: 'ALL',
            role: 'ALL',
            status: 'ALL',
        }).forEach(([key, value]) => {
            onChangeFilters(key, value);
        });
    };

    const isSameAsInitial =
        filters.keyword === '' &&
        filters.team === 'ALL' &&
        filters.role === 'ALL' &&
        filters.designType === 'ALL' &&
        filters.status === 'ALL';

    return (
        <>
            {showFilters && (
                <Box mb="30">
                    <Form>
                        <Box d="flex" alignItems="center" mx="-10">
                            <Box maxW="404" flex="1" mx="10" mb="-10">
                                <Form.Item label="" colon={false} required={false}>
                                    {getFieldDecorator('keyword', {
                                        initialValue: '',
                                    })(
                                        <SearchInput
                                            onChangeText={value => {
                                                debouncedOnChangeFilters('keyword', value);
                                            }}
                                            onClear={() => {
                                                setFieldsValue({ keyword: '' });
                                                onChangeFilters('keyword', '');
                                            }}
                                            placeholder="Search by status, team, user"
                                        />
                                    )}
                                </Form.Item>
                            </Box>
                            <Box maxW="246" flex="1" mx="10" mb="-10">
                                <Form.Item label="" colon={false} required={false}>
                                    {getFieldDecorator('team', {
                                        initialValue: 'ALL',
                                    })(<TeamField onFieldChange={onChangeFilters} />)}
                                </Form.Item>
                            </Box>
                            <Box maxW="246" flex="1" mx="10" mb="-10">
                                <Form.Item label="" colon={false} required={false}>
                                    {getFieldDecorator('role', {
                                        initialValue: 'ALL',
                                    })(<RoleField onFieldChange={onChangeFilters} />)}
                                </Form.Item>
                            </Box>
                            <Box maxW="246" flex="1" mx="10" mb="-10">
                                <Form.Item label="" colon={false} required={false}>
                                    {getFieldDecorator('designType', {
                                        initialValue: 'ALL',
                                    })(<DesignTypeField onFieldChange={onChangeFilters} />)}
                                </Form.Item>
                            </Box>
                        </Box>
                        <Box d="flex" alignItems="center">
                            <Form.Item label="" colon={false} required={false} style={{ marginBottom: 0 }}>
                                {getFieldDecorator('status', {
                                    initialValue: 'ALL',
                                })(<StatusField handleFieldsChange={onChangeFilters} />)}
                            </Form.Item>
                            {!isSameAsInitial && (
                                <Text
                                    ml="20"
                                    textVariant="H6"
                                    colorScheme="cta"
                                    onClick={handleResetForm}
                                    cursor="pointer"
                                >
                                    Reset Filters
                                </Text>
                            )}
                        </Box>
                    </Form>
                </Box>
            )}
        </>
    );
});

export default Form.create()(MembersTabFilters);
