import React from 'react';

const IconFiles = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.12 19.59H5.69001C5.60205 19.5913 5.51478 19.5742 5.43383 19.5398C5.35288 19.5053 5.28005 19.4543 5.22001 19.39L1.41001 15.39C1.29825 15.2703 1.23421 15.1137 1.23001 14.95V0.920004C1.23001 0.747614 1.29849 0.582283 1.42039 0.460385C1.54229 0.338486 1.70762 0.270004 1.88001 0.270004H18.12C18.2924 0.270004 18.4577 0.338486 18.5796 0.460385C18.7015 0.582283 18.77 0.747614 18.77 0.920004V18.92C18.7727 19.007 18.7579 19.0937 18.7264 19.1749C18.6949 19.2561 18.6475 19.3301 18.5868 19.3926C18.5262 19.4551 18.4537 19.5048 18.3735 19.5387C18.2933 19.5726 18.2071 19.59 18.12 19.59ZM6.00001 18.29H17.5V1.57H2.53001V14.67L6.00001 18.29Z"
                fill="currentColor"
            />
            <path
                d="M6.00001 19.17C5.82842 19.1674 5.66459 19.0981 5.54325 18.9768C5.4219 18.8554 5.35259 18.6916 5.35001 18.52V15.58H1.88001C1.70762 15.58 1.54229 15.5115 1.42039 15.3896C1.29849 15.2677 1.23001 15.1024 1.23001 14.93C1.23001 14.7576 1.29849 14.5923 1.42039 14.4704C1.54229 14.3485 1.70762 14.28 1.88001 14.28H6.00001C6.1724 14.28 6.33773 14.3485 6.45963 14.4704C6.58153 14.5923 6.65001 14.7576 6.65001 14.93V18.52C6.64743 18.6916 6.57812 18.8554 6.45678 18.9768C6.33543 19.0981 6.1716 19.1674 6.00001 19.17Z"
                fill="currentColor"
            />
            <path
                d="M14.26 7.62001H6.56C6.38761 7.62001 6.22228 7.55153 6.10038 7.42963C5.97849 7.30773 5.91 7.1424 5.91 6.97001C5.91 6.79762 5.97849 6.63229 6.10038 6.51039C6.22228 6.38849 6.38761 6.32001 6.56 6.32001H14.26C14.4324 6.32001 14.5977 6.38849 14.7196 6.51039C14.8415 6.63229 14.91 6.79762 14.91 6.97001C14.91 7.1424 14.8415 7.30773 14.7196 7.42963C14.5977 7.55153 14.4324 7.62001 14.26 7.62001Z"
                fill="currentColor"
            />
            <path
                d="M14.26 11.56H6.56C6.47464 11.56 6.39012 11.5432 6.31126 11.5105C6.2324 11.4779 6.16074 11.43 6.10038 11.3696C6.04003 11.3093 5.99215 11.2376 5.95948 11.1587C5.92682 11.0799 5.91 10.9954 5.91 10.91C5.91 10.8246 5.92682 10.7401 5.95948 10.6613C5.99215 10.5824 6.04003 10.5107 6.10038 10.4504C6.16074 10.39 6.2324 10.3421 6.31126 10.3095C6.39012 10.2768 6.47464 10.26 6.56 10.26H14.26C14.3454 10.26 14.4299 10.2768 14.5087 10.3095C14.5876 10.3421 14.6593 10.39 14.7196 10.4504C14.78 10.5107 14.8279 10.5824 14.8605 10.6613C14.8932 10.7401 14.91 10.8246 14.91 10.91C14.91 10.9954 14.8932 11.0799 14.8605 11.1587C14.8279 11.2376 14.78 11.3093 14.7196 11.3696C14.6593 11.43 14.5876 11.4779 14.5087 11.5105C14.4299 11.5432 14.3454 11.56 14.26 11.56Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconFiles;
