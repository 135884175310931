import React from 'react';

const IconLogOut = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.8202 14.5799H7.64016C7.46777 14.5799 7.30244 14.5115 7.18054 14.3896C7.05864 14.2677 6.99016 14.1023 6.99016 13.9299C6.99016 13.7576 7.05864 13.5922 7.18054 13.4703C7.30244 13.3484 7.46777 13.2799 7.64016 13.2799H11.1702V2.36995H2.71016V13.9299C2.71016 14.1023 2.64167 14.2677 2.51978 14.3896C2.39788 14.5115 2.23255 14.5799 2.06016 14.5799C1.88777 14.5799 1.72244 14.5115 1.60054 14.3896C1.47864 14.2677 1.41016 14.1023 1.41016 13.9299V1.71995C1.41016 1.54756 1.47864 1.38223 1.60054 1.26033C1.72244 1.13843 1.88777 1.06995 2.06016 1.06995H11.8202C11.9925 1.06995 12.1579 1.13843 12.2798 1.26033C12.4017 1.38223 12.4702 1.54756 12.4702 1.71995V13.9299C12.4702 14.1023 12.4017 14.2677 12.2798 14.3896C12.1579 14.5115 11.9925 14.5799 11.8202 14.5799Z"
        fill="currentColor"
      />
      <path
        d="M7.42987 19.83C7.26786 19.8225 7.11448 19.7547 6.99987 19.64L1.59987 14.4C1.53881 14.3402 1.49011 14.269 1.45656 14.1905C1.42301 14.1119 1.40526 14.0275 1.40434 13.942C1.40341 13.8566 1.41931 13.7718 1.45115 13.6926C1.48298 13.6133 1.53012 13.541 1.58987 13.48C1.64963 13.4189 1.72082 13.3702 1.79939 13.3367C1.87796 13.3031 1.96237 13.2854 2.0478 13.2844C2.13323 13.2835 2.21801 13.2994 2.29729 13.3312C2.37657 13.3631 2.44881 13.4102 2.50987 13.47L6.77987 17.64V7.35997L1.59987 2.16997C1.53847 2.11116 1.4896 2.04053 1.45622 1.96234C1.42283 1.88414 1.40562 1.79999 1.40562 1.71497C1.40562 1.62994 1.42283 1.5458 1.45622 1.4676C1.4896 1.38941 1.53847 1.31878 1.59987 1.25997C1.65955 1.19828 1.73103 1.14922 1.81006 1.11571C1.88908 1.08221 1.97404 1.06494 2.05987 1.06494C2.14571 1.06494 2.23067 1.08221 2.30969 1.11571C2.38872 1.14922 2.4602 1.19828 2.51987 1.25997L7.88987 6.62997C7.95112 6.68972 7.99956 6.76133 8.03222 6.84041C8.06489 6.9195 8.0811 7.00441 8.07987 7.08997V19.18C8.08071 19.3072 8.0442 19.4318 7.97488 19.5385C7.90555 19.6451 7.80646 19.7291 7.68987 19.78C7.60686 19.8119 7.51882 19.8289 7.42987 19.83Z"
        fill="currentColor"
      />
      <path
        d="M16.25 8.53999H9.62998C9.45759 8.53999 9.29226 8.47151 9.17036 8.34961C9.04846 8.22771 8.97998 8.06238 8.97998 7.88999C8.97998 7.7176 9.04846 7.55227 9.17036 7.43037C9.29226 7.30847 9.45759 7.23999 9.62998 7.23999H16.25C16.4224 7.23999 16.5877 7.30847 16.7096 7.43037C16.8315 7.55227 16.9 7.7176 16.9 7.88999C16.9 8.06238 16.8315 8.22771 16.7096 8.34961C16.5877 8.47151 16.4224 8.53999 16.25 8.53999Z"
        fill="currentColor"
      />
      <path
        d="M14.8702 11.14C14.7359 11.1449 14.6033 11.1079 14.4909 11.0344C14.3784 10.9608 14.2915 10.8541 14.2422 10.7291C14.1928 10.6041 14.1834 10.4668 14.2153 10.3363C14.2472 10.2057 14.3188 10.0882 14.4202 10L16.7102 7.80002L14.4902 5.90002C14.3664 5.78696 14.2903 5.63102 14.2772 5.46386C14.2642 5.29671 14.3154 5.13087 14.4202 5.00002C14.4758 4.93474 14.5438 4.88112 14.6203 4.84224C14.6967 4.80335 14.7801 4.77999 14.8656 4.77348C14.9511 4.76697 15.0371 4.77745 15.1185 4.80432C15.2 4.83119 15.2753 4.87391 15.3402 4.93002L18.1102 7.30002C18.1772 7.35906 18.2313 7.43125 18.2691 7.51212C18.307 7.59298 18.3278 7.68077 18.3302 7.77002C18.3337 7.86124 18.3177 7.95215 18.2832 8.03667C18.2487 8.12118 18.1965 8.19734 18.1302 8.26002L15.3202 11C15.1925 11.1005 15.0323 11.1503 14.8702 11.14Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconLogOut;
