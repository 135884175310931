import React, { memo, useCallback, useState, useRef, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { Form } from '@components/Form';
import { injectStripe } from 'react-stripe-elements';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Button } from '@components/Button';
import { Input } from '@components/Input';
import { Select } from '@components/Select';
import { UPDATE_SOURCE } from '@graphql/mutations/subscription';
import {
    StripeCardNumber,
    stripeStyleObject,
    StripeCardExpiry,
    StripeCardCvc,
    CardInformationError,
} from '@components/StripeElements';
import COUNTRIES from '@constants/countries';
import { MANYPIXELS_TERMS_OF_SERVICE_PAGE, MANYPIXELS_PRIVACY_POLICY } from '@constants/general';
import message from '@components/Message';
import { BUY_PLAN } from '@graphql/mutations/plan';
import StripeElements from '@components/StripeElements/StripeElements';

const countriesOptions = COUNTRIES.map(country => (
    <Select.Option key={country.name} value={country.name}>
        {country.name}
    </Select.Option>
));

// const statesOptions = STATES.map(state => (
//     <Select.Option key={state.name} value={state.name}>
//         {state.name}
//     </Select.Option>
// ));

const _CardForm = ({ viewer, total, stripe, selectedPlan, selectedPromotion, referrer, form, onCheckout }) => {
    const { getFieldDecorator, validateFields } = form;

    const [isLoading, setIsLoading] = useState();
    const [isCardError, setIsCardError] = useState(false);
    const [buyPlan, { error }] = useMutation(BUY_PLAN);
    const [updateBillingAddress] = useMutation(UPDATE_SOURCE);

    const cardHolderRef = useRef();
    const line1Ref = useRef();
    const cityRef = useRef();
    const stateRef = useRef();
    const countryRef = useRef('United States');
    const zipRef = useRef();
    useEffect(() => {
        if (error?.graphQLErrors && error.graphQLErrors[0]) {
            message.destroy();
            message.error(error.graphQLErrors[0].message);
            setIsLoading(false)
            setTimeout(() => window.location.reload(), 1500)
        }
    }, [error])

    const handleSubmit = useCallback(
        async ev => {
            ev.preventDefault();

            if (stripe && !isLoading) {
                try {
                    setIsLoading(true);
                    message.destroy();
                    message.loading('Validating checkout details...', 50000);

                    const stripePayload = await stripe.createSource({
                        type: 'card',
                        currency: 'usd',
                        owner: {
                            name: cardHolderRef.current.state.value,
                            email: viewer.email,
                            address: {
                                line1: line1Ref.current.state.value,
                                city: cityRef.current.state.value,
                                state: stateRef.current.state.value,
                                country: countryRef.current,
                                postal_code: zipRef.current.state.value,
                            },
                        },
                        mandate: {
                            notification_method: 'email',
                        },
                    });

                    validateFields(async (err, values) => {
                        if (err) {
                            message.destroy();
                            setIsLoading(false);
                            return false;
                        }

                        if (stripePayload.error) {
                            message.destroy();
                            setIsLoading(false);
                            setIsCardError(stripePayload.error);
                            return false;
                        }

                        const sourceId = stripePayload.source.id;
                        const referralDiscountType = referrer?.id ? 'referral' : null;
                        const couponDiscountType = selectedPromotion?.couponType ?? null;

                        await buyPlan({
                            variables: {
                                card: sourceId,
                                plan: selectedPlan && selectedPlan.id,
                                discount: selectedPromotion?.id,
                                discountType: selectedPromotion ? couponDiscountType : referralDiscountType,
                                referrerId: referrer?.id ?? null,
                            },
                        });
                        await updateBillingAddress({
                            variables: {
                                billingAddress: {
                                    line1: line1Ref.current.state.value,
                                    city: cityRef.current.state.value,
                                    state: stateRef.current.state.value,
                                    country: countryRef.current,
                                    postal_code: zipRef.current.state.value,
                                },
                                card: sourceId
                            },
                        });
                        message.destroy();
                        message.success('Checkout successful!');
                        onCheckout();
                    });
                } catch (e) {
                    setIsLoading(false);
                    message.destroy();
                }
            } else {
                console.log("Stripe.js hasn't loaded yet.");
            }

            return true;
        },
        [isLoading, viewer, stripe, buyPlan, updateBillingAddress, selectedPlan, selectedPromotion, referrer, validateFields, onCheckout]
    );

    return (
        <Form onSubmit={handleSubmit}>
            <Box d="flex" flexDir="row" mx="-10px">
                <Box w="calc(100% - 240px)" px="10px">
                    <Form.Item label="Card number" colon={false} required={true} style={{ marginBottom: 20 }}>
                        {getFieldDecorator('cardnumber', {
                            rules: [
                                {
                                    validator: (rule, value, callback) => {
                                        if (value.type === 'error') {
                                            callback(value.message);
                                        } else {
                                            callback();
                                        }
                                    },
                                    message: 'Your card is not valid',
                                },
                            ],
                        })(
                            <StripeCardNumber
                                id="cardnumber"
                                className="ant-input"
                                showIcon={true}
                                placeholder={`• • • •  • • • •  • • • •  • • • •`}
                                style={stripeStyleObject}
                            />
                        )}
                    </Form.Item>
                </Box>
                <Box w="120" px="10px">
                    <Form.Item label="Expiry date" colon={false} required={true}>
                        {getFieldDecorator('expirydate', {
                            rules: [
                                {
                                    validator: (rule, value, callback) => {
                                        if (value.type === 'error') {
                                            callback(value.message);
                                        } else {
                                            callback();
                                        }
                                    },
                                    message: 'Card expiration date is not valid',
                                },
                            ],
                        })(
                            <StripeCardExpiry id="cardexpiry" className="ant-input" w="100" style={stripeStyleObject} />
                        )}
                    </Form.Item>
                </Box>
                <Box w="120" px="10px">
                    <Form.Item label="CVV" colon={false} required={true}>
                        {getFieldDecorator('cvc', {
                            rules: [
                                {
                                    validator: (rule, value, callback) => {
                                        if (value.type === 'error') {
                                            callback(value.message);
                                        } else {
                                            callback();
                                        }
                                    },
                                    message: 'This field cannot be empty',
                                },
                            ],
                        })(<StripeCardCvc id="cardcvc" className="ant-input" w="100" style={stripeStyleObject} />)}
                    </Form.Item>
                </Box>
            </Box>
            {isCardError && (
                <Box mb="30">
                    <CardInformationError>Your card is invalid</CardInformationError>
                </Box>
            )}
            <Form.Item label="Cardholder name" colon={false} required>
                {getFieldDecorator('cardHolder', {
                    rules: [
                        {
                            required: true,
                            message: 'This field cannot be empty',
                        },
                    ],
                })(<Input ref={cardHolderRef} type="text" placeholder="Enter cardholder name" />)}
            </Form.Item>
            <Form.Item label="Billing address" colon={false} required>
                {getFieldDecorator('billingAddress', {
                    rules: [
                        {
                            required: true,
                            message: 'This field cannot be empty',
                        },
                    ],
                })(<Input ref={line1Ref} type="text" placeholder="Enter your billing address" />)}
            </Form.Item>
            <Form.Item label="City" colon={false} required>
                {getFieldDecorator('city', {
                    rules: [
                        {
                            required: true,
                            message: 'This field cannot be empty',
                        },
                    ],
                })(<Input ref={cityRef} type="text" placeholder="Enter your city" />)}
            </Form.Item>
            <Box d={['block', 'flex']}>
                <Box flex="1 1 0%" mr={['0', '17']}>
                    <Form.Item label="State" colon={false}>
                        {getFieldDecorator('state', {
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                        })(<Input ref={stateRef} type="text" placeholder="Enter your state" /> )}
                    </Form.Item>
                </Box>
                <Box w={['auto', '142']}>
                    <Form.Item label="Zip code" colon={false} required>
                        {getFieldDecorator('zipCode', {
                            rules: [
                                {
                                    required: true,
                                    message: 'This field cannot be empty',
                                },
                            ],
                        })(<Input ref={zipRef} type="text" placeholder="Zip Code" />)}
                    </Form.Item>
                </Box>
            </Box>
            <Form.Item label="Country" colon={false} required>
                {getFieldDecorator('country', {
                    initialValue: 'United States',
                    rules: [
                        {
                            required: true,
                            message: 'This field cannot be empty',
                        },
                    ],
                })(
                    <Select
                        placeholder="Choose your country"
                        onChange={e => {
                            countryRef.current = e;
                        }}
                    >
                        {countriesOptions}
                    </Select>
                )}
            </Form.Item>
            <Button type="primary" htmlType="submit" w="100%" loading={isLoading}>
                Subscribe
            </Button>
            <Text textVariant="P4" colorScheme="secondary" mt="10">
                By clicking Subscribe you agree to ManyPixels{' '}
                <a href={MANYPIXELS_TERMS_OF_SERVICE_PAGE} target="_blank" rel="noopener noreferrer">
                    Terms Of Service
                </a>{' '}
                and{' '}
                <a href={MANYPIXELS_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                </a>
                .
            </Text>
        </Form>
    );
};

const InjectedStripe = injectStripe(_CardForm);
const InjectedAntdForm = Form.create()(InjectedStripe);

const CheckoutForm = memo(props => (
    <Box>
        <StripeElements>
            <InjectedAntdForm {...props} />
        </StripeElements>
    </Box>
));

export default CheckoutForm;
