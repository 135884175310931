import ASSETLOGOSMALLMP from '@assets/icons/logo_small.svg';
import ASSETLOGOMP from '@assets/icons/logo.svg';
import ASSETLOGOMPBLUE from '@assets/icons/logo_blue.svg';
import ASSETLOGOLOGIN from '@assets/icons/login.svg';
import ASSETTRUSTEDCOMPANIES from '@assets/icons/companies.svg';
import ASSETQUESTION from '@assets/icons/help.svg';
import ASSETNOTIFICATION from '@assets/icons/notification.svg';

export const ASSET_LOGO_SMALL_MP = ASSETLOGOSMALLMP;
export const ASSET_LOGO_SMALL_MP_ALT = 'The logo ManyPixels';

export const ASSET_LOGO_MP = ASSETLOGOMP;
export const ASSET_LOGO_MP_ALT = 'The logo ManyPixels';

export const ASSET_LOGO_MP_BLUE = ASSETLOGOMPBLUE;
export const ASSET_LOGO_MP_BLUE_ALT = 'The logo ManyPixels';

export const ASSET_LOGO_LOGIN = ASSETLOGOLOGIN;
export const ASSET_LOGO_LOGIN_ALT = 'The logo ManyPixels';

export const ASSET_TRUSTED_COMPANIES = ASSETTRUSTEDCOMPANIES;
export const ASSET_TRUSTED_COMPANIES_ALT = 'The logo ManyPixels';

export const ASSET_QUESTION = ASSETQUESTION;
export const ASSET_QUESTION_ALT = 'The Help ManyPixels';

export const ASSET_NOTIFICATION = ASSETNOTIFICATION;
export const ASSET_NOTIFICATION_ALT = 'The Notification ManyPixels';
