import React from 'react';

const IconFileSketch = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.32 19.95H2.68V2.83999L6.09 0.0499878H17.32V19.95Z" fill="#FCBB46" />
            <path d="M2.68 2.83999H5.9V0.0499878L2.68 2.83999Z" fill="#F98924" />
            <path d="M6.26 8.93001L8.45 6.60001H12.54L14.51 8.86001L10.49 13.83L6.26 8.93001Z" fill="white" />
        </svg>
    );
};

export default IconFileSketch;
