import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import * as qs from 'query-string';
import withLoggedUser from '@components/WithLoggedUser';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Badge } from '@components/Badge';
import { Tabs } from '@components/Tabs';
import { ALL_ORDERS, ORDER_COUNTERS } from '@graphql/queries/order';
import { getOrderGroupValues, getOrderGroupStatus } from '@constants/order';
import { SectionRequest } from '@pages/Customer/Requests/blocks/ListRequest/SectionRequest';
import { forceInt } from '@utils/forceInt';
import { EmptyData } from '@components/EmptyData';

const initialState = {
    filterTab: 'QUEUE',
    page: 1,
    max: 10,
    status: 'All',
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_LOCATION':
            return {
                ...state,
                ...action.values,
            };
        default:
            return state;
    }
};

const Requests = ({ viewer, company }) => {
    const isNotCustomer = viewer?.role !== 'customer';
    const isWorker = viewer?.role === 'worker';
    const location = useLocation();
    const history = useHistory();
    const didUpdate = useRef(false);
    const [state, dispatch] = useReducer(reducer, initialState, (init) => {
        const parsed = qs.parse(location.search);
        return {
            filterTab: parsed.filterTab?.toUpperCase() ?? init.filterTab,
            page: parsed.page ? parseInt(parsed.page, 10) : init.page,
            max: parsed.max ? parseInt(parsed.max, 10) : init.max,
            status: parsed.status ?? init.status,
        };
    });

    const [{ search, intSearch }, setSearch] = useState({
        search: '',
        intSearch: '',
    });

    const activeFilter = useMemo(() => {
        return getOrderGroupStatus(state.filterTab, state.status);
    }, [state.status, state.filterTab]);

    const { data, loading, refetch } = useQuery(ALL_ORDERS, {
        variables: {
            where: {
                AND: [
                    {
                        company: {
                            id: company?.id,
                        },
                        status_in: activeFilter.filter((item) => (isWorker ? item !== 'ON_HOLD' : item)),
                        archived: false,
                    },
                    search !== ''
                        ? {
                              OR: [{ name_contains: search }, intSearch !== '' ? { id: intSearch } : {}],
                          }
                        : {},
                ],
            },
            ...(state.filterTab !== 'QUEUE' && state.status !== 'All'
                ? {
                      first: state.max,
                      skip: (state.page - 1) * state.max,
                      orderBy: 'priority_ASC',
                  }
                : { orderBy: 'priority_ASC' }),
        },
        fetchPolicy: 'network-only',
    });

    const filterUnreadCounter = (tab) => {
        return {
            AND: [
                {
                    company: {
                        id: company?.id,
                    },
                    status_in: getOrderGroupStatus(tab, 'All').filter((item) => (isWorker ? item !== 'ON_HOLD' : item)),
                    archived: false,
                },
                search !== ''
                    ? {
                          OR: [{ name_contains: search }, intSearch !== '' ? { id: intSearch } : {}],
                      }
                    : {},   
            ],
        };
    };

    const { data: queueCounter, refetch: refetchQueueCounter } = useQuery(ORDER_COUNTERS, {
        variables: {
            where: filterUnreadCounter('QUEUE'),
        },
    });
    const { data: deliveredCounter, refetch: refetchDeliveredCounter } = useQuery(ORDER_COUNTERS, {
        variables: {
            where: filterUnreadCounter('DELIVERED'),
        },
    });

    useEffect(() => {
        if (!didUpdate.current) {
            didUpdate.current = true;
        } else {
            const parsed = qs.parse(location.search);
            const values = {
                page: parsed.page ? parseInt(parsed.page, 10) : 1,
            };
            if (parsed.tab) {
                const uppercased = parsed.tab?.toUpperCase();
                values.tab = uppercased;
                values.status = 'All';
            }
            if (parsed.max) {
                values.max = parseInt(parsed.max, 10);
            }
            dispatch({ type: 'CHANGE_LOCATION', values });
        }
    }, [location.search]);

    const refetchAll = async () => {
        try {
            await refetch();
            await refetchQueueCounter();
            await refetchDeliveredCounter();
        } catch (err) {
            console.log(err);
        }
    };

    const requestData = data?.allOrders.data;

    const navigateLocation = (obj) => {
        const stringify = qs.stringify(obj);
        history.push(`${location.pathname}?${stringify}`);
    };

    const handleSearch = (searchValue) => {
        setSearch({
            search: searchValue,
            intSearch: forceInt(searchValue),
        });
    };

    const handleChangeTab = (tab) => {
        navigateLocation({ filterTab: tab, page: 1, max: state.max, status: 'All', tab: 'requests' });
    };

    const handleChangePage = (page) => {
        navigateLocation({ ...state, page, tab: 'requests' });
    };

    const handleChangeMax = (max) => {
        navigateLocation({ ...state, max, tab: 'requests' });
    };

    const handleChangeStatus = (status) => {
        navigateLocation({ status, filterTab: state.filterTab, max: state.max, page: 1, tab: 'requests' });
    };

    const unreadQueue = queueCounter?.unreadOrder ? queueCounter.unreadOrder : 0;
    const unreadDelivered = deliveredCounter?.unreadOrder ? deliveredCounter.unreadOrder : 0;

    const requestSectionProps = {
        activeFilter,
        data: requestData,
        totalRequestCount: data?._allOrdersMeta?.count,
        allRequestsTotal: data?._allOrdersMeta.total,
        page: state.page,
        max: state.max,
        loading,
        handleChangeStatus,
        handleChangePage,
        handleChangeMax,
        refetch: refetchAll,
        search,
        activeStatusName: state.status,
        showSearchBox: true,
        handleSearch,
        customEmpty: (
            <Box pt="10">
                <EmptyData />
            </Box>
        ),
    };

    return (
        <Box mt="30">
            <Text textVariant="H5">Requests</Text>
            <Box mt="9">
                <Tabs defaultActiveKey={state.filterTab} animated={false} onChange={handleChangeTab}>
                    <Tabs.TabPane
                        tab={
                            <Text d="inline-flex" alignItems="center">
                                Queue{' '}
                                {unreadQueue > 0 && (
                                    <Badge isNotification ml="6">
                                        {unreadQueue}
                                    </Badge>
                                )}
                            </Text>
                        }
                        key="QUEUE"
                    >
                        <SectionRequest
                            isNotCustomer={isNotCustomer}
                            isWorker={isWorker}
                            name="queue"
                            statuses={getOrderGroupValues('QUEUE')}
                            {...requestSectionProps}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={
                            <Text d="inline-flex" alignItems="center">
                                Delivered{' '}
                                {unreadDelivered > 0 && (
                                    <Badge isNotification ml="6">
                                        {unreadDelivered}
                                    </Badge>
                                )}
                            </Text>
                        }
                        key="DELIVERED"
                    >
                        <SectionRequest
                            isNotCustomer={isNotCustomer}
                            isWorker={isWorker}
                            name="delivered"
                            statuses={getOrderGroupValues('DELIVERED')}
                            {...requestSectionProps}
                        />
                    </Tabs.TabPane>
                    {!isWorker && (
                        <Tabs.TabPane tab="Draft" key="DRAFT">
                            <SectionRequest
                                isNotCustomer={isNotCustomer}
                                isWorker={isWorker}
                                {...requestSectionProps}
                            />
                        </Tabs.TabPane>
                    )}
                </Tabs>
            </Box>
            {/* <Text mt="10" textVariant="Badge" colorScheme="primary">
                {data?._allOrdersMeta?.count} request{data?._allOrdersMeta?.count > 1 ? 's' : ''}
            </Text> */}
        </Box>
    );
};

export default withLoggedUser(Requests);
