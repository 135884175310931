import gql from 'graphql-tag';

export const CREATE_CATEGORY = gql`
    mutation CreateCategory($title: String!, $servicesIds: [String!]!, $categoryImageId: String!) {
        createCategory(title: $title, servicesIds: $servicesIds, categoryImageId: $categoryImageId) {
            id
            title
            slug
            description
            createdAt
            icon {
                id
            }
            services(where: { isActivated: true }) {
                id
            }
        }
    }
`;

export const DELETE_CATEGORY = gql`
    mutation DeleteCategory($id: ID!) {
        deleteCategory(id: $id) {
            id
            slug
        }
    }
`;

export const UPDATE_CATEGORY = gql`
    mutation UpdateCategory(
        $id: ID!
        $title: String!
        $servicesIds: [String!]!
        $servicesIdsToDisconnect: [String!]
        $categoryImageId: String
    ) {
        updateCategory(
            id: $id
            title: $title
            servicesIds: $servicesIds
            servicesIdsToDisconnect: $servicesIdsToDisconnect
            categoryImageId: $categoryImageId
        ) {
            id
            title
            description
            services {
                id
                name
            }
            images {
                id
                url
            }
        }
    }
`;

export const ADD_SERVICE_TO_CATEGORY = gql`
    mutation AddServiceToCategory($categoryCategoryId: ID!, $servicesServiceId: ID!) {
        addToCategoryServices(categoryCategoryId: $categoryCategoryId, servicesServiceId: $servicesServiceId) {
            servicesService {
                id
            }
            categoryCategory {
                id
            }
        }
    }
`;

export const DELETE_SERVICE_CATEGORY = gql`
    mutation DeleteServiceCategory($categoryCategoryId: ID!, $servicesServiceId: ID!) {
        removeFromCategoryServices(categoryCategoryId: $categoryCategoryId, servicesServiceId: $servicesServiceId) {
            servicesService {
                id
            }
            categoryCategory {
                id
            }
        }
    }
`;

export const SET_CATEGORY_ICON = gql`
    mutation SetCategoryIcon($categoryCategoryId: ID!, $iconFileId: ID!) {
        setCategoryIcon(categoryCategoryId: $categoryCategoryId, iconFileId: $iconFileId) {
            iconFile {
                id
            }
            categoryCategory {
                id
            }
        }
    }
`;
