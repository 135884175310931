import { memo, useEffect } from 'react';
import withLoggedUser from '../WithLoggedUser';

const HubspotChat = memo(({ viewer }) => {
    useEffect(() => {
        if (viewer && viewer.role === 'customer' && process.env.NODE_ENV === 'production') {
            const { email, tokenHubspot } = viewer;

            if (tokenHubspot) {
                window.hsConversationsSettings = {
                    identificationEmail: email,
                    identificationToken: tokenHubspot,
                };
            }

            if (window && window.HubSpotConversations && window.HubSpotConversations.widget) {
                window.HubSpotConversations.widget.load();
            }


            return () => {
                if (window && window.HubSpotConversations && window.HubSpotConversations.widget) {
                    window.HubSpotConversations.widget.remove();
                }
            };
        }
    }, [viewer]);

    return null;
});

export default withLoggedUser(HubspotChat);
