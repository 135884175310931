import React, { useCallback, useState } from 'react';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Form } from '@components/Form';
import { AttachmentPlugin, toWysiwyg, Wysiwyg, toHtml } from '@components/Wysiwyg';
import { PopupDelete } from '@components/Popup';
import { Text } from '@components/Text';
import { getDefaultKeyBinding } from 'draft-js';

const FormMessageEdit = ({ form, initialValues, onCancel, onSubmit, onDelete, users }) => {
    const [fileIds, setFileIds] = useState([]);
    const [hasSent, setHasSent] = useState(false);
    const [isUploadingFiles, setIsUploadingFiles] = useState(false);
    const [loading, setSubmitting] = useState(false);
    const [hasMessage, setHasMessage] = useState(false);
    const [isShowDelete, setShowDelete] = useState(false);

    const handleChange = useCallback(editorState => {
        if (editorState.getCurrentContent().hasText()) {
            setHasMessage(true);
        } else {
            setHasMessage(false);
        }
    }, []);

    const handleGetFileIds = attachmentIds => {
        setFileIds(attachmentIds);
    };

    const handleRemoveFileId = (attachmentId, index) => {
        const filteredFileIds = fileIds.filter((fileId, fileIndex) =>
            attachmentId !== null ? fileId !== attachmentId : fileIndex !== index
        );
        setFileIds(filteredFileIds);
    };

    const resetSentStatus = () => {
        setHasSent(false);
    };

    const handleUploadingFiles = bool => {
        setIsUploadingFiles(bool);
    };

    const handleHideDelete = () => {
        setShowDelete(false);
        setSubmitting(false);
    };

    const getMentionedIds = val => {
        const htmlVal = toHtml(val);
        let mentionedIds = [];

        // eslint-disable-next-line
        const splitHtmlVal = htmlVal.split(' ').map(word => {
            if (word.includes('datamentionid="')) {
                const newMentionId = word.split('"')[1];
                mentionedIds.push(newMentionId);
            }

            return word;
        });

        return mentionedIds;
    };

    const handleSubmit = e => {
        if (e) {
            e.preventDefault();
        }
        form.validateFields(async (err, values) => {
            if (!err && !loading) {
                setSubmitting(true);
                const isMessageNotEmpty = values.text?.getCurrentContent()?.hasText() ?? false;
                const mentionedIds = getMentionedIds(values.text);
                if (isMessageNotEmpty || fileIds.length) {
                    await onSubmit({ ...values, fileIds, mentionedIds });
                    setFileIds([]);
                    setHasSent(true);
                    setSubmitting(false);
                } else {
                    setShowDelete(true);
                }
            }
        });
    };

    const handleConfirmDelete = async () => {
        await onDelete();
        setFileIds([]);
        setHasSent(true);
    };

    const handleKeyCommand = command => {
        if (command === 'send-message' && !(isUploadingFiles || (!hasMessage && !fileIds.length))) {
            handleSubmit();
            return 'handled';
        }
        return false;
    };

    const handleKeyBindingFn = evt => {
        if (!evt.shiftKey && evt.keyCode === 13) {
            return 'send-message';
        }

        // handle typing event
        return getDefaultKeyBinding(evt);
    };

    const handleReturn = event => {
        return false;
    };

    const mentions = users?.map(user => ({
        text: `${user.firstname} ${user.lastname[0]}`,
        value: `${user.firstname} ${user.lastname[0]}`,
        url: user.id,
    }));

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Form.Item style={{ marginBottom: 12 }}>
                    {form.getFieldDecorator('text', {
                        initialValue: toWysiwyg(initialValues?.text),
                    })(
                        <Wysiwyg
                            placeholder="Type your message here"
                            isFlip
                            contentMaxHeight="164px"
                            toolbarHeight="40px"
                            toolbarColor="gray"
                            onChange={handleChange}
                            handleKeyCommand={handleKeyCommand}
                            keyBindingFn={handleKeyBindingFn}
                            handleReturn={handleReturn}
                            mentions={mentions}
                            toolbarRight={
                                <AttachmentPlugin
                                    onSetFileIds={handleGetFileIds}
                                    onRemoveFileId={handleRemoveFileId}
                                    onResetSentStatus={resetSentStatus}
                                    onUploadingFiles={handleUploadingFiles}
                                    hasSent={hasSent}
                                />
                            }
                        />
                    )}
                </Form.Item>
                <Box>
                    <Button
                        type="default"
                        h={['34', '34']}
                        fontSize="12"
                        htmlType="button"
                        onClick={onCancel}
                        mr="10"
                        disabled={isUploadingFiles}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        h={['34', '34']}
                        loading={loading}
                        fontSize="12"
                        htmlType="submit"
                        disabled={isUploadingFiles}
                    >
                        Save Changes
                    </Button>
                </Box>
            </Form>
            <PopupDelete
                visible={isShowDelete}
                onOk={handleConfirmDelete}
                onCancel={handleHideDelete}
                title="Are you sure you want to delete this message?"
            >
                <Text>This action cannot be undone</Text>
            </PopupDelete>
        </>
    );
};

export default Form.create()(FormMessageEdit);
