import React from 'react';

const Icon = () => {
  return (
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M57.156 65.835H8.844V9.37201L19.47 0.165009H57.156V65.835Z" fill="#E15F33"/>
      <path d="M49.434 20.064H18.084V41.217H49.434V20.064Z" fill="white"/>
      <path d="M8.844 9.37201H19.47V0.165009L8.844 9.37201Z" fill="#E6E6E4"/>
      <path d="M19.602 47.091H23.925C24.4848 47.0394 25.0482 47.0394 25.608 47.091C26.1054 47.1934 26.5768 47.3955 26.994 47.685C27.3949 47.9531 27.7235 48.3158 27.951 48.741C28.2055 49.2764 28.3299 49.8644 28.314 50.457C28.3231 51.0349 28.2107 51.6083 27.984 52.14C27.7728 52.5748 27.4686 52.9578 27.093 53.262C26.6736 53.5407 26.2021 53.7315 25.707 53.823C25.1482 53.8931 24.5828 53.8931 24.024 53.823H22.176V58.344H19.602V47.091ZM22.176 51.975H23.859H24.519C24.7193 51.9356 24.9099 51.857 25.0798 51.7437C25.2497 51.6305 25.3956 51.4848 25.509 51.315C25.5649 51.0986 25.5649 50.8715 25.509 50.655C25.5189 50.3984 25.4498 50.145 25.311 49.929C25.2127 49.7582 25.0774 49.6117 24.915 49.5C24.6968 49.3954 24.4625 49.3285 24.222 49.302H22.176V51.975Z" fill="white"/>
      <path d="M29.898 47.091H34.188C34.7588 47.0394 35.3332 47.0394 35.904 47.091C36.4013 47.1934 36.8728 47.3955 37.29 47.685C37.6909 47.9531 38.0195 48.3158 38.247 48.741C38.489 49.2796 38.602 49.8671 38.577 50.457C38.5956 51.0325 38.4945 51.6056 38.28 52.14C38.0663 52.5826 37.7494 52.9674 37.356 53.262C36.9527 53.548 36.4903 53.7397 36.003 53.823C35.4441 53.8914 34.8789 53.8914 34.32 53.823H32.439V58.344H29.898V47.091ZM32.439 51.975H34.155H34.815C35.0188 51.9479 35.2121 51.8683 35.376 51.744C35.5504 51.6363 35.6972 51.4894 35.805 51.315C35.8609 51.0986 35.8609 50.8715 35.805 50.655C35.8149 50.3984 35.7458 50.145 35.607 49.929C35.4674 49.7544 35.2857 49.6182 35.079 49.533C34.8608 49.4284 34.6265 49.3615 34.386 49.335H32.439V51.975Z" fill="white"/>
      <path d="M42.438 49.5H39.138V47.256H48.279V49.5H44.979V58.806H42.438V49.5Z" fill="white"/>
      <path d="M45.177 31.647C45.0586 33.1527 44.3584 34.553 43.2249 35.5511C42.0913 36.5491 40.6135 37.0663 39.105 36.993C38.3102 36.968 37.5286 36.7837 36.8064 36.451C36.0842 36.1183 35.4361 35.644 34.9006 35.0562C34.3651 34.4684 33.9531 33.779 33.689 33.029C33.4249 32.279 33.314 31.4836 33.363 30.69C33.4317 29.2817 34.0133 27.9472 34.9981 26.9381C35.9829 25.929 37.3028 25.3151 38.709 25.212C38.709 25.443 38.709 25.707 38.709 25.938C38.709 27.555 38.709 29.238 38.709 30.789C38.709 31.449 38.709 31.68 39.534 31.68C41.019 31.68 42.471 31.68 43.923 31.68L45.177 31.647Z" fill="#E15F33"/>
      <path d="M46.2 30.393H39.93V24.288C41.5404 24.3642 43.0672 25.0272 44.2223 26.1519C45.3774 27.2766 46.0809 28.7852 46.2 30.393V30.393Z" fill="#E15F33"/>
      <path d="M26.4 26.895H22.011C21.516 26.895 21.351 26.895 21.351 26.235C21.351 24.717 21.351 24.684 22.902 24.684H30.756C31.35 24.684 31.548 24.684 31.548 25.476C31.548 26.895 31.548 26.895 30.063 26.895H26.4Z" fill="#E15F33"/>
      <path d="M26.4 29.469H30.855C31.383 29.469 31.548 29.634 31.515 30.129C31.515 31.944 31.746 31.647 29.997 31.647H22.143C21.582 31.647 21.318 31.647 21.351 30.888C21.351 29.436 21.351 29.436 22.77 29.436L26.4 29.469Z" fill="#E15F33"/>
      <path d="M26.4 34.221C27.852 34.221 29.304 34.221 30.756 34.221C31.251 34.221 31.482 34.221 31.449 34.848C31.449 36.762 31.647 36.399 29.931 36.432H22.176C21.582 36.432 21.318 36.432 21.351 35.64C21.351 33.99 21.12 34.254 22.77 34.221H26.4Z" fill="#E15F33"/>
    </svg>
  )
}

export default Icon