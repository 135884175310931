import React from 'react';
import { Form } from '@components/Form';
import { Box } from '@components/Box';
import { Input } from '@components/Input';
import IconSearch from '@components/Svg/IconSearch';
import StatusField from '@pages/Admin/Company/MembersTab/blocks/StatusField';

const AccountsFilter = ({ form, onChangeFilters }) => {
    const { getFieldDecorator } = form;
    const handleFieldsChange = (name, value) => {
        const newValue = value;
        onChangeFilters({ [name]: newValue });
    };

    return (
        <Form>
            <Box d="flex" justifyContent="space-between">
                <Box maxW="404" flex="1">
                    <Form.Item label="" colon={false} required={false} style={{ marginBottom: 20 }}>
                        {getFieldDecorator('search', {
                            initialValue: '',
                        })(
                            <Input
                                prefix={
                                    <Box d="inline-flex" alignItems="center" colorScheme="cta" lineH="1">
                                        <IconSearch />
                                    </Box>
                                }
                                placeholder="Search by email, user, company"
                                onChange={e => handleFieldsChange('search', e.target.value)}
                            />
                        )}
                    </Form.Item>
                </Box>
                <Box d="flex" alignItems="center">
                    <Form.Item label="" colon={false} required={false} style={{ marginBottom: 0 }}>
                        {getFieldDecorator('status', {
                            initialValue: 'ALL',
                        })(<StatusField handleFieldsChange={handleFieldsChange} />)}
                    </Form.Item>
                </Box>
            </Box>
        </Form>
    );
};

export default Form.create()(AccountsFilter);
