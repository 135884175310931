import React, { memo, useCallback, useState } from 'react';
import { Button } from '@components/Button';
import message from '@components/Message';
import { Box } from '@components/Box';
import { Form } from '@components/Form';
import { injectStripe } from 'react-stripe-elements';
import { Input } from '@components/Input';
import { UPDATE_SOURCE } from '@graphql/mutations/subscription';
import { useMutation } from '@apollo/react-hooks';
import { StripeCardNumber, StripeCardExpiry, StripeCardCvc, stripeStyleObject } from '@components/StripeElements';
import StripeElements from '@components/StripeElements/StripeElements';

const _CardForm = ({ form, onClose, billingSource, refetchSubscriptionInvoice, stripe }) => {
    const { validateFields, getFieldDecorator, getFieldsValue } = form;
    const [isLoading, setIsLoading] = useState(false);
    const [updateSource] = useMutation(UPDATE_SOURCE);

    const handleSubmit = useCallback(
        ev => {
            ev.preventDefault();

            validateFields(async (err, values) => {
                if (!err && !isLoading) {
                    message.destroy();
                    message.loading('Updating card...');
                    setIsLoading(true);

                    try {
                        const stripePayload = await stripe.createSource({
                            type: 'card',
                            currency: 'usd',
                            owner: {
                                name: values.cardHolder,
                                email: billingSource.owner.email,
                                address: billingSource.owner.address,
                            },
                            mandate: {
                                notification_method: 'email',
                            },
                        });

                        if (stripePayload.error) {
                            throw stripePayload.error;
                        }

                        const sourceId = stripePayload.source.id;
                        const resultUpdateSource = await updateSource({
                            variables: {
                                card: sourceId,
                            },
                        });

                        if (resultUpdateSource.errors?.length > 0) {
                            throw resultUpdateSource.errors[0];
                        }

                        await refetchSubscriptionInvoice();

                        message.success('Card updated.');
                        setIsLoading(false);
                        onClose();
                        return true;
                    } catch (e) {
                        setIsLoading(false);
                        console.log(e);
                        message.destroy();
                        message.error('Error while updating card');
                        return false;
                    }
                }
            });
        },
        [isLoading, validateFields, onClose, stripe, updateSource, refetchSubscriptionInvoice, billingSource]
    );

    const values = getFieldsValue();
    const isDisabled = !(
        values.cardHolder &&
        values.cardnumber?.type === 'success' &&
        values.cvc?.type === 'success' &&
        values.expirydate?.type === 'success'
    )

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Item label="Cardholder name" colon={false} required style={{ marginBottom: 20 }}>
                {getFieldDecorator('cardHolder', {
                    initialValue: billingSource.owner.name,
                    rules: [
                        {
                            required: true,
                            message: 'This field cannot be empty',
                        },
                    ],
                })(<Input type="text" placeholder="Enter cardholder name" />)}
            </Form.Item>
            <Box>
                <Form.Item label="Card number" colon={false} required style={{ marginBottom: 20 }}>
                    {getFieldDecorator('cardnumber', {
                        rules: [
                            {
                                validator: (rule, value, callback) => {
                                    if (value.type === 'error') {
                                        callback(value.message);
                                    } else {
                                        callback();
                                    }
                                },
                                message: 'Your card is not valid',
                            },
                        ],
                    })(
                        <StripeCardNumber
                            id="cardnumber"
                            className="ant-input"
                            showIcon
                            placeholder={`• • • •  • • • •  • • • •  ${billingSource?.card?.last4 ?? '• • • •'}`}
                            style={stripeStyleObject}
                        />
                    )}
                </Form.Item>
                <Box d="flex">
                    <Box pr="20">
                        <Form.Item label="Expiry date" colon={false} required>
                            {getFieldDecorator('expirydate', {
                                rules: [
                                    {
                                        validator: (rule, value, callback) => {
                                            if (value.type === 'error') {
                                                callback(value.message);
                                            } else {
                                                callback();
                                            }
                                        },

                                        message: 'Card expiration date is not valid',
                                    },
                                ],
                            })(
                                <StripeCardExpiry
                                    id="cardexpiry"
                                    className="ant-input"
                                    w="90"
                                    placeholder={`${billingSource.card ? `${billingSource?.card?.exp_month < 10 ? '0' : ''}${
                                        billingSource?.card.exp_month
                                    }/${billingSource?.card.exp_year.toString().slice(-2)}` : 'MM/YY'}`}
                                    style={stripeStyleObject}
                                />
                            )}
                        </Form.Item>
                    </Box>
                    <Box>
                        <Form.Item label="CVV" colon={false} required>
                            {getFieldDecorator('cvc', {
                                rules: [
                                    {
                                        validator: (rule, value, callback) => {
                                            if (value.type === 'error') {
                                                callback(value.message);
                                            } else {
                                                callback();
                                            }
                                        },
                                        message: 'This field cannot be empty',
                                    },
                                ],
                            })(<StripeCardCvc id="cardcvc" className="ant-input" w="89" style={stripeStyleObject} />)}
                        </Form.Item>
                    </Box>
                </Box>
            </Box>
            <Form.Item>
                <Box d="flex" justifyContent="flex-end" alignItems="center">
                    <Button type="primary" disabled={isDisabled} htmlType="submit" loading={isLoading}>
                        Update
                    </Button>
                </Box>
            </Form.Item>
        </Form>
    );
};

const InjectedStripe = injectStripe(_CardForm);
const InjectedAntdForm = Form.create()(InjectedStripe);

const EditCardForm = props => (
    <Box>
        <StripeElements>
            <InjectedAntdForm {...props} />
        </StripeElements>
    </Box>
);

export default memo(EditCardForm);
