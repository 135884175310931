import React, { memo, useState } from 'react';
import { Form } from '@components/Form';
import { TextArea } from '@components/Input';
import { Text } from '@components/Text';
import { Button } from '@components/Button';
import FieldFeedbackRate from './FieldFeedbackRate';

const FormFeedbackRequest = memo(({ form, onSubmit }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async ev => {
        ev.preventDefault();
        ev.stopPropagation();

        form.validateFields(async (err, values) => {
            if (!err && !isLoading) {
                setIsLoading(true);
                await onSubmit(values);
                setIsLoading(false);
            }
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Text textVariant="H6" colorScheme="primary" mb="20">
                How would you rate your experience?
            </Text>
            <Form.Item colon={false} required={false} style={{ marginBottom: 0 }}>
                {form.getFieldDecorator('rate')(<FieldFeedbackRate />)}
            </Form.Item>
            <Form.Item colon={false} required={false} style={{ marginBottom: 0 }}>
                {form.getFieldDecorator('comment')(<TextArea placeholder="Please share your feedback" rows={5} />)}
            </Form.Item>
            <Button mt="10" type="primary" htmlType="submit" block loading={isLoading}>
                Submit Feedback
            </Button>
        </Form>
    );
});

export default Form.create()(FormFeedbackRequest);
