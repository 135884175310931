import React, { useCallback, useState } from 'react';
import { Form } from '@components/Form';
import { Button } from '@components/Button';
import { Box } from '@components/Box';
import { Input } from '@components/Input';
import checkmark from '@assets/icons/checkmark.svg';

const SignUpForm = ({ form, referrerData, onSubmit, invitationData }) => {
    const { getFieldDecorator, validateFields } = form;
    const [isLoading, setIsLoading] = useState(false);
    const [confirmDirty, setConfirmDirty] = useState('');

    const handleOnSubmitForm = useCallback(
        e => {
            e.preventDefault();
            validateFields(async (err, values) => {
                if (!err && !isLoading) {
                    setIsLoading(true);
                    await onSubmit(values);
                    setIsLoading(false);
                }
            });
        },
        [validateFields, onSubmit, isLoading]
    );

    const handleConfirmBlur = e => setConfirmDirty(confirmDirty || !!e.target.value);

    const handleCompareToFirstPassword = (rule, value, callback) => {
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    const handleValidateToNextPassword = (rule, value, callback) => {
        if (value && confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    return (
        <Form onSubmit={handleOnSubmitForm} name="create_account">
            {referrerData && (
                <Form.Item colon={false} label="Your referrer">
                    {getFieldDecorator('referrer', {
                        initialValue: `${referrerData.firstname} ${referrerData.lastname}`,
                    })(<Input prefix={<img src={checkmark} alt="Checkmark" />} disabled />)}
                </Form.Item>
            )}
            {invitationData && (
                <Box d="none">
                    <Form.Item colon={false} label="Your team">
                        {getFieldDecorator('invitation', {
                            initialValue: !!invitationData?.id,
                        })(<Input disabled />)}
                    </Form.Item>
                </Box>
            )}
            <Form.Item label="First name" colon={false} required>
                {getFieldDecorator('firstname', {
                    rules: [
                        {
                            required: true,
                            message: 'This field cannot be empty',
                        },
                    ],
                })(<Input placeholder="Enter your first name" name="first_name" />)}
            </Form.Item>
            <Form.Item label="Last name" colon={false} required>
                {getFieldDecorator('lastname', {
                    rules: [
                        {
                            required: true,
                            message: 'This field cannot be empty',
                        },
                    ],
                })(<Input placeholder="Enter your last name" name="last_name" />)}
            </Form.Item>
            <Box d={invitationData ? 'none' : 'block'}>
                <Form.Item label="Email" colon={false} required>
                    {getFieldDecorator('email', {
                        initialValue: invitationData?.email ?? '',
                        rules: [
                            {
                                type: 'email',
                                message: 'Please enter a valid email address',
                            },
                            {
                                required: true,
                                message: 'Please enter a valid email address',
                            },
                        ],
                    })(<Input placeholder="Enter your email address" name="email"/>)}
                </Form.Item>
            </Box>
            <Form.Item label="Create password" colon={false} required>
                {getFieldDecorator('password', {
                    rules: [
                        {
                            required: true,
                            message: 'This field cannot be empty',
                        },
                        {
                            validator: handleValidateToNextPassword,
                        },
                    ],
                })(<Input type="password" placeholder="Create your password" />)}
            </Form.Item>
            <Form.Item label="Confirm password" colon={false} required>
                {getFieldDecorator('confirm', {
                    rules: [
                        {
                            required: true,
                            message: 'Passwords do not match',
                        },
                        {
                            validator: handleCompareToFirstPassword,
                            message: 'Passwords do not match',
                        },
                    ],
                })(<Input type="password" placeholder="Confirm your password" onBlur={handleConfirmBlur} />)}
            </Form.Item>
            <Form.Item>
                <Button w="100%" type="primary" htmlType="submit" loading={isLoading}>
                    Register
                </Button>
            </Form.Item>
        </Form>
    );
};

export default Form.create()(SignUpForm);
