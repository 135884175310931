import React from 'react';
import { Icon } from 'antd';

const CloseSvg = () => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.19168 14.632C2.09039 14.6286 1.99222 14.596 1.90891 14.5383C1.8256 14.4806 1.76067 14.4001 1.72187 14.3065C1.68306 14.2128 1.67203 14.11 1.69008 14.0103C1.70814 13.9105 1.75452 13.8181 1.82368 13.744L13.8877 1.67201C13.9862 1.57623 14.1183 1.52264 14.2557 1.52264C14.3931 1.52264 14.5251 1.57623 14.6237 1.67201C14.673 1.71976 14.7123 1.77694 14.7391 1.84016C14.7659 1.90338 14.7797 1.97135 14.7797 2.04001C14.7797 2.10868 14.7659 2.17665 14.7391 2.23987C14.7123 2.30309 14.673 2.36027 14.6237 2.40801L2.55968 14.472C2.512 14.5217 2.45491 14.5615 2.39174 14.5889C2.32856 14.6164 2.26056 14.6311 2.19168 14.632Z"
            fill="currentColor"
        />
        <path
            d="M14.2563 14.632C14.1174 14.6292 13.9851 14.5717 13.8883 14.472L1.82431 2.40003C1.72671 2.30243 1.67187 2.17005 1.67188 2.03203C1.67188 1.894 1.72671 1.76163 1.82431 1.66403C1.92191 1.56643 2.05428 1.5116 2.19231 1.5116C2.33033 1.5116 2.46271 1.56643 2.56031 1.66403L14.6243 13.744C14.6935 13.8181 14.7398 13.9105 14.7579 14.0103C14.776 14.11 14.7649 14.2128 14.7261 14.3065C14.6873 14.4001 14.6224 14.4806 14.5391 14.5383C14.4558 14.5961 14.3576 14.6286 14.2563 14.632Z"
            fill="currentColor"
        />
    </svg>
);

const CloseIcon = props => <Icon component={CloseSvg} {...props} />;

export default CloseIcon;
