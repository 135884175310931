import React, { memo } from 'react';
import { Box } from '@components/Box';
import { Form } from '@components/Form';
import { Text } from '@components/Text';
import { Input } from '@components/Input';
import { Select } from '@components/Select';
import STATES from '@constants/states';
import COUNTRIES from '@constants/countries';
import { NUMBER_OF_EMPLOYEES } from '@constants/forms';
import { FormItemFlex } from '@components/FormItemFlex';

const countriesOptions = COUNTRIES.map(country => (
    <Select.Option key={country.name} value={country.name}>
        {country.name}
    </Select.Option>
));

const numberOfEmployeesOptions = NUMBER_OF_EMPLOYEES.map(numberOfEmployees => (
    <Select.Option key={numberOfEmployees.value} value={numberOfEmployees.value}>
        {numberOfEmployees.text}
    </Select.Option>
));

const statesOptions = STATES.map(state => (
    <Select.Option key={state.name} value={state.name}>
        {state.name}
    </Select.Option>
));

const InfoTab = memo(({ company, form, isWorker, refetch }) => {
    const { getFieldDecorator } = form;
    const billingSource = company?.subscription?.billingSource;

    return (
        <Box my={['20', '30']}>
            <Text textVariant="H5" colorScheme="primary" mb="20">
                Profile
            </Text>
            <Form>
                <FormItemFlex justifyContent="space-between" itemWidthPct={22} spacing={10}>
                    <Box mb={['16', '30']} w={['100%', '50%']}>
                        <Form.Item label="Company name" colon={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('name', {
                                initialValue: company?.name,
                            })(<Input disabled />)}
                        </Form.Item>
                    </Box>
                    <Box mb={['16', '30']} w={['100%', '50%']}>
                        <Form.Item label="Industry" colon={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('industry', {
                                initialValue: company?.industry,
                            })(<Input disabled />)}
                        </Form.Item>
                    </Box>
                </FormItemFlex>
                <FormItemFlex justifyContent="space-between" itemWidthPct={22} spacing={10}>
                    <Box mb={['16', '30']} w={['100%', '50%']}>
                        <Form.Item label="Company website" colon={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('website', {
                                initialValue: company?.website,
                            })(<Input disabled />)}
                        </Form.Item>
                    </Box>
                    <Box mb={['16', '30']} w={['100%', '50%']}>
                        <Form.Item label="Number of Employees" colon={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('nbEmployees', {
                                initialValue: company?.nbEmployees,
                            })(
                                <Select disabled>
                                    {numberOfEmployeesOptions}
                                </Select>
                            )}
                        </Form.Item>
                    </Box>
                </FormItemFlex>
                <Box mb={['16', '30']}>
                    <Form.Item label="Address" colon={false} style={{ marginBottom: 0 }}>
                        {getFieldDecorator('billingAddress', {
                            initialValue: billingSource?.owner?.address?.line1,
                        })(<Input disabled />)}
                    </Form.Item>
                </Box>
                <FormItemFlex justifyContent="space-between" itemWidthPct={22} spacing={10}>
                    <Box mb={['16', '30']} w={['100%', '25%']}>
                        <Form.Item label="City" colon={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('city', {
                                initialValue: billingSource?.owner?.address?.city,
                            })(<Input disabled />)}
                        </Form.Item>
                    </Box>
                    <Box mb={['16', '30']} w={['100%', '25%']}>
                        <Form.Item label="Zip code" colon={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('zipCode', {
                                initialValue: billingSource?.owner?.address?.postal_code,
                            })(<Input disabled />)}
                        </Form.Item>
                    </Box>
                    <Box mb={['16', '30']} w={['100%', '25%']}>
                        <Form.Item label="State" colon={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('state', {
                                initialValue: billingSource?.owner?.address?.state,
                            })(
                                <Select disabled>
                                    {statesOptions}
                                </Select>
                            )}
                        </Form.Item>
                    </Box>
                    <Box mb={['16', '30']} w={['100%', '25%']}>
                        <Form.Item label="Country" colon={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('country', {
                                initialValue: billingSource?.owner?.address?.country,
                            })(
                                <Select disabled>
                                    {countriesOptions}
                                </Select>
                            )}
                        </Form.Item>
                    </Box>
                    <Box mb={['16', '30']} w="100%">
                        <Form.Item label="How did you hear about us?" colon={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('heardManyPixelsFrom', {
                                initialValue: company?.subscription?.user?.heardManyPixelsFrom,
                            })(
                                <Input disabled />
                            )}
                        </Form.Item>
                    </Box>
                </FormItemFlex>
            </Form>
        </Box>
    );
});

export default Form.create()(InfoTab);
