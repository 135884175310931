import React, { memo, useCallback } from 'react';
import { Box } from '@components/Box';
import { Form } from '@components/Form';
import { USER_TYPE_WORKER } from '@constants/account';
import SearchInput from '@components/Input/SearchInput';
import TeamField from '../blocks/TeamField';
import DesignerField from '../blocks/DesignerField';
import * as qs from 'query-string';

const AccountsTabFilter = memo(({ form, onChangeFilters, viewer, designerId }) => {
    // const [hasEdit, setHasEdit] = useState(false);
    const { getFieldDecorator, setFieldsValue } = form;
    const isWorker = viewer?.role === USER_TYPE_WORKER;
    const initialDesigner = designerId || (isWorker ? viewer?.id : 'ALL');
    const viewerId = viewer?.id;
    const parsed = qs.parse(window.location.search);

    const handleFieldsChange = (name, value) => {
        onChangeFilters(name, value);
    };

    getFieldDecorator('account', { initialValue: 'ALL' });
    getFieldDecorator('designer', { initialValue: 'ALL' });
    getFieldDecorator('product', { initialValue: 'ALL' });

    const handleSubmit = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
    }, [])
    return (
        <Box mb="20">
            <Form onSubmit={handleSubmit}>
                <Box d="flex" alignItems="center" mx="-10">
                    <Box maxW="404" flex="1" mx="10">
                        <Form.Item label="" colon={false} required={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('keyword', {
                                initialValue: parsed.keyword ? parsed.keyword : '',
                            })(
                                <SearchInput
                                    onChangeText={value => {
                                        handleFieldsChange('keyword', value);
                                    }}
                                    onClear={() => {
                                        setFieldsValue({ keyword: '' });
                                        onChangeFilters('keyword', '');
                                    }}
                                    placeholder="Search by account, request name, request number"
                                />
                            )}
                        </Form.Item>
                    </Box>
                    <Box maxW="180" flex="1" mx="10">
                        <Form.Item label="" colon={false} required={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('team', {
                                initialValue: parsed.team ? parsed.team : 'ALL',
                            })(<TeamField onFieldChange={handleFieldsChange} />)}
                        </Form.Item>
                    </Box>
                    <Box maxW={designerId || isWorker ? '200' : '336'} flex="1" mx="10">
                        <Form.Item label="" colon={false} required={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('designer', {
                                initialValue: initialDesigner,
                            })(
                                <DesignerField
                                    isWorker={designerId || isWorker}
                                    onFieldChange={handleFieldsChange}
                                    viewerId={viewerId}
                                    disabled
                                />
                            )}
                        </Form.Item>
                    </Box>
                </Box>
            </Form>
        </Box>
    );
});

export default Form.create()(AccountsTabFilter);