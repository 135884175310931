import React from 'react';
import { EditorState } from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import linkifyIt from 'linkify-it';
import tlds from 'tlds';

export const linkify = linkifyIt();
linkify.tlds(tlds);

function replaceBetween(originalText, start, end, replacement) {
    return `${originalText.substring(0, start)}${replacement}${originalText.substring(end)}`;
}

export const hasLink = htmlText => {
    return linkify.test(htmlText);
};

export const parseLinks = (htmlText, replacementText) => {
    const matches = linkify.match(htmlText);
    let plainText = htmlText;
    if (typeof matches !== 'undefined' && matches !== null) {
        matches.forEach((match, index) => {
            const link = linkify.match(plainText)[index];
            plainText = replaceBetween(
                plainText,
                link.index,
                link.lastIndex,
                `<a href=${match.url} target='_blank' rel="noopener noreferrer">${replacementText ? replacementText : match.text}</a>`
            );
        });
    }

    return plainText;
};
export const convertHtmlToState = (input) => {
    return convertFromHTML({
        htmlToEntity: (nodeName, node, createEntity) => {
            if (nodeName === 'a') {
                return createEntity('LINK', 'MUTABLE', { url: node.href, targetOption: node?.target });
            }

            if (nodeName === 'span' && node.dataText) {
                return createEntity('MENTION', 'IMMUTABLE', {
                    text: node.dataText,
                    value: node.dataValue,
                    url: node.dataMentionId,
                });
            }
        },
    })(input);

};

export const toWysiwyg = input => {
    if (input) {
        return EditorState.createWithContent(convertHtmlToState(input));
    }
    return EditorState.createEmpty();
};

export const toHtml = editorState => {
    try {
        return convertToHTML({
            entityToHTML: (entity, originalText) => {
                if (entity.type === 'LINK') {
                    return (
                        <a href={entity.data.url} target={entity.data?.targetOption}>
                            {originalText}
                        </a>
                    );
                }
                if (entity.type === 'MENTION' && entity.data.text) {
                    return (
                        <span
                            datavalue={entity.data.value}
                            datatext={entity.data.text}
                            datamentionid={entity.data.url}
                            style={{ color: '#0099F6', fontWeight: 500 }}
                        >
                            {entity.data.text}
                        </span>
                    );
                }

                return originalText;
            },
        })(editorState.getCurrentContent());
    } catch {
        return '';
    }
};
