import gql from 'graphql-tag';

const Order = {
    fragment: {
        customer: gql`
            fragment CustomerOrder on Order {
                id
                name
                createdAt
                startedAt
                endAt
                status
                priority
                unreadMessages
                discussion {
                    id
                    lastSeens
                    messages(where: { isNote: false }, orderBy: createdAt_DESC, first: 1) {
                        id
                        createdAt
                        user {
                            id
                        }
                    }
                }
                service {
                    id
                    name
                }
                category {
                    id
                    title
                }
                workers {
                    id
                    firstname
                    lastname
                }
            }
        `,
        worker: gql`
            fragment WorkerOrder on Order {
                id
                createdAt
                startedAt
                status
                name
                discussion {
                    id
                    lastSeens
                    messages(orderBy: createdAt_DESC, first: 1) {
                        id
                        createdAt
                        user {
                            id
                        }
                    }
                }
                category {
                    id
                    title
                }
                service {
                    id
                    name
                }
                workers {
                    id
                    firstname
                    lastname
                }
                customer {
                    id
                    firstname
                    lastname
                    subscription {
                        id
                        status
                        quantity
                        plan {
                            id
                            name
                        }
                    }
                    teams {
                        id
                        name
                    }
                }
            }
        `,
        files: gql`
            fragment OrderFiles on Order {
                id
                name
                createdAt
                startedAt
                status
                attachments {
                    id
                    contentType
                    secret
                    name
                    size
                    updatedAt
                    url
                    feedback {
                        id
                        x
                        y
                        content
                        createdAt
                        readBy {
                            id
                        }
                        user {
                            id
                            firstname
                            lastname
                            picture {
                                id
                                url
                            }
                        }
                        comments {
                            id
                            content
                            createdAt
                            user {
                                id
                                picture {
                                    id
                                    url
                                }
                                firstname
                                lastname
                            }
                            readBy {
                                id
                            }
                        }
                    }
                }
                previews {
                    id
                    name
                    updatedAt
                    files {
                        id
                        contentType
                        secret
                        name
                        size
                        updatedAt
                        url
                        feedback {
                            id
                            x
                            y
                            content
                            createdAt
                            readBy {
                                id
                            }
                            user {
                                id
                                firstname
                                lastname
                                picture {
                                    id
                                    url
                                }
                            }
                            comments {
                                id
                                content
                                createdAt
                                user {
                                    id
                                    picture {
                                        id
                                        url
                                    }
                                    firstname
                                    lastname
                                }
                                readBy {
                                    id
                                }
                            }
                        }
                    }
                }
            }
        `,
    },
};

export const ORDER = gql`
    query Order($id: Int!) {
        Order(id: $id) {
            id
            name
            priority
            prioritizedAt
            createdAt
            updatedAt
            startedAt
            submittedAt
            pausedAt
            status
            rate
            feedback {
                id
                comment
            }
            category {
                id
                title
            }
            unreadMessages
            service {
                id
                name
            }
            deliverables
            otherDeliverables
            attachments {
                id
                name
                size
                url
                contentType
                createdAt
                updatedAt
            }
            company {
                id
                name
                users {
                    id
                    firstname
                    lastname
                    picture {
                        id
                        url
                    }
                }
                notes {
                    id
                    text
                }
                subscription {
                    id
                    createdAt
                    plan {
                        id
                        name
                        interval
                    }
                }
                isNotesCleared
            }
            recentFiles {
                id
                name
                contentType
                size
                url
                createdAt
                updatedAt
                folder {
                    id
                    isHidden
                }
                feedback {
                    id
                    comments {
                        id
                        readBy {
                            id
                        }
                    }
                    readBy {
                        id
                    }
                }
                isHidden
            }
            brand {
                id
                name
                colors {
                    name
                }
                logos {
                    name
                    size
                    url
                }
            }
            description
            owners {
                id
                firstname
                lastname
            }
            customer {
                id
                firstname
                lastname
            }
            workers {
                id
                firstname
                lastname
            }
            discussion {
                id
            }
            previews {
                id
                name
                files {
                    id
                    name
                    size
                    url
                    contentType
                    isHidden
                }
                size
                updatedAt
                createdAt
                isHidden
                isDefault
            }
            brief {
                id
                answer
                question
                answerType: type
                files {
                    id
                    url
                    name
                    size
                }
            }
            briefAttachments {
                id
                contentType
                name
                size
                url
                createdAt
                feedback {
                    id
                    comments {
                        id
                        readBy {
                            id
                        }
                    }
                    readBy {
                        id
                    }
                }
            }
        }
    }
`;

export const ORDER_INFOS = gql`
    query Order($id: Int!) {
        Order(id: $id) {
            id
            endAt
            createdAt
            workers {
                id
                firstname
                lastname
            }
            service {
                id
                name
            }
            category {
                id
                title
            }
        }
    }
`;

export const ORDER_BRIEF = gql`
    query Order($id: Int!) {
        Order(id: $id) {
            id
            brand {
                id
                name
                url
                colors {
                    hex
                    name
                }
                logos {
                    name
                    size
                    url
                }
            }
            brief {
                id
                answer
                question
                answerType: type
                files {
                    id
                    url
                    name
                    size
                }
            }
        }
    }
`;

export const ORDER_NAME = gql`
    query OrderName($id: Int!) {
        Order(id: $id) {
            id
            name
        }
    }
`;

export const ORDER_STATUS = gql`
    query OrderName($id: Int!) {
        Order(id: $id) {
            id
            status
        }
    }
`;

export const ORDER_COUNTERS = gql`
    query OrderCounter($where: OrderWhereInput) {
        unreadOrder(where: $where)
    }
`;

export const ALL_ORDERS = gql`
    query AllOrders($first: Int, $skip: Int, $where: OrderWhereInput, $orderBy: OrderOrderByInput) {
        _allOrdersMeta(where: $where) {
            total
            count
        }
        allOrders(orderBy: $orderBy, first: $first, skip: $skip, where: $where) {
            data {
                id
                createdAt
                startedAt
                priority
                endAt
                submittedAt
                pausedAt
                resumedAt
                updatedAt
                deliveredAt
                reopenedAt
                status
                name
                rate
                unreadMessages
                description
                deliverables
                otherDeliverables
                brand {
                    id
                    name
                    logos {
                        name
                        size
                        url
                    }
                }
                discussion {
                    id
                    lastSeens
                    messages(orderBy: createdAt_DESC, first: 1) {
                        id
                        createdAt
                        user {
                            id
                        }
                    }
                }
                category {
                    id
                    title
                    slug
                }
                service {
                    id
                    name
                }
                company {
                    id
                    users {
                        id
                        firstname
                        lastname
                    }
                }
                workers {
                    id
                    firstname
                    lastname
                    picture {
                        id
                        url
                    }   
                }
                owners {
                    id
                    firstname
                    lastname
                }
                previews {
                    id
                    name
                    files {
                        id
                        name
                        size
                        url
                        contentType
                    }
                }
            }
            total
        }
    }
`;

export const ALL_ADMIN_ORDERS = gql`
    query AllAdminOrders(
        $account: String
        $designer: String
        $keyword: String
        $product: String
        $status: [String!]
        $team: String
        $skip: Int
        $first: Int
        $page: String
    ) {
        allAdminOrders(
            account: $account
            designer: $designer
            keyword: $keyword
            product: $product
            status: $status
            team: $team
            skip: $skip
            first: $first
            page: $page
        ) {
            data {
                id
                priority
                prioritizedAt
                createdAt
                startedAt
                endAt
                submittedAt
                pausedAt
                resumedAt
                deliveredAt
                reopenedAt
                updatedAt
                status
                name
                rate
                unreadMessages
                description
                deliverables
                otherDeliverables
                discussion {
                    id
                    lastSeens
                    messages(orderBy: createdAt_DESC, first: 1) {
                        id
                        createdAt
                        user {
                            id
                        }
                    }
                }
                category {
                    id
                    title
                    slug
                }
                service {
                    id
                    name
                }
                company {
                    id
                    name
                    users {
                        id
                        firstname
                        lastname
                    }
                    subscription {
                        id
                        plan {
                            id
                            name
                            interval
                        }
                        statusFinal
                    }
                    teams {
                        id
                        name
                    }
                    notes {
                        id
                        text
                    }
                    isNotesCleared
                }
                workers {
                    id
                    firstname
                    lastname
                    archived
                    picture {
                        id
                        url
                    }
                }
                previews {
                    id
                    name
                    files {
                        id
                        name
                        size
                        url
                        contentType
                    }
                }
                brand {
                    id
                    name
                }
                customer {
                    id
                    firstname
                    lastname
                }
            }
            total
        }
    }
`;

export const LAST_MODIFIER_FOLDER = gql`
    query LastModifiedFolder($id: Int!) {
        allFolders(where: { order: { id: $id } }, first: 1, orderBy: updatedAt_DESC) {
            id
            updatedAt
        }
    }
`;

export const ORDER_FEEDBACK = gql`
    query OrderFeedback($id: Int!) {
        Order(id: $id) {
            id
            feedback {
                id
                score
            }
        }
    }
`;

export const ORDER_UNREAD_MESSAGES = gql`
    query OrderFeedback($id: Int!) {
        Order(id: $id) {
            id
            unreadMessages
            discussion {
                id
                lastSeens
                messages(where: { isNote: false }, orderBy: createdAt_DESC, first: 1) {
                    id
                    createdAt
                    user {
                        id
                    }
                }
            }
        }
    }
`;

export const ORDER_MESSAGES = gql`
    query orderMessages($id: Int!, $last: Int, $skip: Int) {
        Order(id: $id) {
            id
            discussion {
                id
                _messagesMeta(where: { isNote: false }) {
                    count
                }
                messages(where: { isNote: false }, orderBy: createdAt_ASC, last: $last, skip: $skip) {
                    id
                    createdAt
                    updatedAt
                    user {
                        id
                        firstname
                        lastname
                        picture {
                            url
                            id
                        }
                    }
                    isNote
                    isAction
                    isPin
                    actionType
                    actionMeta
                    text
                    readBy {
                        id
                    }
                    files {
                        id
                        name
                        size
                        url
                        isHidden
                        contentType
                        feedback {
                            id
                            comments {
                                id
                                readBy {
                                    id
                                }
                            }
                            readBy {
                                id
                            }
                        }
                        folder {
                            id
                            isHidden
                        }
                    }
                    actionFile {
                        id
                        name
                        size
                        url
                        contentType
                        feedback {
                            id
                            comments {
                                id
                                readBy {
                                    id
                                }
                            }
                            readBy {
                                id
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const ADMIN_ORDER_MESSAGES = gql`
    query orderMessages($id: Int!, $last: Int, $skip: Int) {
        Order(id: $id) {
            id
            discussion {
                id
                _messagesMeta {
                    count
                }
                pinnedMessages: messages(where: { isPin: true }) {
                    id
                    createdAt
                    updatedAt
                    user {
                        id
                        firstname
                        lastname
                        picture {
                            url
                            id
                        }
                    }
                    isNote
                    isAction
                    isPin
                    actionType
                    actionMeta
                    text
                    readBy {
                        id
                    }
                    files {
                        id
                        name
                        size
                        url
                        isHidden
                        contentType
                        feedback {
                            id
                            comments {
                                id
                                readBy {
                                    id
                                }
                            }
                            readBy {
                                id
                            }
                        }
                        folder {
                            id
                            isHidden
                        }
                    }
                    actionFile {
                        id
                        name
                        size
                        url
                        contentType
                        feedback {
                            id
                            comments {
                                id
                                readBy {
                                    id
                                }
                            }
                            readBy {
                                id
                            }
                        }
                    }
                }
                messages(orderBy: createdAt_ASC, last: $last, skip: $skip) {
                    id
                    createdAt
                    updatedAt
                    user {
                        id
                        firstname
                        lastname
                        picture {
                            url
                            id
                        }
                    }
                    isNote
                    isAction
                    isPin
                    actionType
                    actionMeta
                    text
                    readBy {
                        id
                    }
                    files {
                        id
                        name
                        size
                        url
                        isHidden
                        contentType
                        feedback {
                            id
                            comments {
                                id
                                readBy {
                                    id
                                }
                            }
                            readBy {
                                id
                            }
                        }
                        folder {
                            id
                            isHidden
                        }
                    }
                    actionFile {
                        id
                        name
                        size
                        url
                        contentType
                        feedback {
                            id
                            comments {
                                id
                                readBy {
                                    id
                                }
                            }
                            readBy {
                                id
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const ORDER_ATTACHMENTS = gql`
    query orderAttachments($orderId: Int!) {
        Order(id: $orderId) {
            name
            id
            attachments {
                id
                createdAt
                updatedAt
                name
                size
                url
            }
        }
    }
`;

export const ORDER_RECENT_FILES = gql`
    query orderAttachments($id: Int!) {
        Order(id: $id) {
            id
            recentFiles {
                id
                createdAt
                name
                size
                url
            }
        }
    }
`;

export const ORDER_FILES = gql`
    query Order($id: Int!) {
        Order(id: $id) {
            ...OrderFiles
        }
    }
    ${Order.fragment.files}
`;

export default Order;
