import Evaporate from 'evaporate';
import sparkMD5 from 'spark-md5';
import { sha256 } from 'js-sha256';
import { v4 as uuid } from 'uuid'

export function uploadToS3(file, setProgress) {
  try {
    const uploader = Evaporate.create({
      awsSignatureVersion: "4",
      awsRegion: process.env.REACT_APP_S3_BUCKET_REGION,
      signerUrl: `${process.env.REACT_APP_SERV_API}/signv4_auth`, // endpoint of your server
      aws_key: process.env.REACT_APP_S3_ACCESS_KEY,
      bucket:  process.env.REACT_APP_S3_BUCKET_NAME,
      computeContentMd5: true,
      cryptoMd5Method: (d) => btoa(sparkMD5.ArrayBuffer.hash(d, true)),
      cryptoHexEncodedHash256: sha256,
      logging: false
    })

    const fileName = `${uuid()}.${file.name.split('.').pop()}`

    return uploader.then((evaporate) => {
      return evaporate.add({
          file: file,
          name: fileName,
          progress: (percent) => setProgress(percent * 100),
      }).then(
          (awsObjectKey) => {
              return {
                success: true,
                awsObjectKey
              }
          },
          (reason) => {
            return {
              success: false,
              reason
            }
          }
      );
  });
  } catch (err) {
    console.log(err)
  }
}
