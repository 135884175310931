import React from 'react';

const IconDeliverableAi = () => {
    return (
        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57.1557 65.835H8.84375V9.37204L20.0968 0.165039H57.1557V65.835Z" fill="#311902" />
            <path d="M8.84375 9.37204H19.4698V0.165039L8.84375 9.37204Z" fill="#F98924" />
            <path
                d="M28.4132 30.1948H30.9872L37.1582 44.7148H33.6272L32.4062 41.6458H26.8952L25.7072 44.7148H22.4072L28.4132 30.1948ZM29.7002 34.4188L27.8852 38.9398H31.3502L29.7002 34.4188Z"
                fill="#F88824"
            />
            <path
                d="M38.0158 31.6142C38.0086 31.1374 38.186 30.6763 38.5108 30.3272C38.6678 30.1448 38.8596 29.9954 39.0749 29.8877C39.2901 29.7801 39.5247 29.7163 39.7648 29.7002C39.9933 29.7025 40.2189 29.7504 40.4286 29.8411C40.6382 29.9317 40.8277 30.0633 40.9858 30.2282C41.3198 30.574 41.5088 31.0345 41.5138 31.5152C41.5071 31.9858 41.3178 32.4355 40.9858 32.7692C40.8277 32.934 40.6382 33.0657 40.4286 33.1563C40.2189 33.247 39.9933 33.2949 39.7648 33.2972C39.2942 33.2905 38.8446 33.1011 38.5108 32.7692C38.2111 32.4571 38.0351 32.0465 38.0158 31.6142ZM38.2468 34.7492H41.2498V44.6492H38.2468V34.7492Z"
                fill="#F88824"
            />
        </svg>
    );
};

export default IconDeliverableAi;
