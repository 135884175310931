import React from 'react';
import styled, { css } from 'styled-components';
import { Skeleton as AntdSkeleton } from 'antd';
import { mediaQueryUtils, sizeUtils, displayUtils } from '../Utils';

const avatarVariant = css`
    height: ${props => `${props.avatarSize ? props.avatarSize : '32'}px`};
    width: ${props => `${props.avatarSize ? props.avatarSize : '32'}px`};
    line-height: ${props => `${props.avatarSize ? props.avatarSize : '32'}px`};
    border-radius: 50%;
`;

const shieldVariant = css`
    position: relative;
    width: 22px;
    height: 20px;
    padding-top: 2px;
    border-radius: 2px;
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: transparent;

    &:before {
        content: '';
        width: 13px;
        height: 13px;
        position: absolute;
        border-bottom: 1px solid;
        border-right: 1px solid;
        border-bottom-left-radius: 2px;
        border-top-right-radius: 2px;
        left: 0px;
        bottom: -6px;
        transform-origin: 11px 10px;
        transform: rotate(45deg) skew(-15deg, -15deg);
        border-color: transparent;
        background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    }
`;

const variants = {
    avatar: avatarVariant,
    shield: shieldVariant,
};

const StyledSkeleton = styled(AntdSkeleton)`
    display: block;
    ${sizeUtils}
    ${displayUtils}
    ${mediaQueryUtils}
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1.4s ease infinite;

    ${props => variants[props.variant]}
`;

/**
 * Skeleton Component
 * @type {React.FC<import('antd/lib/skeleton').SkeletonProps>}
 */
export const Skeleton = ({ ...skeletonProps }) => {
    return <StyledSkeleton active paragraph={false} title={false} {...skeletonProps} />;
};
