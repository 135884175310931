import io from 'socket.io-client';
import { API_LINK } from '@constants/general';

export const socket = io(API_LINK, {
  auth: {
    token: localStorage ? localStorage.getItem('token') : '',
  },
  transports: ['websocket'],
  withCredentials: true
  
});
