import React from 'react';

const IconDeliverablePng = () => {
    return (
        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57.1557 65.835H8.84375V9.37204L20.0968 0.165039H57.1557V65.835Z" fill="#E6E6E4" />
            <path d="M43.4944 19.5688H22.1104V38.2138H43.4944V19.5688Z" fill="white" />
            <path opacity="0.26" d="M26.3674 19.5688H22.1104V23.8258H26.3674V19.5688Z" fill="#31B89A" />
            <path opacity="0.26" d="M34.9142 19.5688H30.6572V23.8258H34.9142V19.5688Z" fill="#31B89A" />
            <path opacity="0.26" d="M43.4611 19.5688H39.2041V23.8258H43.4611V19.5688Z" fill="#31B89A" />
            <path opacity="0.26" d="M30.6574 23.793H26.4004V28.05H30.6574V23.793Z" fill="#31B89A" />
            <path opacity="0.26" d="M39.2043 23.793H34.9473V28.05H39.2043V23.793Z" fill="#31B89A" />
            <path opacity="0.26" d="M26.3674 28.0171H22.1104V32.2741H26.3674V28.0171Z" fill="#31B89A" />
            <path d="M34.9142 28.0171H30.6572V32.2741H34.9142V28.0171Z" fill="#C6C6C4" />
            <path d="M43.4611 28.0171H39.2041V32.2741H43.4611V28.0171Z" fill="#C6C6C4" />
            <path
                d="M22.1104 31.251L32.0434 25.542L37.0264 28.776L43.4944 25.806V38.214H22.1104V31.251Z"
                fill="#31B89A"
            />
            <path
                d="M14.5195 46.1998H19.2385C19.8709 46.1968 20.5017 46.2632 21.1195 46.3978C21.6662 46.4903 22.185 46.7046 22.6375 47.0248C23.0967 47.3252 23.4625 47.7482 23.6935 48.2458C23.9807 48.8416 24.1168 49.499 24.0895 50.1598C24.103 50.8161 23.9793 51.468 23.7265 52.0738C23.5108 52.5615 23.1691 52.9829 22.7365 53.2948C22.2873 53.606 21.7835 53.8299 21.2515 53.9548C20.6337 54.0894 20.0029 54.1558 19.3705 54.1528H17.3245V59.2018H14.5195V46.1998ZM17.3245 51.7108H19.2055H19.9315C20.1534 51.6573 20.3652 51.5681 20.5585 51.4468C20.7416 51.3356 20.8901 51.1756 20.9875 50.9848C21.127 50.7569 21.1959 50.4928 21.1855 50.2258C21.1675 49.9689 21.0883 49.72 20.9545 49.4998C20.7868 49.3104 20.5854 49.1538 20.3605 49.0378C20.1179 48.937 19.8625 48.8704 19.6015 48.8398H18.7765H17.3245V51.7108Z"
                fill="#08122A"
            />
            <path
                d="M25.8389 46.2002H29.6999L35.1119 55.2422V46.2002H37.9169V59.2022H34.2209L28.6769 49.9622V59.2022H25.8389V46.2002Z"
                fill="#08122A"
            />
            <path
                d="M51.9753 58.2779C51.2094 58.7706 50.3755 59.1486 49.5003 59.3999C48.6008 59.5982 47.6823 59.6977 46.7613 59.6969C45.8177 59.7019 44.8802 59.5457 43.9893 59.2349C43.1814 58.9163 42.4421 58.4458 41.8113 57.8489C41.2086 57.2287 40.7273 56.5012 40.3923 55.7039C40.0515 54.7971 39.8836 53.8345 39.8973 52.8659C39.8866 51.8975 40.0544 50.9355 40.3923 50.0279C40.7273 49.2306 41.2086 48.5031 41.8113 47.8829C42.386 47.1898 43.0926 46.6177 43.8903 46.1999C44.7812 45.8891 45.7187 45.7329 46.6623 45.7379C47.6245 45.7658 48.5788 45.9211 49.5003 46.1999C50.3424 46.4556 51.1116 46.908 51.7443 47.5199L49.7643 49.6979C49.4088 49.3066 48.9686 49.0019 48.4773 48.8069C47.9144 48.5683 47.3062 48.4556 46.6953 48.4769C46.1275 48.4621 45.5636 48.5748 45.0453 48.8069C44.5823 49.0035 44.1662 49.296 43.8243 49.6649C43.4632 50.0449 43.1826 50.4939 42.9993 50.9849C42.8261 51.5292 42.7371 52.0967 42.7353 52.6679C42.7316 53.2395 42.8207 53.8079 42.9993 54.3509C43.1826 54.8419 43.4632 55.2909 43.8243 55.6709C44.1734 56.04 44.5872 56.342 45.0453 56.5619C45.5706 56.7676 46.1311 56.8685 46.6953 56.8589C47.2191 56.8688 47.7417 56.8021 48.2463 56.6609C48.6237 56.5433 48.9884 56.3886 49.3353 56.1989V54.1199H47.0253V51.4799H52.0083L51.9753 58.2779Z"
                fill="#08122A"
            />
            <path d="M8.84375 9.37204H19.4698V0.165039L8.84375 9.37204Z" fill="#AFAFAC" />
        </svg>
    );
};

export default IconDeliverablePng;
