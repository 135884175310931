import React from 'react';

const IconFeedbackFilled = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.9077 18.8294C9.7848 18.8259 9.66548 18.7881 9.56375 18.7206C9.46201 18.653 9.38208 18.5584 9.33334 18.448L8.0718 15.6376C6.17809 15.1611 4.52709 14.0248 3.42848 12.4419C2.32987 10.859 1.85912 8.93823 2.10454 7.03988C2.34996 5.14152 3.29468 3.396 4.76151 2.13072C6.22833 0.86544 8.11648 0.167328 10.0718 0.167328C12.0271 0.167328 13.9153 0.86544 15.3821 2.13072C16.8489 3.396 17.7936 5.14152 18.0391 7.03988C18.2845 8.93823 17.8137 10.859 16.7151 12.4419C15.6165 14.0248 13.9655 15.1611 12.0718 15.6376L10.5128 18.5182C10.4509 18.6195 10.3617 18.7021 10.2549 18.757C10.1482 18.8119 10.028 18.8369 9.9077 18.8294Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconFeedbackFilled;
