import React, { memo, useCallback, useState } from 'react';
import { useMutation } from 'react-apollo';
import compact from 'lodash/compact';
import { Form } from '@components/Form';
import { Popup } from '@components/Popup';
import { Input } from '@components/Input';
import { Button } from '@components/Button';
import { Box } from '@components/Box';
import { Select } from '@components/Select';
import { Checkbox } from '@components/Checkbox';
import { Text } from '@components/Text';
import IconAdd from '@components/Svg/IconAdd';
import message from '@components/Message';
import RemoveIcon from '@assets/icons/remove-feature.svg';
import { ADD_QUESTION } from '@graphql/mutations/question';

let choiceKeyId = 1;

const AddQuestion = memo(({ visible, onAdd, onCancel, isEditProduct, serviceId, form }) => {
    const { resetFields, validateFields, getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const [createQuestion] = useMutation(ADD_QUESTION);
    const [isAdding, setIsAdding] = useState(false);

    const addChoiceField = () => {
        const choiceKeys = getFieldValue('choiceKeys');
        const nextKeys = choiceKeys.concat(choiceKeyId++);
        setFieldsValue({
            choiceKeys: nextKeys,
        });
    };

    const removeChoiceField = choiceK => {
        const choiceKeys = getFieldValue('choiceKeys');
        if (choiceKeys.length === 1) {
            return;
        }

        setFieldsValue({
            choiceKeys: choiceKeys.filter(key => key !== choiceK),
        });
    };

    const onCloseModal = () => {
        resetFields();
        onCancel();
    };

    const hasChoices = getFieldValue('answerType') === 'RADIO' || getFieldValue('answerType') === 'DROPDOWN';

    const handleSubmit = useCallback(
        async e => {
            e.preventDefault();
            validateFields(async (err, values) => {
                if (!err && !isAdding) {
                    if (isEditProduct) {
                        setIsAdding(true);
                        message.destroy();
                        message.loading('Adding question...', 50000);
                        await createQuestion({
                            variables: {
                                title: values.title,
                                answerType: values.answerType,
                                choices: hasChoices ? compact(values.choices) : null,
                                placeholder: values.placeholder,
                                help: values.help,
                                required: values.required,
                                serviceId,
                            },
                        })
                            .then(({ data }) => {
                                const { createQuestion } = data;
                                setIsAdding(false);
                                message.destroy();
                                message.success('Question successfully added');
                                onAdd({
                                    id: createQuestion.id,
                                    title: values.title,
                                    answerType: values.answerType,
                                    choices: hasChoices ? compact(values.choices) : null,
                                    choicesIds: hasChoices ? createQuestion.choices.map(c => c.id) : null,
                                    placeholder: values.placeholder,
                                    help: values.help,
                                    required: values.required,
                                });
                                resetFields();
                                onCancel();
                            })
                            .catch(e => {
                                console.log(e);
                                setIsAdding(false);
                                message.destroy();
                                message.error('Error on adding question');
                            });
                    } else {
                        onAdd({
                            title: values.title,
                            answerType: values.answerType,
                            choices: hasChoices ? compact(values.choices) : null,
                            placeholder: values.placeholder,
                            help: values.help,
                            required: values.required,
                        });
                        resetFields();
                        onCancel();
                    }
                }
            });
        },
        [isAdding, validateFields, onAdd, resetFields, onCancel, hasChoices, isEditProduct, createQuestion, serviceId]
    );
    getFieldDecorator('choiceKeys', { initialValue: [0] });
    const choiceKeys = getFieldValue('choiceKeys');

    const choiceFormItems = choiceKeys.map((k, index) => (
        <Box key={k} mt={index === 0 ? '0' : '16'}>
            <Form.Item label={`Choice #${index + 1}`} colon={false} required={false} style={{ marginBottom: 10 }}>
                <Box d="flex" alignItems="center">
                    {getFieldDecorator(`choices[${k}]`, {
                        initialValue: '',
                        rules: [
                            {
                                required: hasChoices ? true : false,
                                message: 'This field cannot be empty',
                            },
                        ],
                    })(<Input placeholder={`Enter choice #${index + 1}`} />)}
                    {choiceKeys.length > 1 ? (
                        <Box
                            ml="18"
                            w="40"
                            h="40"
                            lineH="38"
                            borderW="1"
                            borderStyle="solid"
                            borderColor="outline-gray"
                            cursor="pointer"
                            textAlign="center"
                            onClick={() => removeChoiceField(k)}
                        >
                            <img src={RemoveIcon} alt="Remove Choice" />
                        </Box>
                    ) : null}
                </Box>
            </Form.Item>
        </Box>
    ));

    return (
        <Popup
            variant="default"
            width={500}
            title="Add question"
            visible={visible}
            onCancel={onCloseModal}
            footer={null}
            centered
            destroyOnClose
        >
            <Form onSubmit={handleSubmit}>
                <Form.Item label="Question title" colon={false} required={false} style={{ marginBottom: 19 }}>
                    {getFieldDecorator('title', {
                        rules: [
                            {
                                required: true,
                                message: 'This field cannot be empty',
                            },
                        ],
                    })(<Input placeholder="Ex: Do you prefer clean and discreet design or strong visualy identity?" />)}
                </Form.Item>
                <Form.Item label="Type of answer" colon={false} required={false} style={{ marginBottom: 19 }}>
                    {getFieldDecorator('answerType', {
                        rules: [
                            {
                                required: true,
                                message: 'This field cannot be empty',
                            },
                        ],
                    })(
                        <Select placeholder="Select type of answer">
                            <Select.Option value="TEXT">Text</Select.Option>
                            <Select.Option value="IMG_SELECT">Image select</Select.Option>
                            <Select.Option value="RADIO">Radio</Select.Option>
                            <Select.Option value="UPLOAD_FILES">Upload files</Select.Option>
                            <Select.Option value="DROPDOWN">Dropdown</Select.Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="" colon={false} required={false} style={{ marginBottom: hasChoices ? 10 : 0 }}>
                    {hasChoices && (
                        <>
                            {choiceFormItems}
                            <Box
                                mt="18"
                                mb="9"
                                d="flex"
                                alignItems="center"
                                colorScheme="cta"
                                cursor="pointer"
                                onClick={addChoiceField}
                                w="103"
                            >
                                <IconAdd />
                                <Text ml="8" textVariant="H6">
                                    Add choice
                                </Text>
                            </Box>
                        </>
                    )}
                </Form.Item>
                <Form.Item label="Placeholder" colon={false} required={false} style={{ marginBottom: 19 }}>
                    {getFieldDecorator('placeholder', {})(<Input placeholder="Enter placeholder" />)}
                </Form.Item>
                <Form.Item label="Help information" colon={false} required={false} style={{ marginBottom: 19 }}>
                    {getFieldDecorator('help', {})(<Input placeholder="Enter help information" />)}
                </Form.Item>
                <Form.Item label="" colon={false} required={false}>
                    {getFieldDecorator('required', {
                        valuePropName: 'checked',
                        initialValue: false,
                    })(
                        <Checkbox>
                            <Text d="inline-block" textVariant="P4" colorScheme="headline">
                                Is question required?
                            </Text>
                        </Checkbox>
                    )}
                </Form.Item>
                <Form.Item>
                    <Box d="flex" justifyContent="flex-end">
                        <Button loading={isAdding} type="primary" htmlType="submit">
                            Add Question
                        </Button>
                    </Box>
                </Form.Item>
            </Form>
        </Popup>
    );
});

export default Form.create()(AddQuestion);
