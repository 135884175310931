import React, { memo } from 'react';
import { Popup } from '@components/Popup';
import EditCardForm from '../EditCardForm';

const EditCardModal = memo(({ visible, onClose, billingSource, refetchSubscriptionInvoice }) => {
    return (
        <Popup
            variant="default"
            width={500}
            title="Edit card"
            visible={visible}
            onOk={onClose}
            onCancel={onClose}
            footer={null}
            centered
            destroyOnClose
        >
            {billingSource && (
                <EditCardForm
                    onClose={onClose}
                    billingSource={billingSource}
                    refetchSubscriptionInvoice={refetchSubscriptionInvoice}
                />
            )}
        </Popup>
    );
});

export default EditCardModal;
