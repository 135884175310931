import React from 'react';

const IconGiveGet = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.0499 7.89995C16.8775 7.89995 16.7121 7.83147 16.5902 7.70957C16.4683 7.58767 16.3999 7.42234 16.3999 7.24995V3.24995H5.75986C5.58747 3.24995 5.42214 3.18147 5.30024 3.05957C5.17835 2.93767 5.10986 2.77234 5.10986 2.59995C5.10986 2.42756 5.17835 2.26223 5.30024 2.14033C5.42214 2.01843 5.58747 1.94995 5.75986 1.94995H17.0499C17.2214 1.95253 17.3853 2.02184 17.5066 2.14319C17.628 2.26453 17.6973 2.42836 17.6999 2.59995V7.24995C17.6973 7.42154 17.628 7.58537 17.5066 7.70672C17.3853 7.82806 17.2214 7.89737 17.0499 7.89995Z"
        fill="currentColor"
      />
      <path
        d="M17.12 8.99999C17.0321 9.00132 16.9448 8.98421 16.8639 8.94976C16.7829 8.91531 16.7101 8.86429 16.65 8.79999L14.34 6.35999C14.2203 6.2368 14.1533 6.07178 14.1533 5.89999C14.1533 5.7282 14.2203 5.56318 14.34 5.43999C14.4004 5.37952 14.4721 5.33154 14.551 5.29881C14.63 5.26608 14.7146 5.24923 14.8 5.24923C14.8855 5.24923 14.9701 5.26608 15.049 5.29881C15.128 5.33154 15.1997 5.37952 15.26 5.43999L17.1 7.38999L18.75 5.46999C18.8058 5.40433 18.874 5.3503 18.9507 5.311C19.0274 5.27169 19.1111 5.24788 19.197 5.24092C19.2829 5.23395 19.3693 5.24397 19.4513 5.27041C19.5334 5.29685 19.6094 5.33918 19.675 5.39499C19.7407 5.4508 19.7947 5.519 19.834 5.59569C19.8733 5.67238 19.8971 5.75606 19.9041 5.84196C19.9111 5.92785 19.9011 6.01428 19.8746 6.0963C19.8482 6.17832 19.8058 6.25433 19.75 6.31999L17.61 8.78999C17.5512 8.85973 17.4776 8.91561 17.3946 8.95364C17.3117 8.99167 17.2213 9.01091 17.13 9.00999L17.12 8.99999Z"
        fill="currentColor"
      />
      <path
        d="M14.18 18.71H2.9C2.72761 18.71 2.56228 18.6415 2.44038 18.5196C2.31848 18.3977 2.25 18.2324 2.25 18.06V13.39C2.25 13.2176 2.31848 13.0523 2.44038 12.9304C2.56228 12.8085 2.72761 12.74 2.9 12.74C3.07239 12.74 3.23772 12.8085 3.35962 12.9304C3.48152 13.0523 3.55 13.2176 3.55 13.39V17.39H14.18C14.2714 17.3786 14.3643 17.3867 14.4523 17.4139C14.5404 17.4411 14.6216 17.4868 14.6907 17.5478C14.7598 17.6087 14.8151 17.6837 14.853 17.7677C14.8909 17.8517 14.9105 17.9428 14.9105 18.035C14.9105 18.1271 14.8909 18.2182 14.853 18.3022C14.8151 18.3862 14.7598 18.4612 14.6907 18.5222C14.6216 18.5832 14.5404 18.6288 14.4523 18.656C14.3643 18.6832 14.2714 18.6914 14.18 18.68V18.71Z"
        fill="currentColor"
      />
      <path
        d="M0.700049 15.39C0.544787 15.3921 0.394582 15.3348 0.280049 15.23C0.213592 15.1752 0.158892 15.1075 0.119226 15.031C0.0795596 14.9545 0.0557444 14.8708 0.0492079 14.7849C0.0426713 14.699 0.053548 14.6126 0.0811858 14.531C0.108824 14.4494 0.152654 14.3743 0.210049 14.31L2.33005 11.85C2.3901 11.7809 2.46367 11.7248 2.54623 11.6853C2.62879 11.6457 2.71857 11.6235 2.81005 11.62C2.90006 11.621 2.98896 11.6401 3.07143 11.6761C3.1539 11.7122 3.22825 11.7645 3.29005 11.83L5.60005 14.28C5.71807 14.406 5.78121 14.5737 5.77559 14.7462C5.76996 14.9187 5.69603 15.082 5.57005 15.2C5.44407 15.318 5.27637 15.3812 5.10384 15.3755C4.9313 15.3699 4.76807 15.296 4.65005 15.17L2.84005 13.25L1.19005 15.16C1.1297 15.2313 1.05469 15.2887 0.970129 15.3284C0.885572 15.3681 0.793453 15.3891 0.700049 15.39Z"
        fill="currentColor"
      />
      <path
        d="M9.66 7.49997C9.5738 7.5013 9.4882 7.48546 9.40818 7.45339C9.32816 7.42132 9.25532 7.37365 9.1939 7.31316C9.13248 7.25267 9.08371 7.18057 9.05042 7.10105C9.01713 7.02153 8.99999 6.93618 9 6.84997V4.61997C9 4.44758 9.06848 4.28225 9.19038 4.16035C9.31228 4.03845 9.47761 3.96997 9.65 3.96997C9.82239 3.96997 9.98772 4.03845 10.1096 4.16035C10.2315 4.28225 10.3 4.44758 10.3 4.61997V6.84997C10.3 7.02064 10.2329 7.18448 10.1132 7.30609C9.99342 7.42771 9.83065 7.49734 9.66 7.49997Z"
        fill="currentColor"
      />
      <path
        d="M9.66 16C9.5738 16.0013 9.4882 15.9855 9.40818 15.9534C9.32816 15.9214 9.25532 15.8737 9.1939 15.8132C9.13248 15.7527 9.08371 15.6806 9.05042 15.6011C9.01713 15.5216 8.99999 15.4362 9 15.35V13.29C9 13.1176 9.06848 12.9523 9.19038 12.8304C9.31228 12.7085 9.47761 12.64 9.65 12.64C9.82239 12.64 9.98772 12.7085 10.1096 12.8304C10.2315 12.9523 10.3 13.1176 10.3 13.29V15.35C10.3 15.5207 10.2329 15.6845 10.1132 15.8061C9.99342 15.9277 9.83065 15.9974 9.66 16Z"
        fill="currentColor"
      />
      <path
        d="M12.1302 13.87H7.87021C7.69782 13.87 7.53249 13.8015 7.4106 13.6796C7.2887 13.5577 7.22021 13.3924 7.22021 13.22V12.22C7.22021 12.0476 7.2887 11.8823 7.4106 11.7604C7.53249 11.6385 7.69782 11.57 7.87021 11.57C8.04261 11.57 8.20794 11.6385 8.32983 11.7604C8.45173 11.8823 8.52022 12.0476 8.52022 12.22V12.58H11.5202V10.49H7.93021C7.84486 10.49 7.76033 10.4732 7.68147 10.4405C7.60261 10.4078 7.53095 10.36 7.4706 10.2996C7.41024 10.2392 7.36236 10.1676 7.32969 10.0887C7.29703 10.0099 7.28022 9.92533 7.28022 9.83998V6.99998C7.28022 6.82758 7.3487 6.66225 7.4706 6.54036C7.59249 6.41846 7.75782 6.34998 7.93021 6.34998H11.6302C11.8026 6.34998 11.9679 6.41846 12.0898 6.54036C12.2117 6.66225 12.2802 6.82758 12.2802 6.99998V7.53998C12.2679 7.6942 12.201 7.83898 12.0915 7.94829C11.982 8.05761 11.8371 8.12428 11.6829 8.13633C11.5287 8.14838 11.3752 8.10502 11.25 8.01404C11.1249 7.92305 11.0363 7.79042 11.0002 7.63998H8.58021V9.18998H12.1302C12.3026 9.18998 12.4679 9.25846 12.5898 9.38036C12.7117 9.50225 12.7802 9.66759 12.7802 9.83998V13.22C12.7802 13.3924 12.7117 13.5577 12.5898 13.6796C12.4679 13.8015 12.3026 13.87 12.1302 13.87Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconGiveGet;
