import React from 'react'


const Icon = ({ isFilled }) => {

  if (!isFilled) {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 1H0V5H12V1Z" fill="#E4EBF1"/>
        <path d="M12 8H0V12H12V8Z" fill="#E4EBF1"/>
        <path d="M12 15H0V19H12V15Z" fill="#E4EBF1"/>
        <path d="M20 15H16V19H20V15Z" fill="#E4EBF1"/>
        <path d="M20 8H16V12H20V8Z" fill="#E4EBF1"/>
        <path d="M20 1H16V5H20V1Z" fill="#E4EBF1"/>
      </svg>
    )
  }

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 1H0V5H12V1Z" fill="#009DFF"/>
      <path d="M12 8H0V12H12V8Z" fill="#009DFF"/>
      <path d="M12 15H0V19H12V15Z" fill="#009DFF"/>
      <path d="M20 15H16V19H20V15Z" fill="#009DFF"/>
      <path d="M20 8H16V12H20V8Z" fill="#009DFF"/>
      <path d="M20 1H16V5H20V1Z" fill="#009DFF"/>
    </svg>
  )
}


export default Icon