import React from 'react';

const IconFeedback = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clipIconFeedback)">
                <path
                    d="M12.7998 25.0925C12.624 25.0893 12.4534 25.0333 12.3105 24.9319C12.1676 24.8305 12.0592 24.6884 11.9998 24.5246L10.3464 20.7607C7.85089 20.1182 5.67734 18.5967 4.23278 16.4812C2.78822 14.3657 2.17171 11.8011 2.49869 9.26778C2.82566 6.73443 4.0737 4.40599 6.00913 2.71845C7.94455 1.0309 10.4346 0.0999756 13.0131 0.0999756C15.5916 0.0999756 18.0817 1.0309 20.0171 2.71845C21.9525 4.40599 23.2006 6.73443 23.5275 9.26778C23.8545 11.8011 23.238 14.3657 21.7934 16.4812C20.3489 18.5967 18.1753 20.1182 15.6798 20.7607L13.6131 24.617C13.5385 24.7657 13.4219 24.8897 13.2776 24.9741C13.1332 25.0585 12.9672 25.0996 12.7998 25.0925ZM11.1064 19.1759C11.2485 19.2002 11.3824 19.2586 11.4963 19.3461C11.6102 19.4336 11.7007 19.5475 11.7598 19.6778L12.8664 22.2002L14.2798 19.5589C14.3381 19.4497 14.4194 19.3542 14.5184 19.279C14.6173 19.2038 14.7315 19.1506 14.8531 19.1231C16.9886 18.6652 18.8777 17.4413 20.157 15.6868C21.4362 13.9324 22.0153 11.7713 21.7829 9.61946C21.5504 7.46757 20.5227 5.47684 18.8976 4.03024C17.2725 2.58365 15.1647 1.78337 12.9798 1.78337C10.7948 1.78337 8.68705 2.58365 7.06194 4.03024C5.43683 5.47684 4.40919 7.46757 4.1767 9.61946C3.94422 11.7713 4.52332 13.9324 5.8026 15.6868C7.08188 17.4413 8.97097 18.6652 11.1064 19.1231V19.1759Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clipIconFeedback">
                    <rect width="26" height="25" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconFeedback;
