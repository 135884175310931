import React from 'react';
import { Box } from '@components/Box';
import IconShieldPaused from '@components/Svg/IconShieldPaused';
import { SectionHeader } from './SectionHeader';
import { DroppableRequest } from './DroppableRequest';
import { RowItemRequest } from './RowItemRequest';
import { DropdownActionRequest } from './DropdownActionRequest';

export const SectionPausedRequest = ({
  data,
  refetch,
  isNotCustomer = false,
}) => {
  return (
    <Box pt="10">
      <SectionHeader
        title="Paused"
        total={Array.isArray(data) ? data.length : 0}
        tooltip="Pause your request while you’re waiting for feedback from your team or customers. Resume when ready. We don’t work on Paused requests."
      />
      {Array.isArray(data) && (
        <DroppableRequest name="paused">
          {data.map((item, index) => (
            <RowItemRequest
              key={item.id}
              id={item.id}
              owners={item.owners}
              index={index}
              name={item.name}
              category={item.category}
              date={item.status === 'SUBMITTED' ? item.submittedAt : item.updatedAt}
              dateLabel={item.status === 'SUBMITTED' ? "Submitted" : "Last Updated"}
              status={item.status}
              brand={item.brand}
              workers={item.workers}
              iconProps={{
                colorScheme: '#C7CBD4',
                borderColor: '#C7CBD4',
                pt: '1',
              }}
              unreadMessages={item.unreadMessages}
              icon={<IconShieldPaused />}
              optionComponent={DropdownActionRequest}
              optionProps={{
                id: item.id,
                enableComplete: true,
                enableDownload: true,
                enableDuplicate: true,
                enableResume: true,
                enableDelete: true,
                refetch,
                status: item.status,
                lastFolder: item?.previews?.length
                  ? item.previews[item?.previews?.length - 1]
                  : null,
                requestName: item.name,
              }}
              isNotCustomer={isNotCustomer}
            />
          ))}
        </DroppableRequest>
      )}
    </Box>
  );
};
