import React from 'react';

const IconFilePsd = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.32 19.95H2.68V2.83999L6.09 0.0499878H17.32V19.95Z" fill="#022732" />
            <path d="M2.68 2.83999H5.9V0.0499878L2.68 2.83999Z" fill="#25D2FC" />
            <path
                d="M7.19 9.14999H8.79C9.00146 9.14919 9.21249 9.16928 9.42 9.20999C9.60557 9.24472 9.78235 9.31612 9.94 9.41999C10.0897 9.52454 10.2102 9.66569 10.29 9.82999C10.384 10.0334 10.4286 10.2561 10.42 10.48C10.4275 10.7028 10.3866 10.9246 10.3 11.13C10.2273 11.2956 10.1137 11.4401 9.97 11.55C9.81753 11.6587 9.64368 11.7337 9.46 11.77C9.25264 11.8117 9.04151 11.8318 8.83 11.83H8.14V13.55H7.14L7.19 9.14999ZM8.19 11.01H8.82L9 11C9.07464 10.9822 9.14567 10.9518 9.21 10.91C9.27428 10.8721 9.32633 10.8166 9.36 10.75C9.40318 10.6704 9.42392 10.5805 9.42 10.49C9.42221 10.3938 9.39424 10.2994 9.34 10.22C9.28852 10.154 9.2238 10.0995 9.15 10.06C9.06938 10.03 8.98544 10.0099 8.9 9.99999H8.14L8.19 11.01Z"
                fill="#24D2FD"
            />
            <path
                d="M12.58 11.42C12.5184 11.3379 12.4383 11.2716 12.3462 11.2264C12.2541 11.1812 12.1526 11.1584 12.05 11.16C11.9696 11.1608 11.8906 11.1814 11.82 11.22C11.7851 11.2375 11.7561 11.2648 11.7366 11.2986C11.7171 11.3323 11.7078 11.3711 11.71 11.41C11.7099 11.4473 11.7215 11.4837 11.7429 11.5141C11.7644 11.5446 11.7949 11.5676 11.83 11.58L12.11 11.67L12.48 11.76C12.609 11.79 12.7334 11.8371 12.85 11.9C12.9648 11.9684 13.0636 12.0604 13.14 12.17C13.2175 12.3106 13.2555 12.4696 13.25 12.63C13.2585 12.7984 13.2167 12.9655 13.13 13.11C13.048 13.2361 12.9386 13.3421 12.81 13.42C12.6743 13.4997 12.5252 13.554 12.37 13.58C12.2119 13.6115 12.0512 13.6282 11.89 13.63C11.6768 13.6296 11.4648 13.5993 11.26 13.54C11.0574 13.4838 10.8741 13.3731 10.73 13.22L11.28 12.59C11.3553 12.6889 11.4508 12.7707 11.56 12.83C11.6735 12.8913 11.801 12.9223 11.93 12.92C12.0255 12.9198 12.1203 12.9029 12.21 12.87C12.2479 12.8609 12.2814 12.839 12.3051 12.808C12.3288 12.7771 12.3411 12.739 12.34 12.7C12.3403 12.6627 12.3301 12.6261 12.3107 12.5943C12.2912 12.5625 12.2633 12.5368 12.23 12.52C12.1373 12.4761 12.0401 12.4425 11.94 12.42L11.57 12.33C11.4414 12.3034 11.317 12.2597 11.2 12.2C11.0852 12.1316 10.9864 12.0396 10.91 11.93C10.8316 11.7898 10.7935 11.6306 10.8 11.47C10.7994 11.3101 10.8371 11.1523 10.91 11.01C10.9757 10.8848 11.0721 10.7781 11.19 10.7C11.3085 10.6092 11.4448 10.5445 11.59 10.51C11.7367 10.4704 11.888 10.4503 12.04 10.45C12.2401 10.4503 12.4389 10.4807 12.63 10.54C12.8249 10.5953 13.0012 10.7025 13.14 10.85L12.58 11.42Z"
                fill="#24D2FD"
            />
        </svg>
    );
};

export default IconFilePsd;
