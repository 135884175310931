import React from 'react';
import { Icon } from 'antd';
const IconHide = props => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M18.4023 9.49653C17.7157 8.05005 16.8987 6.87036 15.9512 5.95747L14.9574 6.95122C15.7678 7.72564 16.4736 8.73872 17.084 10.0004C15.459 13.3637 13.1523 14.9614 9.99999 14.9614C9.05377 14.9614 8.18222 14.8157 7.38534 14.5243L6.30858 15.601C7.41536 16.1121 8.64582 16.3676 9.99999 16.3676C13.7539 16.3676 16.5547 14.4125 18.4023 10.5024C18.4766 10.3451 18.5151 10.1734 18.5151 9.99946C18.5151 9.82555 18.4766 9.65379 18.4023 9.49653ZM17.1607 3.23403L16.3281 2.40044C16.3136 2.38591 16.2964 2.37439 16.2774 2.36652C16.2584 2.35866 16.2381 2.35461 16.2176 2.35461C16.197 2.35461 16.1767 2.35866 16.1577 2.36652C16.1388 2.37439 16.1215 2.38591 16.107 2.40044L13.9709 4.5356C12.7932 3.93403 11.4695 3.63325 9.99999 3.63325C6.24608 3.63325 3.4453 5.58833 1.59765 9.49849C1.52338 9.65575 1.48486 9.8275 1.48486 10.0014C1.48486 10.1753 1.52338 10.3471 1.59765 10.5043C2.3358 12.059 3.22447 13.3052 4.26366 14.2428L2.19687 16.309C2.16759 16.3383 2.15114 16.3781 2.15114 16.4195C2.15114 16.4609 2.16759 16.5006 2.19687 16.5299L3.03065 17.3637C3.05995 17.393 3.09968 17.4095 3.1411 17.4095C3.18253 17.4095 3.22225 17.393 3.25155 17.3637L17.1607 3.45513C17.1753 3.44062 17.1868 3.42338 17.1946 3.40442C17.2025 3.38545 17.2066 3.36511 17.2066 3.34458C17.2066 3.32405 17.2025 3.30371 17.1946 3.28475C17.1868 3.26578 17.1753 3.24855 17.1607 3.23403ZM2.91601 10.0004C4.54296 6.63716 6.8496 5.0395 9.99999 5.0395C11.0652 5.0395 12.0338 5.22232 12.9125 5.594L11.5394 6.96704C10.8892 6.6201 10.1446 6.49133 9.41563 6.59975C8.68662 6.70816 8.01176 7.04802 7.4906 7.56917C6.96944 8.09033 6.62959 8.76519 6.52117 9.4942C6.41276 10.2232 6.54152 10.9678 6.88847 11.618L5.25917 13.2473C4.35741 12.4514 3.58007 11.3731 2.91601 10.0004ZM7.73437 10.0004C7.73471 9.65604 7.81629 9.31657 7.97247 9.00961C8.12866 8.70266 8.35505 8.43688 8.63326 8.23386C8.91146 8.03084 9.23363 7.8963 9.5736 7.84119C9.91356 7.78607 10.2617 7.81193 10.5898 7.91665L7.83808 10.6684C7.7691 10.4525 7.73412 10.2271 7.73437 10.0004Z"
                fill="currentColor"
            />
            <path
                d="M9.92202 12.1878C9.85444 12.1878 9.78784 12.1846 9.72182 12.1786L8.69019 13.2102C9.31067 13.4478 9.98671 13.5007 10.6366 13.3623C11.2864 13.2239 11.8823 12.9002 12.3521 12.4304C12.8219 11.9605 13.1456 11.3647 13.284 10.7148C13.4224 10.065 13.3696 9.38892 13.132 8.76843L12.1003 9.80007C12.1064 9.86609 12.1095 9.93269 12.1095 10.0003C12.1097 10.2876 12.0532 10.5721 11.9433 10.8376C11.8334 11.103 11.6723 11.3442 11.4692 11.5474C11.266 11.7506 11.0248 11.9117 10.7593 12.0216C10.4938 12.1314 10.2093 12.1879 9.92202 12.1878Z"
                fill="currentColor"
            />
        </svg>
    );
};

export const CustomIconHide = props => <Icon component={IconHide} {...props} />;

export default IconHide;
