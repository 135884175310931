import React, { forwardRef } from 'react';
import { useQuery } from 'react-apollo';
import { Select } from '@components/Select';
import { Skeleton } from '@components/Skeleton';
import { ALL_SERVICES } from '@graphql/queries/service';

const ProductField = forwardRef(({ value, onChange, onFieldChange }, ref) => {
    const { loading, data } = useQuery(ALL_SERVICES, {
        variables: { activated: true },
        fetchPolicy: 'network-only',
    });

    const handleProductSelect = (key, val) => {
        onFieldChange(key, val);
        onChange(val);
    };

    if (loading) {
        return <Skeleton w="100%" h="38" />;
    }

    const products = data?.allServices;

    const options =
        products && products?.length > 0
            ? products?.map(product => (
                  <Select.Option
                      key={product?.id}
                      value={product?.id}
                      style={{ fontWeight: value === product?.id ? 400 : 300 }}
                  >
                      {product?.name}
                  </Select.Option>
              ))
            : null;

    return (
        <Select
            value={value}
            ref={ref}
            onChange={val => handleProductSelect('product', val)}
            dropdownMatchSelectWidth={false}
            dropdownAlign={{
                offset: [-55, 4],
            }}
        >
            <Select.Option value="ALL" style={{ fontWeight: value === 'ALL' ? 400 : 300 }}>
                All Product Types
            </Select.Option>
            {options}
        </Select>
    );
});

export default ProductField;
