import React from 'react';

const IconEmailBoblo = () => {
    return (
        <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.5 16.7253V2C0.5 1.17157 1.17157 0.5 2 0.5H20C20.8284 0.5 21.5 1.17157 21.5 2V16.7028C21.5 17.2521 21.1998 17.7574 20.7175 18.0201L10.7891 23.428L1.26067 18.0304L1.01422 18.4654L1.26067 18.0304C0.790564 17.7641 0.5 17.2656 0.5 16.7253Z"
                fill="#06D365"
                fillOpacity="0.19"
                stroke="#009846"
            />
            <path
                d="M7.5 13.0225C7.79621 13.8394 8.63808 15.0506 10.5401 15.2197V16.276H11.6938V15.2056C13.4399 14.9802 14.5 13.8253 14.5 12.3605C14.5 9.26192 9.55791 9.61403 9.55791 7.67037C9.55791 6.99431 10.1036 6.4591 11.0702 6.4591C12.1459 6.4591 12.6448 7.13516 12.8942 7.61403L14.3285 7.03656C14.0791 6.41685 13.3463 5.29009 11.6938 5.06473V4.276H10.5401V5.06473C8.85635 5.24783 7.82739 6.30417 7.82739 7.68445C7.82739 10.8535 12.7383 10.3887 12.7383 12.4732C12.7383 13.3042 12.0835 13.7971 11.1013 13.7971C9.90089 13.7971 9.26169 13.107 8.96548 12.4028L7.5 13.0225Z"
                fill="#009846"
            />
        </svg>
    );
};

export default IconEmailBoblo;
