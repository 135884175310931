import React from 'react';

const IconUploadRequest = () => {
    return (
        <svg width="270" height="174" viewBox="0 0 270 174" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clipIconUploadRequest0)">
                <path
                    d="M136.662 138.393L55.495 167.936L20.9684 73.0747L34.2441 50.7253L96.5053 28.0641L136.662 138.393Z"
                    fill="#FCBB46"
                />
                <path d="M20.9684 73.0747L38.8207 66.577L33.1907 51.1087L20.9684 73.0747Z" fill="#F98924" />
                <path
                    d="M53.1056 99.6147L60.5456 82.2775L83.2213 74.0242L98.7038 82.5788L86.4453 118.245L53.1056 99.6147Z"
                    fill="white"
                />
            </g>
            <g clipPath="url(#clipIconUploadRequest1)">
                <path
                    d="M214.505 167.936L133.338 138.393L167.865 43.5324L192.4 34.9452L254.662 57.6064L214.505 167.936Z"
                    fill="#E6E6E4"
                />
                <path
                    d="M227.904 131.122L146.792 101.6L133.393 138.414L214.505 167.936L227.904 131.122Z"
                    fill="#EB222D"
                />
                <path
                    d="M158.342 114.72L166.326 117.625C167.39 117.917 168.428 118.295 169.431 118.755C170.286 119.283 171.039 119.961 171.653 120.757C172.189 121.539 172.53 122.438 172.645 123.379C172.765 124.535 172.599 125.704 172.16 126.781C171.796 127.857 171.202 128.84 170.42 129.664C169.771 130.366 168.935 130.867 168.01 131.11C167.069 131.34 166.092 131.389 165.132 131.255C164.011 131.106 162.911 130.832 161.851 130.438L158.413 129.186L155.387 137.503L150.674 135.788L158.342 114.72ZM159.765 125.472L162.926 126.622L164.145 127.066C164.551 127.112 164.961 127.091 165.36 127.006C165.751 126.938 166.116 126.767 166.419 126.512C166.659 126.145 166.811 125.728 166.863 125.292C167.04 124.822 167.073 124.31 166.959 123.821C166.83 123.382 166.583 122.988 166.244 122.681C165.902 122.357 165.513 122.089 165.09 121.884L161.541 120.593L159.765 125.472Z"
                    fill="white"
                />
                <path
                    d="M177.359 121.641L184.455 124.224C186.086 124.799 187.638 125.575 189.077 126.534C190.413 127.414 191.557 128.556 192.438 129.892C193.302 131.178 193.82 132.664 193.944 134.207C194.087 136.011 193.814 137.824 193.146 139.505C192.623 141.112 191.725 142.571 190.528 143.764C189.426 144.824 188.104 145.629 186.656 146.121C185.191 146.61 183.646 146.815 182.105 146.725C180.46 146.687 178.835 146.371 177.296 145.791L169.59 142.986L177.359 121.641ZM175.876 140.377L178.26 141.245C179.283 141.626 180.347 141.887 181.43 142.022C182.384 142.131 183.349 142.056 184.273 141.801C185.158 141.495 185.963 140.994 186.628 140.335C187.384 139.519 187.952 138.547 188.292 137.487C188.665 136.562 188.814 135.562 188.727 134.569C188.624 133.669 188.315 132.805 187.825 132.043C187.321 131.269 186.666 130.603 185.9 130.087C185.082 129.481 184.176 129.003 183.214 128.67L180.442 127.661L175.876 140.377Z"
                    fill="white"
                />
                <path
                    d="M200.422 130.036L214.782 135.262L213.208 139.586L203.561 136.075L201.987 140.4L210.913 143.649L209.319 148.028L200.393 144.78L197.366 153.096L192.653 151.381L200.422 130.036Z"
                    fill="white"
                />
                <path d="M167.865 43.5324L185.717 50.0301L191.347 34.5618L167.865 43.5324Z" fill="#AFAFAC" />
                <path
                    d="M227.576 82.3433L178.676 64.5453L176.597 70.2558L225.497 88.0538L227.576 82.3433Z"
                    fill="#AFAFAC"
                />
                <path
                    d="M220.937 100.584L172.037 82.7856L169.959 88.4962L218.858 106.294L220.937 100.584Z"
                    fill="#AFAFAC"
                />
            </g>
            <path d="M184.776 135.66H85.2241V19.312L108.412 0.339966H184.776V135.66Z" fill="#022732" />
            <path d="M85.2241 19.312H107.12V0.339966L85.2241 19.312Z" fill="#25D2FC" />
            <path
                d="M115.892 62.22H126.772C128.21 62.2146 129.645 62.3512 131.056 62.628C132.318 62.8642 133.52 63.3497 134.592 64.056C135.61 64.767 136.429 65.7268 136.972 66.844C137.611 68.227 137.914 69.7416 137.856 71.264C137.907 72.779 137.628 74.2871 137.04 75.684C136.545 76.8104 135.773 77.793 134.796 78.54C133.759 79.279 132.577 79.7889 131.328 80.036C129.918 80.3197 128.482 80.4564 127.044 80.444H122.352V92.14H115.552L115.892 62.22ZM122.692 74.868H126.976L128.2 74.8C128.707 74.6793 129.19 74.4723 129.628 74.188C130.065 73.9304 130.419 73.5528 130.648 73.1C130.941 72.5585 131.082 71.9474 131.056 71.332C131.071 70.6782 130.881 70.0361 130.512 69.496C130.162 69.0472 129.722 68.6766 129.22 68.408C128.672 68.2042 128.101 68.0672 127.52 68H122.352L122.692 74.868Z"
                fill="#24D2FD"
            />
            <path
                d="M152.544 77.6561C152.125 77.0978 151.581 76.6466 150.954 76.3393C150.328 76.032 149.638 75.8774 148.94 75.8881C148.393 75.8936 147.856 76.0338 147.376 76.2961C147.139 76.415 146.942 76.6007 146.809 76.8303C146.676 77.0598 146.613 77.3232 146.628 77.5881C146.627 77.8415 146.706 78.0889 146.852 78.2959C146.998 78.503 147.205 78.6596 147.444 78.7441L149.348 79.3561L151.864 79.9681C152.741 80.1722 153.587 80.4923 154.38 80.9201C155.16 81.385 155.832 82.0109 156.352 82.7561C156.879 83.7122 157.137 84.793 157.1 85.8841C157.158 87.0289 156.874 88.165 156.284 89.1481C155.726 90.0054 154.982 90.726 154.108 91.2561C153.185 91.7982 152.171 92.1669 151.116 92.3441C150.041 92.558 148.948 92.6718 147.852 92.6841C146.402 92.6813 144.96 92.4753 143.568 92.0721C142.19 91.6898 140.944 90.9372 139.964 89.8961L143.704 85.6121C144.216 86.2844 144.865 86.8406 145.608 87.2441C146.38 87.6611 147.247 87.8719 148.124 87.8561C148.773 87.8545 149.418 87.7394 150.028 87.5161C150.285 87.4538 150.514 87.3051 150.675 87.0946C150.836 86.8842 150.919 86.6249 150.912 86.3601C150.914 86.1066 150.845 85.8577 150.712 85.6414C150.58 85.4252 150.39 85.2501 150.164 85.1361C149.534 84.8373 148.872 84.6093 148.192 84.4561L145.676 83.8441C144.801 83.6631 143.955 83.3658 143.16 82.9601C142.38 82.4951 141.707 81.8692 141.188 81.1241C140.654 80.1705 140.396 79.0878 140.44 77.9961C140.435 76.9086 140.692 75.8359 141.188 74.8681C141.635 74.0163 142.29 73.2912 143.092 72.7601C143.897 72.1427 144.824 71.7024 145.812 71.4681C146.81 71.199 147.838 71.0618 148.872 71.0601C150.232 71.0622 151.585 71.2685 152.884 71.6721C154.209 72.0481 155.408 72.7767 156.352 73.7801L152.544 77.6561Z"
                fill="#24D2FD"
            />
            <defs>
                <clipPath id="clipIconUploadRequest0">
                    <rect width="118" height="118" fill="white" transform="translate(0.378906 62.7373) rotate(-20)" />
                </clipPath>
                <clipPath id="clipIconUploadRequest1">
                    <rect width="118" height="118" fill="white" transform="translate(158.737 22.3789) rotate(20)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconUploadRequest;
