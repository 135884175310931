import React from 'react';

const IconShieldDelivered = () => {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.76187 10L0 5.19922L1.34763 3.84059L4.76191 7.28274L11.9857 0L13.3333 1.35863L4.76187 10Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconShieldDelivered;
