import React from 'react'

const Icon = ({ isFilled }) => {

  if (!isFilled) {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1H9V9H1V1ZM11 1H19V9H11V1ZM9 11H1V19H9V11ZM11 11H19V19H11V11Z" fill="#E4EBF1"/>
      </svg>
    )
  }
  
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1H9V9H1V1ZM11 1H19V9H11V1ZM9 11H1V19H9V11ZM11 11H19V19H11V11Z" fill="#009DFF"/>
    </svg>
  )
}

export default Icon