import React from 'react';

const IconAttach = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.25 0C6.80393 0 4 2.80397 4 6.24999V15.625C4 18.0371 5.9629 20 8.37501 20C10.7871 20 12.75 18.0371 12.75 15.625V9.37498C12.75 7.30712 11.0679 5.62498 9.00002 5.62498V8.12497C9.68912 8.12497 10.25 8.68588 10.25 9.37498V15.625C10.25 16.6589 9.40897 17.5 8.37506 17.5C7.34115 17.5 6.50007 16.6589 6.50007 15.625V6.24999C6.50007 4.18213 8.18222 2.49999 10.2501 2.49999C12.3179 2.49999 14.0001 4.18213 14.0001 6.24999V17.5H16.5001V6.24999C16.5 2.80397 13.6961 0 10.25 0Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconAttach;
