import React from "react"

const IconPin = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_16067_26173)">
      <path d="M5.48831 13.1122C5.34166 13.1217 5.19465 13.1003 5.05683 13.0493C4.91901 12.9983 4.79345 12.9189 4.68831 12.8162C4.51231 12.6402 4.49631 12.6242 4.00031 8.96019C3.98109 8.81343 3.92764 8.67324 3.84425 8.55095C3.76087 8.42866 3.6499 8.32769 3.52031 8.25619C2.52516 7.75511 1.55836 7.1996 0.624307 6.59219C0.248307 6.22419 0.296307 5.60019 0.800307 4.72819C1.24213 3.97146 1.77992 3.27503 2.40031 2.65619C2.89631 2.17619 5.33631 -0.159813 6.40031 0.880187C6.40031 0.944187 6.40031 0.944187 8.00031 3.85619C8.07445 3.99093 8.18012 4.1057 8.3083 4.19069C8.43648 4.27568 8.58333 4.32834 8.73631 4.34419C12.3683 4.75219 12.3763 4.76819 12.5603 4.95219C13.0323 5.42419 12.9523 6.23219 12.3123 7.35219C11.6762 8.41787 10.9014 9.3944 10.0083 10.2562C8.84031 11.4562 6.80031 13.1122 5.48831 13.1122ZM1.48831 5.84019L4.08831 7.27219C4.3688 7.42905 4.6093 7.64854 4.79108 7.91357C4.97285 8.17861 5.091 8.48202 5.13631 8.80019C5.37631 10.6082 5.52831 11.6002 5.59231 12.0002C6.00831 11.9362 7.41631 11.2722 9.23231 9.44819C11.0483 7.62419 11.6803 6.32819 11.7523 5.84019C11.3203 5.77619 10.3443 5.65619 8.62431 5.46419C8.29058 5.42557 7.97103 5.3073 7.69257 5.11935C7.41412 4.93139 7.18493 4.67925 7.02431 4.38419L5.60031 1.75219C4.68139 2.12701 3.86044 2.70715 3.20031 3.44819C2.46459 4.10989 1.88527 4.92695 1.50431 5.84019H1.48831Z" fill="currentColor"/>
      <path d="M14.7523 15.5204C14.6783 15.5219 14.6047 15.5085 14.536 15.4811C14.4673 15.4536 14.4048 15.4126 14.3523 15.3604L9.35226 10.3524C9.29804 10.3009 9.25487 10.2388 9.22537 10.1701C9.19588 10.1013 9.18066 10.0273 9.18066 9.95243C9.18066 9.87761 9.19588 9.80356 9.22537 9.7348C9.25487 9.66604 9.29804 9.60399 9.35226 9.55243C9.45865 9.44691 9.60242 9.3877 9.75226 9.3877C9.90211 9.3877 10.0459 9.44691 10.1523 9.55243L15.1523 14.5604C15.2065 14.612 15.2497 14.674 15.2792 14.7428C15.3087 14.8116 15.3239 14.8856 15.3239 14.9604C15.3239 15.0353 15.3087 15.1093 15.2792 15.1781C15.2497 15.2468 15.2065 15.3089 15.1523 15.3604C15.1006 15.4137 15.0382 15.4555 14.9693 15.4831C14.9004 15.5107 14.8264 15.5234 14.7523 15.5204Z" fill="currentColor"/>
      </g>
      <defs>
      <clipPath id="clip0_16067_26173">
      <rect width="16" height="16" fill="currentColor"/>
      </clipPath>
      </defs>
    </svg>
    
  )
}

export default IconPin