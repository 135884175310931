import IconDeliverableDesigner from '@components/Svg/IconDeliverableDesigner';
import IconDeliverableJpg from '@components/Svg/IconDeliverableJpg';
import IconDeliverablePng from '@components/Svg/IconDeliverablePng';
import IconDeliverableFigma from '@components/Svg/IconDeliverableFigma';
import IconDeliverableSketch from '@components/Svg/IconDeliverableSketch';
import IconDeliverablePsd from '@components/Svg/IconDeliverablePsd';
import IconDeliverableIndd from '@components/Svg/IconDeliverableIndd';
import IconDeliverableAep from '@components/Svg/IconDeliverableAep';
import IconDeliverableAi from '@components/Svg/IconDeliverableAi';
import IconDeliverableLottie from '@components/Svg/IconDeliverableLottie';
import IconDeliverableCanva from '@components/Svg/IconDeliverableCanva';
import IconDeliverableGif from '@components/Svg/IconDeliverableGif';
import IconDeliverableGoogleSlide from '@components/Svg/IconDeliverableGoogleSlide';
import IconDeliverableJson from '@components/Svg/IconDeliverableJson';
import IconDeliverableMp4 from '@components/Svg/IconDeliverableMp4';
import IconDeliverablePpt from '@components/Svg/IconDeliverablePpt';
import IconDeliverableSvg from '@components/Svg/IconDeliverableSvg';
import IconDeliverableXd from '@components/Svg/IconDeliverableXd';
import IconDeliverablePdf from '@components/Svg/IconDeliverablePdf';
import IconDeliverableOther from '@components/Svg/IconDeliverableOther';

export const DELIVERABLES = [
    { id: 1, value: 'LET_MY_DESIGNER_CHOOSE',title: 'Let my designer choose', icon: IconDeliverableDesigner },
    { id: 2, value: 'JPG', title: 'JPG', icon: IconDeliverableJpg },
    { id: 3, value: 'PNG', title: 'PNG', icon: IconDeliverablePng },
    { id: 4, value: 'FIGMA', title: 'FIGMA', icon: IconDeliverableFigma },
    { id: 5, value: 'SKETCH', title: 'SKETCH', icon: IconDeliverableSketch },
    { id: 6, value: 'PSD', title: 'PSD', icon: IconDeliverablePsd },
    { id: 7, value: 'INDD', title: 'INDD', icon: IconDeliverableIndd },
    { id: 8, value: 'AEP', title: 'AEP', icon: IconDeliverableAep },
    { id: 9, value: 'AI', title: 'AI', icon: IconDeliverableAi },
    { id: 11, value: 'LOTTIE', title: 'LOTTIE', icon: IconDeliverableLottie },
    { id: 12, value: 'CANVA', title: 'CANVA', icon: IconDeliverableCanva },
    { id: 13, value: 'GIF', title: 'GIF', icon: IconDeliverableGif },
    { id: 14, value: 'GOOGLE_SLIDES', title: 'GOOGLE SLIDES', icon: IconDeliverableGoogleSlide },
    { id: 15, value: 'MP4', title: 'MP4', icon: IconDeliverableMp4 },
    { id: 16, value: 'PPT', title: 'PPT', icon: IconDeliverablePpt },
    { id: 17, value: 'SVG', title: 'SVG', icon: IconDeliverableSvg },
    { id: 18, value: 'XD', title: 'XD', icon: IconDeliverableXd },
    { id: 19, value: 'JSON', title: 'JSON', icon: IconDeliverableJson },
    { id: 20, value: 'PDF', title: 'PDF', icon: IconDeliverablePdf },
    { id: 10, value: 'OTHERS', title: 'OTHERS', icon: IconDeliverableOther },
];