import React, { useCallback, useMemo, useState, forwardRef } from 'react';
import { useMutation } from 'react-apollo';
import { Radio, RadioGroup } from '@components/Radio';
import { Text } from '@components/Text';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Input } from '@components/Input';
import { Select } from '@components/Select';
import { Form } from '@components/Form';
import { UPDATE_USER_COMPANY_ROLE } from '@graphql/mutations/user';
import { ADD_INVITATION } from '@graphql/mutations/invitation';
import { COMPANY_ROLE_ADMIN, COMPANY_ROLE_MEMBER } from '@constants/account';
import message from '@components/Message';

const FormTeam = forwardRef(
    (
        {
            firstname,
            lastname,
            userId,
            initialValues = {},
            form,
            refetchMembers,
            onClose,
            users,
            isOnlyAdmin,
            companyId,
        },
        ref
    ) => {
        const isEdit = !!userId;
        const [updateUserCompanyRole] = useMutation(UPDATE_USER_COMPANY_ROLE);
        const [createInvitation] = useMutation(ADD_INVITATION);
        const { validateFields, getFieldValue } = form;
        const [isLoading, setIsLoading] = useState(false);

        const handleSubmit = useCallback(
            e => {
                e.preventDefault();
                validateFields(async (err, values) => {
                    if (!err) {
                        setIsLoading(true);
                        message.destroy();
                        message.loading(isEdit ? 'Updating info...' : 'Sending invitation...', 50000);
                        try {
                            if (isEdit) {
                                await updateUserCompanyRole({
                                    variables: { id: userId, companyRole: values.companyRole },
                                })
                                    .then(async () => {
                                        if (isOnlyAdmin) {
                                            await updateUserCompanyRole({
                                                variables: { id: values.newAdmin, companyRole: COMPANY_ROLE_ADMIN },
                                            })
                                                .then(async () => {
                                                    await refetchMembers();
                                                    message.destroy();
                                                    message.success(
                                                        'You have changed your role and assigned a new admin'
                                                    );
                                                    setIsLoading(false);
                                                    onClose();
                                                    return true;
                                                })
                                                .catch(err => {
                                                    setIsLoading(false);
                                                    console.log(err);
                                                    message.destroy();
                                                    message.error("Error on updating the member's role");
                                                    return false;
                                                });
                                        } else {
                                            await refetchMembers();
                                            message.destroy();
                                            message.success(
                                                <>
                                                    <Text d="inline-block" fontWeight="400">
                                                        {firstname} {lastname}
                                                    </Text>{' '}
                                                    role has been updated
                                                </>
                                            );
                                            setIsLoading(false);
                                            onClose();
                                            return true;
                                        }
                                    })
                                    .catch(err => {
                                        setIsLoading(false);
                                        console.log(err);
                                        message.destroy();
                                        message.error('Error on updating your role');
                                        return false;
                                    });
                            } else {
                                const invitationData = { ...values, companyId };
                                await createInvitation({ variables: invitationData })
                                    .then(() => {
                                        message.destroy();
                                        message.success(
                                            <>
                                                Invitation sent to{' '}
                                                <Text d="inline-block" fontWeight="400">
                                                    {values.email}
                                                </Text>
                                            </>
                                        );
                                        setIsLoading(false);
                                        onClose();
                                        return true;
                                    })
                                    .catch(err => {
                                        setIsLoading(false);
                                        message.destroy();
                                        const errors = err.graphQLErrors || [];
                                        const formErrorMessage =
                                            errors.length > 0 ? errors[0].message : 'Error on sending invitation';
                                        message.error(formErrorMessage);

                                        return false;
                                    });
                            }
                        } catch (e) {
                            setIsLoading(false);
                            console.error(e);
                            message.destroy();
                            message.error('Something went wrong');
                            onClose();
                            return false;
                        }
                    }
                });
            },
            [
                updateUserCompanyRole,
                createInvitation,
                isEdit,
                refetchMembers,
                firstname,
                lastname,
                onClose,
                userId,
                validateFields,
                isOnlyAdmin,
                companyId,
            ]
        );

        let adminOptions = [];
        if (isEdit) {
            adminOptions = useMemo(() => users.filter(user => user.id !== userId), [users, userId]);
        }

        return (
            <div>
                <Text textVariant="H5" mb="20" colorScheme="primary">
                    {isEdit ? 'Change role' : 'Invite team member'}
                </Text>
                <Form ref={ref} onSubmit={handleSubmit}>
                    <Box mb={isEdit ? '-10' : '0'}>
                        <Form.Item label={!isEdit ? 'Email' : ''} colon={false} required={false}>
                            {form.getFieldDecorator('email', {
                                initialValue: initialValues.email,
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email address',
                                    },
                                    {
                                        required: !isEdit,
                                        message: 'Please enter a valid email address',
                                    },
                                ],
                            })(
                                <Input
                                    readOnly={isEdit}
                                    placeholder="user@example.com"
                                    style={
                                        isEdit
                                            ? { borderWidth: 0, pointerEvents: 'none', padding: '0', height: 'auto' }
                                            : {}
                                    }
                                />
                            )}
                        </Form.Item>
                    </Box>
                    <Form.Item label="Role" colon={false} required={false} style={{ marginBottom: 12 }}>
                        {form.getFieldDecorator('companyRole', {
                            initialValue: initialValues.companyRole || 'MEMBER',
                        })(
                            <RadioGroup>
                                <Radio value={COMPANY_ROLE_MEMBER} style={{ marginBottom: 20 }}>
                                    User
                                </Radio>
                                {isOnlyAdmin && getFieldValue('companyRole') === 'MEMBER' && (
                                    <Form.Item
                                        label="Choose New Admin"
                                        colon={false}
                                        required={true}
                                        style={{ marginBottom: 12 }}
                                    >
                                        {form.getFieldDecorator('newAdmin', {
                                            initialValue: undefined,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please select a new admin',
                                                },
                                            ],
                                        })(
                                            <Select placeholder="Choose new admin">
                                                {adminOptions?.map(({ id, firstname, lastname }) => (
                                                    <Select.Option value={id} key={id}>
                                                        {`${firstname} ${lastname}`}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                )}
                                <Radio value={COMPANY_ROLE_ADMIN}>Admin</Radio>
                            </RadioGroup>
                        )}
                    </Form.Item>
                    <Text textVariant="P4" colorScheme="secondary" mb="30">
                        Only admins have access to team management and billing information.
                    </Text>
                    <Form.Item style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            {isEdit ? 'Change Role' : 'Invite'}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
);

export default Form.create()(FormTeam);
