import React, { useState, useCallback, useMemo } from 'react';
import withLoggedUser from '@components/WithLoggedUser/WithLoggedUser';
import { USER_TYPE_CUSTOMER, USER_TYPE_WORKER } from '@constants/account';
import { SNIPPETS } from '@graphql/queries/snippet';
import { DelayedSearchInput } from '@pages/Customer/Requests/blocks/DelayedSearchInput';
import { Popover } from 'antd';
import { useQuery } from 'react-apollo';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { convertFromHTML } from 'draft-js';
import ArrowDownIcon from '@components/Svg/ArrowDown';
import CloseIcon from '@components/Svg/Close';

const getSnippetText = text => {
  if (convertFromHTML(text).contentBlocks[0]?.text) {
    return convertFromHTML(text).contentBlocks[0]?.text
  }
  return null
}

const Snippets = ({ viewer, handleAddSnippet }) => {
  const [filter, setFilter] = useState('');

  const [show, setShow] = useState(false);

  const variables = useMemo(() => {
    const vars = {
      where: {
        name_contains: filter,
      },
    };
    return vars;
  }, [filter]);

  const { data, loading } = useQuery(SNIPPETS, {
    variables,
  });

  const handleSearch = value => {
    setFilter(value);
  };

  const dataRenderer = useCallback(() => {
    if (data?.allSnippets?.length === 0) {
      return (
        <Box fontSize="14" textAlign="center" pt="10" pb="15">
          <Text>Snippet not found</Text>
        </Box>
      );
    }
    const snippets = data.allSnippets;
    return (
      <Box my="10" w="100%" maxH="250" overflowY="scroll">
        {snippets.map((snippet) => (
          <Box
            _hover={{ bg: 'badge-blue' }}
            px="16"
            py="12"
            key={snippet.id}
            cursor="pointer"
            onClick={() => handleAddSnippet(snippet)}
          >
            {snippet?.text && (
              <>
                <Text
                  fontFamily="Geomanist"
                  fontSize="14"
                >
                  {snippet.name}
                </Text>
                <Text
                  textVariant="P4"
                  colorScheme="tertiary"
                  pr="4"
                  whiteSpace="nowrap"
                  fontSize={['12', '14']}
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  {getSnippetText(snippet.text)}
                </Text>
              </>
            )}
          </Box>
        ))}
      </Box>
    );
  }, [data, handleAddSnippet]);

  const content = (
    <Box w="300">
      <Box p="16">
        <DelayedSearchInput onChange={handleSearch}/>
      </Box>
      {loading ? <Box fontSize="14" textAlign="center" pt="10" pb="15">
          <Text> Searching... </Text>
        </Box> : dataRenderer()}
    </Box>
  );

  const title = (
    <Box
      d="flex"
      flexDir="row"
      justifyContent="space-between"
      alignItems="center"
    >

      <Text>
        Snippets
      </Text>
      <Box
        colorScheme="tertiary"
        _hover={{ colorScheme: 'cta' }}
        onClick={() => setShow(false)}
        cursor="pointer"
      >
        <CloseIcon />
      </Box>
    </Box>
  )

  if (viewer.role === USER_TYPE_CUSTOMER || viewer.role === USER_TYPE_WORKER) {
    return null;
  }

  return (
    <Popover visible={show} title={title} content={content} trigger="click" onVisibleChange={setShow}>
      <Box flexDir="row" d="flex">
        <Text mr="4">Snippets</Text>
        <ArrowDownIcon />
      </Box>
    </Popover>
  );
};

export default withLoggedUser(Snippets);
