export const TEXT_LOGIN_1 = 'Get your everyday design done';
export const TEXT_LOGIN_2 = 'Your on-demand graphic design service. Simple, fast and affordable.';
export const TEXT_LOGIN_3 = 'Trusted by companies all around the world';
export const TEXT_LOGIN_4 = "Don't have an account?";
export const TEXT_LOGIN_5 = 'Sign Up';

export const TEXT_ONBOARD_1 = 'Sign In';
export const TEXT_ONBOARD_2 = 'Already a member?';
export const TEXT_ONBOARD_3 = 'Your plan';
export const TEXT_ONBOARD_4 = 'Create account';
export const TEXT_ONBOARD_5 = 'Checkout';
export const TEXT_ONBOARD_2_FORM_1 = 'First name';
export const TEXT_ONBOARD_2_FORM_1_PLACEHOLDER = 'Enter your first name';
export const TEXT_ONBOARD_2_FORM_1_ERROR = 'This field cannot be empty';
export const TEXT_ONBOARD_2_FORM_2 = 'Last name';
export const TEXT_ONBOARD_2_FORM_2_PLACEHOLDER = 'Enter your last name';
export const TEXT_ONBOARD_2_FORM_2_ERROR = 'This field cannot be empty';
export const TEXT_ONBOARD_2_FORM_3 = 'Email';
export const TEXT_ONBOARD_2_FORM_3_PLACEHOLDER = 'Enter your email address';
export const TEXT_ONBOARD_2_FORM_3_ERROR = 'Please enter a valid email address';
export const TEXT_ONBOARD_2_FORM_4 = 'Create password';
export const TEXT_ONBOARD_2_FORM_4_PLACEHOLDER = 'Create your password';
export const TEXT_ONBOARD_2_FORM_4_ERROR = 'This field cannot be empty';
export const TEXT_ONBOARD_2_FORM_5 = 'Confirm password';
export const TEXT_ONBOARD_2_FORM_5_PLACEHOLDER = 'Confirm your password';
export const TEXT_ONBOARD_2_FORM_5_ERROR = 'Passwords do not match';
export const TEXT_ONBOARD_4_TITLE = 'Company information';
export const TEXT_ONBOARD_4_FORM_1 = 'Company name';
export const TEXT_ONBOARD_4_FORM_1_PLACEHOLDER = 'Enter your company name';
export const TEXT_ONBOARD_4_FORM_2 = 'Industry';
export const TEXT_ONBOARD_4_FORM_2_PLACEHOLDER = 'Choose your industry type';
export const TEXT_ONBOARD_4_FORM_3 = 'Job title';
export const TEXT_ONBOARD_4_FORM_3_PLACEHOLDER = 'Enter your job title';
export const TEXT_ONBOARD_4_FORM_4 = 'Company website';
export const TEXT_ONBOARD_4_FORM_4_PLACEHOLDER = 'www.example.com';
export const TEXT_ONBOARD_4_FORM_5 = 'Number of employees';
export const TEXT_ONBOARD_4_FORM_5_PLACEHOLDER = 'Number of employees';
export const TEXT_ONBOARD_4_FORM_6 = 'Time zone';
export const TEXT_ONBOARD_4_FORM_6_PLACEHOLDER = 'Choose your time zone';
export const TEXT_ONBOARD_4_FORM_7 = 'How did you hear about us ?';
export const TEXT_ONBOARD_4_FORM_8 = 'Tell us more';
export const TEXT_ONBOARD_4_FORM_8_PLACEHOLDER = 'How did you hear about us ?';
export const TEXT_ONBOARD_4_BUTTON = 'Finish';

export const TEXT_ONBOARD_MENU_1 = 'CHOOSE PLAN';
export const TEXT_ONBOARD_MENU_2 = 'CREATE ACCOUNT';
export const TEXT_ONBOARD_MENU_3 = 'CHECKOUT';
export const TEXT_ONBOARD_MENU_4 = 'COMPANY INFORMATION';
export const TEXT_ONBOARD_MENU_5 = 'SUCCESS';
