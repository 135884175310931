import React from 'react';

const IconDownload = props => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M19.1502 18.51H0.850195C0.677805 18.51 0.512475 18.4415 0.390576 18.3196C0.268677 18.1977 0.200195 18.0324 0.200195 17.86V14.14C0.200195 13.9676 0.268677 13.8023 0.390576 13.6804C0.512475 13.5585 0.677805 13.49 0.850195 13.49C1.02259 13.49 1.18792 13.5585 1.30981 13.6804C1.43171 13.8023 1.5002 13.9676 1.5002 14.14V17.21H18.5002V14.31C18.5002 14.1376 18.5687 13.9723 18.6906 13.8504C18.8125 13.7285 18.9778 13.66 19.1502 13.66C19.3226 13.66 19.4879 13.7285 19.6098 13.8504C19.7317 13.9723 19.8002 14.1376 19.8002 14.31V17.86C19.8002 17.9454 19.7834 18.0299 19.7507 18.1087C19.7181 18.1876 19.6702 18.2593 19.6098 18.3196C19.5495 18.38 19.4778 18.4278 19.3989 18.4605C19.3201 18.4932 19.2356 18.51 19.1502 18.51Z" />
      <path d="M9.94004 13.54C9.76765 13.54 9.60232 13.4715 9.48042 13.3496C9.35852 13.2277 9.29004 13.0624 9.29004 12.89V2.12998C9.29004 1.95759 9.35852 1.79226 9.48042 1.67036C9.60232 1.54846 9.76765 1.47998 9.94004 1.47998C10.1124 1.47998 10.2778 1.54846 10.3997 1.67036C10.5216 1.79226 10.59 1.95759 10.59 2.12998V12.89C10.59 13.0624 10.5216 13.2277 10.3997 13.3496C10.2778 13.4715 10.1124 13.54 9.94004 13.54Z" />
      <path d="M9.8704 14.29C9.78244 14.2913 9.69517 14.2742 9.61422 14.2398C9.53328 14.2053 9.46044 14.1543 9.4004 14.09L6.0004 10.44C5.88238 10.3074 5.82187 10.1333 5.83219 9.95612C5.8425 9.7789 5.92279 9.61304 6.0554 9.49501C6.18801 9.37699 6.36207 9.31648 6.53929 9.3268C6.71652 9.33711 6.88238 9.41741 7.0004 9.55001L9.9204 12.68L13.0004 9.18001C13.1171 9.04741 13.2817 8.96659 13.458 8.95533C13.6343 8.94408 13.8078 9.00332 13.9404 9.12001C14.073 9.23671 14.1538 9.4013 14.1651 9.57759C14.1763 9.75387 14.1171 9.92741 14.0004 10.06L10.3604 14.06C10.2992 14.1303 10.224 14.1871 10.1397 14.2267C10.0553 14.2663 9.96358 14.2879 9.8704 14.29Z" />
    </svg>
  );
};

export default IconDownload;
