import React, { useCallback, useState, memo } from 'react';
import { Form } from '@components/Form';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Input } from '@components/Input';
import ConfirmCouponModal from './ConfirmCouponModal';
import coupon from '@assets/icons/coupon.svg';

const OnboardingCouponForm = memo(({ referrerId, form, handleCoupon, initialCoupon }) => {
    const { getFieldDecorator, validateFields, resetFields } = form;
    const [isSubmitting, setSubmitting] = useState(false);
    const [showConfirmCouponModal, setShowConfirmCouponModal] = useState(false);
    const [couponCode, setCouponCode] = useState(() => initialCoupon ?? null);

    const handleConfirmCouponModalVisible = () => {
        setShowConfirmCouponModal(!showConfirmCouponModal);
    };

    const handleApplyCoupon = () => {
        setShowConfirmCouponModal(false);
        handleCoupon(couponCode);
        resetFields();
    };

    const handleSubmit = useCallback(
        e => {
            e.preventDefault();
            validateFields(async (err, values) => {
                if (!err && !isSubmitting) {
                    setSubmitting(true);
                    if (!referrerId) {
                        handleCoupon(values.couponCode);
                        resetFields();
                    } else {
                        setShowConfirmCouponModal(true);
                        setCouponCode(values.couponCode);
                    }
                }
                setSubmitting(false);
            });
        },
        [isSubmitting, validateFields, handleCoupon, referrerId, resetFields]
    );

    return (
        <>
            <ConfirmCouponModal
                visible={showConfirmCouponModal}
                onCancel={handleConfirmCouponModalVisible}
                onApplyCoupon={handleApplyCoupon}
            />
            <Box mb="-30">
                <Form onSubmit={handleSubmit}>
                    <Box d="flex">
                        <Box flex="1 1 0%">
                            <Form.Item>
                                {getFieldDecorator('couponCode', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'This field cannot be empty',
                                        },
                                    ],
                                    initialValue: initialCoupon,
                                })(<Input prefix={<img src={coupon} alt="Coupon" />} placeholder="Enter coupon" />)}
                            </Form.Item>
                        </Box>
                        <Box w="106">
                            <Form.Item>
                                <Button
                                    h={['40', '40']}
                                    type="primary"
                                    loading={isSubmitting}
                                    htmlType="submit"
                                    w="100%"
                                >
                                    Apply
                                </Button>
                            </Form.Item>
                        </Box>
                    </Box>
                </Form>
            </Box>
        </>
    );
});

export default Form.create()(OnboardingCouponForm);
