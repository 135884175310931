import React, { useState, memo, useCallback } from 'react';
import { Form } from '@components/Form';
import { Input } from '@components/Input';
import { Button } from '@components/Button';
import { Popup } from '@components/Popup';
import { Box } from '@components/Box';
import message from '@components/Message';
import { Wysiwyg, toWysiwyg } from '@components/Wysiwyg';

const EditSnippet = memo(({ mentions, visible, onCancel, onEdit, refetch, selectedData, form }) => {
    const { getFieldDecorator, validateFields } = form;
    const [isLoading, setIsLoading] = useState(null);

    const handleSubmit = useCallback(
        async e => {
            e.preventDefault();
            validateFields(async (err, values) => {
                if (!err && !isLoading) {
                    setIsLoading(true);
                    message.loading('Updating snippet...', 50000);

                    try {
                        await onEdit({ ...values });
                        message.destroy();
                        message.success('Snippet has been updated');
                        await refetch();
                        setIsLoading(false);
                        onCancel();
                        return true;
                    } catch (e) {
                        message.destroy();
                        setIsLoading(false);
                        console.error(e);
                        return false;
                    }
                }
            });
        },
        [isLoading, validateFields, onEdit, onCancel, refetch]
    );

    return (
        <Popup
            variant="default"
            width={900}
            title="Edit Snippet"
            visible={visible}
            onCancel={onCancel}
            footer={null}
            centered
            destroyOnClose
        >
            <Form onSubmit={handleSubmit}>
                <Form.Item label="Name" colon={false} required={true}>
                    {getFieldDecorator('name', {
                        initialValue: selectedData?.name,
                        rules: [
                            {
                                required: true,
                                message: 'This field cannot be empty',
                            },
                        ],
                    })(<Input autoFocus />)}
                </Form.Item>
                <Form.Item label="Snippet text" colon={false} required={true} style={{ marginBottom: 16 }}>
                    {form.getFieldDecorator('text', {
                      initialValue: toWysiwyg(selectedData?.text),
                    })(
                      <Wysiwyg
                        contentMinHeight="202px"
                        placeholder="Write your snippet text"
                        mentions={mentions}
                      />
                    )}
                </Form.Item>
                <Form.Item>
                    <Box d="flex" justifyContent="flex-end">
                        <Button loading={isLoading} type="primary" htmlType="submit">
                            Update
                        </Button>
                    </Box>
                </Form.Item>
            </Form>
        </Popup>
    );
});

export default Form.create()(EditSnippet);
