import React from 'react';

const IconNoData = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 159 159" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.6602 135.872H105.387L124.447 53.1671L39.9588 50.9936L33.1039 41.6309H15.6602V135.872Z"
                fill="#EF457E"
            />
            <path d="M125.561 135.092H20.3975L48.9874 72.4502H154.151L125.561 135.092Z" fill="white" />
            <path
                d="M125.84 135.537H19.9517V134.924L48.7088 72.0596H154.597L154.319 72.6169L125.84 135.537ZM21.0663 134.645H125.283L153.483 72.8955H49.2661L21.0663 134.645Z"
                fill="#07122B"
            />
            <path
                d="M65.0379 72.4502C64.8945 72.5062 64.7354 72.5062 64.592 72.4502C64.4626 72.3153 64.3903 72.1356 64.3903 71.9486C64.3903 71.7617 64.4626 71.582 64.592 71.447C65.1469 70.8397 65.5439 70.1052 65.748 69.3083C65.9521 68.5113 65.957 67.6764 65.7624 66.8771C63.8675 59.3535 45.9779 52.2756 45.8108 52.2199C45.7249 52.1892 45.6463 52.1413 45.5796 52.0791C45.513 52.0169 45.4598 51.9418 45.4233 51.8583C45.3867 51.7748 45.3676 51.6847 45.3672 51.5935C45.3667 51.5024 45.3849 51.4121 45.4206 51.3282C45.4943 51.1641 45.6264 51.0332 45.7912 50.9611C45.956 50.889 46.1418 50.8808 46.3123 50.9381C47.0926 51.2168 65.0379 58.2946 67.0999 66.5427C67.3626 67.5789 67.3571 68.6649 67.0841 69.6984C66.8111 70.7319 66.2796 71.6789 65.5395 72.4502C65.3786 72.5153 65.1988 72.5153 65.0379 72.4502Z"
                fill="#07122B"
            />
            <path
                d="M70.1095 65.4839C68.4203 65.5106 66.752 65.1079 65.2609 64.3135C65.1073 64.2307 64.9922 64.091 64.9401 63.9244C64.888 63.7578 64.9032 63.5775 64.9823 63.4219C65.0177 63.3336 65.0715 63.2539 65.1402 63.1882C65.2088 63.1224 65.2907 63.072 65.3804 63.0403C65.47 63.0087 65.5654 62.9965 65.6601 63.0046C65.7549 63.0128 65.8468 63.041 65.9297 63.0875C67.093 63.6837 68.3676 64.0312 69.6725 64.108C70.9774 64.1847 72.284 63.989 73.5091 63.5333C75.6699 62.9023 77.6042 61.6644 79.0822 59.9665C79.1069 59.8758 79.1506 59.7912 79.2102 59.7185C79.2698 59.6457 79.3442 59.5863 79.4283 59.5442C79.5125 59.5022 79.6046 59.4783 79.6986 59.4743C79.7926 59.4702 79.8864 59.486 79.9739 59.5207C80.0581 59.5462 80.1365 59.588 80.2046 59.6439C80.2726 59.6997 80.3289 59.7685 80.3704 59.8461C80.4118 59.9238 80.4375 60.0089 80.4459 60.0965C80.4544 60.1841 80.4455 60.2725 80.4197 60.3567C79.2004 62.2008 77.4234 63.6068 75.3482 64.3693C73.7021 65.1121 71.9155 65.4922 70.1095 65.4839Z"
                fill="#07122B"
            />
            <path
                d="M89.7269 72.4501H89.1696C85.2527 69.8715 81.9284 66.4901 79.4167 62.53C78.2328 59.5602 77.8485 56.3319 78.3021 53.1672C78.3018 53.0745 78.3208 52.9827 78.3578 52.8977C78.3948 52.8127 78.4491 52.7363 78.5173 52.6735C78.5854 52.6106 78.6659 52.5625 78.7535 52.5324C78.8412 52.5023 78.9342 52.4907 79.0266 52.4984C79.2137 52.5254 79.3846 52.6194 79.5076 52.7629C79.6306 52.9064 79.6973 53.0897 79.6954 53.2787C79.2823 56.192 79.6081 59.1624 80.6428 61.9169C83.0468 65.6434 86.1967 68.8313 89.8941 71.2797C89.9565 71.3418 90.0059 71.4157 90.0397 71.497C90.0734 71.5782 90.0908 71.6654 90.0908 71.7534C90.0908 71.8414 90.0734 71.9286 90.0397 72.0099C90.0059 72.0912 89.9565 72.165 89.8941 72.2271C89.8562 72.3133 89.799 72.3896 89.7269 72.4501Z"
                fill="#07122B"
            />
            <path
                d="M70.4439 72.45C70.2831 72.5151 70.1032 72.5151 69.9424 72.45C69.8244 72.3095 69.7598 72.1319 69.7598 71.9484C69.7598 71.765 69.8244 71.5874 69.9424 71.4469C74.4036 67.1592 79.9877 64.2222 86.0486 62.9758C100.594 61.4153 111.852 64.1461 119.487 70.9453C119.616 71.0802 119.689 71.2599 119.689 71.4469C119.689 71.6338 119.616 71.8135 119.487 71.9484C119.347 72.0664 119.169 72.131 118.985 72.131C118.802 72.131 118.624 72.0664 118.484 71.9484C111.462 65.0935 100.316 62.5299 86.2158 64.0346C80.4206 65.3084 75.1175 68.231 70.9455 72.45C70.7829 72.5052 70.6066 72.5052 70.4439 72.45Z"
                fill="#07122B"
            />
            <path
                d="M100.316 72.4499H99.9254C98.5957 71.2067 97.5007 69.7342 96.693 68.1029C96.1952 67.2288 95.6365 66.3907 95.0211 65.595C93.9612 64.4263 93.0444 63.1354 92.2903 61.7496C91.1821 58.9885 90.651 56.0297 90.7298 53.0556C90.7221 52.9632 90.7337 52.8702 90.7638 52.7825C90.7939 52.6948 90.8419 52.6143 90.9048 52.5462C90.9677 52.4781 91.0441 52.4238 91.1291 52.3867C91.2141 52.3497 91.3059 52.3307 91.3986 52.3311C91.5779 52.3421 91.7479 52.4147 91.8799 52.5365C92.0118 52.6583 92.0978 52.822 92.1231 52.9998C91.9403 55.8187 92.3386 58.6451 93.2934 61.3037C93.9537 62.5901 94.7986 63.773 95.8013 64.8148C96.3029 65.4835 96.9159 66.4867 97.5847 67.5456C98.3338 68.9962 99.2914 70.3294 100.427 71.5025C100.489 71.5646 100.539 71.6384 100.573 71.7197C100.606 71.801 100.624 71.8882 100.624 71.9762C100.624 72.0642 100.606 72.1514 100.573 72.2326C100.539 72.3139 100.489 72.3878 100.427 72.4499C100.39 72.453 100.353 72.453 100.316 72.4499Z"
                fill="#07122B"
            />
            <path
                d="M84.2648 58.5733C82.6138 58.6961 80.9559 58.6961 79.3048 58.5733C79.2115 58.5669 79.1206 58.5409 79.038 58.4972C78.9553 58.4534 78.8828 58.3928 78.825 58.3193C78.7673 58.2458 78.7256 58.161 78.7026 58.0703C78.6796 57.9797 78.6759 57.8852 78.6918 57.7931C78.7194 57.6108 78.8155 57.4459 78.9605 57.332C79.1054 57.2181 79.2883 57.1638 79.472 57.18C91.1661 57.6722 102.861 56.2221 114.081 52.8887C114.258 52.8348 114.45 52.851 114.616 52.9339C114.781 53.0169 114.909 53.1603 114.972 53.3346C115.007 53.4221 115.023 53.5159 115.019 53.6099C115.015 53.7039 114.991 53.796 114.949 53.8801C114.907 53.9643 114.848 54.0386 114.775 54.0983C114.702 54.1579 114.617 54.2015 114.527 54.2263C104.66 56.9322 94.494 58.3925 84.2648 58.5733Z"
                fill="#07122B"
            />
            <path
                d="M108.731 72.4503H108.452C106.249 70.9737 104.356 69.0804 102.879 66.8772C101.559 64.7737 100.71 62.4088 100.393 59.9456C100.075 57.4824 100.296 54.9797 101.04 52.6101C101.065 52.5193 101.108 52.4348 101.168 52.362C101.228 52.2892 101.302 52.2299 101.386 52.1878C101.47 52.1457 101.562 52.1219 101.656 52.1178C101.75 52.1138 101.844 52.1296 101.932 52.1643C102.016 52.1897 102.094 52.2316 102.162 52.2874C102.23 52.3433 102.287 52.412 102.328 52.4897C102.37 52.5673 102.395 52.6524 102.404 52.74C102.412 52.8276 102.403 52.9161 102.377 53.0002C101.692 55.1836 101.487 57.4892 101.775 59.7592C102.064 62.0293 102.84 64.2102 104.049 66.1527C105.353 68.1799 106.993 69.9703 108.898 71.4471C109.065 71.5312 109.194 71.6768 109.256 71.8534C109.318 72.0301 109.31 72.224 109.232 72.3945C109.075 72.4643 108.9 72.4838 108.731 72.4503Z"
                fill="#07122B"
            />
            <path
                d="M118.094 72.8405H117.759C115.561 71.2457 113.672 69.2626 112.186 66.9888C110.866 64.8853 110.017 62.5204 109.7 60.0572C109.382 57.594 109.603 55.0912 110.347 52.7217C110.346 52.5923 110.383 52.4656 110.453 52.3568C110.523 52.248 110.623 52.1618 110.741 52.1087C110.859 52.0556 110.99 52.0379 111.118 52.0577C111.246 52.0774 111.365 52.1339 111.462 52.2201C111.546 52.2456 111.624 52.2875 111.692 52.3433C111.76 52.3991 111.817 52.4679 111.858 52.5455C111.9 52.6232 111.925 52.7083 111.934 52.7959C111.942 52.8835 111.933 52.9719 111.907 53.0561C111.219 55.2389 111.011 57.5451 111.3 59.8158C111.589 62.0865 112.366 64.2675 113.579 66.2085C114.883 68.2358 116.523 70.0262 118.428 71.503C118.516 71.5384 118.596 71.5922 118.662 71.6608C118.727 71.7295 118.778 71.8114 118.81 71.901C118.841 71.9907 118.853 72.0861 118.845 72.1808C118.837 72.2755 118.809 72.3674 118.762 72.4504C118.694 72.568 118.597 72.6658 118.48 72.7343C118.362 72.8027 118.229 72.8393 118.094 72.8405Z"
                fill="#07122B"
            />
            <path
                d="M67.6573 57.9047C64.5182 57.8956 61.3857 57.616 58.2946 57.0687C58.2062 57.0553 58.1214 57.0242 58.0453 56.9774C57.9691 56.9307 57.9031 56.8691 57.8511 56.7963C57.7992 56.7236 57.7623 56.6412 57.7428 56.554C57.7232 56.4667 57.7213 56.3765 57.7373 56.2885C57.7771 56.1165 57.8783 55.9649 58.022 55.8623C58.1657 55.7597 58.3419 55.7131 58.5175 55.7312C65.3028 57.1065 72.3202 56.8197 78.9707 54.8952C79.0546 54.8595 79.1448 54.8414 79.236 54.8418C79.3271 54.8423 79.4172 54.8613 79.5007 54.8979C79.5842 54.9344 79.6594 54.9876 79.7216 55.0543C79.7838 55.1209 79.8317 55.1995 79.8624 55.2854C79.9163 55.4628 79.9002 55.6542 79.8172 55.8201C79.7343 55.986 79.5909 56.1137 79.4165 56.1771C75.6237 57.4181 71.6468 58.0024 67.6573 57.9047Z"
                fill="#07122B"
            />
            <path
                d="M53.613 72.9518C53.4807 72.9174 53.3574 72.8547 53.2516 72.7681C53.1457 72.6816 53.0599 72.5731 53 72.4502C52.882 72.3097 52.8174 72.1321 52.8174 71.9486C52.8174 71.7652 52.882 71.5876 53 71.447C54.9847 70.2623 56.7097 68.689 58.0715 66.8214C58.559 65.4013 58.8405 63.9186 58.9074 62.4187C59.0927 58.6121 57.9719 54.8565 55.7308 51.7741C55.6014 51.6392 55.5291 51.4595 55.5291 51.2725C55.5291 51.0855 55.6014 50.9058 55.7308 50.7709C55.7929 50.7086 55.8667 50.6591 55.948 50.6253C56.0293 50.5916 56.1165 50.5742 56.2045 50.5742C56.2925 50.5742 56.3797 50.5916 56.461 50.6253C56.5422 50.6591 56.6161 50.7086 56.6782 50.7709C59.2041 54.1989 60.4822 58.3874 60.3007 62.6416C60.212 64.2758 59.8931 65.8894 59.3533 67.4344C57.9215 69.6742 56.0199 71.5758 53.7802 73.0075L53.613 72.9518Z"
                fill="#07122B"
            />
            <path
                d="M97.2504 38.4543C96.5163 38.467 95.7962 38.2529 95.1883 37.8412C94.3567 37.0556 93.8117 36.0145 93.64 34.8834C93.4683 33.7523 93.6798 32.5963 94.2409 31.5994C95.1852 29.4588 96.594 27.5551 98.365 26.0263C93.4854 25.7347 88.6569 27.1537 84.7109 30.0389C82.0726 31.9304 79.8055 34.2919 78.0232 37.0052C77.9496 37.1694 77.8175 37.3002 77.6527 37.3723C77.4878 37.4444 77.3021 37.4527 77.1316 37.3954C77.0457 37.3647 76.9671 37.3168 76.9004 37.2546C76.8338 37.1924 76.7806 37.1172 76.7441 37.0337C76.7075 36.9502 76.6884 36.8601 76.688 36.769C76.6875 36.6778 76.7057 36.5875 76.7414 36.5037C78.5727 33.5665 80.9351 30.9962 83.7078 28.9243C88.3439 25.5241 94.1143 24.0465 99.814 24.8002C102.877 22.8064 106.306 21.4424 109.901 20.7876C112.744 20.2303 115.474 19.9516 118.484 19.673C122.03 19.4303 125.552 18.9086 129.017 18.1125C129.193 18.0888 129.371 18.1255 129.523 18.2167C129.675 18.3078 129.791 18.448 129.853 18.6141C129.904 18.7988 129.88 18.9964 129.786 19.1636C129.692 19.3307 129.536 19.4538 129.351 19.5058C125.811 20.3019 122.215 20.8236 118.595 21.0662C115.809 21.3449 113.022 21.5678 110.18 22.1251C107.266 22.6979 104.463 23.7327 101.876 25.1903C103.142 25.3037 104.316 25.8988 105.156 26.8529C105.997 27.807 106.438 29.0473 106.39 30.3176C106.39 33.4942 103.492 35.8906 101.152 37.061C99.9795 37.8271 98.6426 38.3046 97.2504 38.4543ZM100.316 26.2492C98.2185 27.7544 96.5634 29.7944 95.5227 32.1567C94.8539 33.7171 94.7425 35.8349 95.9686 36.6709C97.1946 37.5068 98.4765 37.1167 100.706 35.8349C102.935 34.5531 105.387 32.8254 105.276 30.2618C105.164 27.6982 104.384 27.0852 101.82 26.4721L100.316 26.2492Z"
                fill="#07122B"
            />
            <path d="M82.1032 96.3432L78.103 100.224L95.0995 117.744L99.0996 113.864L82.1032 96.3432Z" fill="#0099F6" />
            <path
                d="M97.5451 97.2947L76.6416 111.942L79.8398 116.506L100.743 101.859L97.5451 97.2947Z"
                fill="#0099F6"
            />
        </svg>
    );
};

export default IconNoData;
