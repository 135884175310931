import React, { useState } from 'react';
import { Form } from '@components/Form';
import { Text } from '@components/Text';
import FieldOwnerSelector from '@pages/Customer/Team/blocks/FieldOwnerSelector';
import { Box } from '@components/Box';
import { Button } from '@components/Button';

const FormManageOwner = ({ form, onSuccessSubmit, options, initialValue }) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = ev => {
        ev.preventDefault();
        ev.stopPropagation();

        form.validateFields(async (err, values) => {
            if (err && isLoading) {
                return;
            }

            if (onSuccessSubmit) {
                setIsLoading(true);
                await onSuccessSubmit(values);
                setIsLoading(false);
            }
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Text textVariant="P4" mb="20">
                The owner(s) of a request will receive update notifications via email.
            </Text>
            <Form.Item>{form.getFieldDecorator('ownerIds', {
                initialValue: initialValue,
            })(<FieldOwnerSelector options={options} mb="14" />)}</Form.Item>
            <Box textAlign="right">
                <Button type="primary" htmlType="submit" loading={isLoading}>
                    Update
                </Button>
            </Box>
        </Form>
    );
};

export default Form.create()(FormManageOwner);
