import React, { createContext, useContext } from 'react';
import { Box } from '@components/Box';
import { withResponsive } from '@components/ResponsiveProvider';
import Snippets from './Snippets';

export const ToolbarContext = createContext();

export const useToolbarContext = () => {
    return useContext(ToolbarContext);
};

export const ToolbarProvider = withResponsive(({ 
    handleAddSnippet,
    windowWidth,
    editorState,
    onChange,
    left,
    right,
    isCustomer,
}) => {
    const leftLength = Array.isArray(left) ? left.length : left;
    const isMobile = windowWidth < 768;

    return (
        <ToolbarContext.Provider value={{ editorState, onChange }}>
            <>  
                {handleAddSnippet && (
                    <Box
                        fontSize="14"
                        alignItems="center"
                        d="flex"
                        colorScheme="tertiary"
                        className="snippets"
                        _hover={{ colorScheme: 'cta' }}
                    >
                        <Snippets handleAddSnippet={handleAddSnippet} />
                    </Box>
                )}
                {!isMobile && (
                    (isCustomer && !!leftLength) &&  (
                        <Box alignSelf="center" onClick={() => window.open('https://loom.com', '_blank')}>
                            <Box
                                d="flex" 
                                alignItems="center" 
                                hasSpace 
                                space="24" 
                                fontSize="14"
                                colorScheme="tertiary"
                                _hover={{ colorScheme: 'cta' }}
                                cursor="pointer"
                            >
                                    Record a Video
                            </Box>
                        </Box>
                    )
                )}
                {left && (
                    <Box order="-1" alignSelf="center">
                        <Box d="flex" alignItems="center" hasSpace space="24" fontSize="20">
                            {left}
                        </Box>
                    </Box>
                )}
                {right && (
                    <Box ml="auto" alignSelf="center">
                        <Box d="flex" alignItems="center" hasSpace space={["12","24"]} fontSize="20">
                            {right}
                        </Box>
                    </Box>
                )}
            </>
        </ToolbarContext.Provider>
    );
})