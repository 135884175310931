import React, { memo, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import stripeLogo from '@assets/icons/stripe-logo.png';
import { CalendarOutlined, MailOutlined, ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Badge } from '@components/Badge';
import _ from 'lodash';
import { Button } from '@components/Button';
import { CONNECT_AS } from '@graphql/mutations/auth';
import message from '@components/Message';
import { Skeleton } from '@components/Skeleton';
import { Image } from '@components/Image';
import { CUSTOMERS, STRIPE_CUSTOMER_PROFILE } from '@constants/routes';
import { TIMEZONES } from '@constants/forms';
import StatusColoredText from '@components/Text/StatusColoredText';
import { computeStatusFinal } from '@utils/subscription';
import { PopupDelete } from '@components/Popup';
import { DELETE_USER_ACCOUNT } from '@graphql/mutations/user';

const Header = memo(({ loading, company, isWorker }) => {
    const history = useHistory();
    const [connectAs] = useMutation(CONNECT_AS);
    const [deleteAccount] = useMutation(DELETE_USER_ACCOUNT)
    const [showDelete, setShowDelete] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const handleDeleteAccount = useCallback(async () => {
        const [user] = company?.users || []
        if (user) {
            setIsDeleting(true)
            message.loading('Deleting User and Account...', 2000)
            await deleteAccount({
                variables: {
                    userId: user.id,
                    companyId: company.id,
                }
            })
            message.destroy()
            message.success('User and Account Deleted')
            setIsDeleting(false)
            history.push(CUSTOMERS)

        }
    }, [company, deleteAccount, history])
    const handleConnectAs = useCallback(async () => {
        try {
            const response = await connectAs({
                variables: {
                    companyId: company?.id,
                },
            });
            const { token } = response?.data?.connectAs;
            if (!token) {
                message.error('Cannot login as company');
                return;
            }
            history.push(`/connect/${token}`);
        } catch (error) {
            const errors = error.graphQLErrors || [];
            const errorMessage = errors.length > 0 ? errors[0].message : 'Error on signing up';
            message.error(errorMessage);
        }
    }, [company, connectAs, history]);

    const companyBrandWithLogo = company?.brands?.find(brand => brand?.logos?.length > 0);
    const companyBrandLogo = companyBrandWithLogo?.logos?.length > 0 ? companyBrandWithLogo?.logos[0]?.url : null;
    const companyLogo = company?.logo?.url ?? null;
    const accountLogo = companyLogo || companyBrandLogo;
    const timezone = TIMEZONES.find(tz => tz.value === company?.timezone);
    const planStatus = company?.subscription?.status;

    return (
        <Box w="100%" h="150" borderW="1" borderStyle="solid" borderColor="outline-gray" py="20" px="30" mb="28">
            {loading ? (
                <Box d="flex" alignItems="flex-start">
                    <Skeleton variant="avatar" avatarSize="110" />
                    <Box px="35" flex="1 1 0%">
                        <Box d="flex" alignItems="center">
                            <Skeleton w="100" h="26" mr="4" />
                            <Skeleton w="48" h="23" />
                        </Box>
                        <Box d="flex" alignItems="center" mt="18">
                            <Skeleton w="137" h="18" mr="31" />
                            <Skeleton w="137" h="18" mr="31" />
                            <Skeleton w="137" h="18" />
                        </Box>
                        <Box mt="17" d="flex" alignItems="center">
                            <Skeleton w="87" h="32" mr="10" />
                            <Skeleton w="133" h="20" />
                        </Box>
                    </Box>
                    <Skeleton w="133" h="40" />
                </Box>
            ) : (
                <Box d="flex" alignItems="flex-start">
                    <Box>
                        <Image src={accountLogo} size={110} isRounded fontSize={20} name={company?.name} />
                    </Box>
                    <Box px="35" flex="1 1 0%">
                        <Box d="flex" alignItems="flex-end">
                            <Text textVariant="H5" colorScheme="primary" mr="4">
                                {company?.name}
                            </Text>
                            <Box
                                as="a"
                                href={STRIPE_CUSTOMER_PROFILE.replace(
                                    ':customerId',
                                    company?.subscription?.billingSource?.customer ?? ''
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Box as="img" src={stripeLogo} alt="Stripe" />
                            </Box>
                        </Box>
                        <Box d="flex" alignItems="center" mt="16">
                            {!isWorker && (
                                <Box d="flex" alignItems="center" mr="30">
                                    <Box
                                        colorScheme="secondary"
                                        d="inline-flex"
                                        w="20"
                                        h="20"
                                        alignItems="center"
                                        justifyContent="center"
                                        mr="6"
                                    >
                                        <CalendarOutlined style={{ fontSize: '15.62px' }} />
                                    </Box>
                                    <Text textVariant="P5" colorScheme="secondary">
                                        Created on {moment(company?.createdAt).format('DD MMM YYYY')}
                                    </Text>
                                </Box>
                            )}
                            {!isWorker && (
                                <Box d="flex" alignItems="center" mr="30">
                                    <Box
                                        colorScheme="secondary"
                                        d="inline-flex"
                                        w="20"
                                        h="20"
                                        alignItems="center"
                                        justifyContent="center"
                                        mr="6"
                                    >
                                        <MailOutlined style={{ fontSize: '17.5px' }} />
                                    </Box>
                                    <Text textVariant="P5" colorScheme="secondary">
                                        {company?.subscription?.user?.email}
                                    </Text>
                                </Box>
                            )}
                            <Box d="flex" alignItems="center">
                                <Box
                                    colorScheme="secondary"
                                    d="inline-flex"
                                    w="20"
                                    h="20"
                                    alignItems="center"
                                    justifyContent="center"
                                    mr="6"
                                >
                                    <ClockCircleOutlined style={{ fontSize: '17.5px' }} />
                                </Box>
                                <Text textVariant="P5" colorScheme="secondary">
                                    {timezone ? `${timezone.name}` : (company?.timezone ?? '-')}
                                </Text>
                            </Box>
                        </Box>
                        <Box mt="16" d="flex" alignItems="center">
                            {planStatus === 'active' && (
                                <Badge variant="BillingActive">Active</Badge>
                            )}
                            {planStatus === 'paused' && <Badge variant="BillingPaused">Paused</Badge>}
                            {(planStatus === 'cancelled' || planStatus === 'canceled' || planStatus === 'inactive') && (
                                <Badge variant="SubscriptionInactive">Inactive</Badge>
                            )}
                            <Text textVariant="P4" colorScheme="primary" ml="10">
                                <StatusColoredText status={computeStatusFinal(company?.subscription)}>
                                    {company?.subscription?.plan?.name}
                                </StatusColoredText>{' '}
                                -
                            </Text>
                            <Text textVariant="P4" colorScheme="secondary">
                                &nbsp;{_.upperFirst(_.toLower(company?.subscription?.plan?.interval))}
                            </Text>
                        </Box>
                    </Box>
                    {!isWorker && (
                        <Box d="flex" flexDir="column">
                            <Button type="primary" onClick={handleConnectAs}>
                                Connect As
                            </Button>
                            {!planStatus && (
                                <Button type="danger" onClick={() => setShowDelete(true)} mt="10">
                                    Delete Account
                                </Button>
                            )}
                        </Box>
                    )}
                </Box>
            )}
             <PopupDelete
                title={`Are you sure you want to remove this account?`}
                variant="delete"
                visible={showDelete}
                onOk={handleDeleteAccount}
                onCancel={() => setShowDelete(false)}
                confirmLoading={isDeleting}
            >
                <Text textVariant="P4" colorScheme="secondary">
                    The user has no subscription but this action cannot be undone.
                </Text>
            </PopupDelete>
        </Box>
    );
});

export default Header;
