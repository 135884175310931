import React from 'react';

const IconDeliverableJpg = () => {
    return (
        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57.156 65.835H8.84399V9.37201L20.097 0.165009H57.156V65.835Z" fill="#E6E6E4"/>
            <path d="M43.494 19.569H22.11V38.214H43.494V19.569Z" fill="#F2D465"/>
            <path d="M22.11 31.251L32.043 25.542L37.026 28.776L43.494 25.806V38.214H22.11V31.251Z" fill="#31B89A"/>
            <path d="M8.84399 9.37201H19.47V0.165009L8.84399 9.37201Z" fill="#AFAFAC"/>
            <path d="M24.288 55.803C24.3377 56.374 24.3377 56.9481 24.288 57.519C24.2096 58.0438 24.0058 58.5418 23.694 58.971C23.3923 59.429 22.9697 59.7945 22.473 60.027C21.812 60.2885 21.1045 60.412 20.394 60.39C19.4814 60.4217 18.5816 60.1679 17.82 59.664C17.4593 59.3991 17.1563 59.0635 16.9294 58.6777C16.7024 58.2919 16.5564 57.864 16.5 57.42L19.041 56.793C19.0893 57.0983 19.2407 57.3778 19.47 57.585C19.7021 57.8026 20.0099 57.921 20.328 57.915C20.5907 57.9344 20.8508 57.8516 21.054 57.684C21.2438 57.5313 21.382 57.324 21.45 57.09C21.4818 56.827 21.4818 56.5611 21.45 56.298C21.4652 56.0343 21.4652 55.7698 21.45 55.506V47.355H24.255L24.288 55.803Z" fill="#08122A"/>
            <path d="M26.697 47.3551H31.416C32.0375 47.3504 32.6576 47.4168 33.264 47.5531C33.8185 47.6341 34.3482 47.837 34.815 48.1471C35.2563 48.4443 35.6096 48.8546 35.838 49.3351C36.1225 49.92 36.2585 50.5661 36.234 51.2161C36.2508 51.862 36.127 52.5038 35.871 53.0971C35.6544 53.5753 35.3123 53.9858 34.881 54.2851C34.4351 54.6021 33.9302 54.8265 33.396 54.9451C32.775 55.0549 32.1456 55.1101 31.515 55.1101H29.469V60.0601H26.697V47.3551ZM29.469 52.8001H31.35C31.5908 52.8344 31.8352 52.8344 32.076 52.8001C32.2963 52.7579 32.5081 52.6799 32.703 52.5691C32.8556 52.3979 32.9683 52.195 33.033 51.9751C33.1453 51.738 33.2017 51.4783 33.198 51.2161C33.2414 50.9513 33.1824 50.6799 33.033 50.4571C32.8943 50.2524 32.6995 50.092 32.472 49.9951C32.2268 49.8642 31.9571 49.7856 31.68 49.7641H29.469V52.8001Z" fill="#08122A"/>
            <path d="M49.533 59.169C48.7254 59.6025 47.858 59.9139 46.959 60.093C46.0487 60.2931 45.1191 60.3927 44.187 60.39C43.2544 60.3925 42.3282 60.2363 41.448 59.928C40.6357 59.6336 39.8939 59.1727 39.27 58.575C38.6613 57.985 38.1786 57.2778 37.851 56.496C37.1919 54.7068 37.1919 52.7412 37.851 50.952C38.1869 50.165 38.6683 49.4484 39.27 48.84C39.8957 48.2526 40.6377 47.8029 41.448 47.52C42.3221 47.1853 43.2511 47.0174 44.187 47.025C45.1474 47.0153 46.1039 47.1488 47.025 47.421C47.8629 47.6527 48.6326 48.0828 49.269 48.675L47.289 50.82C46.9208 50.4436 46.4839 50.1411 46.002 49.929C45.4215 49.7152 44.8054 49.6144 44.187 49.632C43.6339 49.6258 43.0848 49.7267 42.57 49.929C42.1013 50.1328 41.6758 50.424 41.316 50.787C40.9768 51.1649 40.7085 51.6009 40.524 52.074C40.1714 53.1457 40.1714 54.3023 40.524 55.374C40.7085 55.8471 40.9768 56.2831 41.316 56.661C41.6845 57.0133 42.1081 57.3031 42.57 57.519C43.0848 57.7213 43.6339 57.8222 44.187 57.816C44.7219 57.8282 45.2556 57.7615 45.771 57.618C46.1333 57.5019 46.4864 57.3585 46.827 57.189V55.11H44.55V52.536H49.533V59.169Z" fill="#08122A"/>
        </svg>
    );
};

export default IconDeliverableJpg;
