import React from 'react';
import { InputNumber } from '@components/InputNumber';
import { InputBoxItem, InputBoxLike, PreviewColor } from '../style';
import { rgbaToStr, strToRgba } from '../utils/inputColorConverter';

export const InputRGBA = ({ value, onChange }) => {
    const [r, g, b] = strToRgba(value);

    const setRgba = input => {
        const newValue = { r, g, b, ...input };
        if (onChange) {
            onChange(rgbaToStr([newValue.r, newValue.g, newValue.b]));
        }
    };

    return (
        <InputBoxLike>
            <InputBoxItem>
                <PreviewColor bg={`rgba(${r}, ${g}, ${b})`} />
            </InputBoxItem>
            <InputNumber value={r} onChange={val => setRgba((prev) => ({ ...prev, r: val }))} min={0} max={255} />
            <InputNumber value={g} onChange={val => setRgba((prev) => ({ ...prev, g: val }))} min={0} max={255} />
            <InputNumber value={b} onChange={val => setRgba((prev) => ({ ...prev, b: val }))} min={0} max={255} />
        </InputBoxLike>
    );
};
