import React, { useCallback, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Badge } from '@components/Badge';
import { Table } from '@components/Table';
import { Skeleton } from '@components/Skeleton';
import _ from 'lodash';
import { Button } from '@components/Button';
import moment from 'moment';
import downloadPrimary from '@assets/icons/download-primary.svg';
import downloadSecondary from '@assets/icons/download-secondary.svg';
import { SUBSCRIPTION_INVOICES } from '@graphql/queries/subscription';
import ArrowRightIcon from '@components/Svg/ArrowRight';
import { usdFormatter } from '@constants/utils';
import IconNoData from '@components/Svg/IconNoData';
import { ConfigProvider } from 'antd';

const SkeletonTableHeader = () => (
    <Box
        d="flex"
        h="43"
        alignItems="center"
        px="20"
        borderTop="1px solid"
        borderLeft="1px solid"
        borderRight="1px solid"
        borderColor="outline-gray"
        maxW="780"
    >
        <Skeleton w="73" h="16" mr="244" />
        <Skeleton w="50" h="16" mr="55" />
        <Skeleton w="29" h="16" mr="95" />
        <Skeleton w="37" h="16" mr="86" />
        <Skeleton w="65" h="16" />
    </Box>
);

const SkeletonTableRow = () => (
    <Box
        d="flex"
        h="52"
        px="20"
        borderTop="1px solid"
        borderLeft="1px solid"
        borderRight="1px solid"
        borderColor="outline-gray"
        alignItems="center"
        maxW="780"
        _last={{ borderBottom: '1px solid', borderColor: 'outline-gray' }}
    >
        <Skeleton w="215" h="16" mr="102" />
        <Skeleton w="32" h="16" mr="77" />
        <Skeleton w="67" h="16" mr="55" />
        <Skeleton w="75" h="32" mr="71" radii="18" />
        <Skeleton w="20" h="20" />
    </Box>
);

const SkeletonPagination = () => (
    <Box d="flex" alignItems="center" maxW="780">
        <Box ml="auto">
            <Box my="20" d="flex" alignIteems="center">
                <Skeleton w="82" h="42" mr="8" />
                <Skeleton w="81" h="42" mr="8" />
                <Skeleton w="138" h="42" />
            </Box>
        </Box>
    </Box>
);

const CustomEmptyTable = () => {
    return (
        <Box textAlign="center">
            <Box lineH="1" fontSize="121" mb="10">
                <IconNoData />
            </Box>
            <Text textVariant="H5" colorScheme="primary" mb="2">
                No invoice found
            </Text>
        </Box>
    );
};

const paymentStatusVariant = {
    paid: 'Active',
    void: 'Cancelled',
    uncollectible: 'Inactive',
    draft: 'Draft',
    open: 'Ongoing',
};

const InvoicesHistory = ({ billLoading = false, subscriptionId, isFromCompany = false, isWorker = false }) => {
    const [page, setPage] = useState(1);
    const [invoicePerPage, setInvoicePerPage] = useState(3);
    const [hovered, setHovered] = useState(null);
    const [pageQuery, setPageQuery] = useState({
        startingAfter: null,
    });

    const [invoiceHistory, setInvoiceHistory] = useState({
        data: {
            1: [],
        },
        hasMore: false,
    });

    const { loading } = useQuery(SUBSCRIPTION_INVOICES, {
        fetchPolicy: 'network-only',
        variables: {
            subscriptionId,
            limit: invoicePerPage,
            ...pageQuery,
        },
        onCompleted: result => {
            const resultData = result?.getInvoices;
            setInvoiceHistory(old => ({
                ...old,
                data: {
                    ...old.data,
                    [page]: resultData?.data ?? [],
                },
                // certain bug from has more stripe result, which if go back to page one, hasMore return false
                hasMore: resultData?.hasMore || typeof old.data[page + 1] !== 'undefined',
            }));
        },
    });

    const setInvoicePage = useCallback(
        pageQ => {
            const currentData = invoiceHistory.data[page];
            const lastItem = currentData?.length > 0 ? currentData[currentData.length - 1] : {};
            const firstItem = currentData?.length > 0 ? currentData[0] : {};
            if (pageQ === 'next' && lastItem?.id) {
                setPageQuery({
                    startingAfter: lastItem.id,
                    endingBefore: undefined,
                });
                setPage(p => p + 1);
            } else if (pageQ === 'prev' && firstItem?.id) {
                setPageQuery({
                    endingBefore: firstItem.id,
                    startingAfter: undefined,
                });
                setPage(p => p - 1);
            }
        },
        [page, setPage, setPageQuery, invoiceHistory]
    );

    const total =
        invoiceHistory.hasMore && invoiceHistory.data[page]
            ? invoiceHistory.data[page].length + 1
            : Object.keys(invoiceHistory.data).reduce((prev, item) => {
                  return prev + invoiceHistory.data[item].length;
              }, 0);

    if (loading || billLoading) {
        const currentTotal = invoiceHistory?.data?.length ?? 3;
        return isFromCompany ? (
            <Box my="30">
                <Skeleton w="145" h="26" mb="20" />
                <Box borderW="1" borderStyle="solid" borderColor="other-gray">
                    <Box px="16" py="16" bg="#FAFAFA">
                        <Skeleton w="100%" h="18" />
                    </Box>
                    <Box px="16" py="16" borderW="0" borderT="1" borderStyle="solid" borderColor="other-gray">
                        <Skeleton w="100%" h="18" />
                    </Box>
                    <Box px="16" py="16" borderW="0" borderT="1" borderStyle="solid" borderColor="other-gray">
                        <Skeleton w="100%" h="18" />
                    </Box>
                    <Box px="16" py="16" borderW="0" borderT="1" borderStyle="solid" borderColor="other-gray">
                        <Skeleton w="100%" h="18" />
                    </Box>
                    <Box px="16" py="16" borderW="0" borderT="1" borderStyle="solid" borderColor="other-gray">
                        <Skeleton w="100%" h="18" />
                    </Box>
                </Box>
            </Box>
        ) : (
            <Box mt="34" mb="30">
                <Skeleton w="154" h="20" mb="20" />
                <SkeletonTableHeader />
                <Box>
                    {Array.from({ length: currentTotal }, (_, index) => (
                        <SkeletonTableRow key={index} />
                    ))}
                </Box>
                <SkeletonPagination />
            </Box>
        );
    }

    const columns = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: text => (
                <Text textVariant="P4" colorScheme="primary">
                    {text}
                </Text>
            ),
        },
        {
            title: 'Amount',
            dataIndex: 'amountDue',
            key: 'amountDue',
            width: 100,
            render: amount => (
                <Text textVariant="P4" colorScheme="primary">
                    {usdFormatter.format(amount / 100)}
                </Text>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'periodStart',
            key: 'periodStart',
            width: 130,
            render: ms => (
                <Text textVariant="P4" colorScheme="primary">
                    {moment(ms * 1000).format('DD MMM YYYY')}
                </Text>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: paymentStatus => (
                <Badge variant={paymentStatusVariant[paymentStatus]}>{_.upperFirst(paymentStatus)}</Badge>
            ),
        },
        {
            title: 'Download',
            dataIndex: 'pdf',
            key: 'pdf',
            width: 108,
            render: (pdf, record) =>
                !isWorker ? (
                    <Box
                        textAlign="center"
                        onMouseEnter={() => setHovered(record.id)}
                        onMouseLeave={() => setHovered(null)}
                    >
                        <a href={pdf}>
                            <img src={record.id === hovered ? downloadPrimary : downloadSecondary} alt="Download" />
                        </a>
                    </Box>
                ) : (
                    '-'
                ),
        },
    ];

    return (
        <Box mt="30" mb="30">
            <Text textVariant="H5" colorScheme="primary" mb="20">
                Invoice history
            </Text>
            <ConfigProvider renderEmpty={CustomEmptyTable}>
                <Table
                    columns={columns}
                    dataSource={invoiceHistory.data[page] ?? []}
                    hideEmptyPagination
                    pagination={{
                        itemRender: (pg, type, originalElement) => {
                            if (type === 'prev' && page > 1) {
                                return (
                                    <Button
                                        type="default"
                                        htmlType="button"
                                        onClick={() => setInvoicePage('prev')}
                                        textTransform="capitalize"
                                        fontSize="18"
                                        lineH="26"
                                        textVariant="P2"
                                        icon={<ArrowRightIcon style={{ transform: 'rotate(-180deg)' }} />}
                                    >
                                        prev
                                    </Button>
                                );
                            }

                            if (type === 'next' && invoiceHistory.hasMore) {
                                return (
                                    <Button
                                        type="default"
                                        htmlType="button"
                                        onClick={() => setInvoicePage('next')}
                                        textTransform="capitalize"
                                        fontSize="18"
                                        lineH="26"
                                        textVariant="P2"
                                        iconRight={<ArrowRightIcon />}
                                    >
                                        next
                                    </Button>
                                );
                            }
                            return null;
                        },
                        current: page,
                        showPageNumber: false,
                        total,
                        defaultCurrent: page,
                        defaultPageSize: invoicePerPage,
                        showSizeChanger: true,
                        onShowSizeChange: (current, choice) => setInvoicePerPage(choice),
                        pageSizeOptions: ['3', '10', '20', '30', '40'],
                    }}
                />
            </ConfigProvider>
        </Box>
    );
};

export default InvoicesHistory;
