import React from 'react';

const IconSubmit = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.87021 13.78C9.69863 13.7774 9.53479 13.7081 9.41345 13.5867C9.29211 13.4654 9.2228 13.3016 9.22021 13.13V2.36997C9.22021 2.19758 9.2887 2.03225 9.41059 1.91035C9.53249 1.78845 9.69782 1.71997 9.87021 1.71997C10.0426 1.71997 10.2079 1.78845 10.3298 1.91035C10.4517 2.03225 10.5202 2.19758 10.5202 2.36997V13.13C10.5202 13.3024 10.4517 13.4677 10.3298 13.5896C10.2079 13.7115 10.0426 13.78 9.87021 13.78Z"
        fill="currentColor"
      />
      <path
        d="M6.28987 6.28995C6.12672 6.2924 5.96899 6.23146 5.84987 6.11995C5.73077 5.99845 5.66406 5.83509 5.66406 5.66495C5.66406 5.49481 5.73077 5.33145 5.84987 5.20995L9.49987 1.20995C9.61373 1.08551 9.77151 1.01021 9.93987 0.99995C10.0278 0.998623 10.1151 1.01573 10.196 1.05018C10.277 1.08463 10.3498 1.13565 10.4099 1.19995L13.8099 4.84995C13.9296 4.97314 13.9966 5.13816 13.9966 5.30995C13.9966 5.48174 13.9296 5.64676 13.8099 5.76995C13.7495 5.83042 13.6778 5.8784 13.5989 5.91113C13.5199 5.94386 13.4353 5.96071 13.3499 5.96071C13.2644 5.96071 13.1798 5.94386 13.1009 5.91113C13.0219 5.8784 12.9502 5.83042 12.8899 5.76995L9.93987 2.57995L6.76987 6.07995C6.7092 6.14683 6.63507 6.20012 6.55234 6.23632C6.46962 6.27251 6.38016 6.29079 6.28987 6.28995Z"
        fill="currentColor"
      />
      <path
        d="M17.32 18.3H2.68C2.59215 18.304 2.50439 18.2902 2.42203 18.2594C2.33968 18.2285 2.26443 18.1813 2.20086 18.1205C2.13729 18.0598 2.08671 17.9867 2.05219 17.9059C2.01766 17.825 1.99991 17.7379 2 17.65V8.25999C2 8.17463 2.01681 8.0901 2.04948 8.01124C2.08214 7.93238 2.13002 7.86072 2.19038 7.80037C2.25074 7.74001 2.32239 7.69213 2.40126 7.65946C2.48012 7.6268 2.56464 7.60999 2.65 7.60999H7.41C7.58239 7.60999 7.74772 7.67847 7.86962 7.80037C7.99152 7.92226 8.06 8.08759 8.06 8.25999C8.06 8.43238 7.99152 8.59771 7.86962 8.7196C7.74772 8.8415 7.58239 8.90999 7.41 8.90999H3.33V17H16.67V8.90999H12.19C12.0176 8.90999 11.8523 8.8415 11.7304 8.7196C11.6085 8.59771 11.54 8.43238 11.54 8.25999C11.54 8.08759 11.6085 7.92226 11.7304 7.80037C11.8523 7.67847 12.0176 7.60999 12.19 7.60999H17.32C17.4924 7.60999 17.6577 7.67847 17.7796 7.80037C17.9015 7.92226 17.97 8.08759 17.97 8.25999V17.65C17.97 17.7353 17.9532 17.8199 17.9205 17.8987C17.8879 17.9776 17.84 18.0492 17.7796 18.1096C17.7193 18.17 17.6476 18.2178 17.5687 18.2505C17.4899 18.2832 17.4054 18.3 17.32 18.3Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconSubmit;
