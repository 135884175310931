import React, { memo, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { Breadcrumb, BreadcrumbItem } from '@components/Breadcrumb';
import { Basepage } from '@components/Basepage';
import { PageContainer } from '@components/PageContainer';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Button } from '@components/Button';
// import { Radio } from '@components/Radio';
import withLoggedUser from '@components/WithLoggedUser';
import { TooltipIconBlock } from '@components/LabelWithTooltipBlock';
import { LoadingWithLogo } from '@components/LoadingWithLogo';
import { withResponsive } from '@components/ResponsiveProvider';
import DocumentTitle from '@components/DocumentTitle';
import backLink from '@assets/icons/back-link.svg';
import { PLANS_REVERSE } from '@constants/plans';
import { BILLING } from '@constants/routes';
import { ME_SUBSCRIPTION } from '@graphql/queries/userConnected';
import { ALL_PLANS_TO_SUB } from '@graphql/queries/plan';
import { usdFormatter } from '@constants/utils';
import UpdatedPlanSummary from './UpdatedPlanSummary';
import { DailyOutputSlider } from './DailyOutputSlider';

const calculatePricePerMonth = (frequency, price) => {
    let monthlyPrice = price;
    switch (frequency) {
        case 'QUARTERLY':
            monthlyPrice = price / 3;
            break;
        case 'BIANNUALLY':
            monthlyPrice = price / 6;
            break;
        case 'YEARLY':
            monthlyPrice = price / 12;
            break;
        default:
            monthlyPrice = price;
            break;
    }
    return monthlyPrice % 1 === 0 ? monthlyPrice : parseFloat(monthlyPrice).toFixed(2);
};

const handlePriceUnit = value => {
    let priceUnit;
    switch (value) {
        case 'MONTHLY': {
            priceUnit = 'mo';
            break;
        }
        case 'QUARTERLY': {
            priceUnit = 'qtr';
            break;
        }
        case 'BIANNUALLY': {
            priceUnit = 'bn';
            break;
        }
        case 'YEARLY': {
            priceUnit = 'yr';
            break;
        }
        default:
            break;
    }

    return priceUnit;
};

const EditPlan = memo(({  windowWidth }) => {
    const [frequency, setFrequency] = useState('MONTHLY'); // Should be based from data
    const selectedPlan = 'prebuilt';
    // const [selectedPlan, setSelectedPlan] = useState('prebuilt');
    const [preBuiltPlan, setPreBuiltPlan] = useState(null);
    const [preBuiltPlanName, setPreBuiltPlanName] = useState(null);
    const [customPlan, setCustomPlan] = useState();
    const [dailyOutput, setDailyOutput] = useState();
    const [currentPlanFrequency, setCurrentPlanFrequency] = useState(null);
    const [currentPlan, setCurrentPlan] = useState(null);
    const { loading: plansLoading, data: plansData } = useQuery(ALL_PLANS_TO_SUB);
    const { data: subscriptionData } = useQuery(ME_SUBSCRIPTION, {
        variables: {},
    });

    const subscription = subscriptionData?.user?.company?.subscription
    const plan = subscription?.status === 'inactive' ? null : subscription?.plan;

    useEffect(() => {
       if (plan) {
            const subscribedPlanName = plan?.name;
            const _frequency = plan?.interval;
            if (subscribedPlanName) {
                setCurrentPlan(subscribedPlanName);
                setCurrentPlanFrequency(_frequency);
                setFrequency(_frequency);
            }
       }
    }, [plan]);

    const handleChangeFrequency = useCallback(
        value => {
            if (selectedPlan === 'custom') {
                const selectedCustomPlan = plansData?.allPlans.find(
                    _plan => _plan.dailyOutput === dailyOutput && _plan.name === 'DAILY_OUTPUT' && _plan.interval === value
                );
                setCustomPlan(selectedCustomPlan);
            }
            setFrequency(value);
        },
        [selectedPlan, dailyOutput, plansData]
    );
    // Commenting this out as we will add custom plans later
    // const handleChangePlans = value => {
    //     setSelectedPlan(value);
    // };

    const findPlan = (newFrequency, newSelectedPlanName) =>
        plansData?.allPlans.find(_plan => _plan.interval === newFrequency && _plan.name === newSelectedPlanName);

    const handleChangePreBuiltPlan = value => {
        const newSelectedPlan = findPlan(frequency, value);
        setPreBuiltPlan(newSelectedPlan);
        setPreBuiltPlanName(value);
    };

    const handleChangeDailyOutput = value => {
        const selectedCustomPlan = plansData?.allPlans.find(
            _plan => _plan.dailyOutput === value && _plan.name === 'DAILY_OUTPUT' && _plan.interval === frequency
        );
        setDailyOutput(value);
        setCustomPlan(selectedCustomPlan);
    };

    const isStatusCanceled = subscription?.status === 'canceled';

    const checkIfCurrentPlan = value => {
        let planStatus = currentPlan === value && currentPlanFrequency === frequency && !isStatusCanceled
            ? 'Current plan'
            : 'Select Plan';
        if (subscriptionData?.user?.company?.subscription?.endAt) {
            planStatus = 'Select Plan';
        }
        if (subscriptionData?.user?.company?.subscription?.status === 'paused') {
            planStatus = 'Select Plan';
        }
        return planStatus    
    }    

    return (
        <DocumentTitle title="Edit Plan | ManyPixels">
            <Basepage>
                <PageContainer maxW="1292">
                    <Box d={{ xs: 'block', sm: 'block', md: 'block', lg: 'flex', xl: 'flex', xxl: 'flex' }}>
                        <Box d={windowWidth > 1044 ? 'block' : 'none'} w="56" pt="5">
                            <Link to={BILLING}>
                                <img src={backLink} alt="Back Link" />
                            </Link>
                        </Box>
                        <Box flex="1 1 0%">
                            <Box d="flex" alignItems="center" mb="15">
                                <Box d={windowWidth > 1044 ? 'none' : 'block'} mr="14">
                                    <Link to={BILLING}>
                                        <img src={backLink} alt="Back Link" />
                                    </Link>
                                </Box>
                                <Text hide="mobile" textVariant="H3" colorScheme="headline">
                                    Edit plan
                                </Text>
                                <Text hide="desktop" textVariant="H4" colorScheme="headline">
                                    Billing
                                </Text>
                            </Box>
                            <Box d={['none', 'block']}>
                                <Breadcrumb>
                                    <BreadcrumbItem isFirst as={Link} to={BILLING}>
                                        Billing
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>Edit plan</BreadcrumbItem>
                                </Breadcrumb>
                            </Box>
                            <Box
                                d={{ xs: 'block', sm: 'block', md: 'block', lg: 'flex', xl: 'flex', xxl: 'flex' }}
                                justifyContent="space-between"
                                mt="30"
                            >
                                <Box flex="1 1 auto" mr={{ xs: '0', sm: '0', md: '0', lg: '40', xl: '40', xxl: '40' }}>
                                    <Text textVariant="H5" colorScheme="headline" mb="17">
                                        Select billing frequency and plan
                                    </Text>
                                    <Box w={{ xs: '100%', sm: '100%', md: '100%', lg: '568', xl: '568', xxl: '568' }}>
                                        <Box d="flex" alignItems="center" mb="30" w="100%">
                                            {PLANS_REVERSE.map((plan, planIndex) => (
                                                <Box key={plan.name}>
                                                    <Text
                                                        flex="1 1 0%"
                                                        textAlign="center"
                                                        textVariant={{
                                                            xs: 'SmallNotification',
                                                            sm: 'SmallTitle',
                                                            md: 'SmallTitle',
                                                            lg: 'SmallTitle',
                                                            xl: 'SmallTitle',
                                                            xxl: 'SmallTitle',
                                                        }}
                                                        colorScheme={plan.colorScheme}
                                                        mb="6"
                                                    >
                                                        {plan.reduction ? `SAVE ${plan.reduction}%` : <br />}
                                                    </Text>
                                                    <Box
                                                        p="1"
                                                        cursor="pointer"
                                                        borderT="1"
                                                        borderB="1"
                                                        borderL={planIndex === 0 ? '1' : '0'}
                                                        borderR={planIndex === PLANS_REVERSE.length - 1 ? '1' : '0'}
                                                        borderStyle="solid"
                                                        borderColor="outline-gray"
                                                    >
                                                        <Text
                                                            textVariant={{
                                                                xs: 'SmallNotification',
                                                                sm: 'H6',
                                                                md: 'H6',
                                                                lg: 'H6',
                                                                xl: 'H6',
                                                                xxl: 'H6',
                                                            }}
                                                            colorScheme={frequency === plan.name ? 'white' : 'primary'}
                                                            bg={frequency === plan.name ? 'cta' : 'transparent'}
                                                            px={{
                                                                xs: '14',
                                                                sm: '14',
                                                                md: '14',
                                                                lg: '32',
                                                                xl: '32',
                                                                xxl: '32',
                                                            }}
                                                            py={{
                                                                xs: '12',
                                                                sm: '10',
                                                                md: '10',
                                                                lg: '10',
                                                                xl: '10',
                                                                xxl: '10',
                                                            }}
                                                            flex="1 1 0%"
                                                            textAlign="center"
                                                            onClick={() => handleChangeFrequency(plan.name)}
                                                        >
                                                            {plan.name}
                                                        </Text>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                    {/* 
                                     Commenting this out as custom plans will be added later
                                    <Box d="flex" alignItems="center" mb="20">
                                        <Box w="211">
                                            <Radio
                                                value="prebuilt"
                                                checked={selectedPlan === 'prebuilt'}
                                                onClick={() => handleChangePlans('prebuilt')}
                                            >
                                                Prebuilt plans
                                            </Radio>
                                        </Box>
                                        <Box w="211">
                                            <Radio
                                                value="custom"
                                                checked={selectedPlan === 'custom'}
                                                onClick={() => handleChangePlans('custom')}
                                            >
                                                <TooltipIconBlock
                                                    tooltipIconSize="16px"
                                                    label="Custom plans"
                                                    tooltip="For companies with larger design needs."
                                                    textVariant="P4"
                                                    colorScheme="primary"
                                                    w="278"
                                                />
                                                <Text
                                                    textVariant="P4"
                                                    colorScheme="primary"
                                                    d={['inline-block', 'none']}
                                                >
                                                    Custom plans
                                                </Text>
                                            </Radio>
                                        </Box>
                                    </Box> */}
                                    {selectedPlan === 'prebuilt' &&
                                        (plansLoading ? (
                                            <LoadingWithLogo w="100%" h="58vh" />
                                        ) : (
                                            plansData?.allPlans
                                                ?.filter(plan => plan.name !== 'DAILY_OUTPUT')
                                                ?.filter(plan => plan.interval === frequency)
                                                .map((plan, indexPlan) => (
                                                    <Box
                                                        key={plan.name}
                                                        borderW="1"
                                                        borderStyle="solid"
                                                        borderColor={
                                                            preBuiltPlanName === plan.name &&
                                                            preBuiltPlan.interval === frequency
                                                                ? 'cta'
                                                                : 'outline-gray'
                                                        }
                                                        bg={
                                                            preBuiltPlanName === plan.name &&
                                                            preBuiltPlan.interval === frequency
                                                                ? 'cta'
                                                                : 'white'
                                                        }
                                                        px="16"
                                                        pt="19"
                                                        pb={['24', '4']}
                                                        mb="20"
                                                    >
                                                        <Box
                                                            d="flex"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            mb="20"
                                                        >
                                                            <Box>
                                                                <Text
                                                                    textVariant="H5"
                                                                    colorScheme={
                                                                        preBuiltPlanName === plan.name &&
                                                                        preBuiltPlan.interval === frequency
                                                                            ? 'white'
                                                                            : 'primary'
                                                                    }
                                                                    mb={['20', '6']}
                                                                >
                                                                    {plan.name}
                                                                </Text>
                                                                <Text
                                                                    textVariant="H5"
                                                                    colorScheme={
                                                                        preBuiltPlanName === plan.name &&
                                                                        preBuiltPlan.interval === frequency
                                                                            ? 'white'
                                                                            : 'cta'
                                                                    }
                                                                >
                                                                    {`${usdFormatter.format(
                                                                        calculatePricePerMonth(
                                                                            plan.interval,
                                                                            plan.price
                                                                        )
                                                                    )}/mo`}
                                                                </Text>
                                                            </Box>
                                                            <Box d={['none', 'block']}>
                                                                <Button
                                                                    type="primary"
                                                                    disabled={
                                                                        currentPlan === plan.name && !subscriptionData?.user?.company?.subscription?.endAt &&
                                                                        currentPlanFrequency === frequency && subscriptionData?.user?.company?.subscription?.status !== 'paused'
                                                                    }
                                                                    onClick={() => handleChangePreBuiltPlan(plan.name)}
                                                                    w="152"
                                                                    noColorTransitions
                                                                >
                                                                    {preBuiltPlanName === plan.name &&
                                                                    preBuiltPlan.interval === frequency
                                                                        ? 'Selected'
                                                                        : checkIfCurrentPlan(plan.name)}
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                        <hr />
                                                        {plan?.featuresTitle && (
                                                            <Text
                                                                mt="20"
                                                                textVariant="Button"
                                                                colorScheme={
                                                                    preBuiltPlanName === plan.name &&
                                                                    preBuiltPlan.interval === frequency
                                                                        ? 'white'
                                                                        : 'primary'
                                                                }
                                                            >
                                                                {plan?.featuresTitle}
                                                            </Text>
                                                        )}
                                                        <Box
                                                            d={['block', 'flex']}
                                                            alignItems={['center', 'flex-start']}
                                                            flexWrap="wrap"
                                                            mt="11"
                                                            mx="-6"
                                                            minH={['192', 'auto']}
                                                        >
                                                            {plan?.features.map((feature, indexFeature) =>
                                                                (plan?.tooltips && plan?.tooltips[feature]) ? (
                                                                    <Box key={feature}>
                                                                        <Box
                                                                            d={['block', 'none']}
                                                                            w="183"
                                                                            textVariant="P4"
                                                                            colorScheme={
                                                                                preBuiltPlanName === plan.name &&
                                                                                preBuiltPlan.interval === frequency
                                                                                    ? 'white'
                                                                                    : 'primary'
                                                                            }
                                                                            mb="16"
                                                                            mx="6"
                                                                        >
                                                                            <Box
                                                                                d="inline-block"
                                                                                w="8"
                                                                                h="8"
                                                                                bg="other-yellow"
                                                                                mr="15"
                                                                            />
                                                                            {feature}
                                                                        </Box>
                                                                        <Box
                                                                            d={['none', 'inline-flex']}
                                                                            w="183"
                                                                            textVariant="P4"
                                                                            mb="16"
                                                                            mx="6"
                                                                        >
                                                                            <Box d="inline-block" mr="15" pt="6">
                                                                                <Box w="8" h="8" bg="other-yellow" />
                                                                            </Box>
                                                                            <TooltipIconBlock
                                                                                label={feature}
                                                                                tooltip={plan?.tooltips[feature]}
                                                                                tooltipIconSize="16px"
                                                                                textVariant="P4"
                                                                                colorScheme={
                                                                                    preBuiltPlanName === plan.name &&
                                                                                    preBuiltPlan.interval === frequency
                                                                                        ? 'white'
                                                                                        : 'primary'
                                                                                }
                                                                                tooltipIconColor={
                                                                                    preBuiltPlanName === plan.name &&
                                                                                    preBuiltPlan.interval === frequency
                                                                                        ? 'white'
                                                                                        : 'primary'
                                                                                }
                                                                                isInlineBlock
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                ) : (
                                                                    <Box
                                                                        key={feature}
                                                                        w="183"
                                                                        textVariant="P4"
                                                                        colorScheme={
                                                                            preBuiltPlanName === plan.name &&
                                                                            preBuiltPlan.interval === frequency
                                                                                ? 'white'
                                                                                : 'primary'
                                                                        }
                                                                        mb="16"
                                                                        mx="6"
                                                                        d="flex"
                                                                    >
                                                                        <Box d="inline-block" mr="15" pt="6">
                                                                            <Box w="8" h="8" bg="other-yellow" />
                                                                        </Box>
                                                                        {feature}
                                                                    </Box>
                                                                )
                                                            )}
                                                        </Box>
                                                        <Box d={['block', 'none']}>
                                                            <hr />
                                                        </Box>
                                                        <Box d={['block', 'none']} mt="24">
                                                            <Button
                                                                type="primary"
                                                                disabled={
                                                                    currentPlan === plan.name && !subscriptionData?.user?.company?.subscription?.endAt &&
                                                                    currentPlanFrequency === frequency && subscriptionData?.user?.company?.subscription?.status !== 'paused'
                                                                }
                                                                onClick={() => handleChangePreBuiltPlan(plan.name)}
                                                                w="100%"
                                                            >
                                                                {preBuiltPlanName === plan.name &&
                                                                preBuiltPlan.interval === frequency
                                                                    ? 'Selected'
                                                                    : checkIfCurrentPlan(plan.name)}
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                ))
                                        ))}
                                    {selectedPlan === 'custom' && (
                                        <Box maxW={['100%', '700']}>
                                            <Text textVariant="H6" colorScheme="headline" mb="10">
                                                Choose the number of daily output
                                            </Text>
                                            <Text textVariant="P4" colorScheme="secondary" mb="20">
                                                Adding more daily outputs to your account will increase your daily
                                                capacity. Multiple designers will work on your projects every business
                                                day. To learn more about daily output, visit our{' '}
                                                <Text
                                                    as="a"
                                                    href="https://help.manypixels.co/daily-output-delivery-time"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    fontWeight="400"
                                                >
                                                    Help Center
                                                </Text>
                                                .
                                            </Text>
                                            <DailyOutputSlider
                                                value={customPlan?.dailyOutput}
                                                onChange={handleChangeDailyOutput}
                                            />
                                        </Box>
                                    )}
                                    <Box d={['block', 'none']} mb="20" mt="42">
                                        <hr />
                                    </Box>
                                </Box>
                                <UpdatedPlanSummary
                                    selectedPlan={selectedPlan}
                                    preBuiltPlan={preBuiltPlan}
                                    subscription={subscription}
                                    customPlan={customPlan}
                                    frequency={frequency}
                                    priceUnit={handlePriceUnit(
                                        selectedPlan === 'prebuilt' ? preBuiltPlan?.interval : customPlan?.interval
                                    )}
                                />
                            </Box>
                        </Box>
                    </Box>
                </PageContainer>
            </Basepage>
        </DocumentTitle>
    );
});

export default withResponsive(withLoggedUser(EditPlan));
