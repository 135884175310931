import gql from 'graphql-tag';

export const SUBSCRIPTION = gql`
  query CustomerSubscription($id: ID) {
    CustomerSubscription(id: $id) {
      id
      status
      billingSource
      endAt
      cancellationPromoUsed
      plan {
        id
        name
        interval
        dailyOutput
      }
      stripeData {
        id
        nextBillingDate
      }
      upcomingInvoice {
        amountDue
        items
        total
        subtotal
      }
      user {
        id
        firstname
        lastname
        email
      }
    }
  }
`;

export const COMPANY_SUBSCRIPTIONS = gql`
  query CompanySubscriptions($companyIds: [ID!]) {
    allCustomerSubscriptions(where: { company: { id_in: $companyIds } }) {
      id
      status
      cancelSubscriptionFeedback {
        id
        reason
        feedback
        createdAt
        updatedAt
        additionalReason
      }
    }
  }
`;

export const SUBSCRIPTION_INVOICES = gql`
  query InvoiceHistory(
    $limit: Int
    $startingAfter: String
    $endingBefore: String
    $subscriptionId: ID
  ) {
    getInvoices(
      limit: $limit
      startingAfter: $startingAfter
      endingBefore: $endingBefore
      subscriptionId: $subscriptionId
    ) {
      data {
        id
        key: id
        amountDue
        periodStart
        periodEnd
        billNumber
        subtotal
        total
        paid
        description
        pdf
        brand
        status
      }
      hasMore
    }
  }
`;
