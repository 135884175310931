import React from 'react';

const IconProfile = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#iconProfile)">
                <path
                    d="M17.0698 20.0999H3.17979C3.00739 20.0999 2.84206 20.0315 2.72017 19.9096C2.59827 19.7877 2.52979 19.6223 2.52979 19.4499V12.2199C2.53237 12.0484 2.60168 11.8845 2.72302 11.7632C2.84436 11.6418 3.0082 11.5725 3.17979 11.5699H17.0698C17.2414 11.5725 17.4052 11.6418 17.5266 11.7632C17.6479 11.8845 17.7172 12.0484 17.7198 12.2199V19.4499C17.7198 19.6223 17.6513 19.7877 17.5294 19.9096C17.4075 20.0315 17.2422 20.0999 17.0698 20.0999ZM3.82979 18.7999H16.4198V12.8699H3.82979V18.7999Z"
                    fill="currentColor"
                />
                <path
                    d="M9.99984 11.0001C8.97886 11.0001 7.98083 10.6972 7.13206 10.1297C6.28328 9.56231 5.62192 8.75584 5.23167 7.81239C4.84142 6.86894 4.73982 5.83092 4.93973 4.82971C5.13964 3.82849 5.63208 2.90909 6.35472 2.18785C7.07736 1.46661 7.99771 0.975957 8.99931 0.777988C10.0009 0.580019 11.0387 0.683632 11.9814 1.07571C12.9241 1.4678 13.7293 2.13072 14.2951 2.98059C14.8609 3.83047 15.1618 4.82908 15.1598 5.85006C15.1572 7.21684 14.6124 8.52674 13.645 9.49226C12.6776 10.4578 11.3666 11.0001 9.99984 11.0001ZM9.99984 2.00006C9.2364 2.00006 8.49011 2.22644 7.85533 2.65058C7.22056 3.07473 6.72582 3.67758 6.43366 4.3829C6.14151 5.08822 6.06507 5.86434 6.21401 6.6131C6.36294 7.36187 6.73057 8.04966 7.2704 8.58949C7.81023 9.12932 8.49802 9.49695 9.24679 9.64589C9.99555 9.79483 10.7717 9.71839 11.477 9.42623C12.1823 9.13408 12.7852 8.63933 13.2093 8.00456C13.6335 7.36978 13.8598 6.62349 13.8598 5.86006C13.8598 4.83632 13.4532 3.85451 12.7293 3.13062C12.0054 2.40673 11.0236 2.00006 9.99984 2.00006Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="iconProfile">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconProfile;
