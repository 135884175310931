import React from 'react';

const IconFileIndd = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.32 19.95H2.68V2.83999L6.09 0.0499878H17.32V19.95Z" fill="#391825" />
            <path d="M2.68 2.83999H5.9V0.0499878L2.68 2.83999Z" fill="#FD458D" />
            <path d="M7.12 8.42001H8.26V13.58H7.12V8.42001Z" fill="#FD458D" />
            <path
                d="M12.88 13.58H11.88V13.12L11.7 13.31L11.46 13.49L11.14 13.62C11.0261 13.6521 10.9084 13.6689 10.79 13.67C10.555 13.6741 10.3215 13.6311 10.1034 13.5435C9.88527 13.4559 9.6869 13.3255 9.52 13.16C9.36768 12.9906 9.24891 12.7937 9.17 12.58C9.07107 12.345 9.01355 12.0946 9 11.84C8.99581 11.5988 9.03301 11.3586 9.11 11.13C9.17592 10.9108 9.28469 10.7069 9.43 10.53C9.56618 10.3534 9.74052 10.2098 9.94 10.11C10.149 9.99881 10.3833 9.94368 10.62 9.95001C10.8348 9.94982 11.048 9.98705 11.25 10.06C11.4467 10.1262 11.618 10.2521 11.74 10.42V8.07001H12.84L12.88 13.58ZM11.88 11.81C11.8884 11.572 11.8062 11.3397 11.65 11.16C11.4696 11.0077 11.2411 10.9242 11.005 10.9242C10.7689 10.9242 10.5404 11.0077 10.36 11.16C10.2051 11.3411 10.1199 11.5717 10.1199 11.81C10.1199 12.0484 10.2051 12.2789 10.36 12.46C10.5404 12.6123 10.7689 12.6958 11.005 12.6958C11.2411 12.6958 11.4696 12.6123 11.65 12.46C11.8026 12.2788 11.8812 12.0467 11.87 11.81H11.88Z"
                fill="#FD458D"
            />
        </svg>
    );
};

export default IconFileIndd;
