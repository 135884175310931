import React, { useState, useCallback, memo } from 'react';
import { Box } from '@components/Box';
import MembersList from './MembersList';
import MembersFilter from './MembersFilter';

import debounce from 'lodash/debounce';
// TODO add QC once added to production
const roles = ['teamLeaders', 'designers'];

const MembersTab = memo(({ team, isAddVisible, onAddClose, isEditVisible, onEditClose }) => {
    const onChangeFilters = useCallback(
        debounce(filterValues => setFilters(filterValues), 500),
        []
    );

    const [filters, setFilters] = useState();
    return (
        <Box py="30">
            <MembersFilter onChangeFilters={onChangeFilters} />
            <MembersList
                team={team}
                isAddVisible={isAddVisible}
                isEditVisible={isEditVisible}
                onAddClose={onAddClose}
                onEditClose={onEditClose}
                roles={roles}
                filters={filters}
            />
        </Box>
    );
});

export default MembersTab;
