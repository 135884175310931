import React from 'react';

const IconRateNeutral = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.9999 21.912C8.84171 21.912 6.73198 21.272 4.93751 20.073C3.14304 18.874 1.74443 17.1698 0.918522 15.1759C0.0926187 13.182 -0.123475 10.9879 0.297567 8.87119C0.718608 6.75447 1.75788 4.81014 3.28395 3.28407C4.81002 1.758 6.75435 0.718731 8.87107 0.297689C10.9878 -0.123353 13.1818 0.0927407 15.1757 0.918644C17.1696 1.74455 18.8739 3.14317 20.0729 4.93764C21.2719 6.7321 21.9119 8.84183 21.9119 11C21.909 13.8932 20.7584 16.667 18.7126 18.7128C16.6669 20.7585 13.893 21.9091 10.9999 21.912ZM10.9999 1.51802C9.12453 1.51802 7.29129 2.07413 5.73198 3.11602C4.17267 4.15792 2.95734 5.6388 2.23967 7.37141C1.522 9.10402 1.33422 11.0105 1.70009 12.8499C2.06595 14.6892 2.96903 16.3787 4.29511 17.7048C5.62119 19.0309 7.31072 19.934 9.15005 20.2998C10.9894 20.6657 12.8959 20.4779 14.6285 19.7602C16.3611 19.0426 17.842 17.8272 18.8839 16.2679C19.9258 14.7086 20.4819 12.8754 20.4819 11C20.479 8.48613 19.4791 6.07604 17.7015 4.29845C15.9239 2.52086 13.5138 1.52093 10.9999 1.51802Z"
                fill="currentColor"
            />
            <path
                d="M13.2001 15.73H8.66813C8.4785 15.73 8.29663 15.6547 8.16254 15.5206C8.02846 15.3865 7.95312 15.2047 7.95312 15.015C7.95312 14.8254 8.02846 14.6436 8.16254 14.5095C8.29663 14.3754 8.4785 14.3 8.66813 14.3H13.2001C13.3898 14.3 13.5716 14.3754 13.7057 14.5095C13.8398 14.6436 13.9151 14.8254 13.9151 15.015C13.9151 15.2047 13.8398 15.3865 13.7057 15.5206C13.5716 15.6547 13.3898 15.73 13.2001 15.73Z"
                fill="currentColor"
            />
            <path
                d="M6.86405 9.74605C7.42296 9.74605 7.87605 9.29296 7.87605 8.73405C7.87605 8.17513 7.42296 7.72205 6.86405 7.72205C6.30514 7.72205 5.85205 8.17513 5.85205 8.73405C5.85205 9.29296 6.30514 9.74605 6.86405 9.74605Z"
                fill="currentColor"
            />
            <path
                d="M15.136 9.74605C15.6949 9.74605 16.148 9.29296 16.148 8.73405C16.148 8.17513 15.6949 7.72205 15.136 7.72205C14.5771 7.72205 14.124 8.17513 14.124 8.73405C14.124 9.29296 14.5771 9.74605 15.136 9.74605Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconRateNeutral;
