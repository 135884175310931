import React from 'react';

const IconDeliverablePsd = () => {
    return (
        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57.1557 65.835H8.84375V9.37204L20.0968 0.165039H57.1557V65.835Z" fill="#022732" />
            <path d="M8.84375 9.37204H19.4698V0.165039L8.84375 9.37204Z" fill="#25D2FC" />
            <path
                d="M23.7265 30.1949H29.0065C29.7044 30.1923 30.4007 30.2586 31.0855 30.3929C31.6979 30.5075 32.2813 30.7431 32.8015 31.0859C33.2957 31.4309 33.6933 31.8967 33.9565 32.4389C34.2667 33.11 34.4137 33.8451 34.3855 34.5839C34.4103 35.3191 34.2752 36.051 33.9895 36.7289C33.7495 37.2755 33.3749 37.7524 32.9005 38.1149C32.3974 38.4735 31.8237 38.721 31.2175 38.8409C30.5332 38.9786 29.8365 39.0449 29.1385 39.0389H26.8615V44.7149H23.5615L23.7265 30.1949ZM27.0265 36.3329H29.1055L29.6995 36.2999C29.9458 36.2413 30.1802 36.1408 30.3925 36.0029C30.6046 35.8779 30.7764 35.6946 30.8875 35.4749C31.03 35.2121 31.0985 34.9156 31.0855 34.6169C31.0928 34.2996 31.0005 33.988 30.8215 33.7259C30.6516 33.5081 30.4381 33.3282 30.1945 33.1979C29.9285 33.099 29.6515 33.0325 29.3695 32.9999H26.8615L27.0265 36.3329Z"
                fill="#24D2FD"
            />
            <path
                d="M41.5142 37.6859C41.3111 37.415 41.0467 37.196 40.7427 37.0469C40.4387 36.8977 40.1037 36.8227 39.7652 36.8279C39.4998 36.8306 39.2391 36.8986 39.0062 37.0259C38.8911 37.0836 38.7955 37.1737 38.731 37.2851C38.6665 37.3965 38.636 37.5243 38.6432 37.6529C38.643 37.7759 38.681 37.8959 38.7519 37.9964C38.8228 38.0969 38.9232 38.1729 39.0392 38.2139L39.9632 38.5109L41.1842 38.8079C41.6099 38.9069 42.0205 39.0623 42.4052 39.2699C42.7839 39.4955 43.1101 39.7992 43.3622 40.1609C43.6179 40.6249 43.7433 41.1494 43.7252 41.6789C43.7532 42.2345 43.6153 42.7858 43.3292 43.2629C43.0586 43.6789 42.6976 44.0286 42.2732 44.2859C41.8254 44.549 41.3333 44.7279 40.8212 44.8139C40.2994 44.9177 39.7691 44.9729 39.2372 44.9789C38.5337 44.9775 37.8339 44.8776 37.1582 44.6819C36.4896 44.4964 35.8847 44.1311 35.4092 43.6259L37.2242 41.5469C37.4728 41.8732 37.7877 42.1431 38.1482 42.3389C38.5229 42.5413 38.9434 42.6436 39.3692 42.6359C39.6844 42.6351 39.9971 42.5793 40.2932 42.4709C40.4181 42.4407 40.5289 42.3685 40.607 42.2663C40.6851 42.1642 40.7258 42.0384 40.7222 41.9099C40.723 41.7869 40.6895 41.6661 40.6254 41.5611C40.5612 41.4562 40.469 41.3712 40.3592 41.3159C40.0533 41.1709 39.7324 41.0602 39.4022 40.9859L38.1812 40.6889C37.7568 40.601 37.3462 40.4568 36.9602 40.2599C36.5815 40.0342 36.2553 39.7305 36.0032 39.3689C35.7443 38.9061 35.6187 38.3807 35.6402 37.8509C35.6381 37.3231 35.7625 36.8026 36.0032 36.3329C36.2201 35.9195 36.538 35.5676 36.9272 35.3099C37.3181 35.0103 37.7679 34.7966 38.2472 34.6829C38.7314 34.5523 39.2307 34.4857 39.7322 34.4849C40.3924 34.4859 41.0487 34.586 41.6792 34.7819C42.3224 34.9644 42.904 35.3179 43.3622 35.8049L41.5142 37.6859Z"
                fill="#24D2FD"
            />
        </svg>
    );
};

export default IconDeliverablePsd;
