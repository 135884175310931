import gql from 'graphql-tag';

export const CATEGORY = gql`
    query Category($id: ID!) {
        Category(id: $id) {
            id
            title
            slug
            description
            createdAt
            icon {
                id
                url
            }
            services {
                id
                name
            }
        }
    }
`;

export const CATEGORY_BY_SLUG = gql`
    query CategoryBySlug($slug: String!) {
        Category(slug: $slug) {
            id
            title
            slug
            description
            createdAt
            icon {
                id
            }
            services(where: { isActivated: true }) {
                id
                name
            }
            images {
                id
                url
                name
            }
        }
    }
`;

export const ALL_CATEGORIES = gql`
    query AllCategories($where: CategoryWhereInput) {
        allCategories(where: $where, orderBy: createdAt_DESC) {
            id
            title
            slug
            description
            createdAt
            icon {
                id
                secret
                url
            }
            services(where: { isActivated: true }) {
                id
                name
                icon {
                    id
                    secret
                    url
                }
                deliverables
            }
            images {
                id
                url
            }
        }
    }
`;

export const ALL_CATEGORIES_CUSTOMER = gql`
    query ALL_CATEGORIES($userId: ID!) {
        allCategories(where: { plans_some: { customerSubscriptions_some: { user: { id: $userId } } } }) {
            slug
        }
    }
`;

export const ALL_CATEGORIES_SEARCH = gql`
    query {
        allCategories {
            id
            slug
            title
        }
    }
`;
