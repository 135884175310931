import React from 'react';

const IconRateBad = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.9999 21.912C8.84171 21.912 6.73198 21.272 4.93751 20.073C3.14304 18.874 1.74443 17.1698 0.918522 15.1759C0.0926187 13.182 -0.123475 10.9879 0.297567 8.87119C0.718608 6.75447 1.75788 4.81014 3.28395 3.28407C4.81002 1.758 6.75435 0.718731 8.87107 0.297689C10.9878 -0.123353 13.1818 0.0927407 15.1757 0.918644C17.1696 1.74455 18.8739 3.14317 20.0729 4.93764C21.2719 6.7321 21.9119 8.84183 21.9119 11C21.909 13.8932 20.7584 16.667 18.7126 18.7128C16.6669 20.7585 13.893 21.9091 10.9999 21.912ZM10.9999 1.51802C9.12453 1.51802 7.29129 2.07413 5.73198 3.11602C4.17267 4.15792 2.95734 5.6388 2.23967 7.37141C1.522 9.10402 1.33422 11.0105 1.70009 12.8499C2.06595 14.6892 2.96903 16.3787 4.29511 17.7048C5.62119 19.0309 7.31072 19.934 9.15005 20.2998C10.9894 20.6657 12.8959 20.4779 14.6285 19.7602C16.3611 19.0426 17.842 17.8272 18.8839 16.2679C19.9258 14.7086 20.4819 12.8754 20.4819 11C20.479 8.48613 19.4791 6.07604 17.7015 4.29845C15.9239 2.52086 13.5138 1.52093 10.9999 1.51802Z"
                fill="currentColor"
            />
            <path
                d="M13.2001 15.73C13.0673 15.7311 12.9371 15.6928 12.8261 15.62C12.2942 15.229 11.6541 15.0128 10.9941 15.0009C10.3341 14.9891 9.68666 15.1823 9.14109 15.554C9.0697 15.6165 8.98642 15.6639 8.89624 15.6934C8.80607 15.7229 8.71086 15.7339 8.61633 15.7257C8.52181 15.7174 8.42992 15.6902 8.34619 15.6456C8.26246 15.6009 8.18861 15.5398 8.12909 15.466C8.00914 15.3211 7.95112 15.1348 7.96759 14.9474C7.98406 14.76 8.0737 14.5867 8.21709 14.465C8.99114 13.8956 9.92413 13.583 10.885 13.5711C11.8458 13.5593 12.7862 13.8488 13.5741 14.399C13.7334 14.499 13.8477 14.657 13.8928 14.8396C13.938 15.0222 13.9104 15.2152 13.8161 15.378C13.7529 15.4852 13.6629 15.574 13.5548 15.6357C13.4468 15.6975 13.3245 15.73 13.2001 15.73Z"
                fill="currentColor"
            />
            <path
                d="M6.59977 11.088C6.27683 11.0924 5.95626 11.0324 5.65677 10.9115C5.35728 10.7906 5.08489 10.6113 4.85549 10.3839C4.6261 10.1566 4.4443 9.8858 4.32073 9.58741C4.19715 9.28902 4.13428 8.96899 4.13577 8.64603C4.13577 8.4564 4.2111 8.27454 4.34519 8.14045C4.47928 8.00636 4.66114 7.93103 4.85077 7.93103C5.0404 7.93103 5.22226 8.00636 5.35635 8.14045C5.49044 8.27454 5.56577 8.4564 5.56577 8.64603C5.56577 8.91443 5.67239 9.17184 5.86218 9.36162C6.05196 9.55141 6.30937 9.65803 6.57777 9.65803C6.84617 9.65803 7.10357 9.55141 7.29336 9.36162C7.48315 9.17184 7.58977 8.91443 7.58977 8.64603C7.58977 8.4564 7.6651 8.27454 7.79919 8.14045C7.93327 8.00636 8.11514 7.93103 8.30477 7.93103C8.4944 7.93103 8.67626 8.00636 8.81035 8.14045C8.94444 8.27454 9.01977 8.4564 9.01977 8.64603C9.01979 9.2899 8.76553 9.90772 8.31232 10.3651C7.8591 10.8224 7.24361 11.0822 6.59977 11.088Z"
                fill="currentColor"
            />
            <path
                d="M14.9161 11.088C14.2685 11.088 13.6473 10.8307 13.1894 10.3728C12.7314 9.91482 12.4741 9.29369 12.4741 8.64603C12.4741 8.4564 12.5495 8.27454 12.6835 8.14045C12.8176 8.00636 12.9995 7.93103 13.1891 7.93103C13.3788 7.93103 13.5606 8.00636 13.6947 8.14045C13.8288 8.27454 13.9041 8.4564 13.9041 8.64603C13.9303 8.89765 14.0488 9.13063 14.2367 9.29999C14.4246 9.46936 14.6686 9.56309 14.9216 9.56309C15.1746 9.56309 15.4186 9.46936 15.6065 9.29999C15.7944 9.13063 15.9129 8.89765 15.9391 8.64603C15.9391 8.4564 16.0145 8.27454 16.1485 8.14045C16.2826 8.00636 16.4645 7.93103 16.6541 7.93103C16.8438 7.93103 17.0256 8.00636 17.1597 8.14045C17.2938 8.27454 17.3691 8.4564 17.3691 8.64603C17.3691 8.96765 17.3056 9.28609 17.1822 9.58309C17.0588 9.88009 16.8779 10.1498 16.65 10.3767C16.4221 10.6036 16.1516 10.7832 15.854 10.9053C15.5565 11.0274 15.2377 11.0895 14.9161 11.088Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconRateBad;
