import React, { useMemo } from 'react';
import { Box } from '@components/Box';
import { Form } from '@components/Form';
import debounce from 'lodash/debounce';
import SearchInput from '@components/Input/SearchInput';
import PlanField from './PlanField';
import TeamField from './TeamField';
import StatusField from './StatusField';

const CustomersByAccountsFilter = ({ form, onChangeFilters }) => {
    const { getFieldDecorator, setFieldsValue } = form;

    const debouncedOnChangeFilters = useMemo(() => debounce(onChangeFilters, 1000), [onChangeFilters]);

    return (
        <Box>
            <Form>
                <Box d="flex" alignItems="center" mx="-10">
                    <Box maxW="402" flex="1" mx="10" mb="-10">
                        <Form.Item label="" colon={false} required={false}>
                            {getFieldDecorator('keyword', {
                                initialValue: '',
                            })(
                                <SearchInput
                                    onChangeText={value => {
                                        debouncedOnChangeFilters('keyword', value);
                                    }}
                                    onClear={() => {
                                        setFieldsValue({ keyword: '' });
                                        onChangeFilters('keyword', '');
                                    }}
                                    placeholder="Search..."
                                />
                            )}
                        </Form.Item>
                    </Box>
                    <Box maxW="246" flex="1" mx="10" mb="-10">
                        <Form.Item label="" colon={false} required={false}>
                            {getFieldDecorator('plan', {
                                initialValue: 'ALL',
                            })(<PlanField onFieldChange={onChangeFilters} />)}
                        </Form.Item>
                    </Box>
                    <Box maxW="246" flex="1" mx="10" mb="-10">
                        <Form.Item label="" colon={false} required={false}>
                            {getFieldDecorator('team', {
                                initialValue: 'ALL',
                            })(<TeamField onFieldChange={onChangeFilters} />)}
                        </Form.Item>
                    </Box>
                    <Box maxW="246" flex="1" mx="10" mb="-10">
                        <Form.Item label="" colon={false} required={false}>
                            {getFieldDecorator('status', {
                                initialValue: 'ALL',
                            })(<StatusField onFieldChange={onChangeFilters} />)}
                        </Form.Item>
                    </Box>
                </Box>
            </Form>
        </Box>
    );
};

export default Form.create()(CustomersByAccountsFilter);
