export const redError = '#f94763';
export const greenSuccess = '#34CA78';
export const yellowWarning = '#edc800';

export const blue = '#0050b3';
export const blue2 = '#1a7fc3';
export const blue3 = '#34c2eb';
export const blueMeanGradiant = '#1BA9DA';
export const darkblue = '#0A0E33';
export const green = '#34CA78';
export const purple = '#AC67D5';

export const primaryVeryLight = '#4D68C2';
export const primaryLight = '#2B4CBA';
export const primary = '#1034A6';
export const primaryDark = '#0B2784';
export const primaryVeryDark = '#071D67';

export const gray = '#999';

export const black = '#333333';
export const black2 = '#3D3D3D';

export const mpGradiant = 'linear-gradient(to bottom left, #00BCE8, #0089CA)';

// new theme
export const primaryColor = '#009DFF';

// text
export const textPrimary = '#07122B';
export const textTertiary = '#BEC6C6';
export const textSecondary = '#64769C';
export const sementicRed = '#FF3041';

export const skeletonGradient = 'linear-gradient(90deg, #FAFAFA 0%, #EEEEEE 66.67%, #F8F8F8 100%, #FFFFFF 100%);';
