import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
  Fragment,
} from 'react';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Skeleton } from '@components/Skeleton';
import message from '@components/Message';
import { ItemMessageDetail } from './ItemMessageDetail';
import { MessageNewBorder } from './MessageNewBorder';
import FormInputMessage from './FormInputMessage';
import { useDetailContext } from './DetailContext';
import { useMutation, useQuery } from 'react-apollo';
import { MessageEditingContext } from './MessageEditingContext';
import {
  ORDER_STATUS_ON_HOLD,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_DRAFT,
} from '@constants/order';
import withLoggedUser from '@components/WithLoggedUser';
import { Icon, Spin } from 'antd';
import { unreadCheck } from '../../utils/unreadCheck';
import { ORDER_MESSAGES } from '@graphql/queries/order';
import {
  READ_ORDER_MESSAGES,
  UPDATE_ORDER_OWNERS,
} from '@graphql/mutations/order';
import { MessageTypeDefault } from './MessageTypeDefault';

const initialEditingValues = {
  id: undefined,
  content: undefined,
};

const SHOWN_ITEM = 20;

const skeleton = (
  <Box d="flex" mb="16">
    <Skeleton variant="avatar" w="32" h="32" mr="16" />
    <Box flex="1">
      <Box d="flex" alignItems="center" justifyContent="space-between" mb="6">
        <Skeleton w="92" h="20" />
        <Skeleton w="74" h="20" />
      </Box>
      <Skeleton w="100%" h="40" />
    </Box>
  </Box>
);

export const TabMessage = withLoggedUser(
  ({
    viewer,
    socket,
    sendMessage,
    messages,
    setMessages,
    joinRoom,
    deleteMessage,
    updateMessage,
    leaveRoom,
    usersOnline,
    isTyping,
    setSending,

    typingMessage,
    userTyping,
    sending,
  }) => {
    const {
      request,
      isSubscriptionPaused,
      refetchRequests,
    } = useDetailContext();
    const [editingValues, setEditingValues] = useState(initialEditingValues);
    const roomToJoin = useMemo(() => `discussion/${request.discussion.id}`, [
      request,
    ]);
    const messagesContainerRef = useRef();
    const messagesEndRef = useRef();
    const [readOrderMessages] = useMutation(READ_ORDER_MESSAGES);
    const [updateOrderOwners] = useMutation(UPDATE_ORDER_OWNERS);

    const scrollToBottom = () => {
      const scrollY = messagesContainerRef.current.scrollHeight;
      messagesContainerRef.current.scrollTo({
        top: scrollY,
        behavior: 'smooth',
      });
    };
    const [page, setPage] = useState(1);

    const pageChange = useRef(false);
    const isFirstLoad = useRef(false);
    const prevContainerHeight = useRef(0);

    const { loading, data = {} } = useQuery(ORDER_MESSAGES, {
      variables: {
        id: request.id,
        last: SHOWN_ITEM,
        skip: (page - 1) * SHOWN_ITEM,
      },
      fetchPolicy: 'network-only',
    });

    useEffect(() => {
      const handleMessageSent = async data => {
        if (!data.isNote) {
          setSending(false);
          pageChange.current = false;
          setMessages(msg => [...msg, data]);
          await refetchRequests();
        }
      };

      const handleMessageDeleted = data => {
        setMessages(msgs =>
          msgs.filter(message => message.id !== data.messageId)
        );
      };

      const handleMessageUpdated = data =>
        setMessages(msgs =>
          msgs.map(msg => {
            return msg.id === data.id ? data : msg;
          })
        );

      if (socket) {
        socket.on('messageUpdated', handleMessageUpdated);
        socket.on('messageSent', handleMessageSent);
        socket.on('messageDeleted', handleMessageDeleted);
      }
      return () => {
        if (socket) {
          socket.off('messageSent', handleMessageSent);
          socket.off('messageDeleted', handleMessageDeleted);
          socket.off('messageUpdated', handleMessageUpdated);
        }
      };
    }, [socket, setMessages, setSending, refetchRequests]);

    useEffect(() => {
      const handleClickWindow = async () => {
        try {
          if (request?.unreadMessages > 0 && request?.id) {
            await readOrderMessages({
              variables: {
                id: request?.id,
              },
            });
            setMessages(oldMessages =>
              oldMessages.map(msg => {
                return {
                  ...msg,
                  readBy: Array.isArray(msg.readBy)
                    ? [...msg.readBy, { id: viewer.id }]
                    : [{ id: viewer.id }],
                };
              })
            );
            await refetchRequests();
          }
        } catch (err) {
          console.log(err);
        }
      };

      window.addEventListener('click', handleClickWindow);
      return () => {
        window.removeEventListener('click', handleClickWindow);
      };
    }, [setMessages, viewer, refetchRequests, readOrderMessages, request]);

    useEffect(() => {
      setMessages(old => [
        ...(data?.Order?.discussion?.messages ?? []),
        ...old,
      ]);
      // eslint-disable-next-line
    }, [data?.Order?.discussion?.messages]);

    useEffect(() => {
      joinRoom(roomToJoin, viewer.id);
      const interval = setInterval(() => {
        if (socket.disconnected) {
          socket.connect();
          joinRoom(roomToJoin, viewer.id);
        }
      }, 3000);
      return () => {
        clearInterval(interval);
        leaveRoom(roomToJoin, viewer.id);
      };
    }, [joinRoom, roomToJoin, viewer.id, leaveRoom, socket]);

    useEffect(() => {
      if (messages.length > 0) {
        if (!isFirstLoad.current) {
          isFirstLoad.current = true;
        }
        if (pageChange.current) {
          pageChange.current = false;
          const newHeight = messagesContainerRef.current.scrollHeight;
          const scrollY =
            newHeight -
            prevContainerHeight.current -
            messagesContainerRef.current.clientHeight;
          messagesContainerRef.current.scrollTo(0, scrollY);
        } else {
          scrollToBottom();
        }
        prevContainerHeight.current = messagesContainerRef.current.scrollHeight;
      }
    }, [messages.length]);

    const handleSubmit = useCallback(
      async (content, fileIds, mentionedIds) => {
        pageChange.current = false;
        // typingMessage(roomToJoin);

        // For mobile
        if (editingValues.id) {
          updateMessage({
            room: roomToJoin,
            messageId: editingValues.id,
            text: content,
            fileIds,
            orderId: request.id,
            mentionedIds,
          });
          setEditingValues(initialEditingValues);
        } else {
          sendMessage({
            room: roomToJoin,
            message: content,
            discussionId: request.discussion?.id,
            userId: viewer?.id,
            fileIds,
            orderId: request.id,
            mentionedIds,
          });

          const currentOwners = request.owners?.map(({ id }) => id) ?? [];
          if (currentOwners.indexOf(viewer.id) < 0) {
            const newOwnerIds = [...currentOwners, viewer.id];
            await updateOrderOwners({
              variables: {
                orderId: request.id,
                ownerIds: newOwnerIds,
                ownersToDisconnectIds: [],
              },
            });
            await refetchRequests();
            message.destroy();
            message.success(
              'Your messages has been sent. You are now the owner of this request.'
            );
          }
        }
      },
      [
        request,
        sendMessage,
        roomToJoin,
        viewer.id,
        editingValues,
        updateMessage,
        refetchRequests,
        updateOrderOwners,
      ]
    );

    const handleScroll = useCallback(
      ev => {
        if (
          messagesContainerRef.current.scrollTop === 0 &&
          !pageChange.current &&
          !loading
        ) {
          pageChange.current = true;
          setPage(old => old + 1);
        }
      },
      [loading]
    );

    const renderedMessage = useMemo(() => {
      if (Array.isArray(messages)) {
        let alreadyRenderUnread = false;
        const result = [];
        for (let i = 0; i < messages.length; i++) {
          const msg = messages[i];

          const isUnread =
            msg.actionType !== 'SUBMITTED_BOT'
              ? unreadCheck(msg, viewer)
              : false;
          result.push(
            <Fragment key={msg.id}>
              {isUnread && !alreadyRenderUnread && <MessageNewBorder />}
              <ItemMessageDetail
                isAction={msg.isAction}
                isNote={msg.isNote}
                message={msg}
                usersOnline={usersOnline}
                deleteMessage={deleteMessage}
                updateMessage={updateMessage}
              />
            </Fragment>
          );

          if (isUnread) {
            alreadyRenderUnread = true;
          }
        }
        return result;
      }
      return null;
    }, [messages, deleteMessage, updateMessage, viewer, usersOnline]);

    // const handleChange = useCallback(
    //     editorState => {
    //         if (editorState.getCurrentContent().hasText()) {
    //             typingMessage(roomToJoin, `${viewer.firstname} ${viewer.lastname}`);
    //         }
    //     },
    //     [viewer.firstname, viewer.lastname, typingMessage, roomToJoin]
    // );

    const isComplete = request.status === ORDER_STATUS_COMPLETED;
    const isDraft = request.status === ORDER_STATUS_DRAFT;
    const isPaused = request.status === ORDER_STATUS_ON_HOLD;

    let content = (
      <FormInputMessage
        sending={sending}
        editingValues={editingValues}
        onSubmit={handleSubmit}
        onCloseEdit={() => setEditingValues(initialEditingValues)}
      />
    );
    if (!editingValues.id) {
      if (isSubscriptionPaused) {
        content = (
          <Text textVariant="P4" fontWeight="300">
            Your subscription is currently on pause. Please resume your
            subscription before requesting revisions.
          </Text>
        );
      } else if (isComplete) {
        content = (
          <Text textVariant="P4" p={['8', '0']} fontWeight="300">
            Your request has been marked as complete. Please re-open request
            before sending a new message.
          </Text>
        );
      } else if (isDraft) {
        content = (
          <Text textVariant="P4" p={['8', '0']} fontWeight="300">
            You must submit your request before you can send a message.
          </Text>
        );
      } else if (isPaused) {
        content = (
          <Text textVariant="P4" p={['8', '0']} fontWeight="300">
            You must resume your request before you can send a new message.
          </Text>
        );
      }
    }

    return (
      <MessageEditingContext.Provider
        value={{ editingValues, setEditingValues }}
      >
        <Box
          d="flex"
          flexDir="column"
          maxH={['calc(100vh - 289px)', 'calc(100vh - 244px)']}
          h="100%"
        >
          <Box
            ref={messagesContainerRef}
            overflowY="auto"
            overflowX="hidden"
            pt="8"
            flex="1 1 100vh"
            d="flex"
            flexDir="column"
            onScroll={handleScroll}
          >
            {loading && !isFirstLoad.current && (
              <Box px="16" py="8">
                {skeleton}
                {skeleton}
                {skeleton}
                {skeleton}
              </Box>
            )}
            {loading && isFirstLoad.current && (
              <Box
                d="flex"
                flexDir="row"
                alignItems="center"
                justifyContent="center"
                pb="8"
              >
                <Spin
                  indicator={
                    <Icon
                      type="loading"
                      style={{ fontSize: 18, marginRight: 17 }}
                      spin
                    />
                  }
                />
                <Text colorScheme="cta" textVariant="Badges">
                  Load more
                </Text>
              </Box>
            )}
            {renderedMessage}
            {sending && (
              <MessageTypeDefault
                text={'...'}
                user={viewer}
                usersOnline={usersOnline}
              />
            )}
            <Box ref={messagesEndRef} />
          </Box>
          <Box
            px={['10', '20']}
            py={['8', '14']}
            borderT={isComplete || isDraft || isPaused ? '1' : '0'}
            borderTopStyle="solid"
            borderTopColor="outline-gray"
          >
            {isTyping && (
              <Box mb="2">
                <Text textVariant="P5" colorScheme="secondary">
                  {userTyping} is typing...
                </Text>
              </Box>
            )}
            {content}
          </Box>
        </Box>
      </MessageEditingContext.Provider>
    );
  }
);
