import React, { memo, useCallback, useState } from 'react';
import { Form } from '@components/Form';
import { useMutation } from 'react-apollo';
import { Text } from '@components/Text';
import { Box } from '@components/Box';
import { InputPassword } from '@components/Input';
import { FormItemFlex } from '@components/FormItemFlex';
import { Button } from '@components/Button';
import message from '@components/Message';
import { UPDATE_USER_PASSWORD } from '@graphql/mutations/user';

const ChangePasswordForm = memo(({ user, form }) => {
    const { getFieldDecorator, validateFields, getFieldValue, resetFields } = form;
    const { id: userId } = user || {};
    const [isLoading, setIsLoading] = useState(false);
    const [confirmDirty, setConfirmDirty] = useState(false);
    const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD);

    const handleSubmitChangePassword = useCallback(
        e => {
            e.preventDefault();
            validateFields(async (err, values) => {
                if (!err && !isLoading) {
                    message.destroy();
                    message.loading('Updating password...', 50000);
                    setIsLoading(true);

                    try {
                        await updateUserPassword({
                            variables: {
                                userId,
                                currentpassword: values.currentpassword,
                                newpassword: values.newpassword,
                            },
                        });
                        message.destroy();
                        message.success('Password has been updated');
                        resetFields();
                        setIsLoading(false);
                        return true;
                    } catch (err) {
                        setIsLoading(false);
                        console.log(err);
                        message.destroy();
                        const errors = err.graphQLErrors || [];
                        const formErrorMessage = errors.length > 0 ? errors[0].message : 'Error on updating password';
                        if (formErrorMessage === 'Invalid password!') {
                            message.error('Your current password is incorrect');
                        } else {
                            message.error(formErrorMessage);
                        }
                        return false;
                    }
                }
            });
        },
        [isLoading, validateFields, userId, updateUserPassword, resetFields]
    );

    const handleConfirmPassword = e => {
        const { value } = e.target;
        setConfirmDirty(confirmDirty || !!value);
    };

    const compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== getFieldValue('newpassword')) {
            callback('Passwords do not match');
        } else {
            callback();
        }
    };

    const validateToNextPassword = (rule, value, callback) => {
        if (value && confirmDirty) {
            validateFields(['confirmpassword'], { force: true });
        }
        callback();
    };

    return (
        <>
            <Text textVariant="H5" colorScheme="primary" mt="30">
                Change password
            </Text>
            <Box mt="20" mb="30">
                <Form onSubmit={handleSubmitChangePassword}>
                    <FormItemFlex justifyContent="space-between" itemWidthPct={30}>
                        <Form.Item label="Verify current password" colon={false} required={false}>
                            {getFieldDecorator('currentpassword', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'This field cannot be empty',
                                    },
                                ],
                            })(<InputPassword placeholder="Enter current password" />)}
                        </Form.Item>
                        <Form.Item label="New password" colon={false} required={false}>
                            {getFieldDecorator('newpassword', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'This field cannot be empty',
                                    },
                                    {
                                        validator: validateToNextPassword,
                                    },
                                ],
                            })(<InputPassword placeholder="Enter new password" />)}
                        </Form.Item>
                        <Form.Item label="Confirm new password" colon={false} required={false}>
                            {getFieldDecorator('confirmpassword', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'This field cannot be empty',
                                    },
                                    {
                                        validator: compareToFirstPassword,
                                    },
                                ],
                            })(<InputPassword placeholder="Confirm new password" onBlur={handleConfirmPassword} />)}
                        </Form.Item>
                    </FormItemFlex>
                    <Form.Item>
                        <Button w={['100%', 'auto']} type="primary" htmlType="submit" loading={isLoading}>
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            </Box>
        </>
    );
});

export default Form.create()(ChangePasswordForm);
