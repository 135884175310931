import React from 'react';

const IconDeliverableFigma = () => {
    return (
        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57.1557 65.835H8.84375V9.37204L19.4698 0.165039H57.1557V65.835Z" fill="#333333" />
            <path d="M8.84375 9.37204H19.4698V0.165039L8.84375 9.37204Z" fill="#AFAFAC" />
            <path
                d="M44.4835 24.4198V25.1128C44.5154 25.4089 44.5154 25.7077 44.4835 26.0038C44.2115 26.9855 43.6508 27.8627 42.874 28.5218C42.0973 29.1809 41.1404 29.5913 40.1275 29.6998H39.5995H34.3525V19.7998H39.7645H40.5565C41.6488 20.0399 42.6266 20.6452 43.3285 21.5158C43.9353 22.2362 44.3144 23.1206 44.4175 24.0568C44.4175 24.0568 44.4505 24.3208 44.4835 24.4198Z"
                fill="#FE7262"
            />
            <path
                d="M34.3861 35.2112C34.3861 35.7062 34.3861 36.1682 34.3861 36.6302V39.9302H28.9741H28.4461C27.3861 39.7391 26.4135 39.2183 25.6669 38.4421C24.9203 37.6658 24.4378 36.6737 24.2881 35.6072C24.3044 35.4978 24.3044 35.3866 24.2881 35.2772V34.6172C24.3035 34.5521 24.3035 34.4843 24.2881 34.4192C24.3563 33.8236 24.5236 33.2436 24.7831 32.7032C25.2189 31.7883 25.9358 31.0367 26.8291 30.5582C27.378 30.2382 27.9827 30.0254 28.6111 29.9312H34.3531V35.3102L34.3861 35.2112Z"
                fill="#A159FE"
            />
            <path
                d="M34.3526 29.6998H29.0396H28.4456C27.6229 29.5641 26.8474 29.2241 26.1901 28.711C25.5329 28.1979 25.0149 27.528 24.6836 26.7628C24.4512 26.2303 24.3171 25.6601 24.2876 25.0798V24.4198C24.2713 24.2552 24.2713 24.0894 24.2876 23.9248C24.4982 22.7318 25.1306 21.6542 26.0696 20.8888C26.8012 20.2795 27.6969 19.9005 28.6436 19.7998H29.0066H34.3526V29.6998Z"
                fill="#F14E1E"
            />
            <path
                d="M29.0401 39.9302H34.3531C34.3531 41.6132 34.3531 43.2302 34.3531 44.9792C34.3426 46.1211 33.9454 47.2258 33.2261 48.1128C32.5069 48.9999 31.5082 49.6169 30.3931 49.8632C30.1523 49.8975 29.9079 49.8975 29.6671 49.8632H28.9741C28.2626 49.9007 27.5518 49.7766 26.8951 49.5002C26.1949 49.1228 25.5939 48.5853 25.1408 47.9316C24.6878 47.2778 24.3956 46.5263 24.2881 45.7382C24.3059 45.6289 24.3059 45.5175 24.2881 45.4082V44.7152C24.3044 44.6058 24.3044 44.4946 24.2881 44.3852C24.4367 43.3074 24.9272 42.3057 25.6876 41.5275C26.448 40.7493 27.438 40.2357 28.5121 40.0622L29.0401 39.9302Z"
                fill="#0ACE83"
            />
            <path
                d="M34.3859 35.2109V34.5179C34.3697 34.3093 34.3697 34.0996 34.3859 33.8909C34.5906 32.8981 35.0857 31.9884 35.8084 31.2775C36.5311 30.5665 37.4488 30.0864 38.4449 29.8979H39.0719H39.7319H40.3259C41.3648 30.1074 42.3115 30.6385 43.0319 31.4159C43.7665 32.1684 44.2307 33.1433 44.3519 34.1879C44.3519 34.1879 44.3519 34.4189 44.3519 34.5509V35.2439C44.3519 35.2439 44.3519 35.4419 44.3519 35.5409C44.2195 36.5737 43.7711 37.5402 43.0681 38.3082C42.3651 39.0762 41.4419 39.608 40.4249 39.8309C39.6312 40.0035 38.8075 39.9794 38.0252 39.7609C37.243 39.5423 36.5261 39.1359 35.9369 38.5769C35.0736 37.8059 34.5123 36.7534 34.3529 35.6069C34.3475 35.4741 34.3586 35.3411 34.3859 35.2109Z"
                fill="#1ABBFD"
            />
        </svg>
    );
};

export default IconDeliverableFigma;
