import React, { useEffect, useState } from 'react';
import { Box } from '@components/Box';
import IconFileZip from '@components/Svg/IconFileZip';
import IconFileFigma from '@components/Svg/IconFileFigma';
import IconFileAi from '@components/Svg/IconFileAi';
import IconFileAep from '@components/Svg/IconFileAep';
import IconFileIndd from '@components/Svg/IconFileIndd';
import IconFilePdf from '@components/Svg/IconFilePdf';
import IconFilePsd from '@components/Svg/IconFilePsd';
import IconFilePpt from '@components/Svg/IconFilePpt';
import IconFileSvg from '@components/Svg/IconFileSvg';
import IconFileJpg from '@components/Svg/IconFileJpg';
import IconFilePng from '@components/Svg/IconFilePng';
import IconFileSketch from '@components/Svg/IconFileSketch';
import IconFileOther from '@components/Svg/IconFileOther';
import IconFileFolder from '@components/Svg/IconFileFolder';
import { Image } from '@components/Image';
import { forceInt } from '@utils/forceInt';
import defaultImage from '@assets/icons/default-image.svg';
import defaultImagePdf from '@assets/icons/pdf.svg';

const extensions = {
    zip: IconFileZip,
    fig: IconFileFigma,
    ai: IconFileAi,
    aep: IconFileAep,
    indd: IconFileIndd,
    pdf: IconFilePdf,
    psd: IconFilePsd,
    ppt: IconFilePpt,
    pptx: IconFilePpt,
    svg: IconFileSvg,
    jpg: IconFileJpg,
    png: IconFilePng,
    sketch: IconFileSketch,
};

const previewables = ['jpg', 'png', 'jpeg', 'pdf'];

const PDFJS = window.pdfjsLib;

export const IconFile = ({ name, showPreviewImage, url, fileObj, size, isDirectory, isUploading = false }) => {
    const [objectUrl, setObjectUrl] = useState(null);
    const [isPdf, setIsPdf] = useState(null);

    useEffect( () => {
        if (!isUploading) {
            let fileObjUrl;
            const extension = name.split('.').pop();
            if (extension !== 'pdf') {
                if (fileObj && showPreviewImage && previewables.indexOf(extension) > -1) {
                    fileObjUrl = window.URL.createObjectURL(fileObj);
                    setObjectUrl(fileObjUrl);
                }
                return () => {
                    if (fileObjUrl) {
                        window.URL.revokeObjectURL(fileObjUrl);
                    }
                };
            } else {
                const setPdfToImage = async () => {
                    const _PDF_DOC = await PDFJS.getDocument({ url: url }).promise;
                    const canvas = document.createElement("canvas");
                    const page = await _PDF_DOC.getPage(1);
                    const viewport = page.getViewport({ scale: 1 });
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;
                    const render_context = {
                        canvasContext: canvas.getContext("2d"),
                        viewport: viewport
                    };
                    await page.render(render_context).promise;
                    const img = canvas.toDataURL("image/png");
                    setObjectUrl(img);
                }
                const generate = async () => {
                    setIsPdf(true);
                    try {
                        await setPdfToImage()
                    } catch (error) {
                        // call once as a workaround for cloudfront call bug once error
                       await setPdfToImage()
                    }
                }
                generate()
            }
        }
    }, [fileObj, name, showPreviewImage, url, isUploading]);

    const src = fileObj ? objectUrl : url;
    const ext = name.split('.').pop();

    let Icon = extensions[ext] ?? IconFileOther;
    if (isDirectory) {
        Icon = IconFileFolder;
    }

    if (isUploading) {
        return (
            <Box d="inline-flex" alignItems="center" justifyContent="center" fontSize={size}>
                <Icon />
            </Box>
        );
    }

    if (!isPdf && showPreviewImage && previewables.indexOf(ext) > -1) {
        return (
            <Image
                src={src}
                name={name}
                isBorderLess
                size={forceInt(size)}
                fontSize={14}
                imageProps={{ objectFit: 'contain', h: '100%', maxH: size, w: 'auto', minW: size }}
                textProps={{ bg: 'transparent' }}
                fallbackSrc={defaultImage}
            />
        );
    }
    
    if (isPdf) {
        return (
            <Image
                src={objectUrl}
                name={name}
                isBorderLess
                size={forceInt(size)}
                fontSize={14}
                imageProps={{ objectFit: 'contain', h: '100%', maxH: size, w: 'auto', minW: size }}
                textProps={{ bg: 'transparent' }}
                fallbackSrc={defaultImagePdf}
            />
        );
    }

    return (
        <Box d="inline-flex" alignItems="center" justifyContent="center" fontSize={size}>
            <Icon />
        </Box>
    );
};
