import React from 'react';

const Icon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11.5" fill="#D5D6DD" stroke="#D5D6DD"/>
      <g clip-path="url(#clip0_15777_32949)">
      <path d="M6.69487 12.6031C6.63171 12.6031 6.50539 12.6031 6.44223 12.5397C6.37908 12.4762 6.31592 12.3492 6.31592 12.2222V6.5714C6.31592 6.31743 6.50539 6.19045 6.69487 6.19045L17.2422 6.12695C17.3685 6.12695 17.4317 6.19045 17.558 6.25394C17.6212 6.31743 17.6843 6.44441 17.6843 6.5714V9.30156C17.6843 9.42854 17.6212 9.61902 17.4317 9.68251L12.2528 12.3492C12.1264 12.4127 12.0001 12.4127 11.8738 12.3492L10.0422 11.2698L6.88434 12.5397C6.82118 12.6031 6.75802 12.6031 6.69487 12.6031ZM7.13697 7.01584V11.5873L9.91592 10.4444C10.0422 10.3809 10.1685 10.3809 10.2949 10.4444L12.1264 11.4603L16.9264 8.9841V6.95235L7.13697 7.01584Z" fill="#6A7180"/>
      <path d="M6.94748 17.746C6.69484 17.746 6.56853 17.5556 6.56853 17.3651L6.50537 14.6349C6.50537 14.4445 6.63169 14.3175 6.758 14.254L9.97905 12.8572C10.1054 12.7937 10.2317 12.7937 10.358 12.9206L11.8738 13.9365L17.1791 10.5079C17.3054 10.4445 17.4948 10.4445 17.6212 10.5079C17.7475 10.5714 17.8106 10.6984 17.8106 10.8889L17.9369 17.3651C17.9369 17.4921 17.8738 17.5556 17.8106 17.6825C17.7475 17.8095 17.6212 17.8095 17.4948 17.8095L6.94748 17.746ZM7.26327 14.8889L7.32642 16.9206L16.9896 16.8572L16.9264 11.5873L12.0001 14.7619C11.8738 14.8254 11.6843 14.8254 11.558 14.7619L10.0422 13.746L7.26327 14.8889Z" fill="#6A7180"/>
      <path d="M13.0105 11.3968C12.9473 11.3968 12.821 11.3968 12.7578 11.3333L10.2947 9.36509L7.19994 11.0794C7.01046 11.1429 6.75783 11.0794 6.63152 10.8889C6.5052 10.6984 6.56836 10.4445 6.82099 10.3175L10.1684 8.53969C10.2947 8.4762 10.4841 8.4762 10.6105 8.60318L13.2631 10.6984C13.4526 10.8254 13.4526 11.0794 13.3263 11.2698C13.2631 11.3968 13.1368 11.3968 13.0105 11.3968Z" fill="#6A7180"/>
      <path d="M17.179 15.3333C17.1159 15.3333 16.9895 15.3333 16.9264 15.2698L14.2738 13.1746C14.0843 13.0476 14.0843 12.7936 14.2106 12.6032C14.3369 12.4127 14.5895 12.4127 14.779 12.5397L17.4316 14.6349C17.6211 14.7619 17.6211 15.0159 17.4948 15.2063C17.4316 15.2698 17.3053 15.3333 17.179 15.3333Z" fill="#6A7180"/>
      <path d="M13.2001 9.61906C12.6317 9.61906 12.1265 9.17461 12.1265 8.53969C12.1265 7.90477 12.6317 7.46033 13.2001 7.46033C13.7686 7.46033 14.2738 7.96826 14.2738 8.53969C14.2738 9.11112 13.8317 9.61906 13.2001 9.61906ZM13.2001 8.28572C13.0738 8.28572 12.9475 8.41271 12.9475 8.53969C12.9475 8.66668 13.0738 8.79366 13.2001 8.79366C13.3265 8.79366 13.4528 8.66668 13.4528 8.53969C13.4528 8.41271 13.3265 8.28572 13.2001 8.28572Z" fill="#6A7180"/>
      </g>
      <defs>
      <clipPath id="clip0_15777_32949">
      <rect width="12" height="12" fill="white" transform="translate(6 6)"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default Icon;