import gql from 'graphql-tag';

export const CREATE_TEAM = gql`
    mutation CreateTeam($data: TeamCreateInput!) {
        createTeam(data: $data) {
            id
            name
        }
    }
`;

export const UPDATE_TEAM = gql`
    mutation UpdateTeam($data: TeamUpdateInput!, $where: TeamWhereUniqueInput!) {
        updateTeam(data: $data, where: $where) {
            id
            name
        }
    }
`;
