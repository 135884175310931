import React, { useEffect, useMemo, useReducer, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import * as qs from 'query-string';
import withLoggedUser from '@components/WithLoggedUser';
import { CREATE_REQUEST } from '@constants/routes';
import { Basepage } from '@components/Basepage';
import { PageContainer } from '@components/PageContainer';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Badge } from '@components/Badge';
import { Button } from '@components/Button';
import { Tabs } from '@components/Tabs';
import IconAdd from '@components/Svg/IconAdd';
import { useNavSearchContext, withNavSearchProvider } from '@components/Basepage/NavSearch';
import DocumentTitle from '@components/DocumentTitle';
import { ALL_ORDERS, ORDER_COUNTERS } from '@graphql/queries/order';
import {
    getOrderGroupValues,
    getOrderGroupStatus,
} from '@constants/order';
import { SectionRequest } from './blocks/ListRequest/SectionRequest';
import SubscriptionInactive from './blocks/SubscriptionInactive';
import { PopupFeedbackRequests } from './blocks/FeedbackRequest/PopupFeedbackRequests';
import { DelayedSearchInput } from './blocks/DelayedSearchInput';

const initialState = {
    tab: 'QUEUE',
    page: 1,
    max: 10,
    status: 'All',
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_LOCATION':
            return {
                ...state,
                ...action.values,
            };
        default:
            return state;
    }
};

const Requests = ({ location, viewer, history }) => {
    const didUpdate = useRef(false);
    const [state, dispatch] = useReducer(reducer, initialState, (init) => {
        const parsed = qs.parse(location.search);
        return {
            tab: parsed.tab?.toUpperCase() ?? init.tab,
            page: parsed.page ? parseInt(parsed.page, 10) : init.page,
            max: parsed.max ? parseInt(parsed.max, 10) : init.max,
            status: parsed.status ?? init.status,
        };
    });
    const { search, debouncedSearch, intSearch, setSearch } = useNavSearchContext();

    const activeFilter = useMemo(() => {
        return getOrderGroupStatus(state.tab, state.status);
    }, [state.status, state.tab]);

    const variables = useMemo(() => {
        let vars = { where: { AND: [] }, orderBy: 'updatedAt_DESC' };

        vars.where.AND.push({
            company: {
                id: viewer?.company?.id,
            },
            status_in: activeFilter,
            archived: false,
        });

        if (debouncedSearch !== '') {
            vars.where.AND.push({
                OR: [{ name_contains: debouncedSearch }, intSearch !== '' ? { id: intSearch } : {}],
            });
        }

        if (state.tab !== 'QUEUE') {
            vars = {
                ...vars,
                first: state.max,
                skip: (state.page - 1) * state.max,
            };
        }
        return vars;
    }, [activeFilter, debouncedSearch, intSearch, state, viewer.company.id]);

    const { data, loading, refetch } = useQuery(ALL_ORDERS, {
        variables,
        fetchPolicy: 'network-only',
    });

    const filterUnreadCounter = (tab) => {
        return {
            AND: [
                {
                    company: {
                        id: viewer?.company?.id,
                    },
                    status_in: getOrderGroupStatus(tab, 'All'),
                    archived: false,
                },
                debouncedSearch !== ''
                    ? {
                          OR: [{ name_contains: debouncedSearch }, intSearch !== '' ? { id: intSearch } : {}],
                      }
                    : {},
            ],
        };
    };

    const { data: queueCounter, refetch: refetchQueueCounter } = useQuery(ORDER_COUNTERS, {
        variables: {
            where: filterUnreadCounter('QUEUE'),
        },
    });
    const { data: deliveredCounter, refetch: refetchDeliveredCounter } = useQuery(ORDER_COUNTERS, {
        variables: {
            where: filterUnreadCounter('DELIVERED'),
        },
    });

    useEffect(() => {
        if (!didUpdate.current) {
            didUpdate.current = true;
        } else {
            const parsed = qs.parse(location.search);
            const values = {
                page: parsed.page ? parseInt(parsed.page, 10) : 1,
            };
            if (parsed.tab) {
                const uppercased = parsed.tab?.toUpperCase();
                values.tab = uppercased;
                values.status = 'All';
            }
            if (parsed.max) {
                values.max = parseInt(parsed.max, 10);
            }
            dispatch({ type: 'CHANGE_LOCATION', values });
        }
    }, [location.search]);

    const refetchAll = async () => {
        try {
            await refetch();
            await refetchQueueCounter();
            await refetchDeliveredCounter();
        } catch (err) {
            console.log(err);
        }
    };

    const requestData = data?.allOrders.data;
    const subStatus = viewer?.company?.subscription?.status;
    const isSubscriptionActive = subStatus === 'active' || subStatus === 'paused';
    const isSubscriptionPaused = subStatus === 'paused';
 
    const navigateLocation = (obj) => {
        const stringify = qs.stringify(obj);
        history.push(`${location.pathname}?${stringify}`);
    };

    const handleChangeTab = (tab) => {
        navigateLocation({ tab, page: 1, max: state.max, status: 'All' });
    };

    const handleChangePage = (page) => {
        navigateLocation({ ...state, page });
    };

    const handleChangeMax = (max) => {
        navigateLocation({ ...state, max });
    };

    const handleChangeStatus = (status) => {
        navigateLocation({ status, tab: state.tab, max: state.max, page: 1 });
    };

    const unreadQueue = queueCounter?.unreadOrder ? queueCounter.unreadOrder : 0;
    const unreadDelivered = deliveredCounter?.unreadOrder ? deliveredCounter.unreadOrder : 0;

    const requestSectionProps = {
        activeFilter,
        data: requestData,
        totalRequestCount: data?._allOrdersMeta?.count,
        allRequestsTotal: data?._allOrdersMeta.total,
        page: state.page,
        max: state.max,
        loading,
        handleChangeStatus,
        handleChangePage,
        handleChangeMax,
        refetch: refetchAll,
        search,
        activeStatusName: state.status,
        isSubscriptionPaused,
    };


    return (
        <DocumentTitle title="Requests | ManyPixels">
            <Basepage location={location.pathname}>
                <PageContainer maxW="1234">
                    <PopupFeedbackRequests refetch={refetchAll} />
                    <Box d="flex" justifyContent="space-between" alignItems="center">
                        <Text hide="mobile" textVariant="H3">
                            Requests
                        </Text>
                        <Text hide="desktop" textVariant="H4">
                            Requests
                        </Text>
                            <Box w="100%">
                                <Box d="flex" justifyContent="flex-end">
                                    <Box hide="mobile" mr={isSubscriptionActive && subStatus !== 'paused' ? "20" : "0"} w="100%" maxW="440">
                                        <DelayedSearchInput initialValue={search} onChange={setSearch} />
                                    </Box>
                                {isSubscriptionActive && subStatus !== 'paused' && (

                                    <Link to={CREATE_REQUEST}>
                                        <Button type="primary" icon={<IconAdd style={{ fontSize: 20 }} />}>
                                            CREATE REQUEST
                                        </Button>
                                    </Link>
                                )}
                                </Box>
                            </Box>
                    </Box>
                    {isSubscriptionActive ? (
                        <Box mt="18">
                            <Tabs defaultActiveKey={state.tab} animated={false} onChange={handleChangeTab}>
                                <Tabs.TabPane
                                    tab={
                                        <Text d="inline-flex" alignItems="center">
                                            Queue{' '}
                                            {unreadQueue > 0 && (
                                                <Badge isNotification ml="6">
                                                    {unreadQueue}
                                                </Badge>
                                            )}
                                        </Text>
                                    }
                                    key="QUEUE"
                                >
                                    <SectionRequest
                                        name="queue"
                                        statuses={getOrderGroupValues('QUEUE')}
                                        {...requestSectionProps}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    tab={
                                        <Text d="inline-flex" alignItems="center">
                                            Delivered{' '}
                                            {unreadDelivered > 0 && (
                                                <Badge isNotification ml="6">
                                                    {unreadDelivered}
                                                </Badge>
                                            )}
                                        </Text>
                                    }
                                    key="DELIVERED"
                                >
                                    <SectionRequest
                                        name="delivered"
                                        statuses={getOrderGroupValues('DELIVERED')}
                                        {...requestSectionProps}
                                        showDownloadAllFiles={true}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Draft" key="DRAFT">
                                    <SectionRequest {...requestSectionProps} />
                                </Tabs.TabPane>
                            </Tabs>
                        </Box>
                    ) : (
                        <SubscriptionInactive />
                    )}
                </PageContainer>
            </Basepage>
        </DocumentTitle>
    );
};

export default withLoggedUser(withNavSearchProvider(Requests));
