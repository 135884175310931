import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Card } from '@components/Card';
import { Text } from '@components/Text';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import ModalPlan from '@pages/Public/Onboarding/modals/ModalPlan';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { ALL_PLANS_TO_SUB } from '@graphql/queries/plan';
import { ME_SUBSCRIPTION } from '@graphql/queries/userConnected';
import { useHistory } from 'react-router';
import { BILLING } from '@constants/routes';
import { UPDATE_SUBSCRIPTION } from '@graphql/mutations/subscription';
import defaultImage from '@assets/icons/default-image.svg';
import { Image } from '@components/Image';

const CardProduct = ({ product, value, setShowUpgrade, onChange, enableShowUpgrade }) => {
    const [hovered, setHovered] = useState(false);

    const isActive = value === product.id;

    const handleClickCard = () => {
        if (!enableShowUpgrade) {
            onChange(!isActive ? product.id : undefined);
        }
    };

    return (
        <Col key={product.id} lg={6}>
            <Box
                pos="relative"
                userSelect="none"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <Card
                    h="140"
                    px="26"
                    alignItems="center"
                    hoverable
                    flexDir="column"
                    justifyContent="center"
                    mb="20"
                    pos="relative"
                    isActive={isActive || hovered}
                    onClick={handleClickCard}
                >
                    <Image
                        src={product.icon?.url}
                        name={product.title}
                        size={76}
                        isBorderLess
                        imageProps={{ objectFit: 'contain' }}
                        fallbackSrc={defaultImage}
                    />
                    <Text textVariant="Badge" pt="8" colorScheme="primary" textAlign="center">
                        {product.name}
                    </Text>
                </Card>
                {(hovered || isActive) && enableShowUpgrade && (
                    <Box pos="absolute" top="49" left="50%" transform="translateX(-50%)">
                        <Button type="primary" onClick={() => setShowUpgrade(true)}>
                            Upgrade Plan
                        </Button>
                    </Box>
                )}
            </Box>
        </Col>
    );
};

export const FieldProductRequest = forwardRef(
    ({ showUpgrade, setShowUpgrade, value, onChange, category, viewer, windowWidth }, ref) => {
        const [frequency] = useState('MONTHLY');
        const history = useHistory();
        const [selectedPlanName, setSelectedPlanName] = useState('Essentials');
        const [fetchedSelectedPlan, setFetchedSelectedPlan] = useState(false);
        const [updateSubscription] = useMutation(UPDATE_SUBSCRIPTION);
        const [isUpdating, setIsUpdating] = useState(false);

        const { data: plansData } = useQuery(ALL_PLANS_TO_SUB);
        const { data: subscriptionData } = useQuery(ME_SUBSCRIPTION);

        useEffect(() => {
            const subscribedPlanName = subscriptionData?.user?.company?.subscription?.plan?.name;
            if (subscribedPlanName) {
                setSelectedPlanName(subscribedPlanName);
                setFetchedSelectedPlan(true);
            }
        }, [subscriptionData]);

        const handleConfirmUpgrade = useCallback(
            async newPlan => {
                setIsUpdating(true);
                const selectedPlan = plansData.allPlans?.find(
                    plan => plan.interval === frequency && plan.name === newPlan
                );
                await updateSubscription({
                    variables: {
                        planId: selectedPlan.id,
                        quantity: 1,
                    },
                });
                setIsUpdating(false);
                history.push(BILLING);
            },
            [plansData, history, updateSubscription, frequency]
        );

        const handleCancelUpgrade = useCallback(() => {
            setShowUpgrade(false);
        }, [setShowUpgrade]);

        const enabledServices = subscriptionData?.user?.company?.subscription?.plan?.services ?? [];
        const enabledServiceIds = enabledServices.map(item => item.id);

        if (!category) {
            return (
                <Text textVariant="P4" colorScheme="secondary" mb="20">
                    Please choose a category first
                </Text>
            );
        }

        return (
            <>
                <Row gutter={20}>
                    {Array.isArray(category.services) &&
                        category.services.length > 0 &&
                        category.services.map(product => (
                            <CardProduct
                                key={product.id}
                                product={product}
                                setShowUpgrade={setShowUpgrade}
                                onChange={onChange}
                                value={value}
                                enableShowUpgrade={enabledServiceIds.indexOf(product.id) < 0}
                            />
                        ))}
                </Row>
                {fetchedSelectedPlan && (
                    <ModalPlan
                        visible={showUpgrade}
                        onConfirm={handleConfirmUpgrade}
                        onCancel={handleCancelUpgrade}
                        plans={plansData?.allPlans}
                        frequency={frequency}
                        selectedPlanName={selectedPlanName}
                        setSelectedPlanName={setSelectedPlanName}
                        windowWidth={windowWidth}
                        isUpdating={isUpdating}
                        title="Upgrade plan"
                        okText="Upgrade plan"
                    />
                )}
            </>
        );
    }
);
