import React from 'react';

const IconDeliverableAep = () => {
    return (
        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57.1557 65.835H8.84375V9.37204L20.0968 0.165039H57.1557V65.835Z" fill="#2A0E4F" />
            <path d="M8.84375 9.37204H19.4698V0.165039L8.84375 9.37204Z" fill="#DCAEFE" />
            <path
                d="M26.1688 30.1948H28.8088L35.1448 44.7478H31.5148L30.2608 41.6458H24.6178L23.3968 44.7478H19.7998L26.1688 30.1948ZM27.3898 34.4188L25.6408 38.9398H29.1718L27.3898 34.4188Z"
                fill="#D3A9F4"
            />
            <path
                d="M45.6388 42.9C45.1551 43.5321 44.5194 44.0316 43.7908 44.352C43.0595 44.7024 42.2586 44.8829 41.4478 44.88C40.7069 44.8774 39.9714 44.7548 39.2698 44.517C38.626 44.2771 38.0329 43.919 37.5208 43.461C37.0038 43.0092 36.5973 42.4447 36.3328 41.811C36.0361 41.1239 35.8898 40.3813 35.9038 39.633C35.8861 38.8951 36.0327 38.1624 36.3328 37.488C36.5973 36.8544 37.0038 36.2899 37.5208 35.838C38.0246 35.369 38.6201 35.0095 39.2698 34.782C39.9673 34.5282 40.7056 34.4052 41.4478 34.419C42.1151 34.4019 42.7785 34.5256 43.3948 34.782C43.9556 35.014 44.4533 35.3759 44.8468 35.838C45.2725 36.3188 45.5979 36.8798 45.8038 37.488C46.0417 38.168 46.1753 38.8801 46.1998 39.6V40.59H38.9728C39.081 41.1251 39.3587 41.6111 39.7648 41.976C40.1882 42.3334 40.7289 42.5215 41.2828 42.504C41.7355 42.5286 42.1847 42.4134 42.5698 42.174C42.9331 41.9404 43.2471 41.6377 43.4938 41.283L45.6388 42.9ZM42.8998 38.61C42.9223 38.3669 42.8894 38.1219 42.8037 37.8933C42.718 37.6646 42.5816 37.4584 42.4048 37.29C42.2282 37.1017 42.0128 36.954 41.7735 36.8571C41.5341 36.7603 41.2766 36.7166 41.0188 36.729C40.7241 36.6759 40.4224 36.6759 40.1278 36.729C39.8682 36.8197 39.6316 36.9661 39.4348 37.158C39.2555 37.3271 39.1099 37.5287 39.0058 37.752C38.9719 37.9818 38.9719 38.2153 39.0058 38.445L42.8998 38.61Z"
                fill="#D3A9F4"
            />
        </svg>
    );
};

export default IconDeliverableAep;
