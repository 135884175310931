import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 272px 32px;
    text-align: center;
`;

export const Text = styled.div`
    font-size: 1.25rem;
    line-height: 28px;
    color: rgba(3, 27, 78, 0.74902);
`;
