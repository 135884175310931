import React from 'react';

const IconFileOther = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.32 19.95H2.68V2.83999L5.9 0.0499878H17.32V19.95Z" fill="#E6E6E4" />
            <path d="M2.68 2.83999H5.9V0.0499878L2.68 2.83999Z" fill="#AFAFAC" />
            <path
                d="M7.08 11.42C7.50526 11.42 7.85 11.0753 7.85 10.65C7.85 10.2247 7.50526 9.88 7.08 9.88C6.65474 9.88 6.31 10.2247 6.31 10.65C6.31 11.0753 6.65474 11.42 7.08 11.42Z"
                fill="#AFAFAC"
            />
            <path
                d="M10 11.42C10.4253 11.42 10.77 11.0753 10.77 10.65C10.77 10.2247 10.4253 9.88 10 9.88C9.57474 9.88 9.23 10.2247 9.23 10.65C9.23 11.0753 9.57474 11.42 10 11.42Z"
                fill="#AFAFAC"
            />
            <path
                d="M12.92 11.42C13.3453 11.42 13.69 11.0753 13.69 10.65C13.69 10.2247 13.3453 9.88 12.92 9.88C12.4947 9.88 12.15 10.2247 12.15 10.65C12.15 11.0753 12.4947 11.42 12.92 11.42Z"
                fill="#AFAFAC"
            />
        </svg>
    );
};

export default IconFileOther;
