import React from 'react';

const IconDeliverableIndd = () => {
    return (
        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57.1557 65.835H8.84375V9.37204L20.0968 0.165039H57.1557V65.835Z" fill="#391825" />
            <path d="M8.84375 9.37204H19.4698V0.165039L8.84375 9.37204Z" fill="#FD458D" />
            <path d="M23.4961 27.7861H27.2581V44.8141H23.4961V27.7861Z" fill="#FD458D" />
            <path
                d="M42.5043 44.8139H39.2043V43.2959L38.6103 43.9229L37.8183 44.5169L36.7623 44.9459C36.3862 45.0516 35.9979 45.1071 35.6073 45.1109C34.8317 45.1244 34.0613 44.9825 33.3415 44.6934C32.6217 44.4044 31.9671 43.974 31.4163 43.4279C30.9136 42.8687 30.5217 42.2192 30.2613 41.5139C29.9348 40.7383 29.745 39.9121 29.7003 39.0719C29.6864 38.2759 29.8092 37.4834 30.0633 36.7289C30.2808 36.0055 30.6397 35.3325 31.1193 34.7489C31.5687 34.1659 32.144 33.6921 32.8023 33.3629C33.4921 32.9959 34.2652 32.814 35.0463 32.8349C35.7551 32.8343 36.4586 32.9571 37.1253 33.1979C37.7745 33.4164 38.3396 33.8316 38.7423 34.3859V26.6309H42.3723L42.5043 44.8139ZM39.2043 38.9729C39.2321 38.1875 38.9608 37.4209 38.4453 36.8279C37.8499 36.3253 37.0959 36.0497 36.3168 36.0497C35.5377 36.0497 34.7837 36.3253 34.1883 36.8279C33.677 37.4256 33.3961 38.1863 33.3961 38.9729C33.3961 39.7594 33.677 40.5201 34.1883 41.1179C34.7837 41.6204 35.5377 41.8961 36.3168 41.8961C37.0959 41.8961 37.8499 41.6204 38.4453 41.1179C38.9489 40.5199 39.2082 39.7538 39.1713 38.9729H39.2043Z"
                fill="#FD458D"
            />
        </svg>
    );
};

export default IconDeliverableIndd;
