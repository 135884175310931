export const COLOR_CTA = '#0099F6';

export const COLOR_TEXT_PRIMARY = '#07122B';
export const COLOR_TEXT_SECONDARY = '#6A7180';
export const COLOR_TEXT_TERTIARY = '#B8B8B8';
export const COLOR_TEXT_GRAY = '#262626';

export const COLOR_WHITE = '#ffffff';
export const COLOR_BLACK = '#000000';

export const COLOR_HEADLINE = '#07122B';

export const COLOR_OUTLINE_GRAY = '#D5D6DD';

export const COLOR_TEXT_ERROR = '#FF3041';
export const COLOR_HOVER_ICON = '#0177BF';

export const COLOR_UI_ELEMENT_BLACK = '#07122B';
export const COLOR_UI_ELEMENT_GRAY = '#CCCCCC';
export const COLOR_UI_ELEMENT_SKELETON = 'linear-gradient(90deg, #FAFAFA 0%, #EEEEEE 66.67%, #F8F8F8 100%)';

export const COLOR_BACKGROUND_GRAY = '#F8F8F8';
export const COLOR_BACKGROUND_LIGHT_BLUE = '#F2FAFF';
export const COLOR_BACKGROUND_DISABLED = '#F3F3F3';

export const COLOR_OTHERS_PINK = '#EF457E';
export const COLOR_OTHERS_BLUE = '#0A32C3';
export const COLOR_OTHERS_YELLOW = '#FFD44D';
export const COLOR_OTHERS_PURPLE = '#864DFF';
export const COLOR_OTHERS_GREEN = '#3AB210';
export const COLOR_OTHERS_DARK_YELLOW = '#C89802';
export const COLOR_OTHERS_RED = '#FC3030';
export const COLOR_OTHERS_GRAY = '#F0F0F0';

export const COLOR_BADGE_BLUE = '#E1F1FB';
export const COLOR_BADGE_GRAY = '#F1F0F0';
export const COLOR_BADGE_YELLOW = '#FEF7E1';
export const COLOR_BADGE_RED = '#FFD3D3';
export const COLOR_BADGE_GREEN = '#E4FDDD';

export const COLOR_ELEMENT_STROKE = '#E4EBF1';
