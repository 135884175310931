import React from 'react';

const IconBilling = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.66 7.51999C9.5738 7.52132 9.4882 7.50548 9.40818 7.47341C9.32816 7.44134 9.25532 7.39367 9.1939 7.33318C9.13248 7.27269 9.08371 7.20059 9.05042 7.12107C9.01713 7.04155 8.99999 6.9562 9 6.86999V4.63999C9 4.4676 9.06848 4.30227 9.19038 4.18037C9.31228 4.05847 9.47761 3.98999 9.65 3.98999C9.82239 3.98999 9.98772 4.05847 10.1096 4.18037C10.2315 4.30227 10.3 4.4676 10.3 4.63999V6.86999C10.3 7.04066 10.2329 7.20449 10.1132 7.32611C9.99342 7.44773 9.83065 7.51736 9.66 7.51999Z"
        fill="currentColor"
      />
      <path
        d="M9.66 16C9.57464 16.0013 9.48985 15.9858 9.41048 15.9544C9.33111 15.9229 9.25872 15.8762 9.19743 15.8167C9.13614 15.7573 9.08716 15.6864 9.05328 15.608C9.01941 15.5297 9.0013 15.4454 9 15.36V13.3C9 13.1276 9.06848 12.9623 9.19038 12.8404C9.31228 12.7185 9.47761 12.65 9.65 12.65C9.82239 12.65 9.98772 12.7185 10.1096 12.8404C10.2315 12.9623 10.3 13.1276 10.3 13.3V15.36C10.2974 15.529 10.2292 15.6903 10.1097 15.8097C9.99023 15.9292 9.82893 15.9974 9.66 16Z"
        fill="currentColor"
      />
      <path
        d="M12.1302 13.88H7.87021C7.69782 13.88 7.53249 13.8115 7.4106 13.6896C7.2887 13.5677 7.22021 13.4024 7.22021 13.23V12.23C7.22021 12.0576 7.2887 11.8923 7.4106 11.7704C7.53249 11.6485 7.69782 11.58 7.87021 11.58C8.04261 11.58 8.20794 11.6485 8.32983 11.7704C8.45173 11.8923 8.52022 12.0576 8.52022 12.23V12.59H11.5202V10.5H7.93021C7.84486 10.5 7.76033 10.4832 7.68147 10.4505C7.60261 10.4178 7.53095 10.37 7.4706 10.3096C7.41024 10.2492 7.36236 10.1776 7.32969 10.0987C7.29703 10.0199 7.28022 9.93534 7.28022 9.84998V6.99998C7.2828 6.82839 7.35211 6.66455 7.47345 6.54321C7.59479 6.42187 7.75863 6.35256 7.93021 6.34998H11.6302C11.8018 6.35256 11.9656 6.42187 12.087 6.54321C12.2083 6.66455 12.2776 6.82839 12.2802 6.99998V7.54998C12.277 7.71142 12.2138 7.86589 12.103 7.98331C11.9922 8.10074 11.8416 8.17269 11.6806 8.18517C11.5196 8.19765 11.3597 8.14976 11.2321 8.05083C11.1045 7.95189 11.0183 7.809 10.9902 7.64998H8.58021V9.19998H12.1302C12.3026 9.19998 12.4679 9.26846 12.5898 9.39036C12.7117 9.51226 12.7802 9.67759 12.7802 9.84998V13.23C12.7802 13.4024 12.7117 13.5677 12.5898 13.6896C12.4679 13.8115 12.3026 13.88 12.1302 13.88Z"
        fill="currentColor"
      />
      <path
        d="M18.1201 19.66H5.69006C5.60209 19.6613 5.51483 19.6442 5.43388 19.6097C5.35293 19.5753 5.28009 19.5243 5.22006 19.46L1.41006 15.46C1.29366 15.3352 1.22928 15.1706 1.23006 15V0.999966C1.22873 0.913769 1.24456 0.828169 1.27664 0.748149C1.30871 0.66813 1.35638 0.595289 1.41687 0.533868C1.47736 0.472447 1.54946 0.423672 1.62898 0.390383C1.7085 0.357094 1.79385 0.339956 1.88006 0.339966H18.1201C18.2924 0.339966 18.4578 0.408448 18.5797 0.530346C18.7016 0.652245 18.7701 0.817575 18.7701 0.989966V19C18.7714 19.0862 18.7556 19.1718 18.7235 19.2518C18.6914 19.3318 18.6437 19.4046 18.5832 19.4661C18.5228 19.5275 18.4507 19.5763 18.3711 19.6095C18.2916 19.6428 18.2063 19.66 18.1201 19.66ZM6.00006 18.36H17.5001V1.63997H2.53006V14.74L6.00006 18.36Z"
        fill="currentColor"
      />
      <path
        d="M5.99998 19.24C5.82839 19.2374 5.66456 19.1681 5.54322 19.0467C5.42187 18.9254 5.35256 18.7616 5.34998 18.59V15.65H1.87998C1.70759 15.65 1.54226 15.5815 1.42036 15.4596C1.29846 15.3377 1.22998 15.1724 1.22998 15C1.22998 14.8276 1.29846 14.6623 1.42036 14.5404C1.54226 14.4185 1.70759 14.35 1.87998 14.35H5.99998C6.17237 14.35 6.3377 14.4185 6.4596 14.5404C6.5815 14.6623 6.64998 14.8276 6.64998 15V18.59C6.6474 18.7616 6.57809 18.9254 6.45675 19.0467C6.3354 19.1681 6.17157 19.2374 5.99998 19.24Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconBilling;
