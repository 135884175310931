import gql from 'graphql-tag';

export const INVITATION = gql`
    query GetInvitation($id: ID!) {
        Invitation(id: $id) {
            id
            email
            company {
                id
            }
            companyRole
            role
            team {
                id
            }
            specialities {
                id
            }
        }
    }
`;

export const ALL_INVITATIONS = gql`
    query {
        allInvitations {
            id
            email
            createdAt
        }
    }
`;
