import React from 'react';
import { InputNumber } from '@components/InputNumber';
import { InputBoxItem, InputBoxLike, PreviewColor } from '../style';
import { degreeFormatter, degreeParser, percentFormatter, percentParser } from '../utils/stringConverter';
import { hsvlToStr, strToHsvl } from '../utils/inputColorConverter';

export const InputHSL = ({ value, onChange }) => {
    const [h, s, l] = strToHsvl(value);

    const setHsl = input => {
        const newValue = { h, s, l, ...input };
        if (onChange) {
            onChange(hsvlToStr([newValue.h, newValue.s, newValue.l]));
        }
    };

    return (
        <InputBoxLike>
            <InputBoxItem>
                <PreviewColor bg={`hsl(${h}, ${s}%, ${l}%)`} />
            </InputBoxItem>
            <InputNumber
                value={h}
                formatter={degreeFormatter}
                parser={degreeParser}
                onChange={val => setHsl({ h: val })}
            />
            <InputNumber
                value={s}
                formatter={percentFormatter}
                parser={percentParser}
                onChange={val => setHsl({ s: val })}
                min={0}
                max={100}
            />
            <InputNumber
                value={l}
                formatter={percentFormatter}
                parser={percentParser}
                onChange={val => setHsl({ l: val })}
                min={0}
                max={100}
            />
        </InputBoxLike>
    );
};
