import React from 'react';
import { Icon } from 'antd';

const ArrowDownSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.90997 14.38C9.82369 14.38 9.73834 14.3622 9.65921 14.3278C9.58008 14.2934 9.50886 14.2431 9.44997 14.18C9.38828 14.1203 9.33922 14.0489 9.30572 13.9698C9.27221 13.8908 9.25494 13.8059 9.25494 13.72C9.25494 13.6342 9.27221 13.5492 9.30572 13.4702C9.33922 13.3912 9.38828 13.3197 9.44997 13.26L17.08 5.81002C17.1403 5.74955 17.212 5.70158 17.291 5.66884C17.3699 5.63611 17.4545 5.61926 17.54 5.61926C17.6254 5.61926 17.71 5.63611 17.789 5.66884C17.8679 5.70158 17.9396 5.74955 18 5.81002C18.1197 5.93321 18.1867 6.09823 18.1867 6.27002C18.1867 6.44181 18.1197 6.60683 18 6.73002L10.36 14.18C10.242 14.3031 10.0804 14.375 9.90997 14.38Z"
            fill="currentColor"
        />
        <path
            d="M9.91002 14.38C9.73819 14.3771 9.57384 14.3092 9.45002 14.19L2.00002 6.73C1.93833 6.67033 1.88927 6.59885 1.85576 6.51982C1.82226 6.4408 1.80499 6.35584 1.80499 6.27C1.80499 6.18417 1.82226 6.09921 1.85576 6.02019C1.88927 5.94116 1.93833 5.86968 2.00002 5.81C2.12321 5.69027 2.28823 5.62329 2.46002 5.62329C2.63181 5.62329 2.79683 5.69027 2.92002 5.81L10.37 13.27C10.4898 13.3932 10.5567 13.5582 10.5567 13.73C10.5567 13.9018 10.4898 14.0668 10.37 14.19C10.2462 14.3092 10.0819 14.3771 9.91002 14.38Z"
            fill="currentColor"
        />
    </svg>
);

const ArrowDownIcon = props => <Icon component={ArrowDownSvg} {...props} />;

export default ArrowDownIcon;
