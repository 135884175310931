import React, { memo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Form } from '@components/Form';
import { USER_TYPE_WORKER } from '@constants/account';
import SearchInput from '@components/Input/SearchInput';
import StatusField from '../blocks/StatusField';
import TeamField from '../blocks/TeamField';
import AccountField from '../blocks/AccountField';
import DesignerField from '../blocks/DesignerField';
import ProductField from '../blocks/ProductField';

const RequestsTabFilter = memo(({ form, onChangeFilters, viewer, designerId }) => {
    const [hasEdit, setHasEdit] = useState(false);
    const { getFieldDecorator, resetFields, getFieldsValue, setFieldsValue } = form;
    const isWorker = viewer?.role === USER_TYPE_WORKER;
    const initialDesigner = designerId || (isWorker ? viewer?.id : 'ALL');
    const initialStatus = ['SUBMITTED', 'ONGOING_PROJECT'];

    const handleFieldsChange = (name, value) => {
        const filterValues = getFieldsValue();

        const changedFilters = { ...filterValues, [name]: value };
        const defaultFilters = {
            account: 'ALL',
            designer: initialDesigner,
            keyword: '',
            product: 'ALL',
            status: initialStatus,
            team: 'ALL',
        };
        setHasEdit(!isEqual(changedFilters, defaultFilters));
        onChangeFilters({ ...filterValues, [name]: value });
    };

    const handleResetForm = () => {
        resetFields();
        setHasEdit(false);
        onChangeFilters({
            account: 'ALL',
            designer: initialDesigner,
            keyword: '',
            product: 'ALL',
            status: initialStatus,
            team: 'ALL',
        });
    };

    return (
        <Box mb="20">
            <Form>
                <Box d="flex" alignItems="center" mx="-10">
                    <Box maxW="404" flex="1" mx="10">
                        <Form.Item label="" colon={false} required={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('keyword', {
                                initialValue: '',
                            })(
                                <SearchInput
                                    onChangeText={value => handleFieldsChange('keyword', value)}
                                    onClear={() => {
                                        setFieldsValue({ keyword: '' });
                                        handleFieldsChange('keyword', '');
                                    }}
                                    placeholder="Search by request, account, user"
                                />
                            )}
                        </Form.Item>
                    </Box>
                    <Box maxW={isWorker ? '180' : '140'} flex="1" mx="10">
                        <Form.Item label="" colon={false} required={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('team', {
                                initialValue: 'ALL',
                            })(<TeamField onFieldChange={handleFieldsChange} />)}
                        </Form.Item>
                    </Box>
                    <Box maxW={isWorker ? '356' : '226'} flex="1" mx="10">
                        <Form.Item label="" colon={false} required={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('account', {
                                initialValue: 'ALL',
                            })(<AccountField isWorker={isWorker} onFieldChange={handleFieldsChange} />)}
                        </Form.Item>
                    </Box>
                    <Box maxW={isWorker ? '200' : '170'} flex="1" mx="10">
                        <Form.Item label="" colon={false} required={false} style={{ marginBottom: 0 }}>
                            {getFieldDecorator('designer', {
                                initialValue: initialDesigner,
                            })(
                                <DesignerField isWorker={!!designerId || isWorker} onFieldChange={handleFieldsChange} />
                            )}
                        </Form.Item>
                    </Box>
                    {!isWorker && (
                        <Box maxW="180" flex="1" mx="10">
                            <Form.Item label="" colon={false} required={false} style={{ marginBottom: 0 }}>
                                {getFieldDecorator('product', {
                                    initialValue: 'ALL',
                                })(<ProductField onFieldChange={handleFieldsChange} />)}
                            </Form.Item>
                        </Box>
                    )}
                </Box>
                <Box d="flex" alignItems="center" pt="20">
                    <Text textVariant="H6" colorScheme="primary" mr="20">
                        Status
                    </Text>
                    <Form.Item label="" colon={false} required={false} style={{ marginBottom: 0 }}>
                        {getFieldDecorator('status', {
                            initialValue: initialStatus,
                        })(<StatusField isWorker={true} handleFieldsChange={handleFieldsChange} />)}
                    </Form.Item>
                    {hasEdit && (
                        <Text ml="20" textVariant="H6" colorScheme="cta" onClick={handleResetForm} cursor="pointer">
                            Reset Filters
                        </Text>
                    )}
                </Box>
            </Form>
        </Box>
    );
});

export default Form.create()(RequestsTabFilter);
