import React from 'react';

const IconEdit = props => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.9999 12.84C11.85 12.8388 11.7051 12.7858 11.5899 12.69L8.11985 9.77C8.05089 9.71797 7.993 9.65272 7.94955 9.57806C7.90609 9.5034 7.87796 9.42083 7.86679 9.33518C7.85562 9.24952 7.86163 9.1625 7.88447 9.07919C7.90732 8.99588 7.94654 8.91797 7.99985 8.85L14.7499 0.849996C14.8042 0.784283 14.8711 0.730088 14.9466 0.690591C15.0222 0.651094 15.1049 0.627093 15.1898 0.619996C15.2743 0.611502 15.3596 0.620432 15.4404 0.646234C15.5213 0.672035 15.5959 0.714159 15.6599 0.769996L19.1498 3.69C19.2159 3.74492 19.2703 3.81237 19.3101 3.88847C19.3499 3.96456 19.3742 4.04778 19.3816 4.13332C19.3891 4.21886 19.3795 4.30503 19.3534 4.38684C19.3274 4.46866 19.2854 4.54451 19.2298 4.61L12.4798 12.61C12.4202 12.6796 12.3467 12.7359 12.2641 12.7755C12.1814 12.8151 12.0914 12.8371 11.9999 12.84ZM9.44985 9.19L11.9398 11.28L17.8499 4.28L15.3598 2.14L9.44985 9.19Z" />
      <path d="M7.62001 14.36C7.46068 14.358 7.30766 14.2975 7.19001 14.19C7.11005 14.1122 7.0508 14.0156 7.01762 13.9091C6.98444 13.8026 6.97838 13.6895 7.00001 13.58L7.90001 9.14C7.92404 9.02847 7.97614 8.92492 8.05139 8.83917C8.12665 8.75342 8.22255 8.68831 8.33001 8.65C8.44205 8.60724 8.56363 8.59582 8.68167 8.61697C8.79972 8.63811 8.90977 8.69101 9.00001 8.77L12.48 11.69C12.5694 11.7638 12.637 11.8604 12.6758 11.9696C12.7145 12.0788 12.7229 12.1964 12.7 12.31C12.6803 12.423 12.6311 12.5288 12.5574 12.6166C12.4836 12.7044 12.3879 12.7711 12.28 12.81L7.83001 14.32C7.76354 14.3477 7.69202 14.3614 7.62001 14.36ZM9.00001 10.47L8.53001 12.72L10.73 12L9.00001 10.47Z" />
      <path d="M18.5901 19.42H1.23008C1.05769 19.42 0.892357 19.3515 0.770459 19.2296C0.64856 19.1077 0.580078 18.9424 0.580078 18.77V1.87998C0.580078 1.70759 0.64856 1.54226 0.770459 1.42036C0.892357 1.29846 1.05769 1.22998 1.23008 1.22998H10.6201C10.7925 1.22998 10.9578 1.29846 11.0797 1.42036C11.2016 1.54226 11.2701 1.70759 11.2701 1.87998C11.2701 2.05237 11.2016 2.2177 11.0797 2.3396C10.9578 2.4615 10.7925 2.52998 10.6201 2.52998H1.88008V18.12H17.9401V8.72998C17.9401 8.55759 18.0086 8.39226 18.1305 8.27036C18.2524 8.14846 18.4177 8.07998 18.5901 8.07998C18.7625 8.07998 18.9278 8.14846 19.0497 8.27036C19.1716 8.39226 19.2401 8.55759 19.2401 8.72998V18.73C19.2455 18.8186 19.2328 18.9075 19.2026 18.991C19.1724 19.0746 19.1254 19.151 19.0644 19.2157C19.0035 19.2803 18.93 19.3318 18.8484 19.367C18.7668 19.4021 18.6789 19.4201 18.5901 19.42Z" />
    </svg>
  );
};

export default IconEdit;
