import gql from 'graphql-tag';

export const RESET_PASSWORD_TOKEN = gql`
    query tokenResetPassword($id: ID!) {
        getTokenResetPassword(id: $id) {
            id
            email
        }
    }
`;
