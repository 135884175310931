import React, { forwardRef } from 'react';
import { Col, Row } from 'antd';
import { Card } from '@components/Card';
import { Text } from '@components/Text';
import { Skeleton } from '@components/Skeleton';
import { Image } from '@components/Image';
import defaultImage from '@assets/icons/default-image.svg';

export const FieldCategoryRequest = forwardRef(({ value, categories = [], onChange, handleChange, loading }, ref) => {
    const handleChangeCategory = category => {
        onChange(value !== category.id ? category.id : undefined);
        handleChange(value !== category.id ? category : undefined);
    };

    return (
        <Row gutter={20}>
            {loading && (
                <>
                    <Col lg={6}>
                        <Skeleton w="100%" h="140" px="26" mb="20" />
                    </Col>
                    <Col lg={6}>
                        <Skeleton w="100%" h="140" px="26" mb="20" />
                    </Col>
                    <Col lg={6}>
                        <Skeleton w="100%" h="140" px="26" mb="20" />
                    </Col>
                    <Col lg={6}>
                        <Skeleton w="100%" h="140" px="26" mb="20" />
                    </Col>
                    <Col lg={6}>
                        <Skeleton w="100%" h="140" px="26" mb="20" />
                    </Col>
                    <Col lg={6}>
                        <Skeleton w="100%" h="140" px="26" mb="20" />
                    </Col>
                </>
            )}
            {!loading &&
                Array.isArray(categories) &&
                categories.length > 0 &&
                categories.map(category => (
                    <Col key={category.id} lg={6}>
                        <Card
                            h="140"
                            px="26"
                            alignItems="center"
                            hoverable
                            flexDir="column"
                            justifyContent="center"
                            mb="20"
                            isActive={value === category.id}
                            onClick={() => handleChangeCategory(category)}
                        >
                            <Image
                                src={category.icon?.url}
                                name={category.title}
                                size={76}
                                isBorderLess
                                imageProps={{ objectFit: 'contain' }}
                                fallbackSrc={defaultImage}
                            />
                            <Text textVariant="Badge" pt="8" colorScheme="primary" textAlign="center">
                                {category.title}
                            </Text>
                        </Card>
                    </Col>
                ))}
        </Row>
    );
});
