import React from 'react';

const IconPlus = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.19621 13.587C7.07554 13.587 6.95981 13.5391 6.87448 13.4538C6.78915 13.3684 6.74121 13.2527 6.74121 13.132V1.00102C6.74121 0.880347 6.78915 0.764616 6.87448 0.679287C6.95981 0.593958 7.07554 0.546021 7.19621 0.546021C7.31688 0.546021 7.43262 0.593958 7.51794 0.679287C7.60327 0.764616 7.65121 0.880347 7.65121 1.00102V13.132C7.65121 13.2527 7.60327 13.3684 7.51794 13.4538C7.43262 13.5391 7.31688 13.587 7.19621 13.587Z"
                fill="currentColor"
            />
            <path
                d="M13.3002 7.51803H1.1272C1.01203 7.50929 0.904313 7.45773 0.825276 7.37351C0.746239 7.28929 0.701614 7.17852 0.700195 7.06303C0.702002 6.94292 0.750519 6.82824 0.83546 6.7433C0.9204 6.65836 1.03508 6.60984 1.1552 6.60803H13.3002C13.4209 6.60803 13.5366 6.65597 13.6219 6.7413C13.7073 6.82663 13.7552 6.94236 13.7552 7.06303C13.7552 7.18371 13.7073 7.29944 13.6219 7.38477C13.5366 7.4701 13.4209 7.51803 13.3002 7.51803Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconPlus;
