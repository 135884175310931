import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink, split } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { getMainDefinition } from 'apollo-utilities';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import message from '@components/Message';
import { API_LINK } from './general';

const isProd = process.env.NODE_ENV === 'production';


const httpLink = createUploadLink({ uri: API_LINK });

// split based on operation type
const splitFn = (hasWs = false) => ({ query }) => {
    const { kind, operation } = getMainDefinition(query);

    return hasWs ? kind === 'OperationDefinition' && operation === 'subscription' : kind === 'OperationDefinition';
};

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
let link = split(splitFn(), httpLink);

const setAuthorizationLink = setContext((_, { headers }) => {
    const token = localStorage ? localStorage.getItem('token') : '';
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) console.log(`[Network error]: ${networkError}`);

    if (graphQLErrors) {
        graphQLErrors.forEach(({ extensions, message: errMessage, locations, path }) => {
            if (!isProd) {
                message.destroy();
                message.error('Something went wrong. Please check console log.');
                console.log(
                    `[GraphQL error]: Message: ${errMessage}, Code: ${
                        extensions ? extensions.code : ''
                    }, Location: ${locations}, Path: ${path}`
                );
            }
        });
    }
});

const cache = new InMemoryCache({
    logger: console.log,
    addTypename: true,
    freezeResults: true,
});

const client = new ApolloClient({
    link: ApolloLink.from([onErrorLink, setAuthorizationLink, link]),
    cache,
    name: 'web-admin',
    version: '1.0',
    connectToDevTools: isProd,
});

export default client;

export function openProtectedDownloadLink(pathname) {
    const token = localStorage.getItem('token');

    if (!token) {
        throw new Error('No token');
    }

    if (!API_LINK) {
        throw new Error('No API base URL');
    }

    window.open(`${API_LINK}${pathname}?token=${token}`, '_blank');
}
