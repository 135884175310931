import React, { useState, useRef } from 'react';
import { Form } from '@components/Form';
import { AttachmentPlugin, Wysiwyg } from '@components/Wysiwyg';
import { FieldMove } from './FieldMove';
import { Box } from '@components/Box';
import { Button } from '@components/Button';

const FormReopenRequest = ({ form, onSuccessSubmit }) => {
    const editorRef = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [fileIds, setFileIds] = useState([]);
    const [hasSent, setHasSent] = useState(false);
    const [isUploadingFiles, setIsUploadingFiles] = useState(false);

    const handleSubmit = ev => {
        ev.preventDefault();
        ev.stopPropagation();

        form.validateFields(async (err, values) => {
            if (err && isSubmitting) {
                return;
            }

            if (onSuccessSubmit) {
                setIsSubmitting(true);
                await onSuccessSubmit({ ...values, fileIds });
                setIsSubmitting(false);
            }
        });
    };

    const handleGetFileIds = attachmentIds => {
        setFileIds(attachmentIds);
    };

    const handleRemoveFileId = (attachmentId, index) => {
        const filteredFileIds = fileIds.filter((fileId, fileIndex) =>
            attachmentId !== null ? fileId !== attachmentId : fileIndex !== index
        );
        setFileIds(filteredFileIds);
    };

    const resetSentStatus = () => {
        setHasSent(false);
    };

    const handleUploadingFiles = bool => {
        setIsUploadingFiles(bool);
    };

    const onBlurEditor = () => {
        if (editorRef?.current) {
            editorRef.current.editor.blur();
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Item label="Message" colon={false} required={false}>
                {form.getFieldDecorator('message')(
                    <Wysiwyg
                        ref={editorRef}
                        placeholder="Type your message here"
                        contentMinHeight="120px"
                        isFlip
                        toolbarColor="gray"
                        toolbarRight={
                            <AttachmentPlugin
                                onSetFileIds={handleGetFileIds}
                                onRemoveFileId={handleRemoveFileId}
                                onResetSentStatus={resetSentStatus}
                                onUploadingFiles={handleUploadingFiles}
                                onBlurEditor={onBlurEditor}
                                hasSent={hasSent}
                            />
                        }
                    />
                )}
            </Form.Item>
            <Form.Item label="Move to" colon={false} required={false}>
                {form.getFieldDecorator('move')(<FieldMove showRadio />)}
            </Form.Item>
            <Box textAlign="right">
                <Button disabled={isUploadingFiles} type="primary" htmlType="submit" loading={isSubmitting}>
                    Reopen request
                </Button>
            </Box>
        </Form>
    );
};

export default Form.create()(FormReopenRequest);
