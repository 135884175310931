import React from 'react';

const IconPause = ({ fill = 'none' }) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.36006 19.26H1.21006C1.03767 19.26 0.872338 19.1915 0.750439 19.0696C0.628541 18.9477 0.560059 18.7824 0.560059 18.61V1.61C0.570254 1.44464 0.643211 1.2894 0.764022 1.17603C0.884834 1.06265 1.04438 0.999687 1.21006 1H7.36006C7.52564 1.00237 7.68407 1.06786 7.80302 1.18308C7.92196 1.2983 7.99243 1.45458 8.00006 1.62V18.62C7.99748 18.7889 7.92922 18.9502 7.80975 19.0697C7.69029 19.1892 7.52899 19.2574 7.36006 19.26ZM1.86006 18H6.71006V2.27H1.86006V18Z"
        fill="currentColor"
      />
      <path
        d="M18.6198 19.26H12.4698C12.2974 19.26 12.1321 19.1915 12.0102 19.0696C11.8883 18.9477 11.8198 18.7824 11.8198 18.61V1.61C11.83 1.44464 11.903 1.2894 12.0238 1.17603C12.1446 1.06265 12.3041 0.999687 12.4698 1H18.6198C18.7922 1 18.9575 1.06848 19.0794 1.19038C19.2013 1.31228 19.2698 1.47761 19.2698 1.65V18.65C19.2596 18.8154 19.1867 18.9706 19.0659 19.084C18.945 19.1974 18.7855 19.2603 18.6198 19.26ZM13.1198 18H17.9998V2.27H13.1198V18Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconPause;
