import React, { useState, useEffect } from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { notification } from 'antd';
import { STRIPE_PK } from '@constants/general';

const StripeLoader = ({ children }) => {
    const [stripe, setStripe] = useState(null);

    useEffect(() => {
        if (window.Stripe) {
            setStripe(window.Stripe(STRIPE_PK));
        } else {
            const stripeScript = document.querySelector('#stripe-js');

            if (stripeScript) {
                stripeScript.addEventListener('load', () => {
                    setStripe(window.Stripe(STRIPE_PK));
                });
            }
        }
        notification.config({
            placement: 'bottomRight',
        });
    }, []);

    return <StripeProvider stripe={stripe}>{children}</StripeProvider>;
};

export default StripeLoader;
