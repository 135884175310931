import React from 'react';

const IconFinish = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 8.20695L6.05166 14.4001L16 4.21931L13.4406 1.6001L6.05171 9.16168L2.55939 5.58774L0 8.20695ZM13.4405 2.19195L15.4216 4.21932L6.05157 13.8083L6.05166 13.8083L15.4218 4.21931L13.4406 2.19185L13.4405 2.19195ZM2.55939 6.17949L6.05162 9.75333L6.05152 9.75344L2.55929 6.17959L2.55939 6.17949ZM6.05157 10.3703L6.05154 10.3702L13.4404 2.80867L13.4404 2.8087L6.05157 10.3703ZM6.05147 13.1915L6.0515 13.1915L1.18082 8.20696L2.55923 6.79634L2.5592 6.79631L1.18076 8.20696L6.05147 13.1915Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconFinish;
