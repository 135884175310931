import React, { memo, useCallback, useState } from 'react';
import { Form } from '@components/Form';
import { useMutation } from 'react-apollo';
import { FormItemFlex } from '@components/FormItemFlex';
import { Text } from '@components/Text';
import { Box } from '@components/Box';
import { Input } from '@components/Input';
import { Button } from '@components/Button';
import message from '@components/Message';
import { COMPANY_ROLE_MEMBER } from '@constants/account';
import { UPDATE_PROFILE } from '@graphql/mutations/user';
import UploadPhoto from './UploadPhoto';

const ProfileInfoForm = memo(({ user, form }) => {
    const { getFieldDecorator, validateFields } = form;
    const { id: userId, firstname, lastname, email, picture, companyRole, job } = user || {};
    const { url: profilePic } = picture || {};
    const [isLoading, setIsLoading] = useState(false);
    const [updateUserProfile] = useMutation(UPDATE_PROFILE);

    const handleSubmitProfileInfo = useCallback(
        e => {
            e.preventDefault();
            validateFields(async (err, values) => {
                if (!err && !isLoading) {
                    message.destroy();
                    message.loading('Updating profile...', 50000);
                    setIsLoading(true);

                    console.log(12312312)

                    try {
                        await updateUserProfile({ variables: { ...values, userId } });
                        message.destroy();
                        message.success('Profile has been updated');
                        setIsLoading(false);
                        return true;
                    } catch (err) {
                        setIsLoading(false);
                        console.log(err);
                        message.destroy();
                        const errors = err.graphQLErrors || [];
                        const formErrorMessage = errors.length > 0 ? errors[0].message : 'Error on updating profile';
                        message.error(formErrorMessage);
                        return false;
                    }
                }
            });
        },
        [isLoading, validateFields, userId, updateUserProfile]
    );

    return (
        <>
            <Text textVariant="H5" colorScheme="primary" mt="30">
                Profile information
            </Text>
            <UploadPhoto userId={userId} profilePic={profilePic} />
            <Box mt="20" mb="30">
                <Form onSubmit={handleSubmitProfileInfo}>
                    <FormItemFlex justifyContent="space-between" itemWidthPct={30}>
                        <Form.Item label="First name" colon={false} required={false}>
                            {getFieldDecorator('firstname', {
                                initialValue: firstname || '',
                                rules: [
                                    {
                                        required: true,
                                        message: 'This field cannot be empty',
                                    },
                                ],
                            })(<Input placeholder="Enter your first name" />)}
                        </Form.Item>
                        <Form.Item label="Last name" colon={false} required={false}>
                            {getFieldDecorator('lastname', {
                                initialValue: lastname || '',
                                rules: [
                                    {
                                        required: true,
                                        message: 'This field cannot be empty',
                                    },
                                ],
                            })(<Input placeholder="Enter your last name" />)}
                        </Form.Item>
                        <Form.Item label="Email" colon={false} required={false}>
                            {getFieldDecorator('email', {
                                initialValue: email || '',
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email address',
                                    },
                                    {
                                        required: true,
                                        message: 'Please enter a valid email address',
                                    },
                                ],
                            })(<Input placeholder="Enter your email address" />)}
                        </Form.Item>
                        {companyRole && companyRole !== COMPANY_ROLE_MEMBER && (
                            <Form.Item label="Job title" colon={false} required={false}>
                                {getFieldDecorator('jobtitle', {
                                    initialValue: job || '',
                                    rules: [
                                        {
                                            required: true,
                                            message: 'This field cannot be empty',
                                        },
                                    ],
                                })(<Input placeholder="Enter your job title" />)}
                            </Form.Item>
                        )}
                    </FormItemFlex>
                    <Form.Item>
                        <Button
                            w={['100%', 'auto']}
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                        >
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            </Box>
        </>
    );
});

export default Form.create()(ProfileInfoForm);
