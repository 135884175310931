import React from 'react';
import { Box } from '@components/Box';
import { SectionHeader } from './SectionHeader';
import { DroppableRequest } from './DroppableRequest';
import { RowItemRequest } from './RowItemRequest';
import { DropdownActionRequest } from './DropdownActionRequest';

export const SectionActiveRequest = ({
  data,
  isNotCustomer = false,
  refetch,
  enableDrag,
  enableDrop,
}) => {

  return (
    <Box pt="10">
      <SectionHeader
        title="Active"
        total={Array.isArray(data) ? data.length : 0}
      />
      {Array.isArray(data) && (
        <DroppableRequest name="active">
          {data.map((item, index) => (
            <RowItemRequest
              key={item.id}
              owners={item.owners}
              id={item.id}
              index={index}
              enableDrag={enableDrag}
              enableDrop={enableDrop}
              name={item.name}
              category={item.category}
              workers={item.workers}
              date={item.status === 'SUBMITTED' ? item.submittedAt : item.updatedAt}
              dateLabel={item.status === 'SUBMITTED' ? "Submitted" : "Last Updated"}
              status={item.status}
              brand={item.brand}
              unreadMessages={item.unreadMessages}
              iconProps={{
                borderColor: 'cta',
              }}
              optionComponent={DropdownActionRequest}
              optionProps={{
                id: item.id,
                enableComplete: true,
                enableDuplicate: true,
                enableDownload: true,
                enableDelete: true,
                enablePause: true,
                refetch,
                status: item.status,
                lastFolder: item?.previews?.length
                  ? item.previews[item?.previews?.length - 1]
                  : null,
                requestName: item.name,
              }}
              isNotCustomer={isNotCustomer}
            />
          ))}
        </DroppableRequest>
      )}
    </Box>
  );
};
