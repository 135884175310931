import styled, { css } from 'styled-components';
import { Select as AntdSelect } from 'antd';
import * as theme from '../Theme';

export const selectCss = css`
    .ant-select-auto-complete.ant-select .ant-input,
    .ant-select-selection {
        border-radius: 0;
        border-width: 1px;
        border-style: solid;
        border-color: ${theme.COLOR_OUTLINE_GRAY};
        height: 40px;
    }

    .ant-select-selection {
        &:hover,
        &:focus {
            border-color: ${theme.COLOR_CTA};
        }
    }

    .ant-select-selection__rendered,
    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
        ${theme.TYPO_P4}
    }

    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
        color: ${theme.COLOR_TEXT_TERTIARY};
    }

    .ant-select-selection__rendered {
        line-height: 38px;
        margin-left: 16px;
    }

    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
        height: 40px;
        line-height: 40px;
        top: 0;
        margin-top: 0;
    }

    .ant-select-combobox {
        .ant-select-arrow {
            display: block;
            position: absolute;
            font-size: 16px;
            color: #07122b;
            top: 18px;
        }
    }

    .ant-select-selection.ant-select-selection--multiple {
        height: auto;
        padding-bottom: 0;

        .ant-select-selection__rendered {
            margin-left: 6px;
            margin-right: 6px;
            margin-top: 6px;
            line-height: 32px;

            .ant-select-selection__placeholder {
                height: 30px;
                line-height: 30px;
            }

            > ul > li {
                margin-right: 8px;
                margin-bottom: 8px;
                height: 28px;
                line-height: 28px;
                margin-top: 0;
            }

            .ant-select-selection__choice {
                background-color: ${props => (props.isDisabled ? '#ffffff' : '#f5f5f5')};
                border: none;
                padding: ${props => (props.isDisabled ? '5px 8px' : '5px 30px 5px 8px')};

                .ant-select-selection__choice__content {
                    ${theme.TYPO_P5}
                    color: ${props => (props.isDisabled ? '#6A7180' : '#07122B')};
                }

                .ant-select-selection__choice__remove {
                    font-size: 16px;
                    line-height: 20px;
                    right: 9px;
                }
            }
        }
    }

    .ant-select-selection--multiple .ant-select-arrow {
        top: 50%;
    }

    &.ant-select-disabled {
      .ant-select-selection.ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__choice {
        background-color: #ffffff;
        padding: 5px 8px;
        border-radius: 2px;

        .ant-select-selection__choice__content {
          color: #6A7180;
        }
      }
    }
`;

export const designerFieldSelectCss = css`
    .ant-select-auto-complete.ant-select .ant-input,
    .ant-select-selection {
        border-radius: 0;
        border-width: 1px;
        border-style: solid;
        border-color: ${theme.COLOR_OUTLINE_GRAY};
        height: 40px;
    }

    .ant-select-selection {
        &:hover,
        &:focus {
            border-color: ${theme.COLOR_CTA};
        }
    }

    .ant-select-selection__rendered,
    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
        ${theme.TYPO_P4}
    }

    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
        color: ${theme.COLOR_TEXT_TERTIARY};
    }

    .ant-select-selection__rendered {
        line-height: 38px;
        margin-left: 16px;
    }

    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
        height: 40px;
        line-height: 40px;
        top: 0;
        margin-top: 0;
    }

    .ant-select-combobox {
        .ant-select-arrow {
            display: block;
            position: absolute;
            font-size: 16px;
            color: #07122b;
            top: 18px;
        }
    }

    .ant-select-selection.ant-select-selection--multiple {
        padding-bottom: 0;

        .ant-select-selection__rendered {
            margin-left: 6px;
            margin-right: 30px;
            margin-top: 6px;
            line-height: 32px;
            overflow: hidden;

            .ant-select-selection__placeholder {
                height: 30px;
                line-height: 30px;
            }

            > ul {
                width: 999px;
                display: flex;
            }

            > ul > li {
                margin-right: 8px;
                margin-bottom: 8px;
                height: 28px;
                line-height: 28px;
                margin-top: 0;
            }

            > ul > li.ant-select-search.ant-select-search--inline {
                display: none;
            }

            .ant-select-selection__choice {
                background-color: ${props => (props.isFromDesignerRequestDetails ? '#ffffff' : '#f5f5f5')};
                border: none;
                padding: ${props => (props.isFromDesignerRequestDetails ? '5px 8px' : '5px 30px 5px 8px')};

                .ant-select-selection__choice__content {
                    ${theme.TYPO_P5}
                    color: #6A7180;
                }

                .ant-select-selection__choice__remove {
                    font-size: 16px;
                    line-height: 20px;
                    right: 9px;
                }
            }
        }
    }

    .ant-select-selection--multiple .ant-select-arrow {
        top: 50%;
    }
`;

export const Select = styled(AntdSelect)`
    ${props => (props.horizontalOverflow ? designerFieldSelectCss : selectCss)}
`;
