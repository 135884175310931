import React from 'react';

// Used for breadcrumb, since its small (10x10)
export const IconRightArrow = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.86449 5.37C6.77603 5.369 6.69155 5.33306 6.62949 5.27L2.9045 1.455C2.87426 1.42481 2.85027 1.38896 2.8339 1.3495C2.81754 1.31003 2.80911 1.26772 2.80911 1.225C2.80911 1.18227 2.81754 1.13997 2.8339 1.1005C2.85027 1.06104 2.87426 1.02518 2.9045 0.994999C2.96609 0.935133 3.0486 0.901642 3.13449 0.901642C3.22039 0.901642 3.3029 0.935133 3.36449 0.994999L7.09449 4.82C7.12458 4.84976 7.14846 4.88519 7.16476 4.92424C7.18106 4.96329 7.18945 5.00518 7.18945 5.0475C7.18945 5.08981 7.18106 5.13171 7.16476 5.17076C7.14846 5.20981 7.12458 5.24524 7.09449 5.275C7.06462 5.30562 7.02882 5.32984 6.98927 5.34617C6.94973 5.36251 6.90727 5.37061 6.86449 5.37Z"
                fill="currentColor"
            />
            <path
                d="M3.13418 9.095C3.09149 9.09505 3.0492 9.08668 3.00973 9.07038C2.97027 9.05408 2.9344 9.03016 2.90418 9C2.84432 8.9384 2.81083 8.85589 2.81083 8.77C2.81083 8.6841 2.84432 8.60159 2.90418 8.54L6.63418 4.815C6.66439 4.78479 6.70025 4.76083 6.73971 4.74449C6.77917 4.72814 6.82147 4.71973 6.86418 4.71973C6.9069 4.71973 6.9492 4.72814 6.98866 4.74449C7.02812 4.76083 7.06398 4.78479 7.09418 4.815C7.12439 4.8452 7.14835 4.88106 7.16469 4.92052C7.18104 4.95998 7.18945 5.00228 7.18945 5.045C7.18945 5.08771 7.18104 5.13001 7.16469 5.16947C7.14835 5.20893 7.12439 5.24479 7.09418 5.275L3.36418 9C3.33449 9.03086 3.29872 9.05524 3.25913 9.07159C3.21955 9.08794 3.177 9.09591 3.13418 9.095Z"
                fill="currentColor"
            />
        </svg>
    );
};
