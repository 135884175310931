import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { Form } from '@components/Form';
import { Button } from '@components/Button';
import message from '@components/Message';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Input } from '@components/Input';
import { USER_RESET_PASSWORD } from '@graphql/mutations/auth';

const ResetPasswordForm = ({ form, onSuccess, userId }) => {
    const { getFieldDecorator, validateFields, getFieldValue } = form;
    const [userResetPassword] = useMutation(USER_RESET_PASSWORD);
    const [isLoading, setIsLoading] = useState(false);

    const handleOnSubmit = e => {
        e.preventDefault();

        validateFields(async (err, values) => {
            if (!err && !isLoading) {
                setIsLoading(true);
                try {
                    message.destroy();
                    message.loading('Resetting password...');

                    await userResetPassword({ variables: { id: userId, password: values.password } });
                    setIsLoading(false);

                    message.destroy();
                    onSuccess();
                } catch (e) {
                    console.error(e);
                    setIsLoading(false);
                }
            }
        });
    };

    const compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== getFieldValue('password')) {
            callback('The two passwords do not match');
        } else {
            callback();
        }
    };

    return (
        <Box d="flex" justifyContent="center" alignItems="center" h={['auto', '70vh']}>
            <Box maxW="480" w="100%">
                <Text textVariant="H3" colorScheme="headline" mb="29">
                    Reset Password
                </Text>
                <Form onSubmit={handleOnSubmit}>
                    <Form.Item label="New password" colon={false} required={false}>
                        {getFieldDecorator('password', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter new password',
                                },
                            ],
                        })(<Input type="password" />)}
                    </Form.Item>
                    <Form.Item label="Confirm new password" colon={false} required={false}>
                        {getFieldDecorator('confirm', {
                            validateTrigger: 'onBlur',
                            rules: [
                                {
                                    required: true,
                                    message: 'Please confirm new password',
                                },
                                {
                                    validator: compareToFirstPassword,
                                },
                            ],
                        })(<Input type="password" />)}
                    </Form.Item>
                    <Button w="100%" type="primary" htmlType="submit" loading={isLoading}>
                        Reset password
                    </Button>
                </Form>
            </Box>
        </Box>
    );
};

export default Form.create()(ResetPasswordForm);
