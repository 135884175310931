import { css } from 'styled-components';
import * as theme from '../Theme';

export const selectGlobalCss = css`
    .ant-select-dropdown {
        border-radius: 0;
    }

    .ant-select-dropdown-menu-item {
        ${theme.TYPO_P4}
        height: 38px;
        line-height: 38px;
        padding: 0 15px;
        color: ${theme.COLOR_TEXT_PRIMARY};
    }

    .ant-select-dropdown-menu {
        max-height: 320px;
    }

    .ant-select-dropdown--empty .ant-select-dropdown-menu-item {
        height: 75px;
    }
`;
