import React from 'react';

const IconDragHandler = () => {
  return (
    <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="2.5" cy="2" r="2" fill="#C7CBD4" />
      <circle cx="9.5" cy="2" r="2" fill="#C7CBD4" />
      <circle cx="2.5" cy="9" r="2" fill="#C7CBD4" />
      <circle cx="9.5" cy="9" r="2" fill="#C7CBD4" />
      <circle cx="2.5" cy="16" r="2" fill="#C7CBD4" />
      <circle cx="9.5" cy="16" r="2" fill="#C7CBD4" />
    </svg>
  );
};

export default IconDragHandler;
