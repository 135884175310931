import React, { forwardRef, useState } from 'react';
import { useQuery } from 'react-apollo';
import { Col, Row } from 'antd';
import IconAdd from '@components/Svg/IconAdd';
import { Card } from '@components/Card';
import { Text } from '@components/Text';
import { Popup } from '@components/Popup';
import FormBrand from '@pages/Customer/Brands/blocks/FormBrand';
import { CUSTOMER_BRANDS } from '@graphql/queries/user';
import { Skeleton } from '@components/Skeleton';
import Avatar from '@components/Avatar';

export const FieldBrand = forwardRef(({ value, onChange, viewer, windowWidth }, ref) => {
    const [activePopup, showPopup] = useState(false);
    const { data, loading, refetch } = useQuery(CUSTOMER_BRANDS, { variables: { id: viewer.id } });
    const brands = data?.User?.company?.brands || [];

    const handleSuccessCreate = async () => {
        await refetch();
        showPopup(false);
    };

    return (
        <Row gutter={20}>
            <Col lg={8}>
                <Card h="80" px="26" mb="20" alignItems="center" hoverable onClick={() => showPopup(true)}>
                    <IconAdd style={{ fontSize: 30 }} />
                    <Text textVariant="Badge" pl="16" colorScheme="primary">
                        Create brand
                    </Text>
                </Card>
            </Col>
            {loading && (
                <>
                    <Col lg={8}>
                        <Skeleton w="100%" h="80" px="26" mb="20" />
                    </Col>
                    <Col lg={8}>
                        <Skeleton w="100%" h="80" px="26" mb="20" />
                    </Col>
                    <Col lg={8}>
                        <Skeleton w="100%" h="80" px="26" mb="20" />
                    </Col>
                </>
            )}
            {!loading &&
                Array.isArray(brands) &&
                brands.length > 0 &&
                brands.map(brand => (
                    <Col key={brand.id} lg={8}>
                        <Card
                            h="80"
                            px="26"
                            alignItems="center"
                            hoverable
                            mb="20"
                            isActive={value === brand.id}
                            onClick={() => onChange(value !== brand.id ? brand.id : undefined)}
                        >
                            <Avatar
                                src={(brand.logos && brand.logos[0]?.url) ? brand.logos[0].url : undefined}
                                name={brand.name}
                                size={51}
                                fontSize={14}
                            />
                            <Text textVariant="Badge" pl="16" colorScheme="primary" maxW="180" isTruncate>
                                {brand.name}
                            </Text>
                        </Card>
                    </Col>
                ))}
            <Popup
                visible={activePopup}
                onCancel={() => showPopup(false)}
                variant="default"
                footer={null}
                centered
                width={windowWidth > 768 ? 900 : '100%'}
                destroyOnClose
            >
                <FormBrand viewer={viewer} onSuccessSubmit={handleSuccessCreate} onCancel={() => showPopup(false)} />
            </Popup>
        </Row>
    );
});
