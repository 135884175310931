import React from 'react';

const IconFileAi = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.32 19.95H2.68V2.83999L6.09 0.0499878H17.32V19.95Z" fill="#311902" />
            <path d="M2.68 2.83999H5.9V0.0499878L2.68 2.83999Z" fill="#F98924" />
            <path
                d="M8.61 9.14999H9.39L11.26 13.55H10.19L9.82 12.62H8.15L7.79 13.55H6.79L8.61 9.14999ZM9 10.43L8.45 11.8H9.5L9 10.43Z"
                fill="#F88824"
            />
            <path
                d="M11.52 9.58C11.5178 9.43552 11.5716 9.29578 11.67 9.19C11.7176 9.13471 11.7757 9.08945 11.8409 9.05683C11.9062 9.02421 11.9772 9.00489 12.05 9C12.1192 9.0007 12.1876 9.01521 12.2511 9.04269C12.3147 9.07016 12.3721 9.11005 12.42 9.16C12.5212 9.26478 12.5785 9.40433 12.58 9.55C12.578 9.69262 12.5206 9.82887 12.42 9.93C12.3721 9.97995 12.3147 10.0198 12.2511 10.0473C12.1876 10.0748 12.1192 10.0893 12.05 10.09C11.9074 10.088 11.7711 10.0306 11.67 9.93C11.5792 9.83542 11.5258 9.71101 11.52 9.58ZM11.59 10.53H12.5V13.53H11.59V10.53Z"
                fill="#F88824"
            />
        </svg>
    );
};

export default IconFileAi;
