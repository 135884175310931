import React from 'react';

const IconSaveAsDraft = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.83 18.86H7.12C6.96785 18.8407 6.82796 18.7666 6.72656 18.6515C6.62516 18.5365 6.56921 18.3884 6.56921 18.235C6.56921 18.0816 6.62516 17.9335 6.72656 17.8185C6.82796 17.7034 6.96785 17.6293 7.12 17.61H18.2V5.61L15.55 1.89H4.41V11.89C4.42124 11.9787 4.41347 12.0688 4.3872 12.1543C4.36094 12.2398 4.31678 12.3187 4.25765 12.3857C4.19853 12.4528 4.12581 12.5066 4.04431 12.5434C3.96282 12.5802 3.87442 12.5992 3.785 12.5992C3.69558 12.5992 3.60718 12.5802 3.52569 12.5434C3.44419 12.5066 3.37147 12.4528 3.31234 12.3857C3.25322 12.3187 3.20906 12.2398 3.18279 12.1543C3.15653 12.0688 3.14876 11.9787 3.16 11.89V1.26C3.16262 1.09465 3.23016 0.936968 3.34802 0.820974C3.46588 0.704981 3.62463 0.63998 3.79 0.640001H15.87C15.9699 0.637419 16.0689 0.659851 16.158 0.705257C16.2471 0.750664 16.3234 0.817606 16.38 0.900001L19.38 5.04C19.4506 5.15059 19.4887 5.2788 19.49 5.41V18.23C19.4901 18.3153 19.4729 18.3998 19.4393 18.4782C19.4058 18.5567 19.3567 18.6275 19.295 18.6864C19.2333 18.7453 19.1602 18.7911 19.0803 18.8209C19.0004 18.8508 18.9152 18.8641 18.83 18.86Z"
                fill="currentColor"
            />
            <path
                d="M4.3 19.84C3.46141 19.84 2.64165 19.5913 1.94438 19.1254C1.24712 18.6595 0.703666 17.9973 0.38275 17.2226C0.0618347 16.4478 -0.0221315 15.5953 0.14147 14.7728C0.305071 13.9503 0.708892 13.1948 1.30187 12.6019C1.89484 12.0089 2.65034 11.6051 3.47282 11.4415C4.2953 11.2779 5.14782 11.3618 5.92258 11.6828C6.69734 12.0037 7.35953 12.5471 7.82543 13.2444C8.29133 13.9416 8.54 14.7614 8.54 15.6C8.54 16.7245 8.09329 17.803 7.29813 18.5981C6.50298 19.3933 5.42452 19.84 4.3 19.84ZM4.3 12.61C3.64706 12.6179 3.01451 12.8386 2.49838 13.2386C1.98225 13.6386 1.61073 14.1961 1.44022 14.8264C1.2697 15.4567 1.30951 16.1255 1.55359 16.7312C1.79768 17.3368 2.23271 17.8463 2.79264 18.1823C3.35258 18.5182 4.00684 18.6623 4.65611 18.5927C5.30538 18.523 5.91419 18.2434 6.39013 17.7964C6.86607 17.3493 7.18314 16.7591 7.2932 16.1155C7.40327 15.4718 7.30033 14.8098 7 14.23C6.74428 13.7363 6.35639 13.3234 5.87962 13.0373C5.40285 12.7512 4.85596 12.6033 4.3 12.61Z"
                fill="currentColor"
            />
            <path
                d="M5.51 16.81H3.92C3.75292 16.81 3.59267 16.7436 3.47453 16.6255C3.35638 16.5073 3.29 16.3471 3.29 16.18V14.35C3.27876 14.2613 3.28653 14.1712 3.3128 14.0857C3.33906 14.0002 3.38323 13.9213 3.44235 13.8542C3.50147 13.7872 3.5742 13.7334 3.65569 13.6966C3.73719 13.6598 3.82558 13.6408 3.915 13.6408C4.00442 13.6408 4.09282 13.6598 4.17432 13.6966C4.25581 13.7334 4.32854 13.7872 4.38766 13.8542C4.44678 13.9213 4.49094 14.0002 4.51721 14.0857C4.54348 14.1712 4.55125 14.2613 4.54 14.35V15.56H5.54C5.69215 15.5793 5.83204 15.6534 5.93344 15.7685C6.03484 15.8835 6.09079 16.0316 6.09079 16.185C6.09079 16.3384 6.03484 16.4865 5.93344 16.6015C5.83204 16.7166 5.69215 16.7907 5.54 16.81H5.51Z"
                fill="currentColor"
            />
            <path
                d="M14.61 7.78H7.19001C7.10859 7.78 7.02797 7.76396 6.95274 7.7328C6.87752 7.70165 6.80917 7.65598 6.7516 7.59841C6.69403 7.54083 6.64836 7.47249 6.6172 7.39726C6.58604 7.32204 6.57001 7.24142 6.57001 7.16V1.48C6.58929 1.32785 6.66341 1.18796 6.77848 1.08656C6.89354 0.98516 7.04164 0.929214 7.19501 0.929214C7.34837 0.929214 7.49647 0.98516 7.61154 1.08656C7.7266 1.18796 7.80072 1.32785 7.82001 1.48V6.53H14V1.48C14.0193 1.32785 14.0934 1.18796 14.2085 1.08656C14.3235 0.98516 14.4716 0.929214 14.625 0.929214C14.7784 0.929214 14.9265 0.98516 15.0415 1.08656C15.1566 1.18796 15.2307 1.32785 15.25 1.48V7.16C15.2501 7.24313 15.2334 7.32542 15.201 7.40198C15.1686 7.47853 15.1211 7.54779 15.0614 7.60563C15.0017 7.66347 14.931 7.70872 14.8534 7.73867C14.7759 7.76863 14.6931 7.78268 14.61 7.78Z"
                fill="currentColor"
            />
            <path
                d="M15.71 18.67C15.5456 18.67 15.3879 18.6047 15.2716 18.4884C15.1553 18.3721 15.09 18.2144 15.09 18.05V11.44H7.27999V12.67C7.29124 12.7587 7.28347 12.8488 7.2572 12.9343C7.23093 13.0198 7.18677 13.0987 7.12765 13.1657C7.06853 13.2328 6.9958 13.2866 6.91431 13.3234C6.83281 13.3602 6.74442 13.3792 6.65499 13.3792C6.56557 13.3792 6.47718 13.3602 6.39568 13.3234C6.31419 13.2866 6.24146 13.2328 6.18234 13.1657C6.12322 13.0987 6.07905 13.0198 6.05279 12.9343C6.02652 12.8488 6.01875 12.7587 6.02999 12.67V10.82C6.02997 10.6546 6.09497 10.4959 6.21097 10.378C6.32696 10.2602 6.48465 10.1926 6.64999 10.19H15.71C15.8771 10.19 16.0373 10.2564 16.1555 10.3745C16.2736 10.4927 16.34 10.6529 16.34 10.82V18.05C16.3374 18.2153 16.2698 18.373 16.152 18.489C16.0341 18.605 15.8754 18.67 15.71 18.67Z"
                fill="currentColor"
            />
            <path
                d="M12.94 5.24999C12.7756 5.24999 12.6179 5.18467 12.5016 5.0684C12.3853 4.95213 12.32 4.79443 12.32 4.62999V3.37999C12.3393 3.22784 12.4134 3.08795 12.5285 2.98655C12.6435 2.88515 12.7916 2.82921 12.945 2.82921C13.0984 2.82921 13.2465 2.88515 13.3615 2.98655C13.4766 3.08795 13.5507 3.22784 13.57 3.37999V4.62999C13.5674 4.79534 13.4999 4.95303 13.382 5.06902C13.2641 5.18501 13.1054 5.25001 12.94 5.24999Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconSaveAsDraft;
