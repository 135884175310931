import React from 'react';


const Icon = () => {
  return (
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M57.156 65.835H8.844V9.37201L20.097 0.165009H57.156V65.835Z" fill="#E6E6E4"/>
      <path d="M57.156 43.923H8.877V65.835H57.156V43.923Z" fill="#EB222D"/>
      <path d="M17.457 48.609H22.209C22.824 48.5586 23.442 48.5586 24.057 48.609C24.6076 48.7226 25.1331 48.935 25.608 49.236C26.0395 49.5441 26.3904 49.9516 26.631 50.424C26.9155 51.009 27.0515 51.655 27.027 52.305C27.0411 52.94 26.9173 53.5705 26.664 54.153C26.4571 54.646 26.1136 55.0697 25.674 55.374C25.2231 55.675 24.7192 55.8877 24.189 56.001C23.5714 56.1372 22.9404 56.2036 22.308 56.199H20.262V61.149H17.457V48.609ZM20.262 53.988H22.143H22.869C23.0909 53.9345 23.3026 53.8453 23.496 53.724C23.6881 53.6135 23.8475 53.4541 23.958 53.262C24.0142 53.0233 24.0142 52.7747 23.958 52.536C23.9611 52.255 23.8807 51.9793 23.727 51.744C23.5754 51.5382 23.3698 51.3783 23.133 51.282C22.8918 51.1771 22.6357 51.1103 22.374 51.084H20.262V53.988Z" fill="white"/>
      <path d="M28.776 48.609H33C33.967 48.5991 34.9315 48.7099 35.871 48.939C36.7418 49.1458 37.5614 49.5275 38.28 50.061C38.9799 50.5714 39.5368 51.2532 39.897 52.041C40.3176 52.9615 40.5208 53.9664 40.491 54.978C40.5233 55.9225 40.3311 56.8613 39.93 57.717C39.5534 58.485 39.0126 59.161 38.346 59.697C37.6694 60.2341 36.8966 60.6373 36.069 60.885C35.1977 61.1797 34.2827 61.3247 33.363 61.314H28.776V48.609ZM31.581 58.74H33C33.6106 58.7448 34.2198 58.6783 34.815 58.542C35.3368 58.417 35.8297 58.1929 36.267 57.882C36.6734 57.5518 37.0004 57.1345 37.224 56.661C37.4653 56.0874 37.578 55.4679 37.554 54.846C37.5733 54.2885 37.4602 53.7344 37.224 53.229C36.9979 52.7757 36.6704 52.3804 36.267 52.074C35.8538 51.7635 35.3825 51.539 34.881 51.414C34.335 51.252 33.7675 51.1741 33.198 51.183H31.548L31.581 58.74Z" fill="white"/>
      <path d="M42.504 48.609H51.051V51.183H45.309V53.757H50.622V56.364H45.309V61.314H42.504V48.609Z" fill="white"/>
      <path d="M8.844 9.37201H19.47V0.165009L8.844 9.37201Z" fill="#AFAFAC"/>
      <path d="M47.652 18.348H18.546V21.747H47.652V18.348Z" fill="#AFAFAC"/>
      <path d="M47.652 29.205H18.546V32.604H47.652V29.205Z" fill="#AFAFAC"/>
    </svg>
  )
}

export default Icon