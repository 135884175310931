import React from 'react';

const IconFileJpg = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.32 19.95H2.68V2.83999L6.09 0.0499878H17.32V19.95Z" fill="#E6E6E4" />
            <path d="M13.18 5.92999H6.7V11.58H13.18V5.92999Z" fill="#F2D465" />
            <path d="M6.7 9.46999L9.71 7.73999L11.22 8.71999L13.18 7.81999V11.58H6.7V9.46999Z" fill="#31B89A" />
            <path d="M2.68 2.83999H5.9V0.0499878L2.68 2.83999Z" fill="#AFAFAC" />
            <path
                d="M6.35 16.91C6.36505 17.083 6.36505 17.257 6.35 17.43C6.32624 17.589 6.26449 17.7399 6.17 17.87C6.07857 18.0088 5.95051 18.1195 5.8 18.19C5.59969 18.2692 5.3853 18.3067 5.17 18.3C4.89344 18.3096 4.62078 18.2327 4.39 18.08C4.2807 17.9997 4.18887 17.898 4.12011 17.7811C4.05134 17.6642 4.00707 17.5346 3.99 17.4L4.76 17.21C4.77465 17.3025 4.82053 17.3872 4.89 17.45C4.96033 17.5159 5.05361 17.5518 5.15 17.55C5.22961 17.5559 5.30841 17.5308 5.37 17.48C5.42751 17.4337 5.4694 17.3709 5.49 17.3C5.49963 17.2203 5.49963 17.1397 5.49 17.06C5.49459 16.9801 5.49459 16.8999 5.49 16.82V14.35H6.34L6.35 16.91Z"
                fill="#08122A"
            />
            <path
                d="M7.08 14.35H8.51C8.69835 14.3486 8.88623 14.3687 9.07 14.41C9.23804 14.4346 9.39855 14.496 9.54 14.59C9.67371 14.6801 9.78078 14.8044 9.85 14.95C9.93621 15.1273 9.97742 15.323 9.97 15.52C9.9751 15.7157 9.93757 15.9102 9.86 16.09C9.79438 16.2349 9.6907 16.3593 9.56 16.45C9.42488 16.5461 9.27186 16.6141 9.11 16.65C8.92183 16.6833 8.7311 16.7 8.54 16.7H7.92V18.2H7.08V14.35ZM7.92 16H8.49C8.56297 16.0104 8.63704 16.0104 8.71 16C8.77674 15.9873 8.84093 15.9636 8.9 15.93C8.94623 15.8781 8.98038 15.8167 9 15.75C9.03403 15.6782 9.05113 15.5995 9.05 15.52C9.06316 15.4398 9.04528 15.3575 9 15.29C8.95796 15.228 8.89894 15.1794 8.83 15.15C8.75569 15.1104 8.67398 15.0865 8.59 15.08H7.92V16Z"
                fill="#08122A"
            />
            <path
                d="M14 17.93C13.7553 18.0614 13.4924 18.1557 13.22 18.21C12.9441 18.2706 12.6624 18.3008 12.38 18.3C12.0974 18.3008 11.8167 18.2534 11.55 18.16C11.3038 18.0708 11.079 17.9311 10.89 17.75C10.7056 17.5712 10.5593 17.3569 10.46 17.12C10.2603 16.5778 10.2603 15.9822 10.46 15.44C10.5618 15.2015 10.7077 14.9844 10.89 14.8C11.0796 14.622 11.3045 14.4857 11.55 14.4C11.8149 14.2986 12.0964 14.2477 12.38 14.25C12.671 14.2471 12.9609 14.2875 13.24 14.37C13.4939 14.4402 13.7271 14.5706 13.92 14.75L13.32 15.4C13.2084 15.2859 13.076 15.1943 12.93 15.13C12.7541 15.0652 12.5674 15.0347 12.38 15.04C12.2124 15.0381 12.046 15.0687 11.89 15.13C11.748 15.1918 11.619 15.28 11.51 15.39C11.4072 15.5045 11.3259 15.6366 11.27 15.78C11.1632 16.1048 11.1632 16.4552 11.27 16.78C11.3259 16.9234 11.4072 17.0555 11.51 17.17C11.6217 17.2768 11.75 17.3646 11.89 17.43C12.046 17.4913 12.2124 17.5219 12.38 17.52C12.5421 17.5237 12.7038 17.5035 12.86 17.46C12.9698 17.4248 13.0768 17.3814 13.18 17.33V16.7H12.49V15.92H14V17.93Z"
                fill="#08122A"
            />
        </svg>
    );
};

export default IconFileJpg;
