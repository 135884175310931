import React from 'react';

export const IconOrderInactive = () => {
  return (
    <svg width="163" height="161" viewBox="0 0 163 161" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.8778 101.577C35.6982 108.103 29.9987 117.174 27.7093 127.31C27.7093 128.27 27.3665 129.511 28.1663 130.132C28.9662 130.753 30.4516 130.132 31.4229 129.455C32.9898 128.293 34.4434 126.99 35.765 125.561C39.5225 121.354 42.6579 116.642 45.0776 111.566C46.3852 109.398 47.1856 106.968 47.4201 104.456C47.5005 103.416 47.1733 102.385 46.506 101.577C46.1445 101.236 45.6635 101.045 45.1633 101.045C44.6631 101.045 44.1822 101.236 43.8207 101.577"
        fill="white"
      />
      <path
        d="M29.138 130.922C28.6264 130.918 28.1281 130.761 27.7096 130.47C27.2781 129.996 26.9861 129.414 26.8653 128.787C26.7445 128.16 26.7994 127.513 27.024 126.915C29.3115 116.731 35.0077 107.606 43.1926 101.013C43.4017 100.787 43.6585 100.609 43.9448 100.492C44.2311 100.375 44.5399 100.322 44.8495 100.336C45.6215 100.338 46.3613 100.642 46.9063 101.182C47.6863 102.092 48.0759 103.266 47.9918 104.455C47.8052 106.987 47.0226 109.441 45.7065 111.622C43.2704 116.784 40.0956 121.573 36.2796 125.843C34.9192 127.291 33.4275 128.613 31.8232 129.793C31.0726 130.442 30.1319 130.837 29.138 130.922ZM44.5067 101.577C36.3853 108.067 30.7597 117.109 28.5666 127.197C28.5666 128.326 28.5666 129.059 28.5666 129.342C28.5666 129.624 30.5091 128.947 30.9091 128.664C32.442 127.525 33.8754 126.26 35.194 124.884C38.9146 120.713 42.0121 116.038 44.3924 111.001C45.6763 108.954 46.4407 106.631 46.6206 104.229C46.6783 103.42 46.4345 102.617 45.935 101.972C45.8093 101.844 45.6582 101.743 45.4911 101.675C45.324 101.607 45.1444 101.573 44.9638 101.577H44.5067Z"
        fill="#07122B"
      />
      <path
        d="M49.934 88.5981C49.0942 88.6104 48.2658 88.7919 47.4995 89.1316C46.7333 89.4712 46.0453 89.9617 45.4777 90.5732C44.2664 91.5347 43.1734 92.6333 42.2211 93.8462C41.2626 95.0669 40.7947 96.5943 40.907 98.135C41.0601 98.9943 41.3291 99.8294 41.7069 100.618C42.2782 101.916 43.1923 103.383 44.6207 103.327C45.1536 103.295 45.6674 103.119 46.1061 102.819C48.5565 101.568 50.5529 99.596 51.8194 97.1757C52.4549 95.9839 52.787 94.6571 52.787 93.3101C52.787 91.9631 52.4549 90.6364 51.8194 89.4446C51.4739 88.7333 50.8578 88.1856 50.1054 87.9209"
        fill="white"
      />
      <path
        d="M44.5636 104.06C43.1352 104.06 41.9926 102.988 41.0213 100.9C40.5456 100.072 40.1989 99.1779 39.9929 98.2479C39.8171 96.5087 40.3289 94.7697 41.4212 93.3947C42.3963 92.1306 43.5289 90.993 44.7921 90.0088C45.9729 88.8261 47.5226 88.0707 49.1913 87.8644C49.202 87.7695 49.2348 87.6783 49.2871 87.5979C49.3393 87.5175 49.4096 87.4501 49.4926 87.401C49.5755 87.3518 49.6688 87.3222 49.7652 87.3145C49.8616 87.3068 49.9585 87.3212 50.0483 87.3565C50.5251 87.4931 50.9662 87.7304 51.3411 88.0519C51.7159 88.3735 52.0156 88.7716 52.2194 89.2188C52.9099 90.5152 53.2708 91.958 53.2708 93.4229C53.2708 94.8878 52.9099 96.3307 52.2194 97.6271C50.824 100.069 48.7127 102.037 46.1633 103.27C45.6259 103.602 45.0213 103.814 44.3922 103.891L44.5636 104.06ZM49.477 89.2752C48.1455 89.4812 46.9203 90.1161 45.9919 91.081C44.8008 92.0037 43.7266 93.0648 42.7924 94.2412C41.9296 95.3317 41.5026 96.6974 41.5926 98.0786C41.7441 98.8586 41.9936 99.6169 42.3354 100.336C43.021 101.86 43.7637 102.65 44.5636 102.65C44.9575 102.558 45.3406 102.426 45.7062 102.255C48.1067 101.12 50.0998 99.289 51.4195 97.0064C51.9955 95.9077 52.2962 94.6884 52.2962 93.4511C52.2962 92.2139 51.9955 90.9946 51.4195 89.8959C51.2367 89.5482 50.984 89.2409 50.6768 88.993C50.583 89.0967 50.4687 89.1802 50.341 89.2384C50.2132 89.2966 50.0747 89.3284 49.934 89.3316L49.477 89.2752Z"
        fill="#07122B"
      />
      <path
        d="M51.0195 89.6701C51.0195 89.6701 38.7931 104.568 52.0479 129.793C65.3027 155.018 84.3851 155.47 94.212 141.079C104.039 126.689 114.266 103.27 104.553 89.8958C94.8404 76.5215 51.0195 89.6701 51.0195 89.6701Z"
        fill="white"
      />
      <path
        d="M77.8717 151.576H76.7291C67.245 151.011 58.2752 143.45 51.4192 130.357C46.0639 121.26 44.0462 110.618 45.706 100.223C46.3766 96.2805 47.9389 92.5384 50.2766 89.2751C50.2766 89.2751 50.2766 89.2751 50.6194 89.2751C52.4476 88.7107 94.9545 76.1829 104.953 89.7829C114.951 103.383 103.41 129.285 94.6117 142.152C92.8016 144.943 90.3327 147.259 87.417 148.9C84.5013 150.542 81.2258 151.46 77.8717 151.576ZM51.4192 90.2908C49.3177 93.3682 47.8966 96.8486 47.2485 100.505C45.7183 110.571 47.7353 120.851 52.9618 129.624C59.5892 142.321 68.1592 149.601 77.129 150.165C80.4344 150.193 83.6902 149.37 86.5755 147.777C89.4608 146.183 91.876 143.875 93.5833 141.079C102.21 128.721 114.037 104.06 103.924 90.2908C94.8973 78.0451 54.7901 89.2751 51.4192 90.2908Z"
        fill="#07122B"
      />
      <path
        d="M31.4805 127.366C32.0819 124.935 32.8845 122.557 33.88 120.256C35.7708 116.285 38.0477 112.505 40.6789 108.97"
        fill="white"
      />
      <path
        d="M31.4804 128.044C31.3101 127.981 31.1664 127.863 31.073 127.71C30.9795 127.556 30.9419 127.375 30.9662 127.197C31.6269 124.733 32.4671 122.319 33.48 119.974C35.357 115.984 37.655 112.201 40.336 108.688C40.4585 108.577 40.615 108.51 40.7804 108.498C40.9459 108.485 41.1108 108.528 41.2489 108.619C41.387 108.71 41.4903 108.844 41.5423 108.999C41.5942 109.155 41.592 109.323 41.5358 109.478C38.9211 112.913 36.6625 116.6 34.7941 120.482C33.8208 122.753 33.0188 125.092 32.3945 127.479C32.3745 127.577 32.3345 127.67 32.277 127.751C32.2195 127.833 32.1458 127.903 32.0603 127.956C31.9747 128.008 31.8792 128.043 31.7795 128.059C31.6798 128.074 31.578 128.069 31.4804 128.044Z"
        fill="#07122B"
      />
      <path
        d="M109.124 93.3945C106.837 93.6693 104.666 94.5464 102.839 95.934C101.057 97.3377 99.8743 99.3521 99.5256 101.577C99.4041 103.173 99.7621 104.768 100.555 106.164C101.348 107.559 102.54 108.692 103.982 109.421C105.442 110.128 107.063 110.448 108.685 110.349C110.308 110.25 111.877 109.736 113.238 108.857C114.672 107.988 115.722 106.616 116.178 105.015C116.633 103.414 116.461 101.703 115.694 100.223C114.635 97.4695 112.607 95.1855 109.981 93.7896"
        fill="white"
      />
      <path
        d="M107.981 111.171C106.479 111.175 104.996 110.847 103.639 110.211C102.053 109.411 100.745 108.162 99.8798 106.625C99.0149 105.087 98.633 103.33 98.7827 101.577C98.9244 100.354 99.3104 99.17 99.9185 98.0948C100.527 97.0196 101.344 96.0742 102.325 95.3134C104.261 93.8341 106.575 92.9158 109.009 92.6611C109.096 92.6359 109.187 92.6294 109.276 92.642C109.366 92.6547 109.451 92.6862 109.527 92.7343C109.603 92.7825 109.667 92.8463 109.716 92.9213C109.765 92.9963 109.797 93.0807 109.809 93.169H110.324C113.084 94.6653 115.212 97.0872 116.323 99.9972C116.81 101.19 117.012 102.477 116.913 103.76C116.814 105.042 116.416 106.284 115.751 107.39C115.188 108.216 114.446 108.91 113.58 109.421C111.933 110.545 109.983 111.154 107.981 111.171ZM109.295 94.2412C107.114 94.4745 105.039 95.2943 103.296 96.6113C101.619 97.917 100.531 99.823 100.268 101.916C100.159 103.372 100.485 104.827 101.206 106.101C101.928 107.375 103.012 108.412 104.325 109.083C105.667 109.743 107.162 110.038 108.658 109.939C110.154 109.84 111.597 109.349 112.837 108.518C113.56 108.111 114.184 107.552 114.666 106.882C115.193 105.96 115.499 104.931 115.558 103.874C115.618 102.817 115.43 101.761 115.008 100.787C114.055 98.1802 112.164 96.0108 109.695 94.6926C109.627 94.6794 109.562 94.6525 109.504 94.6137C109.447 94.5749 109.398 94.525 109.36 94.4669C109.323 94.4089 109.298 94.3439 109.287 94.276C109.275 94.2081 109.278 94.1386 109.295 94.0719V94.2412Z"
        fill="#07122B"
      />
      <path
        d="M114.266 110.55C113.809 109.873 112.323 109.139 111.581 109.534C111.198 109.731 110.866 110.011 110.608 110.353C110.351 110.695 110.176 111.09 110.095 111.509C108.267 133.743 115.808 139.161 118.436 143.562C118.694 143.914 119.041 144.193 119.442 144.372C119.843 144.55 120.284 144.621 120.722 144.578C121.293 144.578 121.522 143.788 121.693 143.28C122.579 138.979 122.579 134.546 121.693 130.244C121.44 126.684 120.867 123.152 119.979 119.692C118.966 116.181 116.989 113.017 114.266 110.55Z"
        fill="white"
      />
      <path
        d="M120.379 145.312C119.867 145.297 119.367 145.161 118.92 144.915C118.472 144.67 118.091 144.322 117.808 143.901C117.408 143.224 116.837 142.49 116.208 141.587C113.066 137.355 107.81 130.301 109.409 111.453C109.481 110.914 109.686 110.401 110.004 109.959C110.323 109.516 110.746 109.157 111.238 108.913C112.38 108.292 114.151 109.252 114.78 110.098C117.525 112.639 119.502 115.882 120.493 119.466C121.517 122.947 122.187 126.52 122.493 130.132C123.404 134.525 123.404 139.056 122.493 143.449C122.418 143.849 122.243 144.224 121.984 144.54C121.724 144.855 121.388 145.101 121.007 145.255L120.379 145.312ZM112.095 110.098C111.809 110.226 111.561 110.422 111.371 110.668C111.182 110.914 111.057 111.203 111.009 111.509C109.295 129.793 114.266 136.395 117.351 141.079C117.985 141.843 118.558 142.654 119.065 143.506C119.235 143.73 119.462 143.906 119.723 144.015C119.984 144.124 120.269 144.162 120.55 144.127C120.55 144.127 120.55 144.127 121.007 143.337C121.893 139.13 121.893 134.789 121.007 130.583C120.698 127.05 120.048 123.554 119.065 120.143C118.098 116.793 116.221 113.768 113.637 111.396C113.31 110.785 112.758 110.32 112.095 110.098Z"
        fill="#07122B"
      />
      <path d="M112.38 116.588C112.458 123.599 114.241 130.49 117.579 136.678L112.38 116.588Z" fill="white" />
      <path
        d="M117.579 137.355C117.454 137.356 117.331 137.325 117.221 137.266C117.111 137.206 117.018 137.121 116.951 137.017C113.58 130.734 111.76 123.754 111.638 116.645C111.638 116.45 111.716 116.264 111.855 116.126C111.995 115.988 112.183 115.911 112.38 115.911C112.562 115.911 112.737 115.982 112.865 116.109C112.994 116.236 113.066 116.409 113.066 116.588C113.178 123.481 114.94 130.249 118.208 136.339C118.292 136.505 118.309 136.697 118.256 136.875C118.203 137.053 118.084 137.204 117.922 137.299L117.579 137.355Z"
        fill="#07122B"
      />
      <path
        d="M77.8719 103.327C96.0783 103.327 110.838 88.6475 110.838 70.5398C110.838 52.4321 96.0783 37.7529 77.8719 37.7529C59.6655 37.7529 44.9062 52.4321 44.9062 70.5398C44.9062 88.6475 59.6655 103.327 77.8719 103.327Z"
        fill="white"
      />
      <path
        d="M77.872 104.06C71.148 104.116 64.5588 102.198 58.9405 98.5488C53.3221 94.8997 48.9279 89.6842 46.3155 83.5643C43.703 77.4444 42.99 70.6958 44.2671 64.175C45.5441 57.6542 48.7535 51.655 53.4881 46.9388C58.2227 42.2226 64.269 39.002 70.8598 37.6856C77.4506 36.3691 84.2888 37.0163 90.5066 39.5449C96.7244 42.0734 102.042 46.3695 105.783 51.8879C109.525 57.4062 111.523 63.8981 111.523 70.5398C111.554 79.3904 108.027 87.8911 101.718 94.1759C95.4087 100.461 86.8324 104.016 77.872 104.06ZM77.872 38.4301C71.4305 38.3742 65.1175 40.2098 59.734 43.7039C54.3506 47.198 50.1394 52.1931 47.6351 58.0552C45.1307 63.9174 44.4461 70.3822 45.668 76.6294C46.8899 82.8765 49.9633 88.6243 54.4981 93.1432C59.0329 97.6621 64.8247 100.748 71.1384 102.01C77.4521 103.272 84.0031 102.653 89.9599 100.231C95.9168 97.8096 101.011 93.6944 104.596 88.408C108.181 83.1217 110.095 76.9025 110.095 70.5398C110.125 62.0635 106.749 53.9215 100.708 47.9014C94.6666 41.8813 86.4536 38.4749 77.872 38.4301Z"
        fill="#07122B"
      />
      <path
        d="M96.2404 91.7479C101.118 82.4499 94.6471 69.5773 81.7876 62.9962C68.9281 56.4151 54.5495 58.6175 49.6721 67.9155C44.7947 77.2135 51.2655 90.086 64.125 96.6672C76.9845 103.248 91.363 101.046 96.2404 91.7479Z"
        fill="#EF457E"
      />
      <path
        d="M94.6691 99.3201C94.5018 99.3721 94.3223 99.3721 94.155 99.3201C94.0223 99.1835 93.9482 99.0015 93.9482 98.8122C93.9482 98.6229 94.0223 98.4409 94.155 98.3043C97.2426 95.9556 99.2901 92.5176 99.8682 88.7109C99.784 87.923 99.784 87.1286 99.8682 86.3407V85.4943C99.8917 83.0082 99.5255 80.5336 98.7827 78.1581C98.0024 75.7352 96.9484 73.4068 95.6404 71.217C94.373 69.0665 92.7287 67.1557 90.7841 65.5738L86.9562 62.3572C86.8353 62.2149 86.769 62.0351 86.769 61.8493C86.769 61.6636 86.8353 61.4837 86.9562 61.3414C87.0945 61.2104 87.2788 61.1372 87.4704 61.1372C87.6621 61.1372 87.8463 61.2104 87.9846 61.3414L91.8125 64.5581C93.863 66.111 95.6061 68.0241 96.9545 70.2012C98.3529 72.4667 99.4657 74.8924 100.268 77.4245C101.063 79.9445 101.449 82.5729 101.411 85.2121C101.439 85.4936 101.439 85.7771 101.411 86.0586C101.354 86.7911 101.354 87.5269 101.411 88.2594C100.799 92.5447 98.4995 96.4183 95.0119 99.0379C94.9365 99.1702 94.8145 99.2706 94.6691 99.3201Z"
        fill="#07122B"
      />
      <path
        d="M87.6419 141.531H87.1848C87.0521 141.394 86.978 141.212 86.978 141.023C86.978 140.834 87.0521 140.652 87.1848 140.515C92.014 134.81 95.8731 128.368 98.6114 121.441C98.6428 121.354 98.692 121.275 98.7557 121.207C98.8195 121.14 98.8965 121.086 98.9821 121.049C99.0677 121.012 99.1601 120.993 99.2535 120.992C99.347 120.992 99.4395 121.01 99.5255 121.046C99.6192 121.069 99.7063 121.113 99.7801 121.174C99.8539 121.236 99.9124 121.313 99.9511 121.4C99.9897 121.488 100.008 121.583 100.003 121.678C99.9987 121.773 99.9721 121.866 99.9254 121.949C97.2031 129.004 93.3445 135.579 88.4989 141.418C88.3908 141.531 88.2467 141.604 88.0906 141.625C87.9346 141.645 87.7761 141.612 87.6419 141.531Z"
        fill="#07122B"
      />
      <path
        d="M78.8436 91.6456C76.5582 88.5982 73.1303 84.3094 69.6452 80.3592C66.1601 76.409 62.7321 71.8944 60.4468 69.0728L61.1324 68.5649C63.4177 71.6123 66.8457 75.9011 70.3308 79.8513C73.8159 83.8015 77.2438 88.2597 79.5292 91.1377L78.8436 91.6456Z"
        fill="#07122B"
      />
      <path
        d="M57.1328 85.2684V84.6477C61.6074 83.1609 65.8701 81.1126 69.8163 78.5531L71.416 77.6501C73.1871 76.5779 75.0154 75.7315 76.8436 74.8286L80.6715 72.9663L81.0715 73.7564L77.0722 75.675C75.2439 76.5215 73.4157 77.4244 71.6446 78.4402L70.102 79.3431C66.0529 81.8572 61.6943 83.8485 57.1328 85.2684Z"
        fill="#07122B"
      />
      <path
        d="M84.8421 31.1503H84.4993C84.3277 31.0652 84.1961 30.9177 84.1322 30.7388C84.0683 30.56 84.077 30.3636 84.1565 30.1909C85.419 27.6175 87.2786 25.3746 89.5841 23.6449C90.8262 22.5897 92.3571 21.922 93.9833 21.7262C94.6421 21.584 95.3242 21.584 95.983 21.7262L96.6686 21.2183C98.3989 20.2039 100.37 19.6588 102.382 19.6382C104.664 19.5145 106.952 19.7044 109.181 20.2025C109.363 20.2399 109.522 20.3472 109.624 20.5006C109.725 20.6541 109.761 20.8412 109.723 21.0208C109.686 21.2004 109.577 21.3578 109.422 21.4583C109.266 21.5588 109.077 21.5943 108.895 21.5569C106.794 21.1079 104.642 20.9371 102.496 21.049C100.746 21.0499 99.0286 21.5178 97.5256 22.4034C97.9923 22.7388 98.3657 23.1853 98.6111 23.7013C98.8381 24.1579 98.9561 24.6598 98.9561 25.1685C98.9561 25.6772 98.8381 26.1791 98.6111 26.6357C98.4216 27.0321 98.143 27.3806 97.7964 27.6544C97.4499 27.9282 97.0446 28.1203 96.6114 28.2158C96.339 28.2925 96.0525 28.3076 95.7733 28.2599C95.4941 28.2122 95.2294 28.103 94.9988 27.9403C94.7682 27.7776 94.5777 27.5657 94.4415 27.3203C94.3053 27.0749 94.2268 26.8024 94.2119 26.5229C94.0113 25.2369 94.318 23.9239 95.0689 22.8548H94.269C92.9168 23.0438 91.6478 23.6117 90.6125 24.4913C88.4832 26.1535 86.7967 28.3036 85.6991 30.7553C85.6349 30.9172 85.5101 31.0485 85.3506 31.1221C85.191 31.1956 85.009 31.2057 84.8421 31.1503ZM96.2687 23.5884C95.917 23.9872 95.6539 24.4544 95.4965 24.9599C95.339 25.4653 95.2907 25.9978 95.3545 26.5229C95.3134 26.6897 95.3134 26.8639 95.3545 27.0308C95.5205 27.0912 95.7028 27.0912 95.8687 27.0308C96.133 27.0327 96.3923 26.9592 96.6153 26.8191C96.8383 26.6789 97.0155 26.4781 97.1256 26.2407C97.2527 25.9762 97.3186 25.687 97.3186 25.3942C97.3186 25.1014 97.2527 24.8123 97.1256 24.5478C96.9362 24.1722 96.6627 23.8441 96.3258 23.5884H96.2687Z"
        fill="#07122B"
      />
      <path
        d="M50.4482 31.4327C50.3095 31.4291 50.1752 31.384 50.0629 31.3034C49.9507 31.2228 49.8659 31.1104 49.8197 30.9812C48.6787 28.3628 47.302 25.8509 45.7062 23.4758C45.5896 23.3425 45.5254 23.1722 45.5254 22.9961C45.5254 22.82 45.5896 22.6497 45.7062 22.5164C45.8502 22.397 46.0323 22.3315 46.2203 22.3315C46.4084 22.3315 46.5905 22.397 46.7345 22.5164C48.5231 25.0121 50.0905 27.6553 51.4194 30.4169C51.4812 30.5975 51.4747 30.7942 51.4012 30.9705C51.3277 31.1468 51.1922 31.2909 51.0195 31.3762L50.4482 31.4327Z"
        fill="#07122B"
      />
      <path
        d="M115.466 55.4161C115.299 55.472 115.118 55.472 114.951 55.4161C114.819 55.2795 114.745 55.0975 114.745 54.9082C114.745 54.7189 114.819 54.537 114.951 54.4003C117.941 51.4285 121.632 49.2372 125.692 48.0236C125.874 47.9877 126.062 48.0199 126.22 48.1138C126.379 48.2077 126.496 48.3566 126.549 48.5314C126.576 48.6167 126.585 48.7062 126.576 48.7949C126.568 48.8836 126.541 48.9698 126.499 49.0484C126.456 49.127 126.399 49.1966 126.329 49.2532C126.259 49.3097 126.179 49.3521 126.092 49.3779C122.369 50.4461 118.96 52.3814 116.151 55.0211L115.466 55.4161Z"
        fill="#07122B"
      />
      <path
        d="M59.1323 122.852V116.419C59.1323 116.024 59.1323 115.911 59.6465 115.968C60.1412 115.937 60.6373 115.937 61.132 115.968C61.132 115.968 61.5319 115.968 61.8747 116.306C62.5091 116.105 63.1896 116.094 63.8303 116.275C64.4709 116.456 65.0429 116.82 65.4741 117.322C66.0834 116.922 66.799 116.708 67.5308 116.708C68.2627 116.708 68.9783 116.922 69.5876 117.322C70.1423 117.537 70.6132 117.921 70.9317 118.418C71.2502 118.915 71.3998 119.5 71.3588 120.087V123.586C71.3588 124.037 71.3587 124.207 70.8446 124.15H69.0163V120.2C69.0226 119.84 68.8999 119.49 68.6698 119.211C68.4398 118.932 68.1173 118.743 67.7594 118.676C67.5945 118.667 67.4296 118.694 67.276 118.754C67.1224 118.814 66.9838 118.906 66.8698 119.024C66.7558 119.142 66.669 119.283 66.6154 119.437C66.5619 119.592 66.5428 119.755 66.5596 119.918C66.5596 121.159 66.5596 122.344 66.5596 123.586H64.1029V119.805C64.0901 119.474 63.9551 119.16 63.7236 118.921C63.492 118.681 63.1798 118.534 62.846 118.507C62.6883 118.471 62.5242 118.472 62.3671 118.51C62.21 118.549 62.0643 118.623 61.9418 118.728C61.8194 118.832 61.7236 118.964 61.6623 119.112C61.601 119.26 61.5759 119.42 61.589 119.579V123.021C61.6158 123.133 61.6158 123.249 61.589 123.36L59.1323 122.852Z"
        fill="#0099F6"
      />
      <path
        d="M75.3581 117.773C76.0405 117.43 76.8279 117.349 77.5671 117.547C78.3063 117.745 78.9444 118.208 79.3574 118.845C79.9184 119.587 80.2215 120.487 80.2215 121.413C80.2215 122.338 79.9184 123.239 79.3574 123.981C78.7823 124.529 78.0142 124.835 77.2149 124.835C76.4156 124.835 75.6475 124.529 75.0724 123.981V126.689H72.9585C72.9585 126.689 72.9585 126.294 72.9585 126.125V119.071C72.987 118.658 72.987 118.243 72.9585 117.829C72.9585 117.322 72.9585 117.209 73.587 117.265C74.2154 117.322 74.3868 117.265 74.7867 117.265L75.3581 117.773ZM74.901 120.99C74.8958 121.438 75.0547 121.874 75.3486 122.216C75.6426 122.558 76.0517 122.784 76.5007 122.852C77.3577 122.852 77.9862 122.231 77.9862 121.215C78.0041 120.778 77.8557 120.351 77.5702 120.016C77.2846 119.682 76.8827 119.466 76.4436 119.41C76.2338 119.401 76.0245 119.436 75.8296 119.514C75.6346 119.591 75.4584 119.708 75.3127 119.857C75.1669 120.006 75.0548 120.185 74.9839 120.38C74.9129 120.575 74.8847 120.783 74.901 120.99Z"
        fill="#0099F6"
      />
      <path
        d="M78.7293 116.927L77.4724 116.588C77.4724 116.588 77.4724 116.249 77.4724 116.08C77.4724 115.911 77.4724 115.798 77.4724 115.629C77.4415 115.291 77.4415 114.951 77.4724 114.613H78.5579H79.9862C79.9579 114.857 79.9579 115.103 79.9862 115.346V116.419C79.9585 116.549 79.9585 116.683 79.9862 116.814L78.7293 116.927Z"
        fill="#07122B"
      />
      <path
        d="M82.2712 122.965C83.5282 122.965 83.2996 122.965 83.2996 124.319C83.2996 125.674 83.2996 125.222 82.3855 125.109C80.7287 125.109 80.6715 125.109 80.9001 123.191C80.9001 123.191 80.9001 122.852 81.3 122.852L82.2712 122.965Z"
        fill="#07122B"
      />
    </svg>
  );
};
