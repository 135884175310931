import React from 'react';

const IconPreloader = props => {
    return (
        <svg {...props} width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.34916 1.92848C8.68313 2.71438 8.33929 3.63213 7.58116 3.97833C6.59202 4.43004 5.73895 5.15033 5.1121 6.06312C4.48525 6.9759 4.1079 8.04727 4.01991 9.16401C3.93193 10.2808 4.13659 11.4014 4.61226 12.4075C5.08793 13.4137 5.81695 14.2679 6.72228 14.88C7.62761 15.4921 8.67563 15.8393 9.7556 15.8849C10.8356 15.9306 11.9074 15.673 12.8578 15.1393C13.8082 14.6056 14.6018 13.8157 15.1549 12.8531C15.708 11.8904 16 10.7908 16 9.67031V9.61536C16 8.78693 16.6716 8.11536 17.5 8.11536C18.3284 8.11536 19 8.78693 19 9.61536V9.67031C19 11.351 18.562 13.0005 17.7324 14.4445C16.9027 15.8884 15.7122 17.0733 14.2866 17.8738C12.8611 18.6743 11.2534 19.0607 9.6334 18.9923C8.01344 18.9238 6.44141 18.403 5.08341 17.4848C3.72542 16.5667 2.63189 15.2853 1.91839 13.7761C1.20488 12.2669 0.897896 10.586 1.02987 8.91087C1.16184 7.23575 1.72788 5.6287 2.66815 4.25953C3.60843 2.89035 4.88803 1.8099 6.37175 1.13235C7.12987 0.78614 8.01519 1.14258 8.34916 1.92848Z"
                fill="url(#paint0_angular)"
            />
            <defs>
                <radialGradient
                    id="paint0_angular"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(12.775 -2.69156) rotate(81.2426) scale(16.2561 16.5369)"
                >
                    <stop offset="0.119792" stopColor="currentColor" />
                    <stop offset="1" stopColor="currentColor" stopOpacity="0.12" />
                </radialGradient>
            </defs>
        </svg>
    );
};

export default IconPreloader;
