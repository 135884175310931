import styled from 'styled-components';
import { Radio as AntdRadio } from 'antd';
import * as theme from '../Theme';

export const Radio = styled(AntdRadio)`
    .ant-radio-inner {
        top: 1px;

        &:after {
            top: 3px;
            left: 3px;
            width: 12px;
            height: 12px;
        }
    }

    &.ant-radio-wrapper {
        span {
            ${theme.TYPO_P4}
        }
    }
`;

export const RadioGroup = styled(AntdRadio.Group)`
    width: 100%;

    .ant-radio-wrapper {
        display: block;
        width: 100%;
        margin-bottom: 20px;
        color: ${theme.COLOR_TEXT_PRIMARY};

        span {
            ${theme.TYPO_P4}
        }

        .ant-radio {
            vertical-align: middle;
        }

        &:last-child {
            margin-bottom: 0;
        }

        & > span:nth-child(2) {
            width: calc(100% - 12px);
            display: inline-block;
            white-space: pre-wrap;
            vertical-align: middle;
        }
    }
`;
