import React from 'react';

const IconDeliverableSketch = () => {
    return (
        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57.1557 65.835H8.84375V9.37204L20.0968 0.165039H57.1557V65.835Z" fill="#FCBB46" />
            <path d="M8.84375 9.37204H19.4698V0.165039L8.84375 9.37204Z" fill="#F98924" />
            <path
                d="M20.6582 29.4688L27.8852 21.7798H41.3822L47.8832 29.2378L34.6172 45.6388L20.6582 29.4688Z"
                fill="white"
            />
        </svg>
    );
};

export default IconDeliverableSketch;
