import React from 'react'

const Icon = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M51.96 59.85H8.04V8.51999L17.7 0.149994H51.96V59.85Z" fill="#378ACF"/>
    <path d="M8.04 8.51999H17.7V0.149994L8.04 8.51999Z" fill="#7BBAE2"/>
    <path d="M44.49 12.81H15.51V39.15H44.49V12.81Z" fill="#7BBAE2"/>
    <path d="M21.54 15.39H17.94V18.99H21.54V15.39Z" fill="#378ACF"/>
    <path d="M21.54 21.24H17.94V24.84H21.54V21.24Z" fill="#378ACF"/>
    <path d="M21.54 27.12H17.94V30.72H21.54V27.12Z" fill="#378ACF"/>
    <path d="M21.54 33H17.94V36.6H21.54V33Z" fill="#378ACF"/>
    <path d="M42.09 15.39H38.49V18.99H42.09V15.39Z" fill="#378ACF"/>
    <path d="M42.09 21.24H38.49V24.84H42.09V21.24Z" fill="#378ACF"/>
    <path d="M42.09 27.12H38.49V30.72H42.09V27.12Z" fill="#378ACF"/>
    <path d="M42.09 33H38.49V36.6H42.09V33Z" fill="#378ACF"/>
    <path d="M25.41 19.86V32.1L35.37 25.98L25.41 19.86Z" fill="#215379"/>
    <path d="M17.04 45.15H20.07L22.17 51.06L24.27 45.15H27.27V54.24H25.26V47.28L22.89 54.24H21.36L19.05 47.28V54.24H17.04V45.15Z" fill="white"/>
    <path d="M29.13 45.15H32.49C32.97 45.15 33.42 45.18 33.81 45.27C34.2 45.36 34.59 45.51 34.92 45.72C35.22 45.93 35.49 46.2 35.67 46.56C35.85 46.92 35.94 47.37 35.94 47.91C35.94 48.45 35.85 48.9 35.67 49.23C35.49 49.59 35.25 49.89 34.98 50.1C34.68 50.31 34.32 50.46 33.93 50.55C33.54 50.64 33.06 50.67 32.61 50.67H31.14V54.21H29.13V45.12V45.15ZM31.14 48.99H32.46C32.64 48.99 32.82 48.99 32.97 48.93C33.15 48.9 33.27 48.84 33.42 48.75C33.54 48.66 33.66 48.57 33.75 48.42C33.84 48.27 33.87 48.09 33.87 47.88C33.87 47.64 33.81 47.46 33.72 47.31C33.6 47.16 33.48 47.07 33.3 46.98C33.12 46.89 32.94 46.86 32.73 46.83C32.52 46.8 32.34 46.8 32.13 46.8H31.11V48.96L31.14 48.99Z" fill="white"/>
    <path d="M40.56 52.41H36.6V50.79L40.32 45.15H42.42V50.79H43.59V52.41H42.42V54.24H40.56V52.41ZM40.56 47.67L38.55 50.79H40.59V47.67H40.56Z" fill="white"/>
    </svg>
    
  )
}

export default Icon