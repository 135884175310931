import React from 'react';

const IconFileZip = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.32 19.95H2.68V0.0599878L17.32 0.0499878V19.95Z" fill="#FCB532" />
            <path d="M6.5 17.19L8.45 14.87H6.57V14.12H9.48V14.87L7.57 17.19H9.57V18H6.5V17.19Z" fill="white" />
            <path d="M10.12 14.12H11V18H10.15L10.12 14.12Z" fill="white" />
            <path
                d="M11.74 14.12H13.17C13.3611 14.1208 13.5517 14.1376 13.74 14.17C13.9038 14.2043 14.0598 14.2687 14.2 14.36C14.3344 14.4521 14.4443 14.5757 14.52 14.72C14.6024 14.8983 14.6401 15.0939 14.63 15.29C14.6371 15.4817 14.603 15.6726 14.53 15.85C14.4613 15.9957 14.3583 16.1226 14.23 16.22C14.0907 16.313 13.9344 16.3776 13.77 16.41C13.5862 16.4513 13.3983 16.4714 13.21 16.47H12.59V18H11.74V14.12ZM12.59 15.75H13.15H13.37C13.4409 15.7356 13.5087 15.7085 13.57 15.67C13.6255 15.6363 13.6705 15.5878 13.7 15.53C13.7386 15.4634 13.756 15.3867 13.75 15.31C13.7539 15.2258 13.7331 15.1424 13.69 15.07C13.6418 15.0098 13.5802 14.9619 13.51 14.93C13.4334 14.8983 13.3525 14.878 13.27 14.87H12.59V15.75Z"
                fill="white"
            />
            <path
                d="M17.69 10.74V9.73001H16.6V10.74H15.51V9.73001H14.41V10.74H13.32V9.73001H12.23V10.74H11.14V9.73001H10.05V10.74H8.96V9.73001H7.87V10.74H6.78V9.73001H5.69V10.74H4.6V9.73001H3.51V10.74H2.42V9.73001H1.33V10.74H1.31V11.81H17.73V10.74H17.69Z"
                fill="#B3B3B3"
            />
            <path
                d="M2.42 9.79V10.8H3.51V9.79H4.6V10.8H5.69V9.79H6.78V10.8H7.88V9.79H8.96V10.8H10.05V9.79H11.14V10.8H12.24V9.79H13.33V10.8H14.42V9.79H15.51V10.8H16.6V9.79H17.69V10.8H18.78V9.79H18.8V8.72H2.38V9.79H2.42Z"
                fill="#999999"
            />
            <path d="M18.78 10.8H16.91V11.84H18.78V10.8Z" fill="#B3B3B3" />
            <path d="M3.23 8.73001H1.36V9.77001H3.23V8.73001Z" fill="#999999" />
        </svg>
    );
};

export default IconFileZip;
