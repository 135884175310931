import React from 'react';

const IconDeliverableDesigner = () => {
    return (
        <svg width="85" height="52" viewBox="0 0 85 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M68.6974 49.9402L45.3103 41.428L55.2587 14.0951L62.3283 11.6209L80.2679 18.1504L68.6974 49.9402Z"
                    fill="#022732"
                />
                <path d="M55.2587 14.0951L60.4025 15.9674L62.0247 11.5104L55.2587 14.0951Z" fill="#25D2FC" />
                <path
                    d="M58.7947 26.7977L61.3507 27.728C61.6889 27.8496 62.0144 28.0044 62.3222 28.1901C62.5984 28.3535 62.8393 28.5703 63.0308 28.8279C63.2092 29.082 63.3196 29.3776 63.3515 29.6864C63.3834 30.0659 63.3251 30.4477 63.1812 30.8003C63.0637 31.1606 62.8694 31.4911 62.6116 31.7689C62.3991 31.9913 62.1337 32.1561 61.8402 32.248C61.5335 32.3329 61.2122 32.3517 60.8976 32.3029C60.5421 32.249 60.1931 32.1584 59.8563 32.0325L58.7541 31.6313L57.754 34.3789L56.1565 33.7975L58.7947 26.7977ZM59.3107 30.3504L60.3171 30.7167L60.6105 30.8054C60.74 30.8204 60.8712 30.8131 60.9983 30.7837C61.123 30.7606 61.2384 30.7021 61.3309 30.6153C61.4462 30.5132 61.5316 30.3817 61.578 30.2349C61.6374 30.0826 61.6476 29.9155 61.6071 29.757C61.5633 29.6217 61.4916 29.497 61.3967 29.391C61.2853 29.2962 61.1629 29.2152 61.0322 29.1498L59.8181 28.7079L59.3107 30.3504Z"
                    fill="#24D2FD"
                />
                <path
                    d="M66.0857 33.5578C66.0351 33.3909 65.9457 33.2383 65.8248 33.1126C65.7039 32.9868 65.555 32.8915 65.3902 32.8343C65.2612 32.7889 65.1231 32.7759 64.9879 32.7964C64.922 32.8041 64.8598 32.8309 64.809 32.8734C64.7581 32.916 64.7208 32.9725 64.7017 33.036C64.6799 33.0955 64.6772 33.1603 64.6938 33.2215C64.7104 33.2826 64.7456 33.3371 64.7945 33.3773L65.1895 33.6839L65.7282 34.0428C65.9169 34.1658 66.0883 34.3133 66.2379 34.4816C66.3815 34.6575 66.4859 34.8621 66.5442 35.0815C66.5862 35.3512 66.5545 35.6272 66.4525 35.8803C66.3681 36.1542 66.2042 36.3968 65.9817 36.5774C65.7774 36.7311 65.541 36.8368 65.2902 36.8865C65.0271 36.935 64.7574 36.9349 64.4943 36.8863C64.2234 36.8446 63.957 36.7779 63.6984 36.6871C63.3581 36.5625 63.037 36.3908 62.7444 36.177C62.4534 35.9694 62.2249 35.686 62.0838 35.3576L63.3287 34.671C63.3915 34.8728 63.4964 35.0589 63.6364 35.2172C63.7821 35.3812 63.9677 35.5048 64.1752 35.5761C64.3279 35.6313 64.4891 35.6594 64.6515 35.659C64.7173 35.6664 64.7837 35.651 64.8395 35.6153C64.8953 35.5797 64.9371 35.5259 64.958 35.4631C64.9801 35.4037 64.9852 35.3393 64.9726 35.2772C64.9601 35.2151 64.9304 35.1577 64.887 35.1115C64.7645 34.9875 64.6286 34.8774 64.4819 34.7832L63.9431 34.4243C63.7532 34.307 63.5798 34.1648 63.4276 34.0015C63.2841 33.8255 63.1797 33.621 63.1214 33.4015C63.0776 33.1319 63.1093 32.8554 63.2131 32.6027C63.3051 32.3469 63.457 32.1168 63.6563 31.9319C63.8341 31.77 64.05 31.6556 64.2838 31.5994C64.5259 31.5233 64.7813 31.4991 65.0333 31.5285C65.2907 31.5506 65.5441 31.6064 65.787 31.6943C66.1064 31.8111 66.4065 31.9752 66.6772 32.1811C66.9564 32.3828 67.1757 32.6564 67.3117 32.9729L66.0857 33.5578Z"
                    fill="#24D2FD"
                />
            </g>
            <g clipPath="url(#clip1)">
                <path
                    d="M39.2682 41.428L15.8811 49.9402L5.93275 22.6074L9.75795 16.1677L27.6976 9.63819L39.2682 41.428Z"
                    fill="#2A0E4F"
                />
                <path d="M5.93275 22.6074L11.0766 20.7351L9.45442 16.2782L5.93275 22.6074Z" fill="#DCAEFE" />
                <path
                    d="M17.9884 29.6352L19.2664 29.17L24.8977 35.0985L23.1404 35.7381L21.9869 34.4574L19.2552 35.4517L19.2106 37.1684L17.4694 37.8022L17.9884 29.6352ZM19.3237 31.4648L19.2736 33.9615L20.9829 33.3394L19.3237 31.4648Z"
                    fill="#D3A9F4"
                />
                <path
                    d="M29.6521 32.3547C29.5294 32.7459 29.3096 33.0997 29.0134 33.3831C28.7211 33.6816 28.3652 33.9101 27.9722 34.0516C27.6131 34.1808 27.2355 34.2511 26.8539 34.2596C26.5 34.2569 26.1498 34.188 25.8212 34.0566C25.4913 33.9289 25.1951 33.7273 24.9554 33.4671C24.6907 33.1868 24.489 32.8531 24.364 32.4884C24.2254 32.1343 24.1673 31.7538 24.1937 31.3744C24.2101 31.0211 24.3074 30.6762 24.4781 30.3664C24.6393 30.0506 24.8643 29.7716 25.1387 29.547C25.4316 29.3013 25.7674 29.1116 26.1291 28.9876C26.4491 28.8617 26.792 28.8047 27.1355 28.8202C27.4479 28.8337 27.7526 28.9212 28.0245 29.0756C28.3153 29.2333 28.5716 29.4475 28.7785 29.7057C29.0135 29.9929 29.2036 30.3141 29.3423 30.6583L29.5167 31.1376L26.0182 32.4109C26.1649 32.6509 26.385 32.8372 26.6458 32.9423C26.9138 33.0407 27.2087 33.0365 27.4737 32.9305C27.6972 32.8626 27.8944 32.7276 28.0386 32.5439C28.1733 32.3668 28.2719 32.165 28.3289 31.9498L29.6521 32.3547ZM27.5704 30.7605C27.5384 30.6389 27.4794 30.526 27.3976 30.4305C27.3158 30.3349 27.2134 30.2591 27.0982 30.2088C26.9795 30.1487 26.8492 30.1151 26.7163 30.1104C26.5834 30.1057 26.451 30.1299 26.3284 30.1814C26.1764 30.2075 26.0303 30.2607 25.8971 30.3384C25.7874 30.428 25.6987 30.5405 25.6372 30.6681C25.5802 30.7816 25.5452 30.9048 25.5342 31.0313C25.5583 31.1485 25.5994 31.2615 25.6563 31.3667L27.5704 30.7605Z"
                    fill="#D3A9F4"
                />
            </g>
            <path d="M57.9078 37.9052H30.0918V5.39621L36.5708 0.0952148H57.9078V37.9052Z" fill="#311902" />
            <path d="M30.0918 5.39622H36.2098V0.0952148L30.0918 5.39622Z" fill="#F98924" />
            <path
                d="M41.3594 17.3848H42.8414L46.3944 25.7448H44.3614L43.6584 23.9778H40.4854L39.8014 25.7448H37.9014L41.3594 17.3848ZM42.1004 19.8168L41.0554 22.4198H43.0504L42.1004 19.8168Z"
                fill="#F88824"
            />
            <path
                d="M46.8878 18.2021C46.8837 17.9276 46.9858 17.6621 47.1728 17.4611C47.2632 17.3561 47.3736 17.27 47.4976 17.2081C47.6215 17.1461 47.7565 17.1094 47.8948 17.1001C48.0263 17.1014 48.1563 17.129 48.277 17.1812C48.3977 17.2334 48.5068 17.3092 48.5978 17.4041C48.7901 17.6032 48.8989 17.8683 48.9018 18.1451C48.8979 18.4161 48.7889 18.675 48.5978 18.8671C48.5068 18.962 48.3977 19.0378 48.277 19.09C48.1563 19.1422 48.0263 19.1698 47.8948 19.1711C47.6238 19.1672 47.365 19.0582 47.1728 18.8671C47.0002 18.6874 46.8989 18.451 46.8878 18.2021ZM47.0208 20.0071H48.7498V25.7071H47.0208V20.0071Z"
                fill="#F88824"
            />
            <defs>
                <clipPath id="clip0">
                    <rect width="34" height="34" fill="white" transform="translate(52.6289 8) rotate(20)" />
                </clipPath>
                <clipPath id="clip1">
                    <rect width="34" height="34" fill="white" transform="translate(0 19.6289) rotate(-20)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconDeliverableDesigner;
