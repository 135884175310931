import gql from 'graphql-tag';

export const CHECK_CUSTOMER = gql`
    mutation CheckCustomer($customerId: ID!) {
        checkCustomer(customerId: $customerId) {
            id
            checkedAt
            manager {
                id
                firstname
                lastname
            }
        }
    }
`;

export const CHECK_COMPANY = gql`
    mutation CheckCompany($companyId: ID!) {
        checkCompany(companyId: $companyId) {
            id
            checkedAt
            manager {
                id
                firstname
                lastname
                picture {
                    id
                    url
                }
            }
        }
    }
`;
