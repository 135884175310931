import React from 'react';
import { useQuery } from 'react-apollo';
import { Icon } from 'antd';
import { ME } from '@graphql/queries/userConnected';
import { LoadingWithLogo } from '@components/LoadingWithLogo';

const withLoggedUser = (WrappedComponent, withLogo) => props => {
    const { loading, error, data = {}, refetch } = useQuery(ME, { nextFetchPolicy: 'cache-only' });
    const { pathname } = window.location

    if (pathname === '/onboard' && loading) {
        return <LoadingWithLogo w="100%" h="100vh" />
    }
    
    if (loading) {
        return <Icon type="loading" style={{ fontSize: 20 }} spin />;
    }
    if (error) {
        return <WrappedComponent {...props} viewer={{ connected: false }} />;
    }

    const { user } = data;
    return <WrappedComponent {...props} viewer={{ ...user, connected: !!user }} refetchViewer={refetch} />;
};

export default withLoggedUser;
