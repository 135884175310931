import React from 'react'

const Icon = () => {
  return (
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M57.156 65.835H8.844V9.37201L20.097 0.165009H57.156V65.835Z" fill="#420537"/>
      <path d="M8.844 9.37201H19.47V0.165009L8.844 9.37201Z" fill="#F36AF5"/>
      <path d="M27.8957 34.507L25.1635 30.5605H27.452L29.2151 33.4561L30.9081 30.5605H33.1149L30.441 34.4719L33.4301 38.8271H31.1416L29.1216 35.4994L27.1601 38.8271H25L27.8957 34.507Z" fill="#F36AF5"/>
      <path d="M40 38.8271H38.3887V38.0798H38.3654C38.2953 38.181 38.2019 38.2861 38.0851 38.3951C37.9761 38.4963 37.8438 38.5897 37.6881 38.6753C37.5402 38.7609 37.3729 38.831 37.1861 38.8855C37.007 38.94 36.8202 38.9672 36.6256 38.9672C36.2053 38.9672 35.8239 38.8972 35.4814 38.757C35.1389 38.6091 34.8431 38.4068 34.594 38.1499C34.3527 37.8852 34.1659 37.5739 34.0335 37.2158C33.9012 36.8577 33.835 36.4646 33.835 36.0365C33.835 35.6395 33.8934 35.262 34.0102 34.9039C34.1347 34.5381 34.306 34.2151 34.5239 33.9348C34.7497 33.6546 35.0221 33.4328 35.3413 33.2693C35.6604 33.098 36.0224 33.0124 36.4271 33.0124C36.793 33.0124 37.1316 33.0708 37.4429 33.1876C37.7621 33.2965 38.0229 33.4873 38.2252 33.7597H38.2486V30H40V38.8271ZM38.3887 35.9898C38.3887 35.5617 38.2642 35.2153 38.0151 34.9506C37.7738 34.686 37.4313 34.5537 36.9876 34.5537C36.5439 34.5537 36.1975 34.686 35.9484 34.9506C35.7071 35.2153 35.5864 35.5617 35.5864 35.9898C35.5864 36.4179 35.7071 36.7643 35.9484 37.029C36.1975 37.2936 36.5439 37.426 36.9876 37.426C37.4313 37.426 37.7738 37.2936 38.0151 37.029C38.2642 36.7643 38.3887 36.4179 38.3887 35.9898Z" fill="#F36AF5"/>
    </svg>
  )
}

export default Icon