import React from 'react';

const IconExternalLink = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7299 19.73H0.779883C0.607492 19.73 0.442162 19.6615 0.320263 19.5396C0.198365 19.4177 0.129883 19.2524 0.129883 19.08V4.12998C0.129883 3.95759 0.198365 3.79226 0.320263 3.67036C0.442162 3.54846 0.607492 3.47998 0.779883 3.47998H10.8499C11.0223 3.47998 11.1876 3.54846 11.3095 3.67036C11.4314 3.79226 11.4999 3.95759 11.4999 4.12998C11.4999 4.30237 11.4314 4.4677 11.3095 4.5896C11.1876 4.7115 11.0223 4.77998 10.8499 4.77998H1.42988V18.43H15.0799V9.48998C15.0799 9.31759 15.1484 9.15226 15.2703 9.03036C15.3922 8.90846 15.5575 8.83998 15.7299 8.83998C15.9023 8.83998 16.0676 8.90846 16.1895 9.03036C16.3114 9.15226 16.3799 9.31759 16.3799 9.48998V19.08C16.3799 19.2524 16.3114 19.4177 16.1895 19.5396C16.0676 19.6615 15.9023 19.73 15.7299 19.73Z"
        fill="currentColor"
      />
      <path
        d="M10.1902 10.5299C10.1045 10.5318 10.0195 10.5158 9.94028 10.4831C9.86111 10.4504 9.78957 10.4017 9.73018 10.3399C9.66849 10.2803 9.61943 10.2088 9.58593 10.1298C9.55242 10.0507 9.53516 9.96578 9.53516 9.87994C9.53516 9.79411 9.55242 9.70915 9.58593 9.63013C9.61943 9.5511 9.66849 9.47962 9.73018 9.41994L17.8202 1.32994C17.8806 1.26953 17.9523 1.22162 18.0312 1.18892C18.1102 1.15623 18.1948 1.1394 18.2802 1.1394C18.3656 1.1394 18.4502 1.15623 18.5291 1.18892C18.6081 1.22162 18.6798 1.26953 18.7402 1.32994C18.8006 1.39035 18.8485 1.46207 18.8812 1.54099C18.9139 1.61992 18.9307 1.70451 18.9307 1.78994C18.9307 1.87537 18.9139 1.95997 18.8812 2.03889C18.8485 2.11782 18.8006 2.18953 18.7402 2.24994L10.6502 10.3399C10.5897 10.4003 10.518 10.4481 10.4391 10.4807C10.3602 10.5133 10.2756 10.53 10.1902 10.5299Z"
        fill="currentColor"
      />
      <path
        d="M18.53 5.90996C18.4446 5.90737 18.3606 5.88799 18.2827 5.85291C18.2049 5.81783 18.1347 5.76775 18.0761 5.70553C18.0176 5.6433 17.972 5.57016 17.9417 5.49027C17.9115 5.41039 17.8973 5.32533 17.9 5.23996L18 2.14996L14.58 2.31996C14.4938 2.32536 14.4074 2.31353 14.3258 2.28516C14.2443 2.25679 14.1692 2.21244 14.1049 2.15472C14.0407 2.097 13.9886 2.02705 13.9517 1.94897C13.9148 1.87089 13.8938 1.78624 13.89 1.69996C13.879 1.52646 13.9369 1.35562 14.0511 1.22456C14.1653 1.09349 14.3266 1.01278 14.5 0.999961L18.64 0.799961C18.7289 0.795938 18.8176 0.810789 18.9004 0.843538C18.9831 0.876287 19.0579 0.92619 19.12 0.989961C19.1841 1.05226 19.2349 1.12692 19.2693 1.20943C19.3036 1.29194 19.3209 1.38058 19.32 1.46996L19.18 5.26996C19.18 5.35486 19.1631 5.4389 19.1303 5.51721C19.0976 5.59552 19.0495 5.66652 18.989 5.72608C18.9285 5.78564 18.8568 5.83258 18.778 5.86414C18.6992 5.89571 18.6149 5.91129 18.53 5.90996Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconExternalLink;
