import React, { memo, useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import compact from 'lodash/compact';
import { Form } from '@components/Form';
import { Popup } from '@components/Popup';
import { Input } from '@components/Input';
import { Button } from '@components/Button';
import { Box } from '@components/Box';
import { Select } from '@components/Select';
import { Checkbox } from '@components/Checkbox';
import { Text } from '@components/Text';
import IconAdd from '@components/Svg/IconAdd';
import message from '@components/Message';
import RemoveIcon from '@assets/icons/remove-feature.svg';
import { UPDATE_QUESTION } from '@graphql/mutations/question';

const EditQuestion = memo(({ visible, onEdit, isEditProduct, onCancel, selectedQuestion, form }) => {
    const { resetFields, validateFields, getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const [choiceKeyId, setChoiceKeyId] = useState(1);
    const [initialChoiceKeys, setInitialChoiceKeys] = useState([0]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateQuestion] = useMutation(UPDATE_QUESTION);

    const hasChoicesFromData = selectedQuestion?.answerType === 'RADIO' || selectedQuestion?.answerType === 'DROPDOWN';
    const hasChoicesFromField = getFieldValue('answerType') === 'RADIO' || getFieldValue('answerType') === 'DROPDOWN';
    const hasChoices = getFieldValue('answerType') !== undefined ? hasChoicesFromField : hasChoicesFromData;

    useEffect(() => {
        if (hasChoicesFromData) {
            const cKeys = selectedQuestion.choices.map((c, cIndex) => cIndex);
            setInitialChoiceKeys(cKeys);
            setChoiceKeyId(selectedQuestion.choices.length);
        }
    }, [selectedQuestion, hasChoicesFromData]);

    const addChoiceField = () => {
        const choiceKeys = getFieldValue('choiceKeys');
        const nextKeys = choiceKeys.concat(choiceKeyId + 1);
        setChoiceKeyId(choiceKeyId + 1);
        setFieldsValue({
            choiceKeys: nextKeys,
        });
    };

    const removeChoiceField = choiceK => {
        const choiceKeys = getFieldValue('choiceKeys');
        if (choiceKeys.length === 1) {
            return;
        }

        setFieldsValue({
            choiceKeys: choiceKeys.filter(key => key !== choiceK),
        });
    };

    const onCloseModal = () => {
        setChoiceKeyId(1);
        setInitialChoiceKeys([0]);
        resetFields();
        onCancel();
    };

    const handleSubmit = useCallback(
        async e => {
            e.preventDefault();
            validateFields(async (err, values) => {
                if (!err && !isUpdating) {
                    if (isEditProduct) {
                        setIsUpdating(true);
                        message.destroy();
                        message.loading('Updating question...', 50000);
                        await updateQuestion({
                            variables: {
                                id: selectedQuestion.id,
                                title: values.title,
                                answerType: values.answerType,
                                choices: hasChoices ? compact(values.choices) : null,
                                choicesIds: selectedQuestion.choicesIds || null,
                                placeholder: values.placeholder,
                                help: values.help,
                                required: values.required,
                            },
                        })
                            .then(({ data }) => {
                                const { updateQuestion } = data;
                                setIsUpdating(false);
                                setChoiceKeyId(1);
                                setInitialChoiceKeys([0]);
                                message.destroy();
                                message.success('Question has been updated');
                                onEdit({
                                    id: updateQuestion.id,
                                    title: values.title,
                                    answerType: values.answerType,
                                    choices: hasChoices ? compact(values.choices) : null,
                                    choicesId: hasChoices ? updateQuestion.choices.map(c => c.label) : null,
                                    placeholder: values.placeholder,
                                    help: values.help,
                                    required: values.required,
                                });
                                setChoiceKeyId(1);
                                setInitialChoiceKeys([0]);
                                resetFields();
                                onCancel();
                            })
                            .catch(e => {
                                console.log(e);
                                setIsUpdating(false);
                                message.destroy();
                                message.error('Error on updating question');
                            });
                    } else {
                        onEdit({
                            title: values.title,
                            answerType: values.answerType,
                            choices: hasChoices ? compact(values.choices) : null,
                            placeholder: values.placeholder,
                            help: values.help,
                            required: values.required,
                        });
                        setChoiceKeyId(1);
                        setInitialChoiceKeys([0]);
                        resetFields();
                        onCancel();
                    }
                }
            });
        },
        [isUpdating, validateFields, onEdit, resetFields, onCancel, hasChoices, isEditProduct, selectedQuestion, updateQuestion]
    );
    getFieldDecorator('choiceKeys', { initialValue: initialChoiceKeys });
    const choiceKeys = getFieldValue('choiceKeys');

    const choiceFormItems = choiceKeys.map((k, index) => (
        <Box key={k} mt={index === 0 ? '0' : '16'}>
            <Form.Item label={`Choice #${index + 1}`} colon={false} required={false} style={{ marginBottom: 10 }}>
                <Box d="flex" alignItems="center">
                    {getFieldDecorator(`choices[${k}]`, {
                        initialValue: hasChoicesFromData ? selectedQuestion?.choices[k] : '',
                        rules: [
                            {
                                required: hasChoices ? true : false,
                                message: 'This field cannot be empty',
                            },
                        ],
                    })(<Input placeholder={`Enter choice #${index + 1}`} />)}
                    {choiceKeys.length > 1 ? (
                        <Box
                            ml="18"
                            w="40"
                            h="40"
                            lineH="38"
                            borderW="1"
                            borderStyle="solid"
                            borderColor="outline-gray"
                            cursor="pointer"
                            textAlign="center"
                            onClick={() => removeChoiceField(k)}
                        >
                            <img src={RemoveIcon} alt="Remove Choice" />
                        </Box>
                    ) : null}
                </Box>
            </Form.Item>
        </Box>
    ));

    return (
        <Popup
            variant="default"
            width={500}
            title="Edit question"
            visible={visible}
            onCancel={onCloseModal}
            footer={null}
            centered
            destroyOnClose
        >
            <Form onSubmit={handleSubmit}>
                <Form.Item label="Question title" colon={false} required={false} style={{ marginBottom: 19 }}>
                    {getFieldDecorator('title', {
                        initialValue: selectedQuestion?.title,
                        rules: [
                            {
                                required: true,
                                message: 'This field cannot be empty',
                            },
                        ],
                    })(<Input placeholder="Ex: Do you prefer clean and discreet design or strong visualy identity?" />)}
                </Form.Item>
                <Form.Item label="Type of answer" colon={false} required={false} style={{ marginBottom: 19 }}>
                    {getFieldDecorator('answerType', {
                        initialValue: selectedQuestion?.answerType,
                        rules: [
                            {
                                required: true,
                                message: 'This field cannot be empty',
                            },
                        ],
                    })(
                        <Select placeholder="Select type of answer">
                            <Select.Option value="TEXT">Text</Select.Option>
                            <Select.Option value="IMG_SELECT">Image select</Select.Option>
                            <Select.Option value="RADIO">Radio</Select.Option>
                            <Select.Option value="UPLOAD_FILES">Upload files</Select.Option>
                            <Select.Option value="DROPDOWN">Dropdown</Select.Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="" colon={false} required={false} style={{ marginBottom: hasChoices ? 10 : 0 }}>
                    {hasChoices && (
                        <>
                            {choiceFormItems}
                            <Box
                                mt="18"
                                mb="9"
                                d="flex"
                                alignItems="center"
                                colorScheme="cta"
                                cursor="pointer"
                                onClick={addChoiceField}
                                w="103"
                            >
                                <IconAdd />
                                <Text ml="8" textVariant="H6">
                                    Add choice
                                </Text>
                            </Box>
                        </>
                    )}
                </Form.Item>
                <Form.Item label="Placeholder" colon={false} required={false} style={{ marginBottom: 19 }}>
                    {getFieldDecorator('placeholder', {
                        initialValue: selectedQuestion?.placeholder,
                    })(<Input placeholder="Enter placeholder" />)}
                </Form.Item>
                <Form.Item label="Help information" colon={false} required={false} style={{ marginBottom: 19 }}>
                    {getFieldDecorator('help', {
                        initialValue: selectedQuestion?.help,
                    })(<Input placeholder="Enter help information" />)}
                </Form.Item>
                <Form.Item label="" colon={false} required={false}>
                    {getFieldDecorator('required', {
                        valuePropName: 'checked',
                        initialValue: selectedQuestion?.required,
                    })(
                        <Checkbox>
                            <Text d="inline-block" textVariant="P4" colorScheme="headline">
                                Is question required?
                            </Text>
                        </Checkbox>
                    )}
                </Form.Item>
                <Form.Item>
                    <Box d="flex" justifyContent="flex-end">
                        <Button loading={isUpdating} type="primary" htmlType="submit">
                            Update
                        </Button>
                    </Box>
                </Form.Item>
            </Form>
        </Popup>
    );
});

export default Form.create()(EditQuestion);
