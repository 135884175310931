import React from 'react';

const IconFileAep = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.32 19.95H2.68V2.83999L6.09 0.0499878H17.32V19.95Z" fill="#2A0E4F" />
            <path d="M2.68 2.83999H5.9V0.0499878L2.68 2.83999Z" fill="#DCAEFE" />
            <path
                d="M7.93 9.14999H8.73L10.65 13.56H9.55L9.17 12.62H7.46L7.09 13.56H6L7.93 9.14999ZM8.3 10.43L7.77 11.8H8.84L8.3 10.43Z"
                fill="#D3A9F4"
            />
            <path
                d="M13.83 13C13.6834 13.1916 13.4908 13.3429 13.27 13.44C13.0484 13.5462 12.8057 13.6009 12.56 13.6C12.3355 13.5992 12.1126 13.5621 11.9 13.49C11.7049 13.4173 11.5252 13.3088 11.37 13.17C11.2133 13.0331 11.0902 12.862 11.01 12.67C10.9201 12.4618 10.8758 12.2368 10.88 12.01C10.8747 11.7864 10.9191 11.5644 11.01 11.36C11.0902 11.168 11.2133 10.9969 11.37 10.86C11.5227 10.7179 11.7031 10.6089 11.9 10.54C12.1114 10.4631 12.3351 10.4258 12.56 10.43C12.7622 10.4248 12.9632 10.4623 13.15 10.54C13.32 10.6103 13.4708 10.72 13.59 10.86C13.719 11.0057 13.8176 11.1757 13.88 11.36C13.9521 11.5661 13.9926 11.7818 14 12V12.3H11.81C11.8428 12.4622 11.927 12.6094 12.05 12.72C12.1783 12.8283 12.3422 12.8853 12.51 12.88C12.6472 12.8874 12.7833 12.8525 12.9 12.78C13.0101 12.7092 13.1052 12.6175 13.18 12.51L13.83 13ZM13 11.7C13.0068 11.6263 12.9969 11.5521 12.9709 11.4828C12.9449 11.4135 12.9036 11.351 12.85 11.3C12.7965 11.2429 12.7312 11.1982 12.6587 11.1688C12.5862 11.1395 12.5081 11.1262 12.43 11.13C12.3407 11.1139 12.2493 11.1139 12.16 11.13C12.0813 11.1575 12.0097 11.2018 11.95 11.26C11.8957 11.3112 11.8516 11.3723 11.82 11.44C11.8097 11.5096 11.8097 11.5804 11.82 11.65L13 11.7Z"
                fill="#D3A9F4"
            />
        </svg>
    );
};

export default IconFileAep;
