import React, { memo, useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { Tooltip } from 'antd';
import find from 'lodash/find';
import { useQuery } from '@apollo/react-hooks';
import { Form } from '@components/Form';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Basepage } from '@components/Basepage';
import { PageContainer } from '@components/PageContainer';
import { Button } from '@components/Button';
import { Breadcrumb, BreadcrumbItem } from '@components/Breadcrumb';
import { Link } from '@components/Link';
import { REQUESTS } from '@constants/routes';
import { Prompt } from '@components/Prompt';
import { Popup } from '@components/Popup';
import { Input } from '@components/Input';
import IconSaveAsDraft from '@components/Svg/IconSaveAsDraft';
import { ORDER_STATUS_DRAFT } from '@constants/order';
import ArrowLeftIcon from '@components/Svg/ArrowLeft';
import { ALL_CATEGORIES } from '@graphql/queries/category';
import { withResponsive } from '@components/ResponsiveProvider';
import IconQuestions from '@components/Svg/IconQuestions';
import { Wysiwyg, toWysiwyg } from '@components/Wysiwyg';
import { Uploader } from '@components/Uploader';
import IconLeftArrow from '@components/Svg/IconLeftArrow';
import { FieldBrand } from './FieldBrand';
import { FieldCategoryRequest } from './FieldCategoryRequest';
import { FieldProductRequest } from './FieldProductRequest';
import { FieldDeliverableRequest } from './FieldDeliverableRequest';
import ResponseRequest from './ResponseRequest';
import SideNoteForm from './SideNoteForm';
import { PopupCancelRequest } from './PopupCancelRequest';

const texts = [
    'Clear description of what you want',
    'How/where design will be used',
    '3-4 concepts that you like',
    'What you like from the concepts provided',
    'Dimensions',
    'Color preferences',
    'Design copywriting',
    'Number of design',
];

const FormRequest = memo(
    ({
        isEdit,
        form,
        onSubmit,
        paging,
        initialValues = {},
        title,
        submitText,
        breadcrumbLabel,
        refetch,
        viewer,
        windowWidth,
        isDuplicate,
        handleChangeAttachments,
        handleChangeAttachmentsToDisconnect,
        afterSubmit,
    }) => {
        const productRef = useRef();
        const otherDeliverablesRef = useRef();
        const [step, setStep] = useState(1);
        const [hasSubmitted, setHasSubmited] = useState(false);
        const [saveType, setSaveType] = useState('');
        const [isSubmitting, setIsSubmitting] = useState(false);
        const [category, setCategory] = useState();
        const [isFilled, setIsFilled] = useState(false);
        const [hasSelectedOtherDeliverables, setHasSelectedOtherDeliverables] = useState(false);
        const [showUpgrade, setShowUpgrade] = useState(false);

        const { validateFields, setFieldsValue, resetFields, getFieldValue } = form;

        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        const { data, loading } = useQuery(ALL_CATEGORIES, {
            variables: {
                where: {
                    isActivated: true,
                },
            },
        });

        const requestName = getFieldValue('name');
        const service = getFieldValue('serviceId');
        const categ = getFieldValue('categoryId');

        useEffect(() => {
            setIsFilled(!!requestName && !!service && !!categ);
        }, [requestName, service, categ]);

        const handleSetIsFilled = bool => {
            setIsFilled(bool);
        };

        const handleChangeCategory = selectedCategory => {
            setCategory(selectedCategory);

            if (selectedCategory) {
                window.scrollTo({ top: productRef.current.offsetTop, behavior: 'smooth' });
            }
        };

        const handleSubmit = useCallback(
            ev => {
                // When its triggered from cancel and save as draft button
                if (ev && ev.preventDefault) {
                    ev.preventDefault();
                }

                validateFields(async (err, values) => {
                    if (!err && !isSubmitting) {
                        setSaveType(values.type ?? 'SUBMITTED');
                        setIsSubmitting(true);
                        await onSubmit(values);
                        setIsSubmitting(false);
                        setHasSubmited(true);
                        resetFields();
                        if (afterSubmit) {
                            afterSubmit();
                        }
                    }
                });
            },
            [validateFields, onSubmit, resetFields, afterSubmit, isSubmitting]
        );

        const handleSaveAsDraft = useCallback(() => {
            setFieldsValue({
                type: ORDER_STATUS_DRAFT,
            });
            handleSubmit();
        }, [handleSubmit, setFieldsValue]);

        const handlePromptOkay = useCallback(() => {
            if (!isEdit) {
                handleSaveAsDraft();
            }
        }, [handleSaveAsDraft, isEdit]);

        const handleAfterPromptOkay = useCallback((confirm, closeModal) => {
            confirm();
        }, []);

        const handleAfterPromptCancel = useCallback(
            (confirm, closeModal) => {
                if (isEdit) {
                    closeModal();
                } else {
                    confirm();
                }
            },
            [isEdit]
        );

        const allCategories = useMemo(() => data?.allCategories ?? [], [data]);
        const initialCategoryId = useMemo(() => initialValues.categoryId ?? undefined, [initialValues]);

        useEffect(() => {
            if (initialCategoryId) {
                const foundCategory = allCategories.find(item => item.id === initialCategoryId);
                setCategory(foundCategory);
            }
        }, [initialCategoryId, allCategories]);

        const descriptionHasText = getFieldValue('description')
            ? getFieldValue('description')
                  ?.getCurrentContent()
                  .hasText()
            : false;

        const enableNext =
            getFieldValue('name') && descriptionHasText && getFieldValue('serviceId') && getFieldValue('categoryId');

        const enableSubmitDeliverables = getFieldValue('deliverables') && getFieldValue('deliverables')?.length;

        const hasOtherDeliverables =
            getFieldValue('deliverables') &&
            getFieldValue('deliverables')?.length > 0 &&
            getFieldValue('deliverables')?.includes('OTHERS');

        const enableSubmit = hasOtherDeliverables
            ? enableSubmitDeliverables && getFieldValue('otherDeliverables')
            : enableSubmitDeliverables;

        useEffect(() => {
            if (hasOtherDeliverables && hasSelectedOtherDeliverables) {
                window.scrollTo({ top: otherDeliverablesRef.current.offsetTop, behavior: 'smooth' });
            }
        }, [hasOtherDeliverables, hasSelectedOtherDeliverables]);

        const handleSelectDeliverable = val => {
            setHasSelectedOtherDeliverables(val?.length > 0 && val?.includes('OTHERS'));
        };

        const handleNextStep = () => {
            setStep(old => old + 1);
            window.scrollTo(0, 0);
        };

        const handlePreviousStep = () => {
            setStep(old => old - 1);
            window.scrollTo(0, 0);
        };

        const chosenProductId = getFieldValue('serviceId');
        const chosenProduct = chosenProductId ? find(category?.services, ['id', chosenProductId]) : null;
        const chosenProductDeliverables = chosenProduct?.deliverables ?? null;

        return (
            <>
                <Basepage>
                    <PageContainer maxW="880">
                        <Box d="flex" justifyContent="space-between" alignItems="center" mb={['30', '12']}>
                            <Box d="flex" alignItems="center">
                                {title === 'Create Request' && (
                                    <Box d="inline-flex" pr="20">
                                        <Button
                                            w="36"
                                            h="36"
                                            mobileH="36"
                                            type="default"
                                            className="ant-btn ant-btn-default"
                                            as={Link}
                                            to={REQUESTS}
                                            icon={<ArrowLeftIcon style={{ fontSize: 20 }} />}
                                        />
                                    </Box>
                                )}

                                <Text hide="mobile" textVariant="H3">
                                    {title}
                                </Text>
                                <Text hide="desktop" textVariant="H4">
                                    {title}
                                </Text>
                            </Box>
                            {!isEdit && (
                                <>
                                    <Box hide="mobile">
                                        <Button
                                            loading={isSubmitting}
                                            type="default"
                                            disabled={!isFilled}
                                            onClick={handleSaveAsDraft}
                                        >
                                            Save As Draft
                                        </Button>
                                    </Box>
                                    <Box hide="desktop">
                                        <Button
                                            type="default"
                                            icon={<IconSaveAsDraft />}
                                            disabled={!isFilled}
                                            onClick={handleSaveAsDraft}
                                        />
                                    </Box>
                                </>
                            )}
                        </Box>
                        <Box hide="mobile">
                            <Breadcrumb>
                                <BreadcrumbItem isFirst as={Link} to={REQUESTS}>
                                    Requests
                                </BreadcrumbItem>
                                <BreadcrumbItem>{breadcrumbLabel}</BreadcrumbItem>
                            </Breadcrumb>
                        </Box>
                        <Box mt={['8', '30']}>
                            <Form onSubmit={handleSubmit}>
                                {form.getFieldDecorator('type')(<Input type="hidden" />)}
                                <Box h={(step === 1 && paging) || !paging ? '100%' : '0'} overflow="hidden">
                                    <Box>
                                        <Text textVariant="H5" mb="16">
                                            Name your request
                                        </Text>
                                        <Form.Item colon={false} required={false}>
                                            {form.getFieldDecorator('name', {
                                                initialValue: initialValues.name,
                                            })(<Input placeholder="Give your request a name" />)}
                                        </Form.Item>
                                    </Box>
                                    <Box>
                                        <Text textVariant="H5" mb="16">
                                            Choose a brand{' '}
                                            <Text as="span" colorScheme="tertiary">
                                                (Optional)
                                            </Text>
                                        </Text>
                                        <Form.Item
                                            colon={false}
                                            required={false}
                                            style={{
                                                marginBottom: 10,
                                            }}
                                        >
                                            {form.getFieldDecorator('brandId', {
                                                initialValue: initialValues.brandId,
                                            })(<FieldBrand windowWidth={windowWidth} viewer={viewer} />)}
                                        </Form.Item>
                                    </Box>
                                    <Box>
                                        <Text textVariant="H5" mb="16">
                                            Choose a category
                                        </Text>
                                        <Form.Item
                                            colon={false}
                                            required={false}
                                            style={{
                                                marginBottom: 10,
                                            }}
                                        >
                                            {form.getFieldDecorator('categoryId', {
                                                initialValue: initialValues.categoryId,
                                            })(
                                                <FieldCategoryRequest
                                                    loading={loading}
                                                    categories={allCategories}
                                                    handleChange={handleChangeCategory}
                                                />
                                            )}
                                        </Form.Item>
                                    </Box>
                                    <Box ref={productRef}>
                                        <Text textVariant="H5" mb="16">
                                            Choose a product
                                        </Text>
                                        <Form.Item
                                            colon={false}
                                            required={false}
                                            style={{
                                                marginBottom: 10,
                                            }}
                                        >
                                            {form.getFieldDecorator('serviceId', {
                                                initialValue: initialValues.serviceId,
                                            })(
                                                <FieldProductRequest
                                                    windowWidth={windowWidth}
                                                    category={category}
                                                    viewer={viewer}
                                                    showUpgrade={showUpgrade}
                                                    setShowUpgrade={setShowUpgrade}
                                                />
                                            )}
                                        </Form.Item>
                                    </Box>
                                    <Box>
                                        <Text textVariant="H5" mb="10" d="flex" alignItems="center">
                                            Describe your request
                                            {windowWidth >= 1024 && windowWidth <= 1279 && (
                                                <Tooltip
                                                    title={
                                                        <>
                                                            <Text textVariant="H6" mb="14">
                                                                WHAT TO INCLUDE
                                                            </Text>
                                                            <Box as="ul" pl="20" mb="0">
                                                                {texts.map((text, index) => (
                                                                    <Box
                                                                        as="li"
                                                                        key={text}
                                                                        mb={index === texts.length - 1 ? '0' : '4'}
                                                                    >
                                                                        {text}
                                                                    </Box>
                                                                ))}
                                                            </Box>
                                                        </>
                                                    }
                                                    trigger="hover"
                                                >
                                                    <Box
                                                        as="span"
                                                        d="inline-flex"
                                                        alignItems="center"
                                                        ml="8"
                                                        colorScheme="cta"
                                                    >
                                                        <IconQuestions size="16px" />
                                                    </Box>
                                                </Tooltip>
                                            )}
                                        </Text>
                                        <Text textVariant="P4" colorScheme="secondary" mb="16">
                                            Format your paragraphs and create checklists to make your description easy
                                            to read and follow. Well-written instructions will result in better designs.
                                        </Text>
                                        <Form.Item colon={false} required={false} style={{ marginBottom: 16 }}>
                                            {form.getFieldDecorator('description', {
                                                initialValue: toWysiwyg(initialValues?.description),
                                            })(
                                                <Wysiwyg
                                                    contentMinHeight="202px"
                                                    
                                                    placeholder={
                                                        <>
                                                            <Text>
                                                                We are a small B2B/B2C company providing solutions in
                                                                Digital Marketing and I am looking for a new logo that
                                                                focuses on:
                                                            </Text>
                                                            <Box as="ul" listStyle="disc" ml="20">
                                                                <Box as="li">Social Media</Box>
                                                                <Box as="li">Young Entrepreneurs</Box>
                                                                <Box as="li">Market Leaders</Box>
                                                            </Box>
                                                        </>
                                                    }
                                                />
                                            )}
                                        </Form.Item>
                                    </Box>
                                </Box>
                                <Box h={(step === 2 && paging) || !paging ? '100%' : '0'} overflow="hidden">
                                    <Box>
                                        <Text textVariant="H5" mb="10">
                                            Attachments{' '}
                                            <Text as="span" colorScheme="tertiary">
                                                (Optional)
                                            </Text>
                                        </Text>
                                        <Text textVariant="P4" colorScheme="secondary" mb="16">
                                            Upload any images, files, or examples that maybe helpful in explaining your
                                            request here.
                                        </Text>
                                        <Form.Item colon={false} required={false}>
                                            {form.getFieldDecorator('attachments')(
                                                <Uploader
                                                    listType="picture"
                                                    multiple
                                                    initialValue={initialValues.attachments}
                                                    refetch={refetch}
                                                    isDuplicateEdit={isEdit || isDuplicate}
                                                    handleChangeAttachments={handleChangeAttachments}
                                                    handleChangeAttachmentsToDisconnect={
                                                        handleChangeAttachmentsToDisconnect
                                                    }
                                                />
                                            )}
                                        </Form.Item>
                                    </Box>
                                    <Box>
                                        <Text textVariant="H5" mb="10">
                                            File deliverables
                                        </Text>
                                        {!loading && chosenProductId ? (
                                            <>
                                                <Text textVariant="P4" colorScheme="secondary" mb="16">
                                                    Select the file types you need.
                                                </Text>
                                                <Form.Item colon={false} required={false}>
                                                    {form.getFieldDecorator('deliverables', {
                                                        initialValue: initialValues.deliverables,
                                                    })(
                                                        <FieldDeliverableRequest
                                                            chosenProductDeliverables={chosenProductDeliverables}
                                                            onSelect={handleSelectDeliverable}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </>
                                        ) : (
                                            <Text textVariant="P4" colorScheme="secondary" mb="16">
                                                Please choose a product first.
                                            </Text>
                                        )}
                                        <Box ref={otherDeliverablesRef}>
                                            {hasOtherDeliverables && (
                                                <Form.Item label="Others" colon={false} required={false}>
                                                    {form.getFieldDecorator('otherDeliverables', {
                                                        initialValue: initialValues.otherDeliverables,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'This field cannot be empty',
                                                            },
                                                        ],
                                                    })(<Input placeholder="Svg, ppt, google slides, etc." />)}
                                                </Form.Item>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    hide="desktop"
                                    w="calc(100% + 32px)"
                                    borderT="1"
                                    borderTopStyle="solid"
                                    borderTopColor="outline-gray"
                                    mb="16"
                                    mx="-16px"
                                />
                                <Box d="flex" mx="-5px" flexWrap="wrap" alignItems="center">
                                    <Box px="5px">
                                        <PopupCancelRequest
                                            isFilled={isFilled}
                                            isEdit={isEdit}
                                            form={form}
                                            handleSubmit={handleSubmit}
                                            handleSetIsFilled={handleSetIsFilled}
                                        />
                                    </Box>
                                    {paging && step === 1 && (
                                        <>
                                            <Box hide="mobile" ml="auto" px="5px">
                                                <Button
                                                    type="primary"
                                                    htmlType="button"
                                                    disabled={!enableNext}
                                                    onClick={handleNextStep}
                                                >
                                                    Next
                                                </Button>
                                            </Box>
                                            <Box hide="desktop" flex={1} px="5px">
                                                <Button
                                                    type="primary"
                                                    block
                                                    htmlType="button"
                                                    disabled={!enableNext}
                                                    onClick={handleNextStep}
                                                >
                                                    Next
                                                </Button>
                                            </Box>
                                        </>
                                    )}
                                    {((paging && step === 2) || !paging) && (
                                        <Box flex={['1', 'unset']} ml="auto" px="5px">
                                            <Box d="flex" mx="-5px" alignItems="center" flexWrap="wrap">
                                                {paging && (
                                                    <>
                                                        <Box hide="mobile" px="5px">
                                                            <Button
                                                                type="default"
                                                                htmlType="button"
                                                                icon={<IconLeftArrow />}
                                                                onClick={handlePreviousStep}
                                                                mr="10"
                                                            >
                                                                Previous
                                                            </Button>
                                                        </Box>
                                                        <Box hide="desktop" px="5px" flex={1}>
                                                            <Button
                                                                type="default"
                                                                htmlType="button"
                                                                block
                                                                icon={<IconLeftArrow />}
                                                                onClick={handlePreviousStep}
                                                            >
                                                                Previous
                                                            </Button>
                                                        </Box>
                                                    </>
                                                )}
                                                <Box hide="mobile" px="5px">
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        disabled={!enableSubmit}
                                                        ml={paging ? '0' : '14'}
                                                        loading={isSubmitting}
                                                    >
                                                        {submitText ?? 'Submit'}
                                                    </Button>
                                                </Box>
                                                <Box hide="desktop" px="5px" flex={1}>
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        block
                                                        disabled={!enableSubmit}
                                                        loading={isSubmitting}
                                                    >
                                                        {submitText ?? 'Submit'}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Form>
                        </Box>
                    </PageContainer>
                </Basepage>
                <SideNoteForm />
                {!showUpgrade && (
                    <Prompt
                        isBlocked={isFilled && !hasSubmitted}
                        title={isEdit ? 'Are you sure you want to quit?' : 'Unsaved request'}
                        content={
                            isEdit
                                ? 'All changes on this request will be cancelled.'
                                : 'Would you like to save your request as a draft? You will be able to edit the brief later.'
                        }
                        okayText={isEdit ? 'Quit' : 'Save As Draft'}
                        cancelText={isEdit ? 'Cancel' : "Don't save"}
                        onOkay={handlePromptOkay}
                        afterOkay={handleAfterPromptOkay}
                        afterCancel={handleAfterPromptCancel}
                    />
                )}
                <Popup
                    visible={hasSubmitted && !isEdit}
                    variant="default"
                    width={500}
                    closable={false}
                    maskClosable={false}
                    footer={null}
                    centered
                    paddingBody={['20px 16px', '30px 30px']}
                >
                    <ResponseRequest saveType={saveType} />
                </Popup>
            </>
        );
    }
);

export default withResponsive(Form.create()(FormRequest));
