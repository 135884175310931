import React from 'react';

const IconBrandLinkedin = () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M20.9033 20V19.9992H20.9083V12.6642C20.9083 9.07584 20.1358 6.31167 15.9408 6.31167C13.9241 6.31167 12.5708 7.41834 12.0183 8.4675H11.9599V6.64667H7.98242V19.9992H12.1241V13.3875C12.1241 11.6467 12.4541 9.96334 14.6099 9.96334C16.7341 9.96334 16.7658 11.95 16.7658 13.4992V20H20.9033Z"
                    fill="#0077B7"
                />
                <path d="M1.23828 6.64748H5.38495V20H1.23828V6.64748Z" fill="#0077B7" />
                <path
                    d="M3.30987 0C1.98404 0 0.908203 1.07583 0.908203 2.40167C0.908203 3.7275 1.98404 4.82583 3.30987 4.82583C4.6357 4.82583 5.71154 3.7275 5.71154 2.40167C5.7107 1.07583 4.63487 0 3.30987 0V0Z"
                    fill="#0077B7"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="20" height="20" fill="white" transform="translate(0.908203)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconBrandLinkedin;
