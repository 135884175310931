import React from 'react';
import { Icon } from 'antd';
const IconDelete = props => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.1604 19.85H3.84043C3.66884 19.8474 3.50501 19.7781 3.38366 19.6567C3.26232 19.5354 3.19301 19.3716 3.19043 19.2V7.36997C3.19043 7.19758 3.25891 7.03225 3.38081 6.91035C3.50271 6.78845 3.66804 6.71997 3.84043 6.71997H16.1604C16.3328 6.71997 16.4981 6.78845 16.62 6.91035C16.7419 7.03225 16.8104 7.19758 16.8104 7.36997V19.2C16.8078 19.3716 16.7385 19.5354 16.6172 19.6567C16.4959 19.7781 16.332 19.8474 16.1604 19.85ZM4.49043 18.55H15.4904V7.99997H4.49043V18.55Z" />
      <path d="M18.0703 7.75997H1.93027C1.75788 7.75997 1.59255 7.69149 1.47065 7.56959C1.34876 7.44769 1.28027 7.28236 1.28027 7.10997V3.86997C1.28285 3.69838 1.35216 3.53455 1.47351 3.41321C1.59485 3.29186 1.75869 3.22255 1.93027 3.21997H18.0703C18.2419 3.22255 18.4057 3.29186 18.527 3.41321C18.6484 3.53455 18.7177 3.69838 18.7203 3.86997V7.10997C18.7203 7.28236 18.6518 7.44769 18.5299 7.56959C18.408 7.69149 18.2427 7.75997 18.0703 7.75997ZM2.58027 6.45997H17.4203V4.51997H2.58027V6.45997Z" />
      <path d="M7.47031 16.09C7.29792 16.09 7.13259 16.0215 7.01069 15.8996C6.88879 15.7777 6.82031 15.6124 6.82031 15.44V7.13999C6.82031 6.9676 6.88879 6.80227 7.01069 6.68037C7.13259 6.55847 7.29792 6.48999 7.47031 6.48999C7.6427 6.48999 7.80803 6.55847 7.92993 6.68037C8.05183 6.80227 8.12031 6.9676 8.12031 7.13999V15.44C8.11773 15.6116 8.04842 15.7754 7.92708 15.8968C7.80573 16.0181 7.6419 16.0874 7.47031 16.09Z" />
      <path d="M12.5299 16.23C12.3583 16.2274 12.1945 16.1581 12.0731 16.0368C11.9518 15.9154 11.8825 15.7516 11.8799 15.58V7.42002C11.8799 7.24763 11.9484 7.0823 12.0703 6.9604C12.1922 6.8385 12.3575 6.77002 12.5299 6.77002C12.7023 6.77002 12.8676 6.8385 12.9895 6.9604C13.1114 7.0823 13.1799 7.24763 13.1799 7.42002V15.58C13.1799 15.6654 13.1631 15.7499 13.1304 15.8288C13.0977 15.9076 13.0499 15.9793 12.9895 16.0396C12.9291 16.1 12.8575 16.1479 12.7786 16.1805C12.6998 16.2132 12.6152 16.23 12.5299 16.23Z" />
      <path d="M13.4801 4.44999C13.3085 4.44741 13.1447 4.3781 13.0234 4.25675C12.902 4.13541 12.8327 3.97157 12.8301 3.79999V1.84999H7.17012V3.30999C7.17012 3.48238 7.10164 3.64771 6.97974 3.76961C6.85784 3.89151 6.69251 3.95999 6.52012 3.95999C6.34773 3.95999 6.1824 3.89151 6.0605 3.76961C5.9386 3.64771 5.87012 3.48238 5.87012 3.30999V1.19999C5.87012 1.0276 5.9386 0.862267 6.0605 0.740368C6.1824 0.61847 6.34773 0.549988 6.52012 0.549988H13.5201C13.6925 0.549988 13.8578 0.61847 13.9797 0.740368C14.1016 0.862267 14.1701 1.0276 14.1701 1.19999V3.79999C14.1703 3.88882 14.1522 3.97674 14.1171 4.05833C14.082 4.13992 14.0305 4.21344 13.9658 4.27435C13.9012 4.33526 13.8247 4.38227 13.7412 4.41248C13.6576 4.44269 13.5688 4.45545 13.4801 4.44999Z" />
    </svg>
  );
};

export const CustomIconDelete = (props) => <Icon component={IconDelete} {...props} />

export default IconDelete;
