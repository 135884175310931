import React from 'react';

const IconWarning = () => {
    return (
        <svg width="19.52" height="19.52" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8117_18220)">
                <path
                    d="M10.835 19.76C8.90464 19.76 7.01764 19.1876 5.41262 18.1151C3.8076 17.0427 2.55663 15.5184 1.81792 13.735C1.07921 11.9516 0.88593 9.98917 1.26252 8.09591C1.63911 6.20266 2.56866 4.46359 3.93362 3.09863C5.29858 1.73367 7.03765 0.804122 8.9309 0.42753C10.8242 0.0509385 12.7866 0.244219 14.57 0.98293C16.3534 1.72164 17.8777 2.97261 18.9501 4.57763C20.0226 6.18265 20.595 8.06965 20.595 9.99999C20.5923 12.5877 19.5632 15.0687 17.7334 16.8984C15.9036 18.7282 13.4227 19.7573 10.835 19.76Z"
                    fill="#FFD44D"
                />
                <path
                    d="M9.83513 15.0041C9.83288 14.8719 9.85794 14.7407 9.90873 14.6188C9.95951 14.4968 10.0349 14.3868 10.1302 14.2956C10.2224 14.202 10.3322 14.1276 10.4531 14.0769C10.5741 14.0261 10.7039 14 10.835 14C10.9661 14 11.0959 14.0261 11.2168 14.0769C11.3378 14.1276 11.4476 14.202 11.5398 14.2956C11.6351 14.3868 11.7105 14.4968 11.7613 14.6188C11.812 14.7407 11.8371 14.8719 11.8349 15.0041C11.837 15.1351 11.8118 15.265 11.761 15.3856C11.7102 15.5062 11.6348 15.6148 11.5398 15.7044C11.4476 15.798 11.3378 15.8724 11.2168 15.9231C11.0959 15.9739 10.9661 16 10.835 16C10.7039 16 10.5741 15.9739 10.4531 15.9231C10.3322 15.8724 10.2224 15.798 10.1302 15.7044C10.0351 15.6148 9.9598 15.5062 9.90899 15.3856C9.85818 15.265 9.83302 15.1351 9.83513 15.0041Z"
                    fill="#07122B"
                />
                <path
                    d="M10.835 4C11.0981 3.99999 11.3506 4.07031 11.5378 4.19574C11.725 4.32116 11.8318 4.49158 11.835 4.67004V12.3217C11.835 12.5016 11.7296 12.6741 11.5421 12.8013C11.3546 12.9285 11.1002 13 10.835 13C10.5698 13 10.3154 12.9285 10.1279 12.8013C9.94035 12.6741 9.83499 12.5016 9.83499 12.3217V4.67004C9.8382 4.49158 9.94497 4.32116 10.1322 4.19574C10.3194 4.07031 10.5719 3.99999 10.835 4Z"
                    fill="#07122B"
                />
            </g>
            <defs>
                <clipPath id="clip0_8117_18220">
                    <rect width="20" height="20" fill="white" transform="translate(0.834991)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconWarning;
