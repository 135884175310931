import React from 'react';

const IconQuestions = ({ size = '1em' }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 1H4C2.34315 1 1 2.34315 1 4V16C1 17.6569 2.34315 19 4 19H16C17.6569 19 19 17.6569 19 16V4C19 2.34315 17.6569 1 16 1ZM3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V16C17 16.5523 16.5523 17 16 17H4C3.44772 17 3 16.5523 3 16V4ZM10.0673 4.90601C9.21801 4.90601 8.48068 5.10201 7.85535 5.49401V7.55201C8.18201 7.33734 8.51801 7.18334 8.86335 7.09001C9.20868 6.98734 9.53535 6.93601 9.84335 6.93601C10.45 6.93601 10.7533 7.16467 10.7533 7.62201C10.7533 7.82734 10.702 8.00934 10.5993 8.16801C10.4967 8.32667 10.338 8.51801 10.1233 8.74201C9.82468 9.07801 9.59135 9.39067 9.42335 9.68001C9.26468 9.96001 9.18535 10.3193 9.18535 10.758V11.626H10.6693L10.7673 11.052C10.814 10.8467 10.9167 10.6553 11.0753 10.478C11.234 10.3007 11.4767 10.072 11.8033 9.79201C12.2887 9.38134 12.662 8.99867 12.9233 8.64401C13.194 8.28934 13.3293 7.86467 13.3293 7.37001C13.3293 6.89401 13.1893 6.46934 12.9093 6.09601C12.6387 5.72267 12.256 5.43334 11.7613 5.22801C11.2667 5.01334 10.702 4.90601 10.0673 4.90601ZM11.2713 12.396H8.63935V15H11.2713V12.396Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconQuestions;
