import React from 'react';

const IconRateGreat = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.9999 21.912C8.84171 21.912 6.73198 21.272 4.93751 20.073C3.14304 18.874 1.74443 17.1698 0.918522 15.1759C0.0926187 13.182 -0.123475 10.9879 0.297567 8.87119C0.718608 6.75447 1.75788 4.81014 3.28395 3.28407C4.81002 1.758 6.75435 0.718731 8.87107 0.297689C10.9878 -0.123353 13.1818 0.0927407 15.1757 0.918644C17.1696 1.74455 18.8739 3.14317 20.0729 4.93764C21.2719 6.7321 21.9119 8.84183 21.9119 11C21.909 13.8932 20.7584 16.667 18.7126 18.7128C16.6669 20.7585 13.893 21.9091 10.9999 21.912ZM10.9999 1.51802C9.12453 1.51802 7.29129 2.07413 5.73198 3.11602C4.17267 4.15792 2.95734 5.6388 2.23967 7.37141C1.522 9.10402 1.33422 11.0105 1.70009 12.8499C2.06595 14.6892 2.96903 16.3787 4.29511 17.7048C5.62119 19.0309 7.31072 19.934 9.15005 20.2998C10.9894 20.6657 12.8959 20.4779 14.6285 19.7602C16.3611 19.0426 17.842 17.8272 18.8839 16.2679C19.9258 14.7086 20.4819 12.8754 20.4819 11C20.479 8.48613 19.4791 6.07604 17.7015 4.29845C15.9239 2.52086 13.5138 1.52093 10.9999 1.51802Z"
                fill="currentColor"
            />
            <path
                d="M10.8568 15.7301C9.90105 15.7324 8.97047 15.4235 8.20584 14.8501C8.06173 14.7264 7.97182 14.5512 7.95537 14.362C7.93892 14.1728 7.99724 13.9847 8.11784 13.8381C8.23957 13.6947 8.4129 13.605 8.60027 13.5886C8.78764 13.5721 8.97395 13.6301 9.11884 13.7501C9.66496 14.1242 10.3137 14.3198 10.9756 14.31C11.6375 14.3001 12.2801 14.0853 12.8148 13.6951C12.8955 13.6452 12.9853 13.6119 13.079 13.5971C13.1727 13.5822 13.2684 13.5862 13.3605 13.6087C13.4527 13.6312 13.5394 13.6719 13.6157 13.7282C13.692 13.7846 13.7563 13.8556 13.8048 13.9371C13.8992 14.0998 13.9267 14.2928 13.8816 14.4754C13.8364 14.6581 13.7222 14.816 13.5628 14.9161C12.7525 15.4303 11.8164 15.7119 10.8568 15.7301Z"
                fill="currentColor"
            />
            <path
                d="M8.50324 10.901C8.31361 10.901 8.13175 10.8257 7.99766 10.6916C7.86357 10.5575 7.78824 10.3757 7.78824 10.186C7.76205 9.93443 7.64355 9.70144 7.45563 9.53208C7.26772 9.36271 7.02372 9.26898 6.77074 9.26898C6.51777 9.26898 6.27376 9.36271 6.08585 9.53208C5.89794 9.70144 5.77944 9.93443 5.75324 10.186C5.75324 10.3757 5.67791 10.5575 5.54382 10.6916C5.40974 10.8257 5.22787 10.901 5.03824 10.901C4.84861 10.901 4.66675 10.8257 4.53266 10.6916C4.39857 10.5575 4.32324 10.3757 4.32324 10.186C4.36488 9.56528 4.64084 8.98353 5.09526 8.55858C5.54967 8.13363 6.14859 7.89722 6.77074 7.89722C7.3929 7.89722 7.99181 8.13363 8.44623 8.55858C8.90064 8.98353 9.1766 9.56528 9.21824 10.186C9.21824 10.3757 9.14291 10.5575 9.00882 10.6916C8.87473 10.8257 8.69287 10.901 8.50324 10.901Z"
                fill="currentColor"
            />
            <path
                d="M16.8409 10.901C16.6512 10.901 16.4694 10.8257 16.3353 10.6916C16.2012 10.5575 16.1259 10.3756 16.1259 10.186C16.1259 9.91762 16.0193 9.66021 15.8295 9.47043C15.6397 9.28064 15.3823 9.17402 15.1139 9.17402C14.8455 9.17402 14.5881 9.28064 14.3983 9.47043C14.2085 9.66021 14.1019 9.91762 14.1019 10.186C14.1019 10.3756 14.0265 10.5575 13.8925 10.6916C13.7584 10.8257 13.5765 10.901 13.3869 10.901C13.1972 10.901 13.0154 10.8257 12.8813 10.6916C12.7472 10.5575 12.6719 10.3756 12.6719 10.186C12.6719 9.53836 12.9292 8.91723 13.3871 8.45926C13.8451 8.0013 14.4662 7.74402 15.1139 7.74402C15.7615 7.74402 16.3827 8.0013 16.8406 8.45926C17.2986 8.91723 17.5559 9.53836 17.5559 10.186C17.5559 10.3756 17.4805 10.5575 17.3465 10.6916C17.2124 10.8257 17.0305 10.901 16.8409 10.901Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconRateGreat;
