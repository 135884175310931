import React from 'react';

const IconSearch = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.091 8.36367C14.091 11.5268 11.5268 14.091 8.36367 14.091C5.20057 14.091 2.63637 11.5268 2.63637 8.36367C2.63637 5.20057 5.20057 2.63637 8.36367 2.63637C11.5268 2.63637 14.091 5.20057 14.091 8.36367ZM12.96 14.1171C11.7002 15.1248 10.1023 15.7273 8.36367 15.7273C4.29683 15.7273 1 12.4305 1 8.36367C1 4.29683 4.29683 1 8.36367 1C12.4305 1 15.7273 4.29683 15.7273 8.36367C15.7273 10.1023 15.1248 11.7002 14.1171 12.96L18.7604 17.6033C19.0799 17.9228 19.0799 18.4409 18.7604 18.7604C18.4409 19.0799 17.9228 19.0799 17.6033 18.7604L12.96 14.1171Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconSearch;
