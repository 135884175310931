import React from 'react';
import { Text } from '@components/Text';
import { Box } from '@components/Box';
import { questionEnums } from '@constants/enums';
import { useQuery } from 'react-apollo';
import { CHOICE } from '@graphql/queries/choice';
import { CardAttachment } from './CardAttachment';
import IconDownloadAlt from '@components/Svg/IconDownloadAlt';
import processString from 'react-process-string';
import { textWithUrlConfig } from '@constants/utils';

const Choice = ({ b, n }) => {
  const { loading, data } = useQuery(CHOICE, { variables: { id: b.answer } });
  if (loading) return <div></div>;

  const { Choice } = data;

  if (!Choice) return <div></div>;

  const  { label } = Choice;
  const choice = {
    ...b,
    answer: label
  }

  return <TextAnswer b={choice} n={n} />
}

const TextAnswer = ({ b, n }) => {
  return (
    <Box my="20px">
      <Text textVariant="P4" colorScheme="secondary" mb="8">
          {`${n+1}. ${b.question}`}
      </Text>
      <Text textVariant="P4" colorScheme="primary" mb="8" whiteSpace="pre-line">
          {b.answerType === questionEnums.TEXT.value ? processString(textWithUrlConfig)(b.answer) : b.answer}
      </Text>
    </Box>
  ) 
}

const Answer = ({ b, n }) => {
  if (b.answerType === questionEnums.TEXT.value) {
    return <TextAnswer b={b} n={n} />
  }

  if (b.answerType === questionEnums.RADIO.value) {
    return <Choice b={b} n={n} />
  }

  if (b.answerType === questionEnums.DROPDOWN.value) {
    return <Choice b={b} n={n} />
  }

  if (b.answerType === questionEnums.UPLOAD_FILES.value) {
    return (
      <Box my="20px">
        <Text textVariant="P4" colorScheme="secondary" mb="8">
            {`${n+1}. ${b.question}`}
        </Text>
          <Box d="flex" flexWrap="wrap" mx="-10" mb="0">
              {b.files?.map(attachment => (
                  <Box
                      mb="20"
                      key={attachment.id}
                      mx="10"
                      w={{ xs: '100%', sm: '100%', md: '45%', lg: '237', xl: '270', xxl: '270' }}
                      flex={{
                          xs: '1 1 0%',
                          sm: '1 1 0%',
                          md: '0 1 45%',
                          lg: '0 1 237px',
                          xl: '0 1 270px',
                          xxl: '0 1 270px',
                      }}
                  >
                      <CardAttachment
                          {...attachment}
                          canDownload
                          downloadIcon={<IconDownloadAlt />}
                          py="14"
                          pl="16"
                          pr="16"
                          h="60"
                          downloadIconSize="20"
                          requestName=""
                          isBrief={true}
                      />
                  </Box>
              ))}
          </Box>
      </Box>
    )
  }

  return <TextAnswer b={b} n={n} />
}

export const Briefs = ({
  brief
}) => {
  return brief.map((b,i) => <Answer b={b} n={i} key={i} />)
}