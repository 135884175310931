import gql from 'graphql-tag';

export const CREATE_ASSIGNMENT = gql`
    mutation CREATE_ASSIGNMENT($designerId: ID!, $customerId: ID!, $typeId: ID!) {
        createAssignment(designerId: $designerId, customerId: $customerId, typeId: $typeId) {
            id
            type {
                id
                name
            }
            designer {
                id
                firstname
                lastname
            }
        }
    }
`;

export const DELETE_ASSIGNMENT = gql`
    mutation DELETE_ASSIGNMENT($id: ID!) {
        deleteAssignment(id: $id) {
            id
        }
    }
`;

export const UPDATE_ASSIGNMENTS = gql`
    mutation Assignments($typeId: ID!, $designerIds: [String!], $companyId: ID!) {
        updateAssignments(typeId: $typeId, designerIds: $designerIds, companyId: $companyId) {
            id
        }
    }
`;
