import React, { useState, memo, useCallback } from 'react';
import { Form } from '@components/Form';
import { Input } from '@components/Input';
import { Button } from '@components/Button';
import { Popup } from '@components/Popup';
import { Box } from '@components/Box';
import message from '@components/Message';

const AddDesignType = memo(({ visible, onCancel, onAdd, refetchDesignTypes, form }) => {
    const { getFieldDecorator, validateFields } = form;
    const [isLoading, setIsLoading] = useState(null);

    const handleSubmit = useCallback(
        async e => {
            e.preventDefault();
            validateFields(async (err, values) => {
                if (!err && !isLoading) {
                    setIsLoading(true);
                    message.loading('Adding design type...', 50000);

                    try {
                        await onAdd({ ...values });
                        message.destroy();
                        message.success('Design type successfully added');
                        await refetchDesignTypes();
                        setIsLoading(false);
                        onCancel();
                        return true;
                    } catch (e) {
                        message.destroy();
                        setIsLoading(false);
                        console.error(e);
                        return false;
                    }
                }
            });
        },
        [isLoading, validateFields, onAdd, onCancel, refetchDesignTypes]
    );

    return (
        <Popup
            variant="default"
            width={500}
            title="Add design type"
            visible={visible}
            onCancel={onCancel}
            footer={null}
            centered
            destroyOnClose
        >
            <Form onSubmit={handleSubmit}>
                <Form.Item label="Design type" colon={false} required={false}>
                    {getFieldDecorator('name', {
                        rules: [
                            {
                                required: true,
                                message: 'This field cannot be empty',
                            },
                        ],
                    })(<Input autoFocus placeholder="Enter design type" />)}
                </Form.Item>
                <Form.Item>
                    <Box d="flex" justifyContent="flex-end">
                        <Button loading={isLoading} type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Box>
                </Form.Item>
            </Form>
        </Popup>
    );
});

export default Form.create()(AddDesignType);
