import React, { memo, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { Box } from '@components/Box';
import { USER_TYPE_WORKER } from '@constants/account';
import RequestsTabFilter from './RequestsTabFilter';
import RequestsList from './RequestsList';

const RequestsTab = memo(({ viewer, designerId }) => {
    const [filters, setFilters] = useState({
        account: 'ALL',
        designer: designerId || (viewer?.role === USER_TYPE_WORKER ? viewer?.id : 'ALL'),
        keyword: '',
        product: 'ALL',
        status: viewer?.role === USER_TYPE_WORKER ? ['SUBMITTED', 'ONGOING_PROJECT'] : ['ALL'],
        team: 'ALL',
    });

    const onChangeFilters = useCallback(
        debounce(filterValues => setFilters(filterValues), 1000),
        []
    );

    return (
        <Box>
            <RequestsTabFilter viewer={viewer} onChangeFilters={onChangeFilters} designerId={designerId} />
            <RequestsList viewer={viewer} filters={filters} designerId={designerId} />
        </Box>
    );
});

export default RequestsTab;
