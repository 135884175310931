import React, { useRef, forwardRef, useMemo, useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import { ContainerEditor } from './style';
import { useForkRef } from 'hooks/useForkRef';
import { ToolbarProvider } from './ToolbarContext';
import { WysiwygContext } from './WysiwygContext';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { parseLinks, convertHtmlToState, hasLink } from './utils';
import { getDefaultKeyBinding } from 'draft-js';

/**
 * What You See Is What You Get (WYSIWYG) component
 *
 * @type {React.FC<import('react-draft-wysiwyg').EditorProps>}
 */
export const Wysiwyg = forwardRef(
    (
        {
            contentMinHeight,
            contentMaxHeight,
            toolbarHeight,
            toolbarColor,
            toolbarLeft = [],
            toolbarRight = [],
            isFlip,
            value,
            editorState,
            defaultEditorState,
            defaultValue,
            onChange,
            onEditorStateChange,
            onFocus,
            onBlur,
            handleKeyCommand,
            keyBindingFn,
            handlePastedText,
            isNotCustomer = false,
            placeholder,
            onFocusAfterChangeTab,
            handleChangeMessageType,
            mentions = [],
            isNote,
            addSnippetToContent,
            ...props
        },
        ref
    ) => {
        const [isFocused, setFocused] = useState(false);
        const [activeTab, setActiveTab] = useState('REPLY');
        const [newPlaceHolder, setNewPlaceHolder] = useState(placeholder);
        const [isVisiblePlaceholder, setIsVisiblePlaceholder] = useState(true);
        const containerRef = useRef(null);
        const draftRef = useRef(null);
        const editorRef = useForkRef(ref, draftRef);
        const outerContainerRef = useRef(null);
        const timeout = useRef(null);

        const usedValue = useMemo(() => {
            if (value) {
                return { editorState: value };
            }
            return { defaultEditorState: defaultValue };
        }, [value, defaultValue]);
        
        useEffect(() => {
            const div = outerContainerRef.current;

            const handleClickContainer = ev => {
                const title = ev.target.getAttribute('title');
                if (['Ordered', 'Unordered'].indexOf(title) > -1) {
                    const placeholderDiv = div.querySelector('.public-DraftEditorPlaceholder-root');
                    if (placeholderDiv) {
                        const isSelected = ev.target.getAttribute('aria-selected');
                        if (isSelected === 'false') {
                            setIsVisiblePlaceholder(false);
                        } else {
                            setIsVisiblePlaceholder(true);
                        }
                    }
                }
            };

            const handleClickOption = ev => {
                if (!document.activeElement.classList.contains('.public-DraftEditor-content')) {
                    draftRef.current.editor.focus();
                }
            };

            const unordered = div.querySelector('.rdw-option-wrapper[title="Unordered"]');
            const ordered = div.querySelector('.rdw-option-wrapper[title="Ordered"]');
            if (unordered) {
                unordered.addEventListener('click', handleClickContainer);
            }
            if (ordered) {
                ordered.addEventListener('click', handleClickContainer);
            }

            // Mobile browser fix
            const optionItem = div.querySelectorAll('.rdw-option-wrapper');
            for (let i = 0; i < optionItem.length; i++) {
                optionItem[i].addEventListener('click', handleClickOption);
            }
            return () => {
                if (unordered) {
                    unordered.removeEventListener('click', handleClickContainer);
                }
                if (ordered) {
                    ordered.removeEventListener('click', handleClickContainer);
                }

                for (let i = 0; i < optionItem.length; i++) {
                    optionItem[i].removeEventListener('click', handleClickOption);
                }
            };
        }, []);

        const handlePlaceholderHide = () => {
            if (outerContainerRef.current !== null) {
                const unordered = outerContainerRef.current.querySelector('.rdw-option-wrapper[title="Unordered"]');
                const isUnorderedSelected = unordered ? unordered.getAttribute('aria-selected') === 'true' : false;

                const ordered = outerContainerRef.current.querySelector('.rdw-option-wrapper[title="Ordered"]');
                const isOrderedSelected = ordered ? ordered.getAttribute('aria-selected') === 'true' : false;

                if (isUnorderedSelected || isOrderedSelected) {
                    setIsVisiblePlaceholder(false);
                } else {
                    setIsVisiblePlaceholder(true);
                }
            }
        };

        const handleEditorStateChange = newEditorState => {
            if (!newEditorState.getCurrentContent().hasText()) {
                if (timeout.current !== null) {
                    window.clearTimeout(timeout.current);
                }
                timeout.current = window.setTimeout(() => {
                    handlePlaceholderHide();
                }, 2);
            }

            if (onChange) {
                onChange(newEditorState);
            }
        };

        const handleFocusEditor = ev => {
            if (onFocus) {
                onFocus(ev);
            }
            setFocused(true);
        };

        const handleKeyBindingFn = ev => {
            if (keyBindingFn) {
                return keyBindingFn(ev);
            }
            return getDefaultKeyBinding(ev);
        };

        // This function not extend the current function called
        // inside react-draft-wysiwyg component
        const handleInternalKeyCommand = command => {
            // Call existing function for key command
            // from https://github.com/jpuri/react-draft-wysiwyg/blob/master/src/Editor/index.js#L354
            const currentEditor = draftRef.current;

            const {
                editorState,
                toolbar: { inline },
            } = currentEditor.state;

            if (inline && inline.options.indexOf(command) >= 0) {
                const newState = RichUtils.handleKeyCommand(editorState, command);
                if (newState) {
                    handleEditorStateChange(newState);
                    return true;
                }
            }

            const hasText = editorState.getCurrentContent().hasText();

            // handle backspace when its empty
            if (command === 'backspace' && !hasText) {
                const newState = RichUtils.toggleBlockType(editorState, 'unstyled');
                handleEditorStateChange(newState);
                return true;
            }

            if (handleKeyCommand) {
                return handleKeyCommand(command);
            }
            return false;
        };

        const handleInternalPastedText = (text, html, internalEditorState, internalOnChange) => {
            if (handlePastedText) {
                return handlePastedText(text, html, internalEditorState, internalOnChange);
            }
            if (text) {
                // Handle pasted link
                if (hasLink(text)) {
                    // check if pasted to a highlighted text
                    const selectionState = internalEditorState.getSelection()
                    const currentContent = internalEditorState.getCurrentContent();
                    const start = selectionState.getStartOffset();
                    const end = selectionState.getEndOffset();
                    const selectedText = currentContent.getPlainText().slice(start, end)
                    // current block map
                    const htmlLink = parseLinks(text, selectedText);
                    const blockMap = convertHtmlToState(htmlLink).getBlockMap();
                    const newContentState = Modifier.replaceWithFragment(
                        internalEditorState.getCurrentContent(),
                        internalEditorState.getSelection(),
                        blockMap
                    );
                    internalOnChange(EditorState.push(internalEditorState, newContentState, 'insert-fragment'));
                    return true;
                }
            }
          
            return false;
        };


        const handleAddSnippet = (snippet) => {
            if (addSnippetToContent) {
                addSnippetToContent(snippet);
                return
            }
        }

        const handleBlurEditor = ev => {
            if (onBlur) {
                onBlur(ev);
            }
            setFocused(false);
        };

        const handleChangeTab = val => {
            if (val !== activeTab) {
                setActiveTab(val);

                const getPlaceHolder =
                    val === 'REPLY'
                        ? 'Type your message here'
                        : 'Mention your teammate here (just @ and their name) and they will be notified.';
                setNewPlaceHolder(getPlaceHolder);
                if (onFocusAfterChangeTab) {
                    onFocusAfterChangeTab();
                }
                if (handleChangeMessageType) {
                    handleChangeMessageType(val);
                }
            }
        };

        const getContainerDiv = () => containerRef.current;

        return (
            <WysiwygContext.Provider value={{ getContainerDiv }}>
                <ContainerEditor
                    ref={outerContainerRef}
                    isFlip={isFlip}
                    isVisiblePlaceholder={isVisiblePlaceholder}
                    isFocused={isFocused}
                    contentMinHeight={contentMinHeight}
                    contentMaxHeight={contentMaxHeight}
                    toolbarHeight={toolbarHeight}
                    toolbarColor={toolbarColor}
                    isNotCustomer={isNotCustomer}
                    isNotesActive={activeTab === 'NOTES' || isNote}
                >
                    {isNotCustomer && (
                        <Box
                            d="flex"
                            alignItems="center"
                            px="16"
                            pt="10"
                            border="0"
                            borderT="1"
                            borderL="1"
                            borderR="1"
                            borderStyle="solid"
                            borderColor="outline-gray"
                            bg={activeTab === 'NOTES' ? 'badge-yellow' : 'white'}
                        >
                            <Text
                                textVariant="H6"
                                mr="27"
                                pb="11"
                                pos="relative"
                                overflow="hidden"
                                cursor="pointer"
                                onClick={() => handleChangeTab('REPLY')}
                                colorScheme={activeTab === 'REPLY' ? 'cta' : 'primary'}
                            >
                                Reply
                                <Box
                                    h="3"
                                    w="38.641"
                                    bg="cta"
                                    pos="absolute"
                                    bottom="0"
                                    trans="left 250ms ease-in-out"
                                    left={activeTab === 'REPLY' ? '0' : '-38.641'}
                                />
                            </Text>
                            <Text
                                textVariant="H6"
                                pb="11"
                                pos="relative"
                                overflow="hidden"
                                cursor="pointer"
                                onClick={() => handleChangeTab('NOTES')}
                                colorScheme={activeTab === 'NOTES' ? 'cta' : 'primary'}
                            >
                                Notes
                                <Box
                                    h="3"
                                    w="39.969"
                                    bg="cta"
                                    pos="absolute"
                                    bottom="0"
                                    trans="left 250ms ease-in-out"
                                    left={activeTab === 'NOTES' ? '0' : '-39.969'}
                                />
                            </Text>
                        </Box>
                    )}
                    <div ref={containerRef} />
                    <Editor
                        onFocus={handleFocusEditor}
                        onBlur={handleBlurEditor}
                        {...usedValue}
                        onEditorStateChange={handleEditorStateChange}
                        handleKeyCommand={handleInternalKeyCommand}
                        keyBindingFn={handleKeyBindingFn}
                        handlePastedText={handleInternalPastedText}
                        placeholder={newPlaceHolder}
                        {...props}
                        ref={editorRef}
                        mention={{
                            separator: ' ',
                            trigger: '@',
                            suggestions: mentions,
                        }}
                        stripPastedStyles={false}
                        toolbar={{
                            position: 'bottom',
                            options: ['inline', 'link', 'list', 'emoji'],
                            emoji: {
                                className: undefined,
                                component: undefined,
                                popupClassName: undefined,
                                emojis: [
                                    '😀',
                                    '😁',
                                    '😂',
                                    '😃',
                                    '😉',
                                    '😋',
                                    '😎',
                                    '😍',
                                    '😗',
                                    '🤗',
                                    '🤔',
                                    '😣',
                                    '😫',
                                    '😴',
                                    '😌',
                                    '🤓',
                                    '😛',
                                    '😜',
                                    '😠',
                                    '😇',
                                    '😷',
                                    '😈',
                                    '👻',
                                    '😺',
                                    '😸',
                                    '😹',
                                    '😻',
                                    '😼',
                                    '😽',
                                    '🙀',
                                    '🙈',
                                    '🙉',
                                    '🙊',
                                    '👼',
                                    '👮',
                                    '🕵',
                                    '💂',
                                    '👳',
                                    '🎅',
                                    '👸',
                                    '👰',
                                    '👲',
                                    '🙍',
                                    '🙇',
                                    '🚶',
                                    '🏃',
                                    '💃',
                                    '⛷',
                                    '🏂',
                                    '🏌',
                                    '🏄',
                                    '🚣',
                                    '🏊',
                                    '⛹',
                                    '🏋',
                                    '🚴',
                                    '👫',
                                    '💪',
                                    '👈',
                                    '👉',
                                    '👉',
                                    '👆',
                                    '🖕',
                                    '👇',
                                    '🖖',
                                    '🤘',
                                    '🖐',
                                    '👌',
                                    '👍',
                                    '👎',
                                    '✊',
                                    '👊',
                                    '👏',
                                    '🙌',
                                    '🙏',
                                    '🐵',
                                    '🐶',
                                    '🐇',
                                    '🐥',
                                    '🐸',
                                    '🐌',
                                    '🐛',
                                    '🐜',
                                    '🐝',
                                    '🍉',
                                    '🍄',
                                    '🍔',
                                    '🍤',
                                    '🍨',
                                    '🍪',
                                    '🎂',
                                    '🍰',
                                    '🍾',
                                    '🍷',
                                    '🍸',
                                    '🍺',
                                    '🌍',
                                    '🚑',
                                    '⏰',
                                    '🌙',
                                    '🌝',
                                    '🌞',
                                    '⭐',
                                    '🌟',
                                    '🌠',
                                    '🌨',
                                    '🌩',
                                    '⛄',
                                    '🔥',
                                    '🎄',
                                    '🎈',
                                    '🎉',
                                    '🎊',
                                    '🎁',
                                    '🎗',
                                    '🏀',
                                    '🏈',
                                    '🎲',
                                    '🔇',
                                    '🔈',
                                    '📣',
                                    '🔔',
                                    '🎵',
                                    '🎷',
                                    '💰',
                                    '🖊',
                                    '📅',
                                    '✅',
                                    '❎',
                                    '💯',
                                ],
                            },
                            inline: {
                                inDropdown: false,
                                options: ['bold', 'italic'],
                            },
                            list: {
                                inDropdown: false,
                                options: ['unordered', 'ordered'],
                            },
                            link: {
                                options: ['link'],
                                inDropdown: false,
                                defaultTargetOption: '_blank',
                            },
                        }}
                        toolbarCustomButtons={[
                            <ToolbarProvider 
                                left={toolbarLeft} 
                                right={toolbarRight} 
                                isCustomer={!isNotCustomer}
                                handleAddSnippet={addSnippetToContent ? handleAddSnippet : null}
                            />
                        ]}
                    />
                </ContainerEditor>
            </WysiwygContext.Provider>
        );
    }
);
