import React from 'react';

const IconAdd = props => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.2809 19.41C10.1085 19.41 9.94314 19.3415 9.82124 19.2196C9.69934 19.0977 9.63086 18.9324 9.63086 18.76V1.43C9.63086 1.25761 9.69934 1.09228 9.82124 0.970379C9.94314 0.848481 10.1085 0.779999 10.2809 0.779999C10.4532 0.779999 10.6186 0.848481 10.7405 0.970379C10.8624 1.09228 10.9309 1.25761 10.9309 1.43V18.76C10.9309 18.9324 10.8624 19.0977 10.7405 19.2196C10.6186 19.3415 10.4532 19.41 10.2809 19.41Z" />
      <path d="M19 10.74H1.61C1.44548 10.7275 1.2916 10.6539 1.17869 10.5335C1.06578 10.4132 1.00203 10.255 1 10.09C1.00258 9.91842 1.07189 9.75458 1.19323 9.63324C1.31458 9.51189 1.47841 9.44258 1.65 9.44H19C19.1724 9.44 19.3377 9.50848 19.4596 9.63038C19.5815 9.75228 19.65 9.91761 19.65 10.09C19.65 10.2624 19.5815 10.4277 19.4596 10.5496C19.3377 10.6715 19.1724 10.74 19 10.74Z" />
    </svg>
  );
};

export default IconAdd;
