import React from 'react';

const IconFileSvg = () => {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.32 19.95H2.68V2.83999L6.09 0.0499878H17.32V19.95Z" fill="#E6E6E4" />
            <path
                d="M7 15C6.92849 14.9093 6.83069 14.8429 6.72 14.81C6.61578 14.7648 6.50357 14.741 6.39 14.74H6.2L6 14.79C5.94438 14.8225 5.89658 14.8669 5.86 14.92C5.81819 14.9742 5.79693 15.0416 5.8 15.11C5.79817 15.1632 5.81009 15.216 5.83461 15.2633C5.85914 15.3106 5.89543 15.3508 5.94 15.38C6.03683 15.4511 6.14504 15.5052 6.26 15.54L6.69 15.68C6.84315 15.7183 6.98859 15.7825 7.12 15.87C7.25094 15.9527 7.36331 16.0617 7.45 16.19C7.54093 16.3587 7.58578 16.5484 7.58 16.74C7.58376 16.9399 7.54276 17.138 7.46 17.32C7.38412 17.4781 7.27085 17.6154 7.13 17.72C6.99254 17.833 6.83219 17.9149 6.66 17.96C6.47556 18.0172 6.28307 18.0442 6.09 18.04C5.85459 18.0426 5.6207 18.002 5.4 17.92C5.17469 17.8434 4.96958 17.7169 4.8 17.55L5.4 16.87C5.4898 16.9809 5.6023 17.0713 5.72994 17.1351C5.85758 17.1989 5.9974 17.2347 6.14 17.24C6.20966 17.2497 6.28034 17.2497 6.35 17.24C6.41718 17.2288 6.48165 17.2051 6.54 17.17C6.60767 17.1288 6.66295 17.07 6.7 17C6.71569 16.941 6.71569 16.879 6.7 16.82C6.70045 16.7666 6.68902 16.7138 6.66654 16.6653C6.64405 16.6169 6.61108 16.5741 6.57 16.54C6.46708 16.4621 6.35228 16.4013 6.23 16.36L5.8 16.22C5.6464 16.1698 5.49884 16.1027 5.36 16.02C5.22906 15.9373 5.11669 15.8283 5.03 15.7C4.93056 15.5417 4.88173 15.3568 4.89 15.17C4.88433 14.9752 4.92911 14.7823 5.02 14.61C5.09815 14.4561 5.20707 14.32 5.34 14.21C5.49848 14.1077 5.67494 14.0364 5.86 14C6.03872 13.9479 6.22384 13.9209 6.41 13.92C6.6094 13.9195 6.80792 13.9465 7 14C7.20477 14.0646 7.39262 14.1739 7.55 14.32L7 15Z"
                fill="#08122A"
            />
            <path d="M7.85 14H8.85L9.8 16.58L10.8 14H11.71L10.12 17.94H9.39L7.85 14Z" fill="#08122A" />
            <path
                d="M15.55 17.66C15.3029 17.7962 15.0365 17.894 14.76 17.95C14.4874 18.0101 14.2091 18.0402 13.93 18.04C13.6473 18.0416 13.3665 17.9942 13.1 17.9C12.8514 17.8096 12.6248 17.6675 12.4352 17.483C12.2456 17.2986 12.0972 17.076 12 16.83C11.8886 16.5572 11.8341 16.2646 11.84 15.97C11.8352 15.6755 11.8896 15.3831 12 15.11C12.0924 14.8566 12.2386 14.6262 12.4285 14.4346C12.6184 14.243 12.8474 14.0947 13.1 14C13.3665 13.9058 13.6473 13.8584 13.93 13.86C14.217 13.8589 14.5028 13.8958 14.78 13.97C15.0382 14.0474 15.2745 14.1844 15.47 14.37L14.87 15.03C14.7584 14.9159 14.626 14.8243 14.48 14.76C14.3062 14.6869 14.1184 14.6527 13.93 14.66C13.7612 14.6563 13.5938 14.6905 13.44 14.76C13.2967 14.8194 13.1673 14.9079 13.06 15.02C12.9535 15.1355 12.8718 15.2717 12.82 15.42C12.7594 15.5832 12.7289 15.756 12.73 15.93C12.7271 16.1042 12.7576 16.2773 12.82 16.44C12.8718 16.5883 12.9535 16.7245 13.06 16.84C13.1695 16.952 13.2982 17.0434 13.44 17.11C13.596 17.1713 13.7624 17.2019 13.93 17.2C14.0921 17.2037 14.2538 17.1835 14.41 17.14C14.5205 17.1027 14.6276 17.0559 14.73 17V16.37H14V15.6H15.51L15.55 17.66Z"
                fill="#08122A"
            />
            <path d="M2.68 2.83999H5.9V0.0499878L2.68 2.83999Z" fill="#AFAFAC" />
            <path d="M10.53 7.32999H9.52V8.50999H10.53V7.32999Z" fill="#08122A" />
            <path d="M6.49 7.87H13.57" stroke="#08122A" strokeWidth="0.25" strokeMiterlimit="10" />
            <path
                d="M6.56 10.86C6.56 10.86 7.56 7.93001 10.03 7.92001"
                stroke="#08122A"
                strokeWidth="0.25"
                strokeMiterlimit="10"
            />
            <path
                d="M13.66 10.86C13.66 10.86 12.66 7.93001 10.19 7.92001"
                stroke="#08122A"
                strokeWidth="0.25"
                strokeMiterlimit="10"
            />
            <path
                d="M6.39 8.27999C6.59987 8.27999 6.77 8.10986 6.77 7.89999C6.77 7.69012 6.59987 7.51999 6.39 7.51999C6.18013 7.51999 6.01 7.69012 6.01 7.89999C6.01 8.10986 6.18013 8.27999 6.39 8.27999Z"
                fill="#08122A"
            />
            <path
                d="M13.83 8.27999C14.0399 8.27999 14.21 8.10986 14.21 7.89999C14.21 7.69012 14.0399 7.51999 13.83 7.51999C13.6201 7.51999 13.45 7.69012 13.45 7.89999C13.45 8.10986 13.6201 8.27999 13.83 8.27999Z"
                fill="#08122A"
            />
            <path
                d="M6.43 11.57C6.63987 11.57 6.81 11.3999 6.81 11.19C6.81 10.9801 6.63987 10.81 6.43 10.81C6.22013 10.81 6.05 10.9801 6.05 11.19C6.05 11.3999 6.22013 11.57 6.43 11.57Z"
                stroke="#08122A"
                strokeWidth="0.25"
                strokeMiterlimit="10"
            />
            <path
                d="M13.77 11.57C13.9799 11.57 14.15 11.3999 14.15 11.19C14.15 10.9801 13.9799 10.81 13.77 10.81C13.5601 10.81 13.39 10.9801 13.39 11.19C13.39 11.3999 13.5601 11.57 13.77 11.57Z"
                stroke="#08122A"
                strokeWidth="0.25"
                strokeMiterlimit="10"
            />
        </svg>
    );
};

export default IconFileSvg;
