import React, { useRef, useState, useCallback } from 'react';
import { useMutation } from 'react-apollo';
import { Radio, RadioGroup } from '@components/Radio';
import { SET_COMPANY_INFORMATION } from '@graphql/mutations/user';
import { ME } from '@graphql/queries/userConnected';
import withLoggedUser from '@components/WithLoggedUser';
import { NUMBER_OF_EMPLOYEES, TIMEZONES, HEARD_FROM, INDUSTRIES } from '@constants/forms';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Button } from '@components/Button';
import { Input } from '@components/Input';
import { Select } from '@components/Select';
import { TooltipIconBlock } from '@components/LabelWithTooltipBlock';
import { Form } from '@components/Form';
import message from '@components/Message';

const numberOfEmployeesOptions = NUMBER_OF_EMPLOYEES.map(numberOfEmployees => (
    <Select.Option key={numberOfEmployees.value} value={numberOfEmployees.value}>
        {numberOfEmployees.text}
    </Select.Option>
));

const industriesOptions = INDUSTRIES.map(industry => (
    <Select.Option key={industry.value} value={industry.value}>
        {industry.name}
    </Select.Option>
));

const timezonesOptions = TIMEZONES.map(timezone => (
    <Select.Option key={timezone.value} value={timezone.value}>
        {timezone.name}
    </Select.Option>
));

const CompanyInformation = ({ viewer, goNextStep, form, windowWidth }) => {
    const companyNameRef = useRef();
    const companyIndustryRef = useRef();
    const companyWebsiteRef = useRef();
    const numberOfEmployeesRef = useRef();
    const timezoneRef = useRef();
    const heardFromOtherRef = useRef();
    const [heardFromState, setHeardFromState] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [setCompanyInformation] = useMutation(SET_COMPANY_INFORMATION, {
        refetchQueries: [{ query: ME }],
        awaitRefetchQueries: true,
    });

    const { getFieldDecorator, validateFields } = form;

    const handleSubmit = useCallback(
        async ev => {
            ev.preventDefault();

            validateFields(async (err, values) => {
                if (!err && !isLoading) {
                    if (!viewer || !viewer.id) {
                        //TODO Error
                    }

                    setIsLoading(true);
                    message.destroy();
                    message.loading('Setting company information...', 50000);

                    try {
                        const userInfo = {
                            companyName: companyNameRef.current.state.value,
                            nbEmployees: numberOfEmployeesRef.current,
                            companyIndustry: companyIndustryRef.current,
                            companyWebsite: companyWebsiteRef.current.state.value,
                            timezone: timezoneRef.current,
                            heardManyPixelsFrom:
                                heardFromState === 'Other' ? heardFromOtherRef.current.state.value : heardFromState,
                        };

                        await setCompanyInformation({ variables: { id: viewer.id, userInfo } });

                        message.destroy();
                        message.success('Company information saved!');
                        setIsLoading(false);
                        goNextStep();
                    } catch (e) {
                        setIsLoading(false);
                        console.error(e);
                        message.destroy();
                        message.error('Cannot save company information');
                        return false;
                    }
                }
            });
        },
        [isLoading, setCompanyInformation, heardFromState, viewer, goNextStep, validateFields]
    );

    return (
        <Box>
            <Text textVariant="H3" colorScheme="headline" mb="30">
                Company information
            </Text>
            <Form onSubmit={handleSubmit}>
                <Form.Item label={windowWidth > 768 ? '' : 'Company name'} colon={false}>
                    <Box hide="mobile" mb="9">
                        <TooltipIconBlock
                            tooltipIconSize="16px"
                            textVariant="H6"
                            colorScheme="primary"
                            label="Company name"
                            tooltip="If you don't have a company, you can enter your name."
                            required={true}
                            w="370"
                        />
                    </Box>
                    {getFieldDecorator('companyName', {
                        rules: [
                            {
                                required: true,
                                message: 'This field cannot be empty',
                            },
                        ],
                    })(<Input ref={companyNameRef} placeholder="Enter your company name" />)}
                </Form.Item>
                <Form.Item label="Industry" colon={false} required>
                    {getFieldDecorator('industry', {
                        rules: [
                            {
                                required: true,
                                message: 'This field cannot be empty',
                            },
                        ],
                    })(
                        <Select
                            placeholder="Choose your industry type"
                            onChange={e => {
                                companyIndustryRef.current = e;
                            }}
                        >
                            {industriesOptions}
                        </Select>
                    )}
                </Form.Item>
                <Box d={['block', 'flex']} justifyContent="space-between">
                    <Box w={['auto', '48%']}>
                        <Form.Item label="Company website" colon={false}>
                            {getFieldDecorator(
                                'companyWebsite',
                                {}
                            )(<Input ref={companyWebsiteRef} placeholder="www.example.com" />)}
                        </Form.Item>
                    </Box>
                    <Box w={['auto', '48%']}>
                        <Form.Item label="Number of employees" colon={false}>
                            {getFieldDecorator(
                                'numberOfEmployees',
                                {}
                            )(
                                <Select
                                    placeholder="Number of employees"
                                    onChange={e => {
                                        numberOfEmployeesRef.current = e;
                                    }}
                                >
                                    {numberOfEmployeesOptions}
                                </Select>
                            )}
                        </Form.Item>
                    </Box>
                </Box>
                <Form.Item label={windowWidth > 768 ? '' : 'Time zone'} colon={false}>
                    <Box hide="mobile" mb="9">
                        <TooltipIconBlock
                            tooltipIconSize="16px"
                            textVariant="H6"
                            colorScheme="primary"
                            label="Time zone"
                            tooltip="This will help us match you with a design team in a suitable time zone."
                            required={true}
                        />
                    </Box>
                    {getFieldDecorator('timezone', {
                        rules: [
                            {
                                required: true,
                                message: 'This field cannot be empty',
                            },
                        ],
                    })(
                        <Select
                            placeholder="Choose your time zone"
                            onChange={e => {
                                timezoneRef.current = e;
                            }}
                        >
                            {timezonesOptions}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item>
                    <Text textVariant="H5" colorScheme="primary" mb="20">
                        How did you hear about us?
                        <Text d="inline" colorScheme="other-red">
                            *
                        </Text>
                    </Text>
                    {getFieldDecorator('hearAboutUs', {
                        rules: [
                            {
                                required: true,
                                message: 'This field cannot be empty',
                            },
                        ],
                    })(
                        <RadioGroup onChange={e => setHeardFromState(e.target.value)}>
                            {HEARD_FROM.map(heardFrom => (
                                <Box key={heardFrom} d="inline-block" mb="16" w="50%">
                                    <Radio value={heardFrom}>{heardFrom}</Radio>
                                </Box>
                            ))}
                        </RadioGroup>
                    )}
                </Form.Item>
                {heardFromState === 'Other' && (
                    <Box mt="-30">
                        <Form.Item label="Tell us more" colon={false}>
                            <Input ref={heardFromOtherRef} placeholder="How did you hear about us ?" />
                        </Form.Item>
                    </Box>
                )}
                <Box mt="-10">
                    <Form.Item>
                        <Button w="100%" type="primary" htmlType="submit" loading={isLoading}>
                            Finish
                        </Button>
                    </Form.Item>
                </Box>
            </Form>
        </Box>
    );
};

export default withLoggedUser(Form.create()(CompanyInformation));
