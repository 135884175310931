import React from 'react';

const IconDuplicate = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.42978 17C7.25739 17 7.09206 16.9316 6.97017 16.8097C6.84827 16.6878 6.77979 16.5224 6.77979 16.35V8.56003C6.77979 8.38764 6.84827 8.22231 6.97017 8.10042C7.09206 7.97852 7.25739 7.91003 7.42978 7.91003C7.60218 7.91003 7.76751 7.97852 7.8894 8.10042C8.0113 8.22231 8.07978 8.38764 8.07978 8.56003V16.32C8.08384 16.4079 8.07003 16.4956 8.03919 16.578C8.00835 16.6604 7.96111 16.7356 7.90034 16.7992C7.83958 16.8627 7.76654 16.9133 7.68566 16.9478C7.60478 16.9824 7.51773 17.0001 7.42978 17Z"
        fill="currentColor"
      />
      <path
        d="M11.3099 13.09H3.5499C3.37751 13.09 3.21218 13.0216 3.09028 12.8997C2.96838 12.7778 2.8999 12.6124 2.8999 12.44C2.8999 12.2676 2.96838 12.1023 3.09028 11.9804C3.21218 11.8585 3.37751 11.79 3.5499 11.79H11.3099C11.4823 11.79 11.6476 11.8585 11.7695 11.9804C11.8914 12.1023 11.9599 12.2676 11.9599 12.44C11.9599 12.6124 11.8914 12.7778 11.7695 12.8997C11.6476 13.0216 11.4823 13.09 11.3099 13.09Z"
        fill="currentColor"
      />
      <path
        d="M13.1699 19.52H1.52988C1.3583 19.5175 1.19446 19.4481 1.07312 19.3268C0.951774 19.2055 0.882463 19.0416 0.879883 18.87V6.44004C0.882463 6.26845 0.951774 6.10462 1.07312 5.98327C1.19446 5.86193 1.3583 5.79262 1.52988 5.79004H13.1699C13.3423 5.79004 13.5076 5.85852 13.6295 5.98042C13.7514 6.10232 13.8199 6.26765 13.8199 6.44004V18.87C13.8199 19.0424 13.7514 19.2078 13.6295 19.3297C13.5076 19.4516 13.3423 19.52 13.1699 19.52ZM2.16988 18.22H12.5199V7.09004H2.17988L2.16988 18.22Z"
        fill="currentColor"
      />
      <path
        d="M18.4699 14.21H13.1699C12.9976 14.21 12.8322 14.1415 12.7103 14.0196C12.5884 13.8977 12.5199 13.7324 12.5199 13.56V7.08998H6.83994C6.66835 7.0874 6.50452 7.01809 6.38318 6.89675C6.26183 6.7754 6.19252 6.61157 6.18994 6.43998V1.12998C6.19252 0.958394 6.26183 0.794559 6.38318 0.673215C6.50452 0.551872 6.66835 0.482561 6.83994 0.47998H18.4699C18.6415 0.482561 18.8054 0.551872 18.9267 0.673215C19.048 0.794559 19.1174 0.958394 19.1199 1.12998V13.56C19.1174 13.7316 19.048 13.8954 18.9267 14.0167C18.8054 14.1381 18.6415 14.2074 18.4699 14.21ZM13.8199 12.91H17.8199V1.77998H7.48994V5.77998H13.1699C13.3423 5.77998 13.5077 5.84846 13.6296 5.97036C13.7515 6.09226 13.8199 6.25759 13.8199 6.42998V12.91Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconDuplicate;
