import React from 'react';

const IconOptionsSmall = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#iconOptionSmall)">
                <ellipse
                    cx="12.6"
                    cy="7.00001"
                    rx="1.4"
                    ry="1.4"
                    transform="rotate(90 12.6 7.00001)"
                    fill="currentColor"
                />
                <ellipse
                    cx="6.9999"
                    cy="7.00001"
                    rx="1.4"
                    ry="1.4"
                    transform="rotate(90 6.9999 7.00001)"
                    fill="currentColor"
                />
                <ellipse
                    cx="1.3998"
                    cy="7.00001"
                    rx="1.4"
                    ry="1.4"
                    transform="rotate(90 1.3998 7.00001)"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="iconOptionSmall">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconOptionsSmall;
