import React from 'react';
import { Tooltip } from 'antd';
import { Tabs } from '@components/Tabs';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Badge } from '@components/Badge';
import { withResponsive } from '@components/ResponsiveProvider';
import { useDetailContext } from './DetailContext';
import { TabMessage } from './TabMessage';
import { TabBrief } from './TabBrief';
import { TabFile } from './TabFile';
import { CardAsideDetail } from './CardAsideDetail';
import { CardAsideFeedback } from './CardAsideFeedback';
import { ORDER_STATUS_COMPLETED } from '@constants/order';
import withSocket from '@components/WithSocket';
import { RequestTabContainer } from '../../style';

const helpText = (
    <>
        If you would like to contact your project manager directly, please use the{' '}
        <Text as="span" fontWeight="600">
            chat button{' '}
        </Text>{' '}
        below.
    </>
);
const DetailContent = ({ windowHeight, windowWidth, ...socket }) => {
    const { request, activeTab, setActiveTab } = useDetailContext();

    return (
        <Box d="flex" flexDir="row" mx={["0", "-15px"]} flexWrap="wrap">
            <Box x="14px" w="100%" maxW="930">
                <RequestTabContainer borderW="1" borderStyle="solid" borderColor="outline-gray" overflow="hidden">
                    <Tabs
                        activeKey={activeTab}
                        renderTabBar={({ panels, ...props }, DefaultTabBar) => {
                            const filteredPanels =
                                windowWidth >= 768 ? panels.filter(panel => panel.key !== 'details') : panels;
                            return (
                                <Box
                                    px={['14', '20']}
                                    h="49"
                                    d="flex"
                                    alignItems="flex-end"
                                    borderB="1"
                                    borderBottomStyle="solid"
                                    borderBottomColor="outline-gray"
                                >
                                    <DefaultTabBar
                                        {...props}
                                        panels={filteredPanels}
                                        style={{ borderBottomWidth: 0 }}
                                    />
                                </Box>
                            );
                        }}
                        onChange={setActiveTab}
                    >
                        <Tabs.TabPane
                            tab={
                                <Text d="inline-flex" alignItems="center">
                                    Messages{' '}
                                    {request.unreadMessages > 0 && (
                                        <Badge isNotification ml="6">
                                            &nbsp;
                                        </Badge>
                                    )}
                                </Text>
                            }
                            key="messages"
                        >
                            <TabMessage {...socket} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Files" key="files">
                            <TabFile />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Brief" key="brief">
                            <TabBrief />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Details" key="details">
                            <CardAsideDetail hideHeader />
                        </Tabs.TabPane>
                    </Tabs>
                </RequestTabContainer>
            </Box>
            <Box hide="mobile" px="15px" w="100%" maxW={['100%', '300px']}>
                <CardAsideDetail />
                {request.rate && request.status === ORDER_STATUS_COMPLETED ? (
                    <CardAsideFeedback rate={request.rate} />
                ) : (
                    <Box bg="bg-gray" py={windowHeight >= 850 ? '20' : '11'} px="20">
                        {windowHeight >= 850 ? (
                            <>
                                <Text textVariant="H6" colorScheme="primary" mb="10" lineH="20">
                                    Need Help?
                                </Text>
                                <Text textVariant="Badge" colorScheme="primary" fontWeight="300">
                                    {helpText}
                                </Text>
                            </>
                        ) : (
                            <Tooltip title={helpText}>
                                <Text d="inline" textVariant="H6" colorScheme="cta" cursor="pointer">
                                    Need Help?
                                </Text>
                            </Tooltip>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default withSocket(withResponsive(DetailContent));
