import React from 'react';

const IconDeliverableOther = () => {
    return (
        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57.1557 65.835H8.84375V9.37204L19.4698 0.165039H57.1557V65.835Z" fill="#E6E6E4" />
            <path d="M8.84375 9.37204H19.4698V0.165039L8.84375 9.37204Z" fill="#AFAFAC" />
            <path
                d="M23.3642 37.686C24.7676 37.686 25.9052 36.5484 25.9052 35.145C25.9052 33.7416 24.7676 32.604 23.3642 32.604C21.9609 32.604 20.8232 33.7416 20.8232 35.145C20.8232 36.5484 21.9609 37.686 23.3642 37.686Z"
                fill="#AFAFAC"
            />
            <path
                d="M33 37.686C34.4033 37.686 35.541 36.5484 35.541 35.145C35.541 33.7416 34.4033 32.604 33 32.604C31.5966 32.604 30.459 33.7416 30.459 35.145C30.459 36.5484 31.5966 37.686 33 37.686Z"
                fill="#AFAFAC"
            />
            <path
                d="M42.6357 37.686C44.0391 37.686 45.1767 36.5484 45.1767 35.145C45.1767 33.7416 44.0391 32.604 42.6357 32.604C41.2324 32.604 40.0947 33.7416 40.0947 35.145C40.0947 36.5484 41.2324 37.686 42.6357 37.686Z"
                fill="#AFAFAC"
            />
        </svg>
    );
};

export default IconDeliverableOther;
