import gql from 'graphql-tag';

export const ADD_INVITATION = gql`
    mutation CreateInvitation(
        $email: String!
        $companyId: String
        $companyRole: String
        $role: String
        $teamId: ID
        $specialityIds: [ID!]
    ) {
        createInvitation(
            email: $email
            companyId: $companyId
            companyRole: $companyRole
            role: $role
            teamId: $teamId
            specialityIds: $specialityIds
        ) {
            id
            email
            createdAt
        }
    }
`;

export const DELETE_INVITATION = gql`
    mutation DeleteInvitation($id: ID!) {
        deleteInvitation(id: $id) {
            id
        }
    }
`;

export const INVITATION_SIGNUP = gql`
    mutation InvitationSignup($id: ID!, $password: String!, $firstname: String!, $lastname: String!) {
        invitationSignup(id: $id, password: $password, firstname: $firstname, lastname: $lastname) {
            id
            token
        }
    }
`;
