import gql from 'graphql-tag';

export const PLAN = gql`
    query Plan($id: ID!) {
        Plan(id: $id) {
            id
            name
            price
            interval
            activated
            dailyOutput
            visible
            stripeId
            services {
                id
                name
                isActivated
            }
            featuresTitle
            features
            tooltips
            type
            createdAt
        }
    }
`;

export const PLAN_BY_STRIPEID = gql`
    query Plan($stripeId: String!) {
        PlanByStripeId(stripeId: $stripeId) {
            id
            name
            price
            type
            nbSameTimeOrder
            maxQuantity
            interval
            features
            icon {
                id
                url
            }
        }
    }
`;

export const PLAN_FULL = gql`
    query Plan($id: ID!) {
        Plan(id: $id) {
            id
            name
            activated
            visible
            createdAt
            price
            description
            nbSameTimeOrder
            stripeId
            maxQuantity
            features
            interval
            type
            monthlyPriceReference
            icon {
                id
            }
            categories {
                id
                title
            }
            services {
                id
                name
            }
        }
    }
`;

export const ALL_PLANS = gql`
    query allPlans($activated: Boolean) {
        allPlans(where: { activated: $activated }, orderBy: createdAt_DESC) {
            id
            name
            price
            description
            visible
            activated
            interval
            createdAt
            _customerSubscriptionsMeta(where: { status: "active" }) {
                count
            }
        }
    }
`;

export const ALL_PLANS_VISITOR = gql`
    query {
        allPlans {
            id
            name
            price
            description
            visible
            activated
            interval
        }
    }
`;

export const ALL_PLANS_TO_SUB = gql`
    query AllPlans($interval: PLAN_INTERVAL) {
        allPlans(where: { visible: true, interval: $interval }, orderBy: price_ASC) {
            id
            name
            price
            type
            nbSameTimeOrder
            maxQuantity
            interval
            features
            monthlyPriceReference
            icon {
                id
                url
            }
            tooltips
            featuresTitle
            dailyOutput
        }
    }
`;

export const GET_STRIPE_PLAN = gql`
    query GetUserPlan($userId: ID) {
        getStripeSubscription(userId: $userId) {
            price
            name
            nextBillingDate
            status
            isCanceledAtEnd
            interval
            cancelAt
            endedAt
            quantity
            frequency
        }
    }
`;

export const All_PRODUCTS = gql`
    query Plan($id: ID!) {
        Plan(id: $id) {
            id
            name
            services {
                id
                name
            }
        }
    }
`;

export const ALL_PLANS_TO_TYPE = gql`
    query AllPlans($type: PLAN_TYPE) {
        allPlans(where: { type: $type }) {
            id
            name
            price
            type
            nbSameTimeOrder
            maxQuantity
            interval
            features
            icon {
                id
                url
            }
        }
    }
`;

export const PAUSE_PLAN = gql`
    query PausePlan {
        PausePlan {
            id
            name
            price
            interval
            stripeId
        }
    }
`;
