import React from 'react';
import { Icon } from 'antd';

const ArrowLeftSvg = () => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.27003 10.7399C6.44696 10.7379 6.61592 10.666 6.74003 10.5399L14.19 2.90994C14.2505 2.84956 14.2985 2.77786 14.3312 2.69893C14.3639 2.62 14.3808 2.53539 14.3808 2.44994C14.3808 2.36449 14.3639 2.27988 14.3312 2.20094C14.2985 2.12201 14.2505 2.05031 14.19 1.98994C14.0668 1.8702 13.9018 1.80322 13.73 1.80322C13.5582 1.80322 13.3932 1.8702 13.27 1.98994L5.81003 9.63994C5.74987 9.69945 5.7021 9.77031 5.6695 9.84841C5.6369 9.92651 5.62012 10.0103 5.62012 10.0949C5.62012 10.1796 5.6369 10.2634 5.6695 10.3415C5.7021 10.4196 5.74987 10.4904 5.81003 10.5499C5.86979 10.6112 5.94139 10.6596 6.02048 10.6923C6.09956 10.725 6.18448 10.7412 6.27003 10.7399Z"
            fill="currentColor"
        />
        <path
            d="M13.7297 18.19C13.8151 18.1901 13.8997 18.1734 13.9786 18.1408C14.0575 18.1082 14.1292 18.0603 14.1897 18C14.3094 17.8768 14.3764 17.7118 14.3764 17.54C14.3764 17.3682 14.3094 17.2032 14.1897 17.08L6.72968 9.62999C6.66927 9.56958 6.59756 9.52167 6.51863 9.48897C6.4397 9.45628 6.35511 9.43945 6.26968 9.43945C6.18425 9.43945 6.09966 9.45628 6.02073 9.48897C5.9418 9.52167 5.87009 9.56958 5.80968 9.62999C5.74927 9.6904 5.70135 9.76211 5.66866 9.84104C5.63597 9.91997 5.61914 10.0046 5.61914 10.09C5.61914 10.1754 5.63597 10.26 5.66866 10.3389C5.70135 10.4179 5.74927 10.4896 5.80968 10.55L13.2697 18C13.3291 18.0617 13.4006 18.1105 13.4798 18.1432C13.5589 18.1759 13.644 18.1918 13.7297 18.19Z"
            fill="currentColor"
        />
    </svg>
);

const ArrowLeftIcon = props => <Icon component={ArrowLeftSvg} {...props} />;

export default ArrowLeftIcon;
