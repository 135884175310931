import React, { useState } from 'react';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Popup } from '@components/Popup';
import { TooltipIconBlock } from '@components/LabelWithTooltipBlock';
import { Button } from '@components/Button';

const ModalPlan = ({
    visible,
    onConfirm,
    onCancel,
    plans,
    frequency,
    selectedPlanName,
    windowWidth,
    title = 'Change plan',
    isUpdating = false,
    okText = 'Update',
}) => {
    const [newPlan, setNewPlan] = useState(selectedPlanName);

    const handleChangePlan = newPlan => {
        setNewPlan(newPlan);
    };

    const handleConfirm = () => {
        onConfirm(newPlan);
    };

    let modalWidth;
    if (windowWidth > 1237) {
        modalWidth = 1238;
    } else if (windowWidth > 768 && windowWidth <= 1237) {
        modalWidth = '100%';
    } else if (windowWidth <= 768) {
        modalWidth = 353;
    }

    return (
        <Popup
            variant="default"
            title={title}
            visible={visible}
            okText={okText}
            onCancel={onCancel}
            footer={null}
            width={modalWidth}
            centered
        >
            <Box d="flex" mx="-10" hide="mobile">
                {plans
                    ?.filter(plan => plan.interval === frequency)
                    .map((plan, indexPlan) => {
                        let monthlyRate;
                        switch (plan.interval) {
                            case 'YEARLY':
                                monthlyRate = plan.price / 12;
                                break;
                            case 'BIANNUALLY':
                                monthlyRate = plan.price / 6;
                                break;
                            case 'QUARTERLY':
                                monthlyRate = plan.price / 3;
                                break;
                            case 'MONTHLY':
                                monthlyRate = plan.price;
                                break;
                            default:
                                break;
                        }

                        const formattedMonthlyRate = monthlyRate % 1 !== 0 ? monthlyRate.toFixed(2) : monthlyRate;

                        return (
                            <Box
                                key={plan.id}
                                w="386"
                                mx="10"
                                borderW="1"
                                borderStyle="solid"
                                borderColor="outline-gray"
                                py="24"
                                px="16"
                                bg={newPlan === plan.name ? 'cta' : 'white'}
                            >
                                <Text
                                    textVariant="H5"
                                    colorScheme={newPlan === plan.name ? 'white' : 'primary'}
                                    mb="20"
                                >
                                    {plan.name}
                                </Text>
                                <Text
                                    textVariant="H5"
                                    colorScheme={newPlan === plan.name ? 'white' : 'cta'}
                                    mb="20"
                                >{`$${formattedMonthlyRate}/mo`}</Text>
                                <hr />
                                <Box h="243" py="20">
                                    {plan?.featuresTitle && (
                                        <Text
                                            textVariant="Button"
                                            colorScheme={newPlan === plan.name ? 'white' : 'primary'}
                                            mt="-4"
                                            mb="11"
                                        >
                                            {plan?.featuresTitle}
                                        </Text>
                                    )}
                                    {plan.features.map((feature, indexFeature) => (
                                        <Box
                                            key={feature}
                                            d="flex"
                                            alignItems="center"
                                            mb={plan?.features.length - 1 === indexFeature ? '0' : '16'}
                                        >
                                            <Box w="8" h="8" bg="other-yellow" mr="16" />
                                            {(plan.tooltips && plan?.tooltips[indexFeature]) ? (
                                                <TooltipIconBlock
                                                    label={feature}
                                                    tooltip={plan?.tooltips[indexFeature]}
                                                    tooltipIconSize="16px"
                                                    textVariant="P4"
                                                    colorScheme={newPlan === plan.name ? 'white' : 'primary'}
                                                    tooltipIconColor={newPlan === plan.name ? 'white' : 'primary'}
                                                />
                                            ) : (
                                                <Text
                                                    textVariant="P4"
                                                    colorScheme={newPlan === plan.name ? 'white' : 'primary'}
                                                >
                                                    {feature}
                                                </Text>
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                                <hr />
                                <Box mt="24">
                                    <Button
                                        type="primary"
                                        onClick={() => handleChangePlan(plan.name)}
                                        w="100%"
                                        noColorTransitions
                                    >
                                        {newPlan === plan.name ? 'SELECTED' : 'SELECT PLAN'}
                                    </Button>
                                </Box>
                            </Box>
                        );
                    })}
            </Box>
            <Box hide="desktop">
                {plans
                    ?.filter(plan => plan.interval === frequency)
                    .map((plan, indexPlan) => {
                        let monthlyRate;
                        switch (plan.interval) {
                            case 'YEARLY':
                                monthlyRate = plan.price / 12;
                                break;
                            case 'BIANNUALLY':
                                monthlyRate = plan.price / 6;
                                break;
                            case 'QUARTERLY':
                                monthlyRate = plan.price / 3;
                                break;
                            case 'MONTHLY':
                                monthlyRate = plan.price;
                                break;
                            default:
                                break;
                        }

                        const formattedMonthlyRate = monthlyRate % 1 !== 0 ? monthlyRate.toFixed(2) : monthlyRate;

                        return (
                            <Box
                                key={plan.id}
                                w="100%"
                                borderW="1"
                                borderStyle="solid"
                                borderColor="outline-gray"
                                py="24"
                                px="16"
                                mb={plan.name === 'Business' ? '0' : '20'}
                                bg={newPlan === plan.name ? 'cta' : 'white'}
                            >
                                <Text
                                    textVariant="H5"
                                    colorScheme={newPlan === plan.name ? 'white' : 'primary'}
                                    mb="20"
                                >
                                    {plan.name}
                                </Text>
                                <Text
                                    textVariant="H5"
                                    colorScheme={newPlan === plan.name ? 'white' : 'cta'}
                                    mb="20"
                                >{`$${formattedMonthlyRate}/mo`}</Text>
                                <hr />
                                <Box h="243" py="20">
                                    {plan?.featuresTitle && (
                                        <Text
                                            textVariant="Button"
                                            colorScheme={newPlan === plan.name ? 'white' : 'primary'}
                                            mt="-4"
                                            mb="11"
                                        >
                                            {plan?.featuresTitle}
                                        </Text>
                                    )}
                                    {plan.features.map((feature, indexFeature) => (
                                        <Box
                                            key={feature}
                                            d="flex"
                                            alignItems="center"
                                            mb={plan?.features.length - 1 === indexFeature ? '0' : '16'}
                                        >
                                            <Box w="8" h="8" bg="other-yellow" mr="16" />
                                            {(plan.tooltips && plan?.tooltips[feature]) ? (
                                                <>
                                                    <TooltipIconBlock
                                                        label={feature}
                                                        tooltip={plan?.tooltips[feature]}
                                                        tooltipIconSize="16px"
                                                        textVariant="P4"
                                                        colorScheme={newPlan === plan.name ? 'white' : 'primary'}
                                                        tooltipIconColor={
                                                            newPlan === plan.name ? 'white' : 'primary'
                                                        }
                                                    />
                                                    <Text
                                                        textVariant="P4"
                                                        colorScheme={newPlan === plan.name ? 'white' : 'primary'}
                                                        hide="desktop"
                                                    >
                                                        {feature}
                                                    </Text>
                                                </>
                                            ) : (
                                                <Text
                                                    textVariant="P4"
                                                    colorScheme={newPlan === plan.name ? 'white' : 'primary'}
                                                >
                                                    {feature}
                                                </Text>
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                                <hr />
                                <Box mt="24">
                                    <Button
                                        type="primary"
                                        onClick={() => handleChangePlan(plan.name)}
                                        w="100%"
                                        noColorTransitions
                                    >
                                        {newPlan === plan.name ? 'SELECTED' : 'SELECT PLAN'}
                                    </Button>
                                </Box>
                            </Box>
                        );
                    })}
            </Box>
            <Box d="flex" justifyContent="flex-end" alignItems="center" mt="30">
                <Button type="primary" w="140" loading={isUpdating} onClick={handleConfirm}>
                    {okText}
                </Button>
            </Box>
        </Popup>
    );
};

export default ModalPlan;
