import React from 'react';

const IconShieldPaused = () => {
  return (
    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 10H2.66667V0H0V10ZM5.33333 0V10H8V0H5.33333Z" fill="#C7CBD4" />
    </svg>
  );
};

export default IconShieldPaused;
