import React from 'react'

const Icon = () => {
  return (
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M57.156 65.835H8.844V9.37199L19.47 0.164993H57.156V65.835Z" fill="#54DBB3"/>
      <path d="M8.844 9.37199H19.47V0.164993L8.844 9.37199Z" fill="#3CAA85"/>
      <path d="M27 49.7156H27.5661V56.4699H28.6149V58.1944H27V49.7156Z" fill="white"/>
      <path d="M28.7895 53.955C28.7895 53.2764 28.8234 52.6616 28.8911 52.1107C28.9613 51.5598 29.0581 51.0928 29.1814 50.7096C29.3048 50.3183 29.4512 50.019 29.6206 49.8114C29.7899 49.6038 29.975 49.5 30.1758 49.5C30.3766 49.5 30.5617 49.6038 30.731 49.8114C30.9004 50.019 31.0468 50.3183 31.1702 50.7096C31.2935 51.0928 31.3891 51.5598 31.4568 52.1107C31.527 52.6616 31.5621 53.2764 31.5621 53.955C31.5621 54.6336 31.527 55.2484 31.4568 55.7993C31.3891 56.3502 31.2935 56.8212 31.1702 57.2124C31.0468 57.5956 30.9004 57.891 30.731 58.0986C30.5617 58.3062 30.3766 58.41 30.1758 58.41C29.975 58.41 29.7899 58.3062 29.6206 58.0986C29.4512 57.891 29.3048 57.5956 29.1814 57.2124C29.0581 56.8212 28.9613 56.3502 28.8911 55.7993C28.8234 55.2484 28.7895 54.6336 28.7895 53.955ZM29.3774 53.955C29.3774 54.3542 29.3956 54.7215 29.4318 55.0568C29.4706 55.3841 29.525 55.6715 29.5952 55.919C29.6653 56.1585 29.7488 56.3462 29.8456 56.4819C29.9448 56.6176 30.0548 56.6855 30.1758 56.6855C30.2968 56.6855 30.4056 56.6176 30.5024 56.4819C30.6016 56.3462 30.6863 56.1585 30.7564 55.919C30.8266 55.6715 30.8798 55.3841 30.9161 55.0568C30.9548 54.7215 30.9742 54.3542 30.9742 53.955C30.9742 53.5638 30.9548 53.2005 30.9161 52.8652C30.8798 52.5299 30.8266 52.2425 30.7564 52.0029C30.6863 51.7554 30.6016 51.5638 30.5024 51.4281C30.4056 51.2924 30.2968 51.2245 30.1758 51.2245C30.0548 51.2245 29.9448 51.2924 29.8456 51.4281C29.7488 51.5638 29.6653 51.7554 29.5952 52.0029C29.525 52.2425 29.4706 52.5299 29.4318 52.8652C29.3956 53.2005 29.3774 53.5638 29.3774 53.955Z" fill="white"/>
      <path d="M32.4441 51.3682H31.711V49.7156H33.7433V51.3682H33.0102V58.1944H32.4441V51.3682Z" fill="white"/>
      <path d="M34.5279 51.3682H33.7949V49.7156H35.8271V51.3682H35.094V58.1944H34.5279V51.3682Z" fill="white"/>
      <path d="M36.1073 49.7156H36.6735V58.1944H36.1073V49.7156Z" fill="white"/>
      <path d="M37.2102 49.7156H38.9558V51.4401H37.7763V53.0209H38.8904V54.7454H37.7763V56.4699H39.0211V58.1944H37.2102V49.7156Z" fill="white"/>
      <path d="M23.562 38.082C23.034 37.983 22.671 38.148 22.308 37.917C21.681 37.521 20.889 37.092 21.021 36.267C21.186 35.244 21.912 34.617 23.001 34.452C24.453 34.221 25.872 33.891 27.06 32.901C27.984 32.109 28.743 31.218 29.337 30.195C30.888 27.588 32.34 24.915 33.792 22.275C35.706 18.81 38.61 16.896 42.537 16.533C43.626 16.434 44.748 17.094 44.913 17.919C45.144 18.975 44.484 20.064 43.23 20.262C41.184 20.592 39.303 21.186 38.049 22.902C36.63 24.816 35.343 26.829 34.386 29.04C33.033 32.076 31.317 34.881 28.347 36.663C26.829 37.587 25.113 37.851 23.562 38.115V38.082Z" fill="#F9F9FA"/>
    </svg>
  )
}

export default Icon