import React from 'react' 

const Icon = () => {
  return (
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M57.156 65.835H8.844V9.37201L20.097 0.165009H57.156V65.835Z" fill="#E6E6E4"/>
      <path d="M22.9759 49.4391C22.7439 49.1449 22.4267 48.9296 22.0677 48.8228C21.7296 48.6763 21.3657 48.5991 20.9973 48.5957H20.381L19.7323 48.7579C19.5519 48.8635 19.3969 49.0075 19.2782 49.1796C19.1426 49.3556 19.0736 49.5739 19.0836 49.7959C19.0776 49.9685 19.1163 50.1399 19.1959 50.2933C19.2754 50.4467 19.3931 50.577 19.5377 50.6716C19.8518 50.9022 20.2027 51.0777 20.5756 51.1906L21.9704 51.6447C22.4671 51.7688 22.9389 51.9773 23.3651 52.261C23.7898 52.5292 24.1543 52.8827 24.4355 53.2989C24.7305 53.8462 24.8759 54.4615 24.8572 55.0829C24.8694 55.7312 24.7364 56.374 24.4679 56.9642C24.2218 57.4772 23.8544 57.9225 23.3976 58.2616C22.9517 58.6283 22.4316 58.8939 21.8731 59.0401C21.2748 59.2257 20.6505 59.3134 20.0242 59.2996C19.2606 59.3081 18.502 59.1762 17.7862 58.9103C17.0553 58.662 16.3901 58.2517 15.84 57.7102L17.7862 55.5046C18.0774 55.8643 18.4423 56.1575 18.8563 56.3645C19.2703 56.5715 19.7239 56.6875 20.1864 56.7047C20.4124 56.7362 20.6416 56.7362 20.8676 56.7047C21.0855 56.6685 21.2946 56.5915 21.4838 56.4776C21.7033 56.3439 21.8826 56.1534 22.0028 55.9262C22.0537 55.7349 22.0537 55.5337 22.0028 55.3424C22.0043 55.1692 21.9672 54.9979 21.8943 54.8408C21.8213 54.6837 21.7144 54.5448 21.5811 54.4342C21.2473 54.1815 20.8749 53.9844 20.4783 53.8503L19.0836 53.3962C18.5854 53.2333 18.1067 53.0158 17.6564 52.7475C17.2317 52.4793 16.8672 52.1258 16.586 51.7096C16.2635 51.196 16.1051 50.5963 16.1319 49.9905C16.1135 49.3588 16.2588 48.7331 16.5536 48.1741C16.8071 47.6751 17.1604 47.2335 17.5915 46.8766C18.1056 46.5449 18.6779 46.3137 19.2782 46.1955C19.8579 46.0264 20.4583 45.939 21.0622 45.936C21.7089 45.9346 22.3529 46.0219 22.9759 46.1955C23.6401 46.4051 24.2494 46.7596 24.7599 47.2334L22.9759 49.4391Z" fill="#08122A"/>
      <path d="M25.905 46.2H29.2971L32.5197 54.9517L35.9118 46.2H38.9986L33.6051 59.565H31.1289L25.905 46.2Z" fill="#08122A"/>
      <path d="M50.934 57.8878C50.1439 58.3232 49.2922 58.6359 48.4081 58.815C47.5366 59.0071 46.6468 59.1036 45.7543 59.1028C44.8505 59.1078 43.9527 58.9563 43.1006 58.6551C42.3057 58.3661 41.5812 57.9117 40.975 57.322C40.3687 56.7322 39.8944 56.0206 39.5835 55.234C39.2272 54.3619 39.0532 53.4262 39.072 52.4843C39.0567 51.5428 39.2307 50.6077 39.5835 49.7346C39.8791 48.9244 40.3465 48.1877 40.9535 47.5751C41.5606 46.9625 42.2931 46.4885 43.1006 46.1856C43.9527 45.8844 44.8505 45.733 45.7543 45.738C46.6718 45.7344 47.5857 45.8526 48.472 46.0897C49.2975 46.3373 50.0532 46.7754 50.6782 47.3686L48.7598 49.4789C48.4031 49.1142 47.9798 48.8211 47.5129 48.6156C46.9571 48.3818 46.3568 48.2726 45.7543 48.2959C45.2148 48.2841 44.6794 48.3933 44.1876 48.6156C43.7295 48.8056 43.3157 49.0886 42.9727 49.4469C42.6322 49.8163 42.371 50.2516 42.2053 50.7258C42.0116 51.2475 41.9141 51.8 41.9176 52.3564C41.9081 52.9134 42.0058 53.467 42.2053 53.9871C42.371 54.4613 42.6322 54.8966 42.9727 55.266C43.3228 55.6241 43.7343 55.9165 44.1876 56.1293C44.6864 56.3253 45.2184 56.423 45.7543 56.417C46.2726 56.4288 46.7897 56.3642 47.289 56.2252C47.6425 56.1061 47.9848 55.9563 48.3122 55.7776V53.7633H45.9781V51.3013H50.8061L50.934 57.8878Z" fill="#08122A"/>
      <path d="M8.844 9.37201H19.47V0.165009L8.844 9.37201Z" fill="#AFAFAC"/>
      <path d="M34.749 24.189H31.416V28.083H34.749V24.189Z" fill="#08122A"/>
      <path d="M21.417 25.971H44.781" stroke="#08122A" stroke-width="0.25" stroke-miterlimit="10"/>
      <path d="M21.648 35.838C21.648 35.838 24.948 26.169 33.099 26.136" stroke="#08122A" stroke-width="0.25" stroke-miterlimit="10"/>
      <path d="M45.078 35.838C45.078 35.838 41.778 26.169 33.627 26.136" stroke="#08122A" stroke-width="0.25" stroke-miterlimit="10"/>
      <path d="M21.087 27.324C21.7796 27.324 22.341 26.7626 22.341 26.07C22.341 25.3774 21.7796 24.816 21.087 24.816C20.3944 24.816 19.833 25.3774 19.833 26.07C19.833 26.7626 20.3944 27.324 21.087 27.324Z" fill="#08122A"/>
      <path d="M45.639 27.324C46.3316 27.324 46.893 26.7626 46.893 26.07C46.893 25.3774 46.3316 24.816 45.639 24.816C44.9464 24.816 44.385 25.3774 44.385 26.07C44.385 26.7626 44.9464 27.324 45.639 27.324Z" fill="#08122A"/>
      <path d="M21.219 38.181C21.9116 38.181 22.473 37.6196 22.473 36.927C22.473 36.2344 21.9116 35.673 21.219 35.673C20.5264 35.673 19.965 36.2344 19.965 36.927C19.965 37.6196 20.5264 38.181 21.219 38.181Z" stroke="#08122A" stroke-width="0.25" stroke-miterlimit="10"/>
      <path d="M45.441 38.181C46.1336 38.181 46.695 37.6196 46.695 36.927C46.695 36.2344 46.1336 35.673 45.441 35.673C44.7484 35.673 44.187 36.2344 44.187 36.927C44.187 37.6196 44.7484 38.181 45.441 38.181Z" stroke="#08122A" stroke-width="0.25" stroke-miterlimit="10"/>
    </svg>
  )
}

export default Icon