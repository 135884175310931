import React, { useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import { SectionActiveRequest } from './SectionActiveRequest';
import { SectionCompletedRequest } from './SectionCompletedRequest';
import { SectionDeliveredRequest } from './SectionDeliveredRequest';
import { SectionPausedRequest } from './SectionPausedRequest';
import { SectionDraftRequest } from './SectionDraftRequest';
import * as OrderStatus from '@constants/order';
import { DragDropContext } from 'react-beautiful-dnd';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_ORDERS_PRIORITY } from '@graphql/mutations/order';

const types = {
    ACTIVE: SectionActiveRequest, // For grouping submitted and ongoing inside queue tab
    [OrderStatus.ORDER_STATUS_ONGOING_PROJECT]: SectionActiveRequest,
    [OrderStatus.ORDER_STATUS_ONGOING_REVISION]: SectionActiveRequest,
    [OrderStatus.ORDER_STATUS_SUBMITTED]: SectionActiveRequest,
    [OrderStatus.ORDER_STATUS_ON_HOLD]: SectionPausedRequest,
    [OrderStatus.ORDER_STATUS_NOT_STARTED]: SectionPausedRequest,
    [OrderStatus.ORDER_STATUS_DELIVERED_PROJECT]: SectionDeliveredRequest,
    [OrderStatus.ORDER_STATUS_DELIVERED_REVISION]: SectionDeliveredRequest,
    [OrderStatus.ORDER_STATUS_COMPLETED]: SectionCompletedRequest,
    [OrderStatus.ORDER_STATUS_DRAFT]: SectionDraftRequest,
};

const StartIndexes = {
    ACTIVE: 0, // For grouping submitted and ongoing inside queue tab
    [OrderStatus.ORDER_STATUS_ONGOING_PROJECT]: 0,
    [OrderStatus.ORDER_STATUS_ONGOING_REVISION]: 0,
    [OrderStatus.ORDER_STATUS_SUBMITTED]: 0,
    [OrderStatus.ORDER_STATUS_ON_HOLD]: 500,
    [OrderStatus.ORDER_STATUS_NOT_STARTED]: 3000,
    [OrderStatus.ORDER_STATUS_DELIVERED_PROJECT]: 1000,
    [OrderStatus.ORDER_STATUS_DELIVERED_REVISION]: 1000,
    [OrderStatus.ORDER_STATUS_COMPLETED]: 1500,
    [OrderStatus.ORDER_STATUS_DRAFT]: 2000,
};

const RenderRequest = ({ type, ...props }) => {
    const Component = types[type] ?? null;
    if (Component !== null) {
        return <Component {...props} />;
    }

    return null;
};

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const SectionRequestRenderer = ({ type, ...props }) => {
    const [updateOrdersPriority] = useMutation(UPDATE_ORDERS_PRIORITY);
    const [data, setData] = useState(() => type === 'ACTIVE' ? orderBy(props.data, ['priority'], ['asc']) : props.data);

    useEffect(() => {
        const sortedData = type === 'ACTIVE' ? orderBy(props.data, ['priority'], ['asc']) : props.data;
        setData(sortedData);
    }, [props.data, type]);

    const handleDragEnd = async result => {
        if (!result.destination) return;
        if (result.destination.index === result.source.index) return;

        const newOrders = reorder(data, result.source.index, result.destination.index);
        setData(newOrders);

        await updateOrdersPriority({
            variables: {
                orders: newOrders.map((order, index) => ({ id: order.id, priority: StartIndexes[type] + index + 1 })),
            },
        });

        if (props.refetch) {
            await props.refetch();
        }
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <RenderRequest {...props} data={data} type={type} />
        </DragDropContext>
    );
};
